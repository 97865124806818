import { OrbitControls, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useEffect, useRef, useState } from "react";
import { useFBX, PositionalAudio, PerspectiveCamera } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

import Model1 from "./GlobalComponents/Model1.tsx";
import Model2 from "./GlobalComponents/Model2.tsx";
import ParcelasBien from "./GlobalComponents/ParcelasBien.tsx";
import Lava from "./GlobalComponents/Lava.tsx";
import Water from "./GlobalComponents/Water.tsx";

import VolcanicIsland from "./GlobalComponents/GlobalAssets/VolcanicIsland.tsx";
import JungleIsland from "./GlobalComponents/GlobalAssets/JungleIsland.tsx";
import SwampIsland from "./GlobalComponents/GlobalAssets/SwampIsland.tsx";
import GrassLandIsland from "./GlobalComponents/GlobalAssets/GrassLandIsland.tsx";
import DesertMainland from "./GlobalComponents/GlobalAssets/DesertMainland.tsx";
import SavanahMainland from "./GlobalComponents/GlobalAssets/SavanahMainland.tsx";
import BeachMainland from "./GlobalComponents/GlobalAssets/BeachMainland.tsx";
import GrassLandMainland from "./GlobalComponents/GlobalAssets/GrassLandMainland.tsx";
import MountainMainland from "./GlobalComponents/GlobalAssets/MountainMainland.tsx";
import SnowMainland from "./GlobalComponents/GlobalAssets/SnowMainland.tsx";
import TradeRoutes from "./GlobalComponents/TradeRoutes.tsx";

const GlobalMap = ({ ...props }) => {
  const [mode, setMode] = useState(false);
  const controlsRef = useRef<any>(null);

  const bakedAssetsTexture1 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-new2.png"
  );
  const bakedAssetsTexture2 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-specular.png"
  );
  var bakedAssetsTexture3 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-emission.png"
  );
  bakedAssetsTexture1.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTexture1.flipY = false;
  bakedAssetsTexture2.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTexture2.flipY = false;
  bakedAssetsTexture3.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTexture3.flipY = false;

  if (props.funkyMode) {
    bakedAssetsTexture3 = bakedAssetsTexture1;
  }

  const bakedMaterial1 = new THREE.MeshPhongMaterial({
    map: bakedAssetsTexture1,
    emissiveMap: bakedAssetsTexture3,
    emissive: new THREE.Color(0xff0000),
    specularMap: bakedAssetsTexture2,
    emissiveIntensity: 10,
    specular: 0x666666,
    fog: true,
  });

  // const bakedMaterial1 = new THREE.MeshBasicMaterial({
  //   color: 0xff0000,
  //   wireframe: true,
  // });

  useFrame(() => {
    // ORBIT CONTROLS
    // console.log(controlsRef.current);
    const vectorPosition = controlsRef.current.target;
    if (vectorPosition.x > 40) {
      vectorPosition.x = 40;
    } else if (vectorPosition.x < -50) {
      vectorPosition.x = -50;
    }

    if (vectorPosition.z > 40) {
      vectorPosition.z = 40;
    } else if (vectorPosition.z < -40) {
      vectorPosition.z = -40;
    }
  });

  // const AmogusFBX = () => {
  //   const amogusRef = useRef<THREE.Mesh>(null!);
  //   let fbx = useFBX("among us.FBX");
  //   const fbxMesh = fbx.children[0] as THREE.Mesh;
  //   const susTexture = useTexture("Plastic_4K_Diffuse.jpg");
  //   susTexture.colorSpace = THREE.SRGBColorSpace;
  //   susTexture.flipY = true;
  //   const susReflectivity = useTexture("Plastic_4K_Reflect.jpg"); //when the reflectivity is sus 0_0  <---- me being mega silly
  //   susReflectivity.flipY = true;
  //   const susMaterial = new THREE.MeshPhongMaterial({
  //     map: susTexture,
  //     specularMap: susReflectivity,
  //     specular: 0xaaaaff,
  //   });
  //   fbxMesh.material = susMaterial;
  //   useFrame(({ clock }) => {
  //     if (amogusRef.current !== null) {
  //       // amogusRef.current.rotation.y = mode ? clock.getElapsedTime()*(Math.random()/100+1) : clock.getElapsedTime()
  //       amogusRef.current.rotation.y = mode
  //         ? clock.getElapsedTime()
  //         : (2 * Math.PI) / 3;
  //       amogusRef.current.position.y = mode
  //         ? 5
  //         : 3.9 + 0.05 * Math.sin(clock.getElapsedTime() * 2);
  //     }
  //   });
  //   return (
  //     <group>
  //       <primitive
  //         ref={amogusRef}
  //         object={fbx}
  //         scale={mode ? 0.01 : 0.001}
  //         position={mode ? [-26, 5, -15] : [-26, 3.9, -15]}
  //         rotation={[0, Math.PI, 0]}
  //         onClick={() => {
  //           setMode(!mode);
  //         }}
  //       />
  //       {mode && (
  //         <PositionalAudio
  //           url="./FreeBird.m4a"
  //           distance={1}
  //           position={[0, 0, 0]}
  //           loop
  //           autoplay
  //           {...props} // All THREE.PositionalAudio props are valid
  //         />
  //       )}
  //     </group>
  //   );
  // };

  //Cant use fog in global map because water isn't affected by it

  // var fogArgs = null

  // if(props.classCO2.CO2_total<=210*props.classCO2.totalStudents){
  //     fogArgs = ['#ffffff', 1, 200]
  // } else if(props.classCO2.CO2_total<=600*props.classCO2.totalStudents){
  //     fogArgs = ['#a6a6a6', 1, 40]
  // } else if(props.classCO2.CO2_total<=1000*props.classCO2.totalStudents){
  //     fogArgs = ['#a6a6a6', 0, 30]
  // } else {
  //     fogArgs = ['#949494', 0, 24]
  // }

  return (
    <>
      {/* <fog attach="fog" args={fogArgs} />
        <color attach="background" args={['#ffffff']} /> */}
      <OrbitControls
        ref={controlsRef}
        target={[5, 0, 5]}
        panSpeed={1}
        enableDamping={false}
        dampingFactor={0}
        minDistance={9}
        maxDistance={props.maxDist}
        minPolarAngle={Math.PI / 4}
        maxPolarAngle={Math.PI / 4}
        screenSpacePanning={false}
        mouseButtons-LEFT={THREE.MOUSE.PAN}
        mouseButtons-RIGHT={THREE.MOUSE.ROTATE}
        touches-ONE={THREE.TOUCH.PAN}
        keys={{
          LEFT: "ArrowLeft", //left arrow
          UP: "ArrowUp", // up arrow
          RIGHT: "ArrowRight", // right arrow
          BOTTOM: "ArrowDown", // down arrow
        }}
      />
      <pointLight
        castShadow
        position={[35, 55, 30]}
        intensity={5}
        decay={0}
        shadow-normalBias={0.04}
        color={0xfafafa}
        shadow-mapSize={[2048, 2048]}
      />
      <ambientLight color={0xe9e9e9} intensity={0.4} />

      <Model1 renderShadow={() => props.renderShadow()} />
      <Model2 renderShadow={() => props.renderShadow()} />
      <ParcelasBien
        toggleMap={(studentId: number, plotId: number) =>
          props.toggleMap(studentId, plotId)
        }
        renderShadow={() => props.renderShadow()}
        renderAnimation={() => props.renderAnimation()}
      />
      <Lava renderShadow={() => props.renderShadow()} />
      {props.renderShadow() && (
        <Water renderShadow={() => props.renderShadow()} />
      )}
      {!props.renderShadow() && (
        <mesh rotation={[-Math.PI * 0.5, 0, 0]} position-y={-0.15} {...props}>
          <planeGeometry args={[200, 200, 512, 512]} />
          <meshBasicMaterial color={0x0295d4} />
        </mesh>
      )}
      {/* Assets Global Map */}
      <VolcanicIsland
        renderShadow={() => props.renderShadow()}
        bakedMaterial1={bakedMaterial1}
      />
      <JungleIsland
        renderShadow={() => props.renderShadow()}
        bakedMaterial1={bakedMaterial1}
      />
      <SwampIsland
        renderShadow={() => props.renderShadow()}
        bakedMaterial1={bakedMaterial1}
      />
      <GrassLandIsland
        renderShadow={() => props.renderShadow()}
        bakedMaterial1={bakedMaterial1}
      />
      <DesertMainland
        renderShadow={() => props.renderShadow()}
        bakedMaterial1={bakedMaterial1}
      />
      <SavanahMainland
        renderShadow={() => props.renderShadow()}
        bakedMaterial1={bakedMaterial1}
      />
      <BeachMainland
        renderShadow={() => props.renderShadow()}
        bakedMaterial1={bakedMaterial1}
      />
      <GrassLandMainland
        renderShadow={() => props.renderShadow()}
        bakedMaterial1={bakedMaterial1}
      />
      <MountainMainland
        renderShadow={() => props.renderShadow()}
        bakedMaterial1={bakedMaterial1}
      />
      <SnowMainland
        renderShadow={() => props.renderShadow()}
        bakedMaterial1={bakedMaterial1}
      />
      {/* <TradeRoutes /> */}
    </>
  );
};

export default GlobalMap;
