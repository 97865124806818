
const SchoolIcon = (
  props: {
    color?: string;
    size?: number; // Adding size as an optional number prop
  } = {
      color: "white",
      size: 21, // Default size set to 30
    }
) => {
  const { color, size } = props; // Destructuring props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.95 8.4H15.75V6.3L11.025 4.41V2.1H13.65V0H9.975V4.41L5.25 6.3V8.4H1.05C0.4725 8.4 0 8.8725 0 9.45V21H8.4V15.75H12.6V21H21V9.45C21 8.8725 20.5275 8.4 19.95 8.4ZM6.3 18.9H2.1V15.75H6.3V18.9ZM6.3 13.65H2.1V10.5H6.3V13.65ZM10.5 6.3C11.0775 6.3 11.55 6.7725 11.55 7.35C11.55 7.9275 11.0775 8.4 10.5 8.4C9.9225 8.4 9.45 7.9275 9.45 7.35C9.45 6.7725 9.9225 6.3 10.5 6.3ZM12.6 13.65H8.4V10.5H12.6V13.65ZM18.9 18.9H14.7V15.75H18.9V18.9ZM18.9 13.65H14.7V10.5H18.9V13.65Z" fill={color} />
    </svg>
  );
};

export default SchoolIcon;
