import "./ProfessorEvaluationMenu.css";
import { useAppSelector } from "../../../hooks/hooks";
import ProfessorEvaluationStudentList from "./evaluation_submenus/ProfessorEvaluationStudentList";
import ProfessorEvaluationStudentInfo from "./evaluation_submenus/ProfessorEvaluationStudentInfo";

const ProfessorEvaluationMenu = () => {

  const evaluationMenu = useAppSelector(
    (state) => state.teacher.evaluationMenu
  );

  return (
    <div className="professor_evaluation_menu">
      {(evaluationMenu === "studentList" && (
        <ProfessorEvaluationStudentList />
      )) || <ProfessorEvaluationStudentInfo />}
    </div>
  );
};

export default ProfessorEvaluationMenu;
