import env from "../../config/env.json";
import APIFetch from "../../utils/APIFetch";
import { HouseUpgradeType } from "../gamecontent/house_upgrades";

export interface StudentHouseType {
    id: string,
    level: number,
    population: number,
    used_power: number,
    house_upgrade_id: string,
    student_id: string,
}

export interface StudentHouseAndUpgradeType {
    id: string,
    level: number,
    population: number,
    used_power: number,
    house_upgrade_id: string,
    student_id: string,
    house_upgrade: HouseUpgradeType,
}

export const SetStudentHouseAPI = async (house_upgrade_id: string): Promise<StudentHouseType> => {
    // var req = await fetch(env.backend_host + "/me/student_house/", {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "POST",
    //     "mode": "cors",
    //     "body": JSON.stringify({
    //         house_upgrade_id: house_upgrade_id
    //     }),
    //     "credentials": "include"
    // });
    // var data = await req.json();
    const data = APIFetch(env.backend_host + "/me/student_house/", {
        method: "POST",
        body: JSON.stringify({
            house_upgrade_id: house_upgrade_id
        }),
    });
    return data;
}

export const UpdateStudentHouseAPI = async (student_house_id: string): Promise<StudentHouseType> => {
    // var req = await fetch(env.backend_host + "/me/student_house/" + student_house_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "POST",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();
    const data = APIFetch(env.backend_host + "/me/student_house/" + student_house_id, {
        method: "POST"
    });
    return data;
}

export const GetStudentHouseAllAPI = async (student_id: string): Promise<StudentHouseAndUpgradeType[]> => {
    // var req = await fetch(env.backend_host + "/student_house/" + student_id + "/all", {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();
    var data = APIFetch(env.backend_host + "/student_house/" + student_id + "/all", {
        method: "GET"
    });

    data = data || null
    return data;
}