import { useEffect } from "react";
import ReactPlayer from "react-player";
import { useAppDispatch } from "../../hooks/hooks";
import { volumeMusic, volumeSounds } from "../../reducers/mainSlice";

const VideoPlayer = ({ ...props }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(volumeMusic(0));
    dispatch(volumeSounds(0));
  }, []);

  return (
    <div className="foreground" style={{ background: "black" }}>
      <ReactPlayer
        width={"100%"}
        height={"100%"}
        url="https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Animaci%C3%B3n+-+Cinem%C3%A1tica+incial.mp4"
        onEnded={() => props.backHandler()}
        playing={true}
        controls={props.controls}
      />
    </div>
  );
};

export default VideoPlayer;
