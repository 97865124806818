import { motion } from "framer-motion";
import { ExerciseType, ScenarioType } from "../../../_newapios/content/unit";
import {
  ExperimentProgressType,
  ResearchProgressType,
} from "../../../_newapios/progress/student_scenario";
import { BundleType, ModifiedBundleType } from "../../../_newapios/user/class";
import BackArrow from "../../../assets/exercises/back_arrow";
import Calendar from "../../../assets/student/calendar";
import i18n from "../../../i18n";
import Time from "../../../assets/student/time";
import { t } from "i18next";
import {
  getExerciseProgressPercentage,
  getExerciseProgressPoints,
} from "../../../utils/progress";
import { useEffect, useState } from "react";
import {
  GetScenarioAPI,
  ModifiedScenarioType,
} from "../../../_newapios/content/scenario";
import {
  GetResearchAPI,
  ResearchType,
} from "../../../_newapios/content/research.ts";
import {
  GetExperimentAPI,
  ExperimentType,
} from "../../../_newapios/content/experiment.ts";
import {
  changeX,
  setExperiment,
  setResearch,
  setSelectedCourseId,
} from "../../../reducers/mainSlice.ts";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { CreateResearchProgressAPI } from "../../../_newapios/progress/student_research.ts";

const ScenarioMenu = (props: {
  bundle: ModifiedBundleType;
  scenario_id: string;
  researchProgress: ResearchProgressType | null;
  experimentProgress: ExperimentProgressType | null;
  updateProgress: () => Promise<void>;
  color: string | undefined;
  closeSituationMenu: () => void;
}) => {
  const dispatch = useAppDispatch();
  const studentState = useAppSelector((state) => state.student);
  const scenario_id = props.scenario_id as string;

  useEffect(() => {
    GetScenarioAPI(scenario_id).then((scenario) => {
      setScenario(scenario);
    });
  }, [scenario_id]);

  useEffect(() => {
    props.updateProgress(); //...
  }, [studentState.updateProgress]);

  const [scenario, setScenario] = useState<ModifiedScenarioType | null>(null);
  const [localResearch, setLocalResearch] = useState<ResearchType | null>(null);
  const [localExperiment, setLocalExperiment] = useState<ExperimentType | null>(
    null
  );

  useEffect(() => {
    if (scenario === null) return;
    GetResearchAPI(scenario.research.id).then((research) => {
      setLocalResearch(research);
    });
    GetExperimentAPI(scenario.experiment.id).then((experiment) => {
      setLocalExperiment(experiment);
    });
  }, [scenario]);

  const openResearchHandler = async () => {
    let research = localResearch;
    if (research === null || research.research_progress === undefined)
      return console.error("Research not found");
    if (bundle.scenario === undefined)
      return console.error("Scenario not found");
    if (research.research_progress.length === 0) {
      const researchProgress = await CreateResearchProgressAPI(scenario_id);
      research = { ...research, research_progress: [researchProgress] };
      dispatch(setResearch(research));
      dispatch(setSelectedCourseId(bundle.scenario.course_id));
    } else {
      dispatch(setResearch(research));
      dispatch(setSelectedCourseId(bundle.scenario.course_id));
    }
  };

  const openExperimentHandler = async () => {
    dispatch(changeX(Math.random()));
    const experiment = localExperiment;
    if (experiment === null) return console.error("Experiment not found");
    if (bundle.scenario === undefined)
      return console.error("Scenario not found");
    // Change X?
    dispatch(setExperiment(experiment));
    dispatch(setSelectedCourseId(bundle.scenario.course_id));
  };

  if (scenario === null || localResearch === null) return "Loading...";

  const bundle = props.bundle;
  const experimentProgress = props.experimentProgress;
  const researchProgress = props.researchProgress;
  const color = props.color;
  const closeSituationMenu = props.closeSituationMenu;

  const pelotas = getExerciseProgressPoints(
    experimentProgress?.exercise_progress
  );
  const experimentCompleted = experimentProgress !== null;

  const picture_subtopic =
    scenario.banner.url ||
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Asset+30%40300x12.svg";
  const description = scenario.description;
  const mainKnowHow = scenario.main_know_how || "";

  const researchProgressPercentage = getExerciseProgressPercentage(
    researchProgress,
    localResearch.exercises
  );

  return (
    <div className="campus_exercise_background">
      <div className="campus_exercise">
        <div className="campus_exercise_top">
          <img className="banner" src={picture_subtopic} alt="banner" />
        </div>
        <div className="campus_exercise_bottom">
          <div
            className="campus_exercise_bottom_title"
            style={{ color: color }}
          >
            <motion.div
              className="left_container"
              //style={{display: "flex"}}
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              whileHover={{
                scale: 1.05,
                textShadow: color + " 1px 0px 10px",
              }}
              transition={{ type: "tween", ease: "easeInOut", duration: 0.1 }}
            >
              <div
                onClick={() => {
                  closeSituationMenu();
                }}
              >
                <BackArrow color={color} size={"50"} />
              </div>
              <div
                className="text_black_medium_bold"
                style={{
                  color: color,
                  fontWeight: "bolder",
                }}
                onClick={() => {
                  closeSituationMenu();
                }}
              >
                {scenario.name}
              </div>
            </motion.div>
            <div className="center_container">
              <div className="text_black_small" style={{ color: color }}>
                {" | "}
              </div>
            </div>
            <div className="left_container">
              <div className="text_black_small" style={{ color: color }}>
                {mainKnowHow}
              </div>
            </div>
          </div>
          <div className="top_left_container">
            <div className="text_black_small">{description}</div>
          </div>
          <div className="campus_exercise_bottom_stuff">
            <div className="campus_exercise_bottom_stuff_dates">
              <div className="campus_exercise_bottom_stuff_dates_time">
                <div className="center_container">
                  <Calendar color={color} />
                </div>
                <div className="left_container">
                  <div
                    className="text_black_very_small"
                    style={{
                      textDecoration: "underline solid " + color + " 3px",
                    }}
                  >
                    {t("posted_at") + ": "}
                    <b>
                      {new Date(bundle.start_date).toLocaleDateString(
                        i18n.language,
                        {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }
                      )}
                    </b>
                  </div>
                </div>
              </div>
              <div className="campus_exercise_bottom_stuff_dates_time">
                <div className="center_container">
                  <Time color={color} />
                </div>
                <div className="left_container">
                  <div
                    className="text_black_very_small"
                    style={{
                      textDecoration: "underline solid " + color + " 3px",
                    }}
                  >
                    {t("due_date") + ": "}
                    <b>
                      {new Date(bundle.end_date).toLocaleDateString(
                        i18n.language,
                        {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }
                      )}
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div className="campus_exercise_research">
              <div className="center_container">
                <div className="text_black_very_small_bold">
                  {t("research")}
                </div>
              </div>
              <div className="center_container">
                <motion.div
                  className="campus_exercise_research_button"
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "0px 0px 10px 1px " + color,
                  }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  style={{
                    backgroundColor: color,
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={() => openResearchHandler()}
                >
                  <div className="center_container">
                    <div className="text_white_very_small_bold">
                      {researchProgressPercentage === 100
                        ? t("review")
                        : researchProgressPercentage > 0
                        ? t("continue")
                        : t("start")}
                    </div>
                  </div>
                </motion.div>
              </div>
              <div className="campus_exercise_research_bar_container">
                <div className="campus_exercise_research_bar_line" />
                <div
                  className="campus_exercise_research_bar_progress"
                  style={{
                    backgroundColor: color,
                    width: researchProgressPercentage + "%",
                  }}
                />
              </div>
            </div>
            <div className="campus_exercise_experiment">
              <div className="center_container">
                <div className="text_black_very_small_bold">
                  {t("experiment")}
                </div>
              </div>
              <div className="center_container">
                <motion.div
                  className="campus_exercise_research_button"
                  whileHover={
                    researchProgressPercentage !== 100
                      ? {}
                      : {
                          scale: 1.1,
                          boxShadow: "0px 0px 10px 1px " + color,
                        }
                  }
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  style={{
                    backgroundColor:
                      researchProgressPercentage !== 100 ? "#D9D9D9" : color,
                    cursor:
                      researchProgressPercentage !== 100
                        ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                        : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={
                    researchProgressPercentage !== 100
                      ? () => {}
                      : () => openExperimentHandler()
                  }
                >
                  <div className="center_container">
                    <div className="text_white_very_small_bold">
                      {researchProgressPercentage !== 100
                        ? t("blocked")
                        : experimentCompleted
                        ? t("retry")
                        : t("start")}
                    </div>
                  </div>
                </motion.div>
              </div>
              <div className="campus_exercise_experiment_progress">
                {[1, 1, 1, 1].map((_, index) => {
                  if (experimentProgress?.exercise_progress[index]?.correct) {
                    return (
                      <div
                        className="campus_exercise_experiment_progress_bar"
                        style={{
                          marginRight: index < 3 ? "5%" : "0",
                          left: index * 25 + "%",
                          display: "inline-block",
                          backgroundColor: color,
                        }}
                      ></div>
                    );
                  }
                  return (
                    <div
                      className="campus_exercise_experiment_progress_bar"
                      style={{
                        marginRight: index < 3 ? "5%" : "0",
                        left: index * 25 + "%",
                        display: "inline-block",
                        backgroundColor: "#D9D9D9",
                      }}
                    ></div>
                  );
                })}
              </div>
            </div>
            <div className="campus_exercise_bottom_stuff_pilotes center_container text_black_very_small">
              <span></span>
              <div>
                {[1, 1, 1, 1].map((_, index) => {
                  if (index < pelotas) {
                    return (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: 0.3,
                          ease: [0, 0.71, 0.2, 1.01],
                          scale: {
                            type: "spring",
                            damping: 5,
                            stiffness: 100,
                            restDelta: 0.001,
                          },
                          delay: index * 0.6 + 0.25,
                        }}
                        style={{
                          borderRadius: "1em",
                          marginRight: index < 3 ? "1.5em" : "0",
                          display: "inline-block",
                          height: "2em",
                          width: "2em",
                          backgroundColor: color,
                        }}
                      />
                    );
                  }
                  return (
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        delay: index * 0.6 + 0.25,
                        duration: 1,
                      }}
                      style={{
                        borderRadius: "1em",
                        marginRight: index < 3 ? "1.5em" : "0",
                        display: "inline-block",
                        height: "2em",
                        width: "2em",
                        backgroundColor: "#CCC", // TODO - fix this color
                      }}
                    />
                  );
                })}
              </div>
              <div>
                {t("space_achieved")}
                <b>{pelotas + "/4"}</b>
                {t("space_points")}
              </div>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScenarioMenu;
