const BackArrow = ({ ...props }) => {
  return (
    <svg
      width={props.size !== undefined ? props.size : "70"}
      height={props.size !== undefined ? props.size : "70"}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chevron-left">
        <g id="arrow-down">
          <path
            id="Path 3"
            d="M40.8333 17.5L23.3333 35L40.8333 52.5"
            stroke={props.color}
            strokeWidth="5"
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default BackArrow;
