import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces";

const BiosphereJungle = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_jungla_op.glb"
  ) as DreiGLTF;
  return (
    <group {...props} dispose={null}>
      <group
        position={[17.994, 0.651, 20.192]}
        rotation={[0, -1.244, 0]}
        scale={0.177}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_jungla_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_jungla_2.geometry}
          material={materials.gris_puertas}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_jungla_3.geometry}
          material={materials.blanco_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_jungla_4.geometry}
          material={materials.verde_jungla_puertas}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_jungla_5.geometry}
          material={materials.verde_jungla_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_jungla_6.geometry}
          material={materials.verde_jungla_emision}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_jungla_7.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
        />
      </group>
      <group
        position={[18.197, 1.226, 20.123]}
        rotation={[0, -1.244, 0]}
        scale={0.177}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_jungla_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_jungla_2.geometry}
          material={materials.verde_jungla_cristales}
        />
      </group>
      <group position={[18.197, 1.796, 20.123]} scale={0.177}>
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_jungla_1.geometry}
          material={materials.verde_jungla_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_jungla_2.geometry}
          material={materials.logo_bio_jungla}
        />
      </group>
      <group
        position={[12.525, -0.05, 22.031]}
        rotation={[-Math.PI, 1.244, -Math.PI]}
        scale={[0.138, 0.537, 5.467]}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_jungla_1.geometry}
          material={materials.camino_bordes}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_jungla_2.geometry}
          material={materials.verde_jungla_metal}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_jungla_op.glb"
);

export default BiosphereJungle;
