const IconoAsignaturas = ({ ...props }) => {
  return (
    <svg
      width={props.size !== undefined ? props.size : "65"}
      height={props.size !== undefined ? props.size : "65"}
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6791_5377)">
        <mask
          id="mask0_6791_5377"
          style={
            {
              /*"mask-type":"alpha"*/
            }
          }
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="65"
          height="65"
        >
          <path
            d="M0 5C0 2.23858 2.23858 0 5 0H60C62.7614 0 65 2.23858 65 5V60C65 62.7614 62.7614 65 60 65H5C2.23858 65 0 62.7614 0 60V5Z"
            fill="#F0684F"
          />
          <path
            d="M0 5C0 2.23858 2.23858 0 5 0H60C62.7614 0 65 2.23858 65 5V60C65 62.7614 62.7614 65 60 65H5C2.23858 65 0 62.7614 0 60V5Z"
            stroke="white"
          />
        </mask>
        <g mask="url(#mask0_6791_5377)">
          <path
            d="M36 11.5C36 26.1355 24.1355 38 9.5 38C-5.13555 38 -17 26.1355 -17 11.5C-17 -3.13555 -5.13555 -15 9.5 -15C24.1355 -15 36 -3.13555 36 11.5ZM-6.5158 11.5C-6.5158 20.3453 0.654716 27.5158 9.5 27.5158C18.3453 27.5158 25.5158 20.3453 25.5158 11.5C25.5158 2.65472 18.3453 -4.5158 9.5 -4.5158C0.654716 -4.5158 -6.5158 2.65472 -6.5158 11.5Z"
            fill="white"
          />
          <rect
            x="44.75"
            y="26"
            width="35"
            height="35"
            transform="rotate(45 44.75 26)"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6791_5377">
          <rect width="65" height="65" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconoAsignaturas;
