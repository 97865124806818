import env from "../../config/env.json";
import APIFetch from "../../utils/APIFetch";
import { StudentEutonType } from "./student_euton";

export interface StudentExplorerType {
  id: string;
  times_used: number;
  points_used: number;
  explorer_id: string;
  student_id: string;
  student_euton_id: string;
  student_euton: StudentEutonType;
}

export const SetStudentExplorerAPI = async (
  explorer_id: string,
  biome_id: string
): Promise<StudentExplorerType> => {
  // var req = await fetch(
  //   env.backend_host + "/me/student_explorer/" + explorer_id,
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "POST",
  //     body: JSON.stringify({
  //       biome_id: biome_id,
  //     }),
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );
  // var data = await req.json();
  var data = APIFetch(env.backend_host + "/me/student_explorer/" + explorer_id, {
    method: "POST",
    body: JSON.stringify({
      biome_id: biome_id,
    }),
  });
  return data;
};

export const GetStudentExplorerAllAPI = async (
  student_id: string
): Promise<StudentExplorerType[]> => {
  // var req = await fetch(
  //   env.backend_host + "/student_explorer/" + student_id + "/all",
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "GET",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );
  // var data = await req.json();
  var data = APIFetch(env.backend_host + "/student_explorer/" + student_id + "/all", {
    method: "GET",
  });

  data = data || null;
  return data;
};
