import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { DreiGLTF } from "../../../../interfaces";

const Lava = ({ ...props }) => {
  const { nodes } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/lava/GLOBAL_archipielago_lava.gltf"
  ) as DreiGLTF;

  const bakedMaterialLava = new THREE.MeshPhongMaterial({
    color: new THREE.Color(0xff1200),
    emissive: new THREE.Color(0xff1200),
    emissiveIntensity: 1,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Lava.geometry}
        material={bakedMaterialLava}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/lava/GLOBAL_archipielago_lava.gltf"
);

export default Lava;
