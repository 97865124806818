const MejoraCaminos = ({ ...props }) => {
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.Camino_ayuntamiento01.geometry}
        material={props.bakedAssetsMaterial}
        position={[0.45, 1.16, 4.38]}
        scale={[2.84, 0.02, 0.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.Camino_casa01.geometry}
        material={props.bakedAssetsMaterial}
        position={[-13.82, 1.01, 3.05]}
        scale={[0.67, 0.04, 0.54]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.Camino_feria01.geometry}
        material={props.bakedAssetsMaterial}
        position={[-0.18, 0.41, 8.57]}
        scale={[0.31, 0.02, 1.28]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.Camino_inicial01.geometry}
        material={props.bakedAssetsMaterial}
        position={[3.43, 0.53, 1.32]}
        scale={[7.01, 0.02, 0.31]}
      />
    </group>
  );
};

export default MejoraCaminos;
