import { GlobalCompetenceType, ScenarioType, SpecificCompetenceType } from "../_newapios/content/unit.ts"
import { simpleGlobalCompetences } from "../constants.ts"
import _ from "lodash"
import data from "../assets/csvjson.json"

export const specificCompetencesBooleanArray = (specificCompetencesIds: string[], courseSpecificCompetences: SpecificCompetenceType[]): boolean[] => {

    const result = Array(courseSpecificCompetences.length).fill(false)
    specificCompetencesIds.forEach(selectedSpecificCompId => {
        const index = (courseSpecificCompetences.find(specificComp => specificComp.id === selectedSpecificCompId)?.number || 1) - 1
        result[index] = true
    })
    return result
}

export const globalCompetencesBooleanArray = (globalCompetencesIds: string[], globalCompetences: GlobalCompetenceType[]): boolean[] => {

    const result = Array(simpleGlobalCompetences.length).fill(false)
    globalCompetencesIds.forEach(selectedGlobalCompId => {
        const selectedGlobalComp = globalCompetences.find(globalComp => globalComp.id === selectedGlobalCompId)
        const index = simpleGlobalCompetences.findIndex(comp => comp[0] === selectedGlobalComp?.acronym)
        result[index] = true
    })
    return result
}

export const prettifyCourseAcronyms = (uglyAcronym: string | undefined): string => {
    if (!uglyAcronym) return ''
    const prettyAcronym = uglyAcronym.replace(/\_.*/g,"$'") // regex babyyyyy
    return prettyAcronym
}

export const completeCriteriaJSON = () => {
    const courses = ["CM_EP", "CN_EP", "CS_EP",	"Art_EP", "EF_EP", "Val_EP", "LCL_EP", "LEX_EP", "MAT_EP"]
    for (let course of courses) {
        const courseData = data.filter((obj: any) => obj[course] !== "").map((obj: any) => {
            return (course + obj[course].substr(3,3) + "_desc: " + obj[course])
        })
        console.log(courseData)
    }
}