import "./RadarChart.css";
import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RadarChart = ({ ...props }) => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.studentData,
        backgroundColor: "rgb(80, 144, 240, 50%)",
        borderColor: "rgb(80, 144, 240, 100%)",
        borderWidth: 2,
      },
      {
        data: props.schoolData,
        backgroundColor: "rgb(80, 240, 192, 50%)",
        borderColor: "rgb(80, 240, 192, 100%)",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    scales: {
      r: {
        ticks: {
          display: false,
          stepSize: props.max / 4,
        },
        beginAtZero: true,
        min: -props.max / 4,
        max: props.max,
        pointLabels: {
          font: {
            size: props.fontSize
              ? props.fontSize
              : Math.floor(screenSize.dynamicWidth * 0.01),
            family: "Causten",
          },
          color: "black",
        },
        grid: {
          lineWidth: [3, 1, 1, 1, 1],
          color: "#292F4C",
        },
        backgroundColor: "#E2EEFF",
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className={props.full ? "radar_chart_full" : "radar_chart"}>
      <div className="center_container">
        <Radar data={data} options={options} updateMode="none" />
      </div>
    </div>
  );
};

export default RadarChart;
