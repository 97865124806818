import Shuttle from "./T2Buildings/Shuttle/Shuttle.tsx";
import ShuttleProps from "./T2Buildings/Shuttle/ShuttleProps.tsx";
import HutHippie from "./T2Buildings/Huts/HutHippie.tsx";
import HutIndy from "./T2Buildings/Huts/HutIndy.tsx";
import HutOhio from "./T2Buildings/Huts/HutOhio.tsx";
import PathsHuts from "./T2Buildings/Huts/PathsHuts.tsx";
import Bridges from "./T2Buildings/General/Bridges.tsx";
import Paths from "./T2Buildings/General/Paths.tsx";
import Vegetation from "./T2Buildings/General/Vegetation.tsx";
import BiosphereSwamp from "./T2Buildings/Biospheres/BiosphereSwamp.tsx";
import BiosphereVolcano from "./T2Buildings/Biospheres/BiosphereVolcano.tsx";
import BiosphereDesert from "./T2Buildings/Biospheres/BiosphereDesert.tsx";
import BiosphereForest from "./T2Buildings/Biospheres/BiosphereForest.tsx";
import BiosphereJungle from "./T2Buildings/Biospheres/BiosphereJungle.tsx";
import BiosphereMountain from "./T2Buildings/Biospheres/BiosphereMountain.tsx";
import BiosphereBeach from "./T2Buildings/Biospheres/BiosphereBeach.tsx";
import BiosphereSavannah from "./T2Buildings/Biospheres/BiosphereSavannah.tsx";
import BiosphereSnow from "./T2Buildings/Biospheres/BiosphereSnow.tsx";
import FountainBiosphere from "./T2Buildings/Biospheres/FountainBiosphere.tsx";
import { useTranslation } from "react-i18next";
import { Group, MeshPhongMaterial } from "three";

import {
  Text3D,
  Center,
  useCursor,
  Float,
  useGLTF,
  useAnimations,
} from "@react-three/drei";

import {
  Selection,
  Select,
  EffectComposer,
  Outline,
} from "@react-three/postprocessing";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../../../hooks/hooks.ts";
import { DreiGLTF } from "../../../../interfaces.ts";
import { convertToEuler, convertToVector3 } from "../../../../utils/R3F.ts";
import LegendaryPlace from "./T2Buildings/General/LegendaryPlace.tsx";
import BiosphereGrasslandDesert from "./T2Buildings/Biospheres/BiosphereGrasslandDesert.tsx";
import BiosphereGrasslandBeach from "./T2Buildings/Biospheres/BiosphereGrasslandBeach.tsx";
import BiosphereGrasslandForest from "./T2Buildings/Biospheres/BiosphereGrasslandForest.tsx";
import BiosphereGrasslandMountain from "./T2Buildings/Biospheres/BiosphereGrasslandMountain.tsx";
import BiosphereGrasslandJungle from "./T2Buildings/Biospheres/BiosphereGrasslandJungle.tsx";
import BiosphereGrasslandSavannah from "./T2Buildings/Biospheres/BiosphereGrasslandSavannah.tsx";
import BiosphereGrasslandSnow from "./T2Buildings/Biospheres/BiosphereGrasslandSnow.tsx";
import BiosphereGrasslandSwamp from "./T2Buildings/Biospheres/BiosphereGrasslandSwamp.tsx";
import BiosphereGrasslandVolcano from "./T2Buildings/Biospheres/BiosphereGrasslandVolcano.tsx";
import EutonStatic from "../../R3FAnimations/Biospheres/EutonStatic.tsx";
import { EutonType } from "../../../../_newapios/gamecontent/eutons.ts";
import Kowalski from "../../R3FAnimations/LocalMap/Kowalski.tsx";
import EutopoStatic from "../../R3FAnimations/LocalMap/EutopoStatic.tsx";
import EutopoWalking from "../../R3FAnimations/LocalMap/EutopoWalking.tsx";
import TorcasStatic from "../../R3FAnimations/LocalMap/TorcasStatic.tsx";
import TorcasWalking from "../../R3FAnimations/LocalMap/TorcasWalking.tsx";
import SannyStatic from "../../R3FAnimations/LocalMap/SannyStatic.tsx";
import SannyWalking from "../../R3FAnimations/LocalMap/SannyWalking.tsx";
import SkippyStatic from "../../R3FAnimations/LocalMap/SkippyStatic.tsx";
import SkippyWalking from "../../R3FAnimations/LocalMap/SkippyWalking.tsx";
import HihihienaWalking from "../../R3FAnimations/LocalMap/HihihienaWalking.tsx";
import MultiVehicle from "../../R3FAnimations/LocalMap/MultiVehicles.tsx";
import { BiomeNameType } from "../../../../_newapios/gamecontent/plots.ts";
import AmalteaStatic from "../../R3FAnimations/LocalMap/AmalteaStatic.tsx";
import DrInkariusStatic from "../../R3FAnimations/LocalMap/DrInkariusStatic.tsx";

const T2R3F = ({ ...props }) => {
  const { t } = useTranslation();
  const [hovered, hover] = useState(false);
  const [eutons, setEutons] = useState<EutonType[]>(props.eutons);
  const studentState = useAppSelector((state) => state.student);
  const performanceMode = useAppSelector((state) => state.main.performanceMode);
  const theme = studentState.theme;
  const biomeName: BiomeNameType = props.biomeName;
  const tutorialPart = props.tutorial;
  const flechaRef = useRef<Group>(null!);

  const flecha = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/indicador_rombo_global.gltf"
  ) as DreiGLTF;

  const bakedflechas = new MeshPhongMaterial({
    color: 0xfb9e12,
    emissive: 0xfb9e12,
    emissiveIntensity: 0,
    shininess: 0,
    fog: true,
  });

  const bakedGreyMaterial = new MeshPhongMaterial({
    color: 0x9e9e9e,
    emissiveIntensity: 0,
    shininess: 0,
    fog: true,
  });

  const notRenderT2Building = () => {
    return performanceMode && theme?.id !== "theme_id_2";
  };

  useEffect(() => {
    setEutons(props.eutons);
  }, [props.eutons]);

  const { actions } = useAnimations(flecha.animations, flechaRef);

  useEffect(() => {
    if (
      actions["CubeAction.001"] !== null &&
      theme?.id === "theme_id_2" &&
      (tutorialPart === "campus" ||
        tutorialPart === "energy_lab" ||
        tutorialPart === "workshop")
    ) {
      if (props.renderAnimation()) {
        actions["CubeAction.001"].play();
      } else {
        actions["CubeAction.001"].stop();
      }
    }
  }, [props.renderAnimation()]);

  const calcArrowPosition = (part: string) => {
    switch (part) {
      case "campus":
        return [-3.2, 4.63, 4.35];
      case "energy_lab":
        return [-14.88, 5.02, 18.69];
      case "workshop":
        return [-2.993, 7.2, 19.838];
      default:
        return [0, 0, 0];
    }
  };

  const calcArrowClickable = (part: string) => {
    switch (part) {
      case "campus":
        return () => props.openLab();
      case "energy_lab":
        return props.studentId == null && theme?.id !== "theme_id"
          ? () => props.openExplorersHut()
          : () => {};
      case "workshop":
        return props.studentId == null && theme?.id !== "theme_id"
          ? () => props.openWorkshop()
          : () => {};
      default:
        return () => {};
    }
  };

  const arrowPosition = useMemo(
    () => calcArrowPosition(tutorialPart),
    [tutorialPart]
  );

  const renderArrow = useMemo(() => {
    return (
      <Float
        rotationIntensity={0}
        speed={props.renderAnimation() ? 5 : 0}
        floatingRange={[-0.5, 0.5]}
      >
        <group ref={flechaRef} {...props} dispose={null}>
          <group name="Scene">
            <mesh
              name="Cube"
              castShadow={props.renderShadow()}
              receiveShadow={props.renderShadow()}
              geometry={flecha.nodes.Cube.geometry}
              material={bakedflechas}
              position={convertToVector3(arrowPosition)}
              scale={[0.33, 0.45, 0.33]}
              onClick={calcArrowClickable(tutorialPart)}
            />
          </group>
        </group>
      </Float>
    );
  }, [props.renderAnimation, props.renderShadow]);

  const renderEutons = () => {
    return (
      <>
        {eutons.map((euton, index) => {
          let position: number[];
          let rotation: number[];
          switch (euton.tier) {
            case 1:
              switch (euton.name) {
                case "Serket":
                  position = [1.2, 0.67, 20.5];
                  break;
                case "ChapChap":
                  position = [1.2, 0.4, 20.5];
                  break;
                case "Eutopo":
                  position = [1.2, 0, 20.5];
                  break;
                case "Pukupuku":
                  position = [1.2, 0.8, 20.5];
                  break;
                case "Sorpis":
                  position = [1.2, 0.7, 20.5];
                  break;
                case "Hihihiena":
                  position = [1.2, 0.8, 20.5];
                  break;
                case "Icyx":
                  position = [1.2, 0.7, 20.5];
                  break;
                case "Toadog":
                  position = [1.2, 0.75, 20.5];
                  break;
                case "Volcánula":
                  position = [1.2, 0.8, 20.5];
                  break;
                default:
                  position = [1.2, 1, 20.5];
                  break;
              }
              rotation = [0, Math.PI / 4, 0];
              break;
            case 2:
              switch (euton.name) {
                case "Gaara":
                  position = [-12.7, 1.15, 26.3];
                  break;
                case "Randall":
                  position = [-12.7, 1.7, 26.3];
                  break;
                case "TicTac":
                  position = [-12.7, 1.07, 26.3];
                  break;
                case "Mochimp":
                  position = [-12.7, 0.95, 26.3];
                  break;
                case "Torcas":
                  position = [-12.7, 0.4, 26.3];
                  break;
                case "Iris":
                  position = [-11, 0.2, 25.7];
                  break;
                case "Kowalski":
                  position = [-12.7, 0.4, 26.3];
                  break;
                case "Goostavo":
                  position = [-12.7, 1.2, 26.3];
                  break;
                case "Magecko":
                  position = [-12.7, 0.55, 26.3];
                  break;
                default:
                  position = [-12.7, 0.45, 26.3];
                  break;
              }
              rotation = [0, (Math.PI * 5) / 8, 0];
              break;
            case 3:
              switch (euton.name) {
                case "Dr. Inkarius":
                  position = [5.7, 0.36, 30.8];
                  break;
                case "Ronoa":
                  position = [5.7, 0.72, 30.8];
                  break;
                case "Echo":
                  position = [5.6, 0.5, 30.8];
                  break;
                case "Galo Gonçalo":
                  position = [5.7, 0.23, 30.3];
                  break;
                case "Serpegia":
                  position = [6, 0.68, 30.8];
                  break;
                case "Amaltea":
                  position = [5.5, 0.37, 31.3];
                  break;
                case "Chillmandril":
                  position = [5.3, 0.48, 31.3];
                  break;
                case "Gatsby":
                  position = [5.5, -0.05, 30.8];
                  break;
                case "Chymaina":
                  position = [5.3, 0.775, 31.3];
                  break;
                case "Dante":
                  position = [5.3, 0.41, 32];
                  break;
                default:
                  position = [5.3, 0.8, 31.3];
                  break;
              }
              rotation = [0, (Math.PI * 8) / 8, 0];
              break;

            default:
              position = [-1.2, 1, 22];
              rotation = [0, Math.PI, 0];
              break;
          }
          if (euton.name === "Kowalski") {
            return (
              <Kowalski
                groupProps={{
                  position: convertToVector3(position),
                  rotation: convertToEuler(rotation),
                }}
                static={true}
                key={euton.id}
                asset={euton.asset[0]}
                renderShadow={() => props.renderShadow()}
              />
            );
          }
          const asset =
            euton.asset.length > 1
              ? euton.asset.find((a) => a.gltf.url.endsWith("static_op.glb"))
              : euton.asset[0];
          if (!asset) {
            console.warn("Asset not found for Euton:", euton);
            return null;
          }
          if (euton.name === "Eutopo") {
            return (
              <EutopoStatic
                key={euton.id}
                groupProps={{
                  position: convertToVector3(position),
                  rotation: convertToEuler(rotation),
                }}
                propsAsset={asset}
                renderShadow={() => props.renderShadow()}
              />
            );
          }
          if (euton.name === "Torcas") {
            return (
              <TorcasStatic
                key={euton.id}
                groupProps={{
                  position: convertToVector3(position),
                  rotation: convertToEuler(rotation),
                }}
                propsAsset={asset}
                renderShadow={() => props.renderShadow()}
              />
            );
          }
          if (euton.name === "Sanny") {
            return (
              <SannyStatic
                key={euton.id}
                groupProps={{
                  position: convertToVector3(position),
                  rotation: convertToEuler(rotation),
                }}
                propsAsset={asset}
                renderShadow={() => props.renderShadow()}
              />
            );
          }
          if (euton.name === "Skippy") {
            const assetSkippy =
              euton.asset.length > 1
                ? euton.asset.find((a) => a.gltf.url.endsWith("walking_op.glb"))
                : euton.asset[0];
            if (!assetSkippy) {
              console.warn("Asset not found for Euton:", euton);
              return null;
            }
            return (
              <SkippyWalking
                key={euton.id}
                groupProps={{
                  position: convertToVector3([-8, -1, 8]),
                  rotation: convertToEuler([0, 0, 0]),
                }}
                propsAsset={assetSkippy}
                renderShadow={() => props.renderShadow()}
              />
            );
          }
          if (euton.name === "Amaltea") {
            return (
              <AmalteaStatic
                key={euton.id}
                groupProps={{
                  position: convertToVector3(position),
                  rotation: convertToEuler(rotation),
                }}
                propsAsset={asset}
                renderShadow={() => props.renderShadow()}
              />
            );
          }
          if (euton.name === "Dr. Inkarius") {
            return (
              <DrInkariusStatic
                key={euton.id}
                groupProps={{
                  position: convertToVector3(position),
                  rotation: convertToEuler(rotation),
                }}
                propsAsset={asset}
                renderShadow={() => props.renderShadow()}
              />
            );
          }
          // if (euton.name === "Hihihiena") {
          //   return (
          //     <HihihienaWalking
          //       key={euton.id}
          //       groupProps={{
          //         position: convertToVector3(position),
          //         rotation: convertToEuler(rotation),
          //       }}
          //       propsAsset={asset}
          //       renderShadow={() => props.renderShadow()}
          //     />
          //   );
          // }
          return (
            <EutonStatic
              key={euton.id}
              groupProps={{
                position: convertToVector3(position),
                rotation: convertToEuler(rotation),
              }}
              propsAsset={asset}
              renderShadow={() => props.renderShadow()}
            />
          );
        })}
      </>
    );
  };

  useCursor(
    hovered,
    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
    'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
  );
  return (
    <>
      {/* Lanzadera */}
      {theme?.id === "theme_id_2" &&
        (tutorialPart === "campus" ||
          tutorialPart === "energy_lab" ||
          tutorialPart === "workshop") &&
        renderArrow}
      {!notRenderT2Building() && (
        <Shuttle
          onPointerOver={() => {
            if (theme?.id !== "theme_id") {
              hover(true);
            }
          }}
          bakedGreyMaterial={bakedGreyMaterial}
          onPointerOut={() => hover(false)}
          onClick={
            props.studentId == null && theme?.id !== "theme_id"
              ? () => props.openShuttle()
              : () => {}
          }
          renderAnimation={() => props.renderAnimation()}
          renderShadow={() => props.renderShadow()}
          tutorial={props.tutorial}
        />
      )}
      {!notRenderT2Building() && (
        <ShuttleProps
          bakedGreyMaterial={bakedGreyMaterial}
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          biomeName={props.biomeName}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Cabanhas */}

      {!notRenderT2Building() && theme?.id !== "theme_id" && (
        <>
          <Center
            position={[-14.88, 4.02, 18.69]}
            rotation={[-0.03, 1.8, -0.03]}
          >
            <Text3D
              font="./Causten Bold_Regular.json"
              size={0.5}
              height={0.01}
              curveSegments={12}
              bevelEnabled
              bevelThickness={0.02}
              bevelSize={0.02}
              bevelOffset={0}
              bevelSegments={5}
            >
              {t("explorers_hut")}
              <meshStandardMaterial
                color={tutorialPart === "energy_lab" ? "#fb9e12" : "#4da7FF"}
              />
            </Text3D>
          </Center>
        </>
      )}
      {!notRenderT2Building() && (
        <group
          onClick={() => {
            if (props.studentId == null && theme?.id !== "theme_id") {
              props.openExplorersHut();
            } else {
            }
          }}
          onPointerOver={() => {
            if (theme?.id !== "theme_id") {
              hover(true);
            }
          }}
          onPointerOut={() => hover(false)}
        >
          <HutHippie
            bakedGreyMaterial={bakedGreyMaterial}
            renderShadow={() => props.renderShadow()}
          />
          <HutIndy
            bakedGreyMaterial={bakedGreyMaterial}
            renderAnimation={() => props.renderAnimation()}
            renderShadow={() => props.renderShadow()}
          />
          <HutOhio
            bakedGreyMaterial={bakedGreyMaterial}
            renderAnimation={() => props.renderAnimation()}
            renderShadow={() => props.renderShadow()}
          />
        </group>
      )}
      {!notRenderT2Building() && (
        <PathsHuts
          bakedGreyMaterial={bakedGreyMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {/* General */}
      {!notRenderT2Building() && (
        <Bridges
          bakedGreyMaterial={bakedGreyMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && (
        <Paths
          bakedGreyMaterial={bakedGreyMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && (
        <Vegetation
          bakedAssetsMaterial={props.bakedAssetsMaterial}
          biomeName={props.biomeName}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && (
        <LegendaryPlace
          bakedGreyMaterial={bakedGreyMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && renderEutons()}
      {/* Biospheres */}
      {!notRenderT2Building() && biomeName === "beach" && (
        <BiosphereGrasslandBeach renderShadow={() => props.renderShadow()} />
      )}
      {!notRenderT2Building() && biomeName !== "beach" && (
        <BiosphereBeach
          openBiosphere={props.openBiosphere}
          bakedGreyMaterial={bakedGreyMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && biomeName === "desert" && (
        <BiosphereGrasslandDesert renderShadow={() => props.renderShadow()} />
      )}
      {!notRenderT2Building() && biomeName !== "desert" && (
        <BiosphereDesert
          openBiosphere={props.openBiosphere}
          bakedGreyMaterial={bakedGreyMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && biomeName === "forest" && (
        <BiosphereGrasslandForest renderShadow={() => props.renderShadow()} />
      )}
      {!notRenderT2Building() && biomeName !== "forest" && (
        <BiosphereForest
          openBiosphere={props.openBiosphere}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && biomeName === "jungle" && (
        <BiosphereGrasslandJungle renderShadow={() => props.renderShadow()} />
      )}
      {!notRenderT2Building() && biomeName !== "jungle" && (
        <BiosphereJungle
          openBiosphere={props.openBiosphere}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && biomeName === "mountain" && (
        <BiosphereGrasslandMountain renderShadow={() => props.renderShadow()} />
      )}
      {!notRenderT2Building() && biomeName !== "mountain" && (
        <BiosphereMountain
          openBiosphere={props.openBiosphere}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && biomeName === "savannah" && (
        <BiosphereGrasslandSavannah renderShadow={() => props.renderShadow()} />
      )}
      {!notRenderT2Building() && biomeName !== "savannah" && (
        <BiosphereSavannah
          openBiosphere={props.openBiosphere}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && biomeName === "snow" && (
        <BiosphereGrasslandSnow renderShadow={() => props.renderShadow()} />
      )}
      {!notRenderT2Building() && biomeName !== "snow" && (
        <BiosphereSnow
          openBiosphere={props.openBiosphere}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && biomeName === "swamp" && (
        <BiosphereGrasslandSwamp renderShadow={() => props.renderShadow()} />
      )}
      {!notRenderT2Building() && biomeName !== "swamp" && (
        <BiosphereSwamp
          openBiosphere={props.openBiosphere}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && biomeName === "volcano" && (
        <BiosphereGrasslandVolcano renderShadow={() => props.renderShadow()} />
      )}
      {!notRenderT2Building() && biomeName !== "volcano" && (
        <BiosphereVolcano
          openBiosphere={props.openBiosphere}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!notRenderT2Building() && (
        <FountainBiosphere
          renderShadow={() => props.renderShadow()}
          renderAnimation={props.renderAnimation}
          bakedGreyMaterial={bakedGreyMaterial}
        />
      )}
    </>
  );
};

export default T2R3F;
