import ExcelJS from "exceljs";
import { chooseLanguageFromDB } from "../../../utils/content.ts";
import { StudentType } from "../../../_newapios/user/student.ts";
import { ClassType } from "../../../_newapios/user/class.ts";
import { GlobalCompetencePoints } from "../../../_newapios/progress/student_global_competences.ts";


const keyCompetencesWorksheet = (
    workbook: ExcelJS.Workbook,
    t: any,
    student: StudentType,
    selectedClass: ClassType,
    globalCompetencesPoints: number[],
    globalCompetencesNames: string[]
) => {

    const keyCompSheet = workbook.addWorksheet("Métricas del proyecto", {});

    const font = {
        name: "Lato",
        family: 1,
        color: { argb: "00000000" },
        size: 10,
        bold: false,
    };

    const borderStyle: Partial<ExcelJS.Border> = {
        style: "thin",
        color: { argb: "00000000" },
    };
    const alignment: Partial<ExcelJS.Alignment> = {
        vertical: "middle",
        horizontal: "center",
    };
    // STUDENT INFO
    const studentCell = keyCompSheet.getCell("H3");
    const classCell = keyCompSheet.getCell("H4");
    const nameCell = keyCompSheet.getCell("I3");
    const classValueCell = keyCompSheet.getCell("I4");
    const mainLangCell = keyCompSheet.getCell("H6");
    const foreignLangCell = keyCompSheet.getCell("H7");
    const mainLangValueCell = keyCompSheet.getCell("I6");
    const foreignLangValueCell = keyCompSheet.getCell("I7");

    studentCell.value = t("student_xls");
    studentCell.font = {
        name: "Lato",
        family: 1,
        color: { argb: "000000" },
        size: 10,
    };
    studentCell.border = {
        top: borderStyle,
        left: borderStyle,
        bottom: borderStyle,
        right: { style: "thin", color: { argb: "BBBBBBBB" } },
    };
    classCell.value = t("class_xls");
    classCell.font = font;
    classCell.border = {
        top: borderStyle,
        left: borderStyle,
        bottom: borderStyle,
        right: { style: "thin", color: { argb: "BBBBBBBB" } },
    };
    nameCell.value = student.username;
    nameCell.font = font;
    nameCell.border = {
        top: borderStyle,
        left: { style: "thin", color: { argb: "BBBBBBBB" } },
        bottom: borderStyle,
        right: borderStyle,
    };
    classValueCell.value = selectedClass.name;
    classValueCell.font = font;
    classValueCell.border = {
        top: borderStyle,
        left: { style: "thin", color: { argb: "BBBBBBBB" } },
        bottom: borderStyle,
        right: borderStyle,
    };

    mainLangCell.value = t("main_language_xls");
    mainLangCell.font = font;
    mainLangCell.border = {
        top: borderStyle,
        left: borderStyle,
        bottom: borderStyle,
        right: { style: "thin", color: { argb: "BBBBBBBB" } },
    };
    foreignLangCell.value = t("foreign_language_xls");
    foreignLangCell.font = font;
    foreignLangCell.border = {
        top: borderStyle,
        left: borderStyle,
        bottom: borderStyle,
        right: { style: "thin", color: { argb: "BBBBBBBB" } },
    };
    mainLangValueCell.value = t(
        chooseLanguageFromDB(selectedClass.main_language)
    );
    mainLangValueCell.font = font;
    mainLangValueCell.border = {
        top: borderStyle,
        left: { style: "thin", color: { argb: "BBBBBBBB" } },
        bottom: borderStyle,
        right: borderStyle,
    };
    foreignLangValueCell.value = t(
        chooseLanguageFromDB(selectedClass.foreign_language)
    );
    foreignLangValueCell.font = font;
    foreignLangValueCell.border = {
        top: borderStyle,
        left: { style: "thin", color: { argb: "BBBBBBBB" } },
        bottom: borderStyle,
        right: borderStyle,
    };

    ////////////////////////////////////////////////////////////////


    keyCompSheet.mergeCells("B2", "F2");

    keyCompSheet.getCell("B2").value = t("end_profile");
    keyCompSheet.getCell("B3").value = t("key_competences");
    keyCompSheet.getCell("D3").value = t("adq?");
    keyCompSheet.getCell("E3").value = t("adq_grade");
    keyCompSheet.getCell("F3").value = t("points");

    keyCompSheet.mergeCells("B3", "C3");
    keyCompSheet.mergeCells("B4", "C4");
    keyCompSheet.mergeCells("B5", "C5");
    keyCompSheet.mergeCells("B6", "C6");
    keyCompSheet.mergeCells("B7", "C7");
    keyCompSheet.mergeCells("B8", "C8");
    keyCompSheet.mergeCells("B9", "C9");
    keyCompSheet.mergeCells("B10", "C10");
    keyCompSheet.mergeCells("B11", "C11");

    for (let i = 0; i < globalCompetencesNames.length; i++) {
        const startRow = 4
        keyCompSheet.getCell(`B${startRow + i}`).value = t("competence") + " " + globalCompetencesNames[i];
        keyCompSheet.getCell(`F${startRow + i}`).value = globalCompetencesPoints[i];

        const adqCell = keyCompSheet.getCell(`D${startRow + i}`);
        adqCell.value = (globalCompetencesPoints[i] >= 2400) ? true : false;

        // adqCell.font = font;
        // adqCell.alignment = alignment;
        // Original values were 1500, 1200 and 1000; playing with the numbers a bit
        const gradeAdqValue =
            globalCompetencesPoints[i] >= 4000 ? 'Alto' :
                globalCompetencesPoints[i] >= 3200 ? 'Medio' :
                    globalCompetencesPoints[i] >= 2400 ? 'Bajo' : 'N/A';

        // Set status value
        const gradeAdqCell = keyCompSheet.getCell(`E${startRow + i}`);
        gradeAdqCell.value = gradeAdqValue;
        gradeAdqCell.font = font;
        gradeAdqCell.alignment = alignment;

        gradeAdqCell.fill =
            gradeAdqCell.value == 'Alto'
                ? {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "EAFCD9" },
                }
                : gradeAdqCell.value == 'Medio'
                    ? {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "FDF2CF" },
                    }
                    : gradeAdqCell.value == 'Bajo'
                        ? {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "FBD2CA" },
                        }
                        : {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "f3f3f3" },
                        };
        {
            gradeAdqCell.border = {
                top: { style: "thin", color: { argb: "BBBBBBBB" } }, // Add top border
                left: { style: "thin", color: { argb: "BBBBBBBB" } },
                right: { style: "thin", color: { argb: "BBBBBBBB" } },
                bottom: (i === globalCompetencesNames.length - 1) ? { style: "thin", color: { argb: "000000" } } : { style: "thin", color: { argb: "BBBBBBBB" } }, // Add bottom border
            };
        }

    }


    keyCompSheet.columns = [
        {},
        {
            key: "title",
            width: 14.7,
        },
        {
            width: 14.5,
        },
        {
            key: "adq",
            width: 18.2,
        },
        {
            key: "adqGrade",
            width: 20.4,
        },
        {
            key: "points",
            width: 14.5,
        },
        {},
        {
            width: 18,
        },
        {
            width: 14.5,
        },
    ];

    const titleRow = keyCompSheet.getRow(2);

    titleRow.eachCell(function (cell, colNumber) {
        if (colNumber > 1 && colNumber < 3) {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "4A86E8" },
            };
            cell.font = {
                name: "Lato",
                family: 1,
                color: { argb: "FFFFFFFF" },
                size: 10,
                bold: true,
            };
            cell.border = {
                top: { style: "thin", color: { argb: "00000000" } },
                left: { style: "thin", color: { argb: "00000000" } },
                right: { style: "thin", color: { argb: "00000000" } },
                bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
            };
        }
    });

    const headersRow = keyCompSheet.getRow(3);

    headersRow.eachCell(function (cell, colNumber) {
        if (colNumber < 8) {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "6D9EEB" },
            };
            cell.font = {
                name: "Lato",
                family: 1,
                color: { argb: "FFFFFFFF" },
                size: 10,
                bold: true,
            };
            cell.border =
                colNumber == 3
                    ? {
                        left: { style: "thin", color: { argb: "00000000" } },
                        bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                    }
                    : colNumber == 6
                        ? {
                            right: { style: "thin", color: { argb: "00000000" } },
                            left: { style: "thin", color: { argb: "BBBBBBBB" } },
                            bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                        }
                        : {
                            left: { style: "thin", color: { argb: "BBBBBBBB" } },
                            bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                        };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
            };
        }
    });

    const titleCol = keyCompSheet.getColumn(2);

    // iterate over all current cells in this column
    titleCol.eachCell((cell, rowNumber) => {
        if (rowNumber > 3 && rowNumber < 12) {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "C9DAF8" },
            };
            cell.font = {
                name: "Lato",
                family: 1,
                color: { argb: "00000000" },
                size: 10,
                bold: false,
            };
            cell.border =
                rowNumber == 11
                    ? {
                        bottom: { style: "thin", color: { argb: "00000000" } },
                        left: { style: "thin", color: { argb: "00000000" } },
                    }
                    : {
                        left: { style: "thin", color: { argb: "00000000" } },
                        bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                    };
            cell.alignment = {
                vertical: "middle",
                horizontal: "left",
            };
        }
    });

    const adqCol = keyCompSheet.getColumn(4);

    adqCol.eachCell((cell, rowNumber) => {
        if (rowNumber > 3 && rowNumber < 12) {
            cell.fill =
                cell.value == true
                    ? {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "CBFBEC" },
                    }
                    : {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "FBD2CA" },
                    };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            cell.font = {
                name: "Lato",
                family: 1,
                color: { argb: "00000000" },
                size: 10,
                bold: false,
            };
            cell.border =
                rowNumber == 11
                    ? {
                        left: { style: "thin", color: { argb: "BBBBBBBB" } },
                        bottom: { style: "thin", color: { argb: "00000000" } },
                    }
                    : {
                        left: { style: "thin", color: { argb: "BBBBBBBB" } },
                        bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                    };
            cell.value = cell.value == true ? "Adquirida" : "No adquirida";
        }
    });

    const pointsCol = keyCompSheet.getColumn(6);

    // iterate over all current cells in this column
    pointsCol.eachCell((cell, rowNumber) => {
        if (rowNumber > 3 && rowNumber < 12) {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "f3f3f3" },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            cell.font = {
                name: "Lato",
                family: 1,
                color: { argb: "00000000" },
                size: 10,
                bold: false,
            };
            cell.border =
                rowNumber == 11
                    ? {
                        bottom: { style: "thin", color: { argb: "00000000" } },
                        right: { style: "thin", color: { argb: "00000000" } },
                        left: { style: "thin", color: { argb: "BBBBBBBB" } },
                    }
                    : {
                        right: { style: "thin", color: { argb: "00000000" } },
                        bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                        left: { style: "thin", color: { argb: "BBBBBBBB" } },
                    };
        }
    });
    return keyCompSheet
}
export default keyCompetencesWorksheet