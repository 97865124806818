import "./ChallengeBlackOut.css";
import { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { motion, useAnimationControls } from "framer-motion";

import flechaLeft from "../../../assets/student/tutorial/FlechaTutLeft.svg";
import flechaRight from "../../../assets/student/tutorial/FlechaTutRight.svg";
import {
  kilowatts,
  setChallenge,
  setChallengeBlackOut,
  updateMetrics,
} from "../../../reducers/studentSlice";
import { SetStudentChallengeAPI } from "../../../_newapios/progress/student_challenge";
import { ChallengeType } from "../../../_newapios/gamecontent/challenges";

const ChallengeBlackOut = ({ ...props }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const studentState = useAppSelector((state) => state.student);
  const challenge = studentState.challenge;
  var step = studentState.challenge1Step;
  var maxWatts = 0;
  if (step >= 3 && step <= 10) {
    maxWatts = 9999;
  }
  if (step >= 11 && step <= 15) {
    maxWatts = 5300;
  }
  if (step >= 16 && step <= 17) {
    maxWatts = 7450;
  }
  if (step >= 18 && step <= 19) {
    maxWatts = 9999;
  }
  if (step >= 20 && step <= 21) {
    maxWatts = 9999;
  }
  if (step >= 22 && step <= 23) {
    maxWatts = 9200;
  }
  const challenge1 = [...studentState.challengeBlackOut];
  var tempWatts = maxWatts;

  const [generalSwitch, setGeneralSwitch] = useState(false);
  const [livingSwitch, setLivingSwitch] = useState(true);
  const [kitchenSwitch, setKitchenSwitch] = useState(true);
  const [bathSwitch, setBathSwitch] = useState(true);
  const [dormSwitch, setDormSwitch] = useState(true);
  const [watts, setWatts] = useState(0);
  const [prevWatts, setPrevWatts] = useState(0);
  const [houseNumber, setHouseNumber] = useState(0);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    var tempHouseNumber = 1;
    for (let i = 0; i < studentState.challengeBlackOut.length; i++) {
      if (studentState.challengeBlackOut[i] == 1) {
        tempHouseNumber = i + 1;
      }
    }
    setHouseNumber(tempHouseNumber);
  }, [step]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (step == 8 || step == 9) {
      tempWatts =
        (livingSwitch ? 870 : 0) +
        (kitchenSwitch ? 1000 : 0) +
        (bathSwitch ? maxWatts : 0) +
        (dormSwitch ? 720 : 0);
    }
    if (step == 14 || step == 15) {
      tempWatts =
        (livingSwitch ? 300 : 0) +
        (kitchenSwitch ? maxWatts - 2000 : 0) +
        (bathSwitch ? 1200 : 0) +
        (dormSwitch ? 500 : 0);
    }
    if (step == 17) {
      tempWatts =
        (livingSwitch ? maxWatts - 2500 : 0) +
        (kitchenSwitch ? 1110 : 0) +
        (bathSwitch ? 360 : 0) +
        (dormSwitch ? 1030 : 0);
    }
    if (step == 19) {
      tempWatts =
        (livingSwitch ? 1300 : 0) +
        (kitchenSwitch ? maxWatts : 0) +
        (bathSwitch ? 150 : 0) +
        (dormSwitch ? 735 : 0);
    }
    if (step == 21) {
      tempWatts =
        (livingSwitch ? 700 : 0) +
        (kitchenSwitch ? 1800 : 0) +
        (bathSwitch ? 420 : 0) +
        (dormSwitch ? maxWatts : 0);
    }
    if (step == 23) {
      tempWatts =
        (livingSwitch ? 980 : 0) +
        (kitchenSwitch ? 1020 : 0) +
        (bathSwitch ? 500 : 0) +
        (dormSwitch ? maxWatts - 2500 : 0);
    }
    setWatts(tempWatts);
    if (
      step == 8 &&
      generalSwitch &&
      livingSwitch &&
      kitchenSwitch &&
      !bathSwitch &&
      dormSwitch
    ) {
      advanceStepHandler();
      challenge1[houseNumber - 1] = 2;
      dispatch(setChallengeBlackOut(challenge1));
    }

    if (
      step == 14 &&
      generalSwitch &&
      livingSwitch &&
      !kitchenSwitch &&
      bathSwitch &&
      dormSwitch
    ) {
      advanceStepHandler();
      challenge1[houseNumber - 1] = 2;
      dispatch(setChallengeBlackOut(challenge1));
    }

    if (
      step == 17 &&
      generalSwitch &&
      !livingSwitch &&
      kitchenSwitch &&
      bathSwitch &&
      dormSwitch
    ) {
      setTimeout(() => advanceStepHandler(), 1000);
      challenge1[houseNumber - 1] = 2;
      dispatch(setChallengeBlackOut(challenge1));
    }

    if (
      step == 19 &&
      generalSwitch &&
      livingSwitch &&
      !kitchenSwitch &&
      bathSwitch &&
      dormSwitch
    ) {
      setTimeout(() => advanceStepHandler(), 1000);
      challenge1[houseNumber - 1] = 2;
      dispatch(setChallengeBlackOut(challenge1));
    }

    if (
      step == 21 &&
      generalSwitch &&
      livingSwitch &&
      kitchenSwitch &&
      bathSwitch &&
      !dormSwitch
    ) {
      setTimeout(() => advanceStepHandler(), 1000);
      challenge1[houseNumber - 1] = 2;
      dispatch(setChallengeBlackOut(challenge1));
    }

    if (
      step == 23 &&
      generalSwitch &&
      livingSwitch &&
      kitchenSwitch &&
      bathSwitch &&
      !dormSwitch
    ) {
      setTimeout(() => advanceStepHandler(), 1000);
      challenge1[houseNumber - 1] = 2;
      dispatch(setChallengeBlackOut(challenge1));
    }

    if (step == 25) {
      challengeCompleted();
    }
  }, [
    generalSwitch,
    livingSwitch,
    kitchenSwitch,
    bathSwitch,
    dormSwitch,
    maxWatts,
    step,
  ]);

  const challengeCompleted = async () => {
    if (challenge === undefined) return;
    await SetStudentChallengeAPI(challenge.id);
    dispatch(setChallenge(undefined));
    dispatch(updateMetrics());
  };

  useEffect(() => {
    if (step == 3 || step == 11) {
      setPrevWatts(maxWatts);
    } else {
      setPrevWatts(watts);
    }
  }, [generalSwitch, maxWatts]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const advanceStepHandler = () => {
    if (step == 9) {
      onTapBath();
      onTapGeneral();
    }
    if (step == 15) {
      onTapKitch();
      onTapGeneral();
    }
    if (step == 17) {
      onTapLiv();
      onTapGeneral();
    }
    if (step == 19) {
      onTapKitch();
      onTapGeneral();
    }
    if (step == 21) {
      onTapDorm();
      onTapGeneral();
    }
    dispatch({ type: "student/setChallenge1Step", payload: step + 1 });
  };

  const onTapGeneral = () => {
    setGeneralSwitch(!generalSwitch);
    if (step == 5 || step == 12) {
      advanceStepHandler();
    }
  };

  const onTapLiv = () => {
    setLivingSwitch(!livingSwitch);
  };

  const onTapKitch = () => {
    setKitchenSwitch(!kitchenSwitch);
  };

  const onTapBath = () => {
    setBathSwitch(!bathSwitch);
  };

  const onTapDorm = () => {
    setDormSwitch(!dormSwitch);
  };

  const livingMove = (direction: string) => {
    onTapLiv();
    controlsLiving.start(direction);
  };

  const kitchenMove = (direction: string) => {
    onTapKitch();
    controlsKitchen.start(direction);
  };

  const bathMove = (direction: string) => {
    onTapBath();
    controlsBath.start(direction);
  };

  const dormMove = (direction: string) => {
    onTapDorm();
    controlsDorm.start(direction);
  };

  const wrapperVariants = {
    down: {
      y: "7.5vh",
      transition: { type: "spring", delay: 0.1, duration: 0.5 },
    },
    up: {
      y: 0,
      transition: { type: "spring", delay: 0.1, duration: 0.5 },
    },
  };

  const wattsVariants = {
    off: {
      transition: { type: "spring", delay: 0.1, duration: 0.5 },
    },
    on: {
      y: 0,
      transition: { type: "spring", delay: 0.1, duration: 0.5 },
    },
  };

  const controlsGeneral = useAnimationControls();
  const controlsLiving = useAnimationControls();
  const controlsKitchen = useAnimationControls();
  const controlsBath = useAnimationControls();
  const controlsDorm = useAnimationControls();

  const generalSwitchCalculator = () => {
    if (generalSwitch) {
      controlsGeneral.start("down");
      onTapGeneral();
    } else {
      if (watts > 3000 || watts == undefined) {
        tooMuchPower();
      } else {
        controlsGeneral.start("up");
        onTapGeneral();
      }
    }
  };

  const tooMuchPower = () => {
    controlsGeneral.start("up");
    onTapGeneral();
    setTimeout(() => setGeneralSwitch(() => false), 1000);
    setTimeout(() => controlsGeneral.start("down"), 1000);
  };

  const renderProfessor = () => {
    if ((step != 3 && step != 11 && step <= 15) || step == 24) {
      return (
        <div className="challenge_1_professor">
          <div className="bottom_left_container">
            <img
              className="image"
              src={
                window.innerWidth > 991
                  ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
                  : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
              }
              alt="professor"
            />
          </div>
        </div>
      );
    } else {
    }
  };

  const renderBubble = () => {
    if ((step != 3 && step != 11 && step <= 15) || step == 24) {
      return (
        <div className="challenge_1_bubble">
          <div className="center_container">
            <div className="text_black_medium_bold">
              {step == 0 ? (
                <div>
                  <p>{t("challenge_1_text_1")}</p>
                </div>
              ) : null}
              {step == 1 ? (
                <div>
                  <p>{t("challenge_1_text_2")}</p>
                </div>
              ) : null}
              {step == 2 ? (
                <div>
                  <p>{t("challenge_1_text_3")}</p>
                </div>
              ) : null}
              {step == 4 ? (
                <div>
                  <p>{t("challenge_1_text_4")}</p>
                </div>
              ) : null}
              {step == 5 ? (
                <div>
                  <p>{t("challenge_1_text_5")}</p>
                </div>
              ) : null}
              {step == 6 ? (
                <div>
                  <p>{t("challenge_1_text_6")}</p>
                </div>
              ) : null}
              {step == 7 ? (
                <div>
                  <p>{t("challenge_1_text_7")}</p>
                </div>
              ) : null}
              {step == 8 ? (
                <div>
                  <p>{t("challenge_1_text_8")}</p>
                </div>
              ) : null}
              {step == 9 ? (
                <div>
                  <p>{t("challenge_1_text_9")}</p>
                </div>
              ) : null}
              {step == 10 ? (
                <div>
                  <p>{t("challenge_1_text_10")}</p>
                </div>
              ) : null}
              {step == 12 ? (
                <div>
                  <p>{t("challenge_1_text_11")}</p>
                </div>
              ) : null}
              {step == 13 ? (
                <div>
                  <p>{t("challenge_1_text_12")}</p>
                </div>
              ) : null}
              {step == 14 ? (
                <div>
                  <p>{t("challenge_1_text_13")}</p>
                </div>
              ) : null}
              {step == 15 ? (
                <div>
                  <p>{t("challenge_1_text_14")}</p>
                </div>
              ) : null}
              {step == 24 ? (
                <div>
                  <p>{t("challenge_1_text_17")}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderButton = () => {
    if (
      (step != 3 &&
        step != 5 &&
        step != 8 &&
        step != 11 &&
        step != 12 &&
        step != 14 &&
        step <= 15) ||
      step == 24
    ) {
      return (
        <div
          className="challenge_1_button"
          onClick={() => advanceStepHandler()}
        >
          <div className="center_container">
            <div className="text_white_big_bold">{t("confirm")}</div>
          </div>
        </div>
      );
    }
  };

  const renderMains = () => {
    if (
      step > 3 &&
      step != 10 &&
      step != 11 &&
      step != 16 &&
      step != 18 &&
      step != 20 &&
      step != 22 &&
      step != 24
    ) {
      return (
        <div className="challenge_1_mains_background">
          <div className="challenge_1_mains_header">
            <div className="left_container">
              <div className="text_white_medium_bold">
                {t("mains") + " - " + t("house") + " " + houseNumber}
              </div>
            </div>
          </div>
          <div className="challenge_1_mains_body">
            <div className="challenge_1_mains_body_left">
              <div className="challenge_1_mains_body_left_box">
                <div className="challenge_1_mains_body_left_all_switches">
                  <div className="challenge_1_mains_body_left_general_switch">
                    <div className="center_container">
                      <div className="text_black_medium_bold">
                        {t("general")}
                      </div>
                    </div>
                    <div className="challenge_1_switch">
                      <motion.div
                        className="challenge_1_switch_orange_general"
                        variants={wrapperVariants}
                        initial="down"
                        animate={controlsGeneral}
                        onClick={() => generalSwitchCalculator()}
                      />
                      <div className="challenge_1_switch_rail_general" />
                    </div>
                  </div>
                  <div className="challenge_1_mains_body_left_room_switches">
                    <div className="center_container">
                      <div className="text_black_small_bold">{t("living")}</div>
                    </div>
                    <div className="center_container">
                      <div className="text_black_small_bold">
                        {t("kitchen")}
                      </div>
                    </div>
                    <div className="center_container">
                      <div className="text_black_small_bold">{t("bath")}</div>
                    </div>
                    <div className="center_container">
                      <div className="text_black_small_bold">{t("dorm")}</div>
                    </div>
                    <div className="challenge_1_switch">
                      <motion.div
                        className="challenge_1_switch_orange"
                        variants={wrapperVariants}
                        initial="up"
                        animate={controlsLiving}
                        onClick={() => {
                          generalSwitch == true
                            ? console.log("Nope")
                            : livingSwitch == false
                            ? livingMove("up")
                            : livingMove("down");
                        }}
                      />
                      <div className="challenge_1_switch_rail" />
                    </div>
                    <div className="challenge_1_switch">
                      <motion.div
                        className="challenge_1_switch_orange"
                        variants={wrapperVariants}
                        initial="up"
                        animate={controlsKitchen}
                        onClick={() => {
                          generalSwitch == true
                            ? console.log("Nope")
                            : kitchenSwitch == false
                            ? kitchenMove("up")
                            : kitchenMove("down");
                        }}
                      />
                      <div className="challenge_1_switch_rail" />
                    </div>
                    <div className="challenge_1_switch">
                      <motion.div
                        className="challenge_1_switch_orange"
                        variants={wrapperVariants}
                        initial="up"
                        animate={controlsBath}
                        onClick={() => {
                          generalSwitch == true
                            ? console.log("Nope")
                            : bathSwitch == false
                            ? bathMove("up")
                            : bathMove("down");
                        }}
                      />
                      <div className="challenge_1_switch_rail" />
                    </div>
                    <div className="challenge_1_switch">
                      <motion.div
                        className="challenge_1_switch_orange"
                        variants={wrapperVariants}
                        initial="up"
                        animate={controlsDorm}
                        onClick={() => {
                          generalSwitch == true
                            ? console.log("Nope")
                            : dormSwitch == false
                            ? dormMove("up")
                            : dormMove("down");
                        }}
                      />
                      <div className="challenge_1_switch_rail" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="challenge_1_mains_body_right">
              <div className="challenge_1_mains_body_right_watts">
                <div className="challenge_1_mains_body_right_watts_background">
                  <div
                    className="challenge_1_mains_body_right_watts_measure"
                    style={{ transform: "translate(-50%, -50%) rotate(0deg)" }}
                  >
                    {generalSwitch && (
                      <motion.div
                        className="challenge_1_mains_body_right_watts_moving"
                        variants={wattsVariants}
                        style={{
                          transform:
                            prevWatts > 3000
                              ? "rotate(0deg)"
                              : "rotate(" +
                                ((18 * prevWatts) / 300 - 180) +
                                "deg)",
                          backgroundColor:
                            prevWatts > 3000 ? "#F0684F" : "#6EE6A7",
                        }}
                      />
                    )}
                  </div>
                  <div className="challenge_1_mains_body_right_watts_semi" />
                  <div className="challenge_1_mains_body_right_watts_0">
                    <div className="text_white_very_small">0</div>
                  </div>
                  <div className="challenge_1_mains_body_right_watts_3000">
                    <div className="text_white_very_small">3000</div>
                  </div>
                  <div className="challenge_1_mains_body_right_watts_units">
                    <div className="text_white_very_small">{t("watts")}</div>
                  </div>
                  <div className="challenge_1_mains_body_right_watts_number">
                    <div className="center_container">
                      <div className="text_white_big">
                        {generalSwitch ? Math.min(watts, 9999) : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="challenge_1_mains_body_right_plate">
                <div
                  className="challenge_1_mains_body_right_plate_screws"
                  style={{ top: "1vh", left: "1vw" }}
                />
                <div
                  className="challenge_1_mains_body_right_plate_screws"
                  style={{ top: "1vh", right: "1vw" }}
                />
                <div
                  className="challenge_1_mains_body_right_plate_screws"
                  style={{ bottom: "1vh", left: "1vw" }}
                />
                <div
                  className="challenge_1_mains_body_right_plate_screws"
                  style={{ bottom: "1vh", right: "1vw" }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {renderProfessor()}
      {renderBubble()}
      {renderButton()}
      {renderMains()}
    </div>
  );
};

export default ChallengeBlackOut;
