import env from "../../config/env.json";
import APIFetch from "../../utils/APIFetch";

export interface ClassCO2Type {
    class_co2: number;
    class_size: number;
}

export interface BiomeHealthType {
    [key: string]: number;
}


export const GetStudentPointsAPI = async (student_id: string): Promise<number> => {
    // var req = await fetch(env.backend_host + "/student_game_metrics/points/" + student_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(env.backend_host + "/student_game_metrics/points/" + student_id, {
        method: "GET"
    });


    data = data || null
    if (data == null) {
        return 0;
    }
    return data;
}

export const GetStudentWattsAPI = async (student_id: string): Promise<number> => {
    // var req = await fetch(env.backend_host + "/student_game_metrics/watts/" + student_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(env.backend_host + "/student_game_metrics/watts/" + student_id, {
        method: "GET"
    });

    data = data || null
    return data;
}

export const GetStudentCO2API = async (student_id: string): Promise<number> => {
    // var req = await fetch(env.backend_host + "/student_game_metrics/co2/" + student_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(env.backend_host + "/student_game_metrics/co2/" + student_id, {
        method: "GET"
    });

    data = data || null
    return data;
}

export const GetStudentPopulationAPI = async (student_id: string): Promise<number> => {
    // var req = await fetch(env.backend_host + "/student_game_metrics/population/" + student_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(env.backend_host + "/student_game_metrics/population/" + student_id, {
        method: "GET"
    });

    data = data || null
    return data;
}

export const GetClassCO2API = async (student_id: string): Promise<ClassCO2Type> => {
    // var req = await fetch(env.backend_host + "/student_game_metrics/class_co2/" + student_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(env.backend_host + "/student_game_metrics/class_co2/" + student_id, {
        method: "GET"
    });

    data = data || null
    return data;
}

export const GetStudentBiodiversityAPI = async (student_id: string): Promise<number> => {
    // var req = await fetch(env.backend_host + "/student_game_metrics/biodiversity/" + student_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();
    var data = APIFetch(env.backend_host + "/student_game_metrics/biodiversity/" + student_id, {
        method: "GET"
    });
    data = data || null
    return data;
}

export const GetStudentActiveItemsAPI = async (student_id: string): Promise<number> => {
    // var req = await fetch(env.backend_host + "/student_game_metrics/active_items/" + student_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(env.backend_host + "/student_game_metrics/active_items/" + student_id, {
        method: "GET"
    });

    data = data || null
    return data;
}

export const GetStudentDestructionAPI = async (student_id: string): Promise<number> => {
    // var req = await fetch(env.backend_host + "/student_game_metrics/destruction/" + student_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();
    var data = APIFetch(env.backend_host + "/student_game_metrics/destruction/" + student_id, {
        method: "GET"
    });
    data = data || null
    return data;
}

export const GetClassBiomesHealthAPI = async (student_id: string): Promise<BiomeHealthType> => {
    // var req = await fetch(env.backend_host + "/student_game_metrics/class_biomes_health/" + student_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(env.backend_host + "/student_game_metrics/class_biomes_health/" + student_id, {
        method: "GET"
    });

    data = data || null
    return data;
}