import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../interfaces";

const VolcanicIsland = ({ ...props }) => {
  const fireplaceNoFire = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/fireplace_nofire_volcanico.gltf"
  ) as DreiGLTF;
  const fireplace = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/fireplace_volcanico.gltf"
  ) as DreiGLTF;
  const smallStump = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/stump_small_volcanico.gltf"
  ) as DreiGLTF;
  const treeDead = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/tree_dead_volcanico.gltf"
  ) as DreiGLTF;
  const treeForestOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/tree_forest_orange_volcanico.gltf"
  ) as DreiGLTF;
  const treeOld = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/tree_old_volcanico.gltf"
  ) as DreiGLTF;
  const treePoplarOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/tree_poplar_orange_jungla.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={fireplace.nodes.fireplace001.geometry}
        material={props.bakedMaterial1}
        position={[-25.79, 0.25, -10.93]}
        rotation={[-0.03, 0.34, -0.04]}
        scale={0.58}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-25.3, 0.41, -12.43]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-17, -0.23, -13.96]}
        rotation={[0, 0.66, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-26.9, 0.12, -12.4]}
        rotation={[0, 0.49, 0]}
        scale={0.32}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treePoplarOrange.nodes["tree-poplar_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-25.09, 0.31, -11.52]}
        rotation={[0, 1.1, 0]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={fireplaceNoFire.nodes["fireplace-nofire001"].geometry}
        material={props.bakedMaterial1}
        position={[-23.8, -0.02, -7.65]}
        scale={0.49}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.61, 0.42, -12.64]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.21, -0.01, -12.02]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-25.23, -0.2, -6.33]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.52, 0.01, -5.95]}
        rotation={[-Math.PI, 0.49, -Math.PI]}
        scale={0.23}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.87, 0.03, -5.4]}
        rotation={[0, 1.51, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treePoplarOrange.nodes["tree-poplar_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-21.66, 0.26, -7.73]}
        rotation={[0, 0.51, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treePoplarOrange.nodes["tree-poplar_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-21.16, 0.09, -7.51]}
        rotation={[0, 1.26, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.76, 0.08, -4.81]}
        rotation={[0, 1.18, 0]}
        scale={0.32}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.97, 0.15, -6.72]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-21.08, -0.28, -5.28]}
        rotation={[0, 1.07, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-20.4, 0.1, -5.84]}
        rotation={[0, 1.42, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-20.73, -0.04, -5.56]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-29.25, 0.13, -13.39]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-28.18, 0.15, -12.89]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-28.02, -0.34, -11.92]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-28.97, 0.05, -11.22]}
        rotation={[0, -0.61, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-29.03, -0.21, -9.82]}
        rotation={[-Math.PI, 0.16, -Math.PI]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-28.88, -0.45, -9.15]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-30.02, 0.12, -12.47]}
        rotation={[0, 0.62, 0]}
        scale={0.32}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-23.68, 0.35, -13.43]}
        rotation={[Math.PI, -0.17, Math.PI]}
        scale={0.32}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.91, 1.06, -13.52]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-24, 0.21, -12.75]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.44, -0.25, -20.7]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-28.24, 0.41, -17.35]}
        scale={0.32}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-26.32, 0.04, -18.65]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-18.39, -0.38, -14.22]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-25.6, 0.12, -19.5]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-28.85, 0.22, -16.31]}
        rotation={[0, -1.13, 0]}
        scale={0.32}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-28.19, 0.98, -15.67]}
        scale={0.32}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-29.5, 0.02, -15.55]}
        rotation={[0, -1.03, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-29.64, 0.03, -16.92]}
        rotation={[-Math.PI, 0.47, -Math.PI]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-29.19, 0.06, -17.4]}
        rotation={[0, 1.36, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-29.29, 0.28, -15]}
        rotation={[0, -0.58, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-28.5, 0.84, -14.98]}
        rotation={[0, 0.98, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treePoplarOrange.nodes["tree-poplar_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-29.01, 0.1, -11.94]}
        rotation={[0, -0.68, 0]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treePoplarOrange.nodes["tree-poplar_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-28.02, 0.26, -18.76]}
        rotation={[0, -0.55, 0]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treePoplarOrange.nodes["tree-poplar_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-28.89, 0.18, -18.3]}
        rotation={[0, 1.45, 0]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.67, -0.05, -21.33]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.18, -0.05, -21.2]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={smallStump.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-17.88, -0.01, -16.9]}
        rotation={[0, -0.64, 0]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={smallStump.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-21.43, 0, -5.43]}
        rotation={[0, 0.1, 0]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-18.27, -0.19, -13.77]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treePoplarOrange.nodes["tree-poplar_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-18.93, 0.06, -14.39]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-16.38, -0.34, -16.97]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-15.94, -0.34, -16.04]}
        rotation={[0, -1.05, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-15.7, -0.26, -15.37]}
        rotation={[0, 0.86, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead001"].geometry}
        material={props.bakedMaterial1}
        position={[-16.09, -0.81, -14.65]}
        rotation={[0, -0.97, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-17.9, -0.35, -17.5]}
        rotation={[Math.PI, -0.13, Math.PI]}
        scale={0.32}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/fireplace_nofire_volcanico.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/fireplace_volcanico.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/stump_small_volcanico.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/tree_dead_volcanico.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/tree_forest_orange_volcanico.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/tree_old_volcanico.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_volcanico/tree_poplar_orange_jungla.gltf"
);

export default VolcanicIsland;
