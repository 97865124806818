const StarIcon = (
  props: {
    color?: string;
    size?: number; // Adding size as an optional number prop
  } = {
      color: "#FCC924",
      size: 30, // Default size set to 30
    }
) => {
  const { color, size } = props; // Destructuring props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.999 22.3051L7.72803 26.1276L9.11665 18.0313L3.23434 12.2975L11.3635 11.1163L14.999 3.75L18.6344 11.1163L26.7636 12.2975L20.8813 18.0313L22.2699 26.1276L14.999 22.3051Z" fill="#FCC924" />
    </svg>
  );
};

export default StarIcon;
