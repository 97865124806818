import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { GetAllCompletionsBusiness } from "../../../_newapios/content/bundle";
import { BundleCompletionBusinessType } from "../../../_newapios/content/bundle";
import "./BVStats.css";
import { motion } from "framer-motion";
import DatePicker from "react-datepicker";
import BOChart from "./BOChart";
import { Select } from "chakra-react-select";
import {
  aggregateBundleCompletions,
  BOChartData,
  getLoginsFromStudents,
  groupBundlesByUser,
  groupDataByTime,
  groupStudentsByUser,
  groupTeacherLoginsByUser,
} from "../../../utils/backoffice";
import { LoginsType } from "../../../_newapios/user/teacher";
import { BusinessSchoolType } from "../../../_newapios/user/school";
import { BusinessStudentType } from "../../../_newapios/user/student";

const BVStats = ({ ...props }) => {
  const [bundleChartData, setBundleChartData] = useState<BOChartData[]>([]);
  const [loginsChartData, setLoginsChartData] = useState<BOChartData[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date("2024-09-01"));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [completionRate, setCompletionRate] = useState<number>(0);

  const [activeButton, setActiveButton] = useState<string>("access");

  const fillSchools = () => {
    const tempUserOptions = [
      { value: "all", label: "Todos los usuarios", color: "#000000" },
      { value: "schools", label: "Schools", color: "#000000" },
      { value: "Free", label: "Free teachers", color: "#000000" },
      { value: "Ultimate", label: "Paid teachers", color: "#000000" },
    ];

    const newSchools = props.schools.map((school: BusinessSchoolType) => {
      return {
        value: school.id,
        label: school.name,
        color: "#000000",
      };
    });
    tempUserOptions.push(...newSchools);
    return tempUserOptions;
  };

  const userOptions = fillSchools();

  const timeOptions = [
    { value: "daily", label: "Diario", color: "#000000" },
    //{ value: "weekly", label: "Semanal", color: "#000000" },
    { value: "monthly", label: "Mensual", color: "#000000" },
  ];

  const [selectedData, setSelectedData] = useState<{
    value: string;
    label: string;
    color: string;
  } | null>(userOptions[0]);

  const [selectedView, setSelectedView] = useState<{
    value: string;
    label: string;
    color: string;
  } | null>(timeOptions[0]);

  const [noData, setNoData] = useState<boolean>(false);

  const fetchBundleData = async () => {
    const bundles: BundleCompletionBusinessType[] =
      await GetAllCompletionsBusiness(
        startDate.toISOString(),
        endDate.toISOString()
      );
    const filteredBundles = await groupBundlesByUser(
      bundles,
      props.schools,
      selectedData?.value || "all"
    );
    const aggregatedBundles = aggregateBundleCompletions(filteredBundles);
    const data_assigned = Object.keys(aggregatedBundles).map((b: string) => ({
      x: b,
      y: aggregatedBundles[b].assigned,
    }));
    const data_completed = Object.keys(aggregatedBundles).map((b: string) => ({
      x: b,
      y: aggregatedBundles[b].completed,
    }));
    let aggregated_completion = { assigned: 0, completed: 0 };
    Object.keys(aggregatedBundles).forEach((b: string) => {
      aggregated_completion.assigned += aggregatedBundles[b].assigned;
      aggregated_completion.completed += aggregatedBundles[b].completed;
    });
    const completion_rate = aggregated_completion.completed
      ? (aggregated_completion.completed / aggregated_completion.assigned) * 100
      : 0;
    setCompletionRate(completion_rate);
    setBundleChartData(
      groupDataByTime(
        [
          {
            label: "Situaciones asignadas",
            type: "line",
            borderColor: "blue",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            data: data_assigned,
          },
          {
            label: "Situaciones completadas",
            type: "line",
            borderColor: "yellow",
            backgroundColor: "rgba(255, 206, 86, 0.2)",
            data: data_completed,
          },
        ],
        selectedView?.value || "daily",
        startDate,
        endDate
      )
    );
  };
  const fetchLoginsData = async () => {
    const allTeacherLogins: LoginsType[] = props.logins;
    const students: BusinessStudentType[] = props.students;

    const filteredTeacherLogins = groupTeacherLoginsByUser(
      allTeacherLogins,
      props.teachers,
      selectedData?.value || "all"
    );
    const filteredStudents = groupStudentsByUser(
      students,
      props.schools,
      selectedData?.value || "all"
    );

    let teacherLogins: { [date: string]: number } = {};
    for (let i = 0; i < filteredTeacherLogins.length; i++) {
      const date = new Date(filteredTeacherLogins[i].timestamp).toDateString();
      if (teacherLogins[date]) {
        teacherLogins[date]++;
      } else {
        teacherLogins[date] = 1;
      }
    }
    const studentLogins: { [date: string]: number } =
      getLoginsFromStudents(filteredStudents);
    const teacherData = Object.keys(teacherLogins).map((date: string) => ({
      x: date,
      y: teacherLogins[date],
    }));
    const studentData = Object.keys(studentLogins).map((date: string) => ({
      x: date,
      y: studentLogins[date],
    }));

    setLoginsChartData(
      groupDataByTime(
        [
          {
            label: "Profesores",
            type: "line",
            borderColor: "#5090F0",
            backgroundColor: "#5090F0",
            data: teacherData,
          },
          {
            label: "Media profesores",
            type: "line",
            borderColor: "#5090F088",
            backgroundColor: "#5090F088",
            data: teacherData,
          },
          {
            label: "Alumnos",
            type: "line",
            borderColor: "#6EE6A7",
            backgroundColor: "#6EE6A7",
            data: studentData,
          },
          {
            label: "Media alumnos",
            type: "line",
            borderColor: "#6EE6A788",
            backgroundColor: "#6EE6A788",
            data: studentData,
          },
        ],
        selectedView?.value || "daily",
        startDate,
        endDate
      )
    );
  };

  const calcNoData = () => {
    let no_data = false;
    if (activeButton === "access") {
      loginsChartData.forEach((data) => {
        if (data.data.length === 0) {
          no_data = true;
        }
      });
    } else {
      bundleChartData.forEach((data) => {
        if (data.data.length === 0) {
          no_data = true;
        }
      });
    }
    return no_data;
  };
  useEffect(() => {
    setNoData(calcNoData());
  }, [loginsChartData, bundleChartData]);

  useEffect(() => {
    fetchBundleData();
    fetchLoginsData();
  }, [startDate, endDate, selectedView, selectedData]);

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  const handleButton = (button: string) => {
    setActiveButton(button);
  };

  const renderCompletionRate = () => {
    return (
      <div className="stats_completion_rate">
        <div className="text_black_small">Tasa de resolución: </div>
        <div className="text_blue_small_bold" style={{ marginLeft: "0.5em" }}>
          {completionRate.toFixed(2)}%
        </div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="stats_top_buttons">
        <div className="center_container">
          <motion.div
            className="stats_button"
            onClick={() => handleButton("access")}
            style={{
              backgroundColor:
                activeButton === "access" ? "#459bdc" : "#e2eeff",
            }}
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 300, damping: 12 }}
          >
            <div className="text_black_medium">Accesos</div>
          </motion.div>
        </div>
        <div className="center_container">
          <motion.div
            className="stats_button"
            onClick={() => handleButton("scenarios")}
            style={{
              backgroundColor:
                activeButton === "scenarios" ? "#459bdc" : "#e2eeff",
            }}
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 300, damping: 12 }}
          >
            <div className="text_black_medium">Situaciones completadas</div>
          </motion.div>
        </div>
      </div>
    );
  };

  const renderMiddle = () => {
    return (
      <div className="stats_middle_container">
        <div className="stats_dropdown_left">
          <div className="text_black_small"> Datos</div>
          <Select
            options={userOptions}
            value={selectedData}
            onChange={(option) => setSelectedData(option)}
            placeholder="Select user type"
            useBasicStyles
            chakraStyles={{
              control: (provided) => ({
                ...provided,
                width: "15vw",
                fontFamily: "Causten, sans-serif",
                fontWeight: "bold",
                fontSize: "1em",
                cursor: "pointer",
                background: "#eeeeee",
                borderRadius: "10px",
              }),
              option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? "white" : state.data.color,
                fontFamily: "Causten, sans-serif",
                fontSize: "1em",
                cursor: "pointer",
                "&:hover": {
                  background: "#5090F0",
                  color: "white",
                },
              }),
            }}
          />
        </div>

        <div className="stats_middle_dates_pickers">
          <div className="text_black_small">Desde: </div>
          <DatePicker
            className="scenario_edit_date_picker"
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date: Date) => handleStartDateChange(date)}
            shouldCloseOnSelect
            useWeekdaysShort
          />
          <div className="text_black_small">Hasta: </div>
          <DatePicker
            className="scenario_edit_date_picker"
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date: Date) => handleEndDateChange(date)}
            shouldCloseOnSelect
            useWeekdaysShort
          />
        </div>

        <div className="stats_dropdown_right">
          <div className="text_black_small"> Vista</div>
          <Select
            options={timeOptions}
            value={selectedView}
            onChange={(option) => setSelectedView(option)}
            placeholder="Select time range"
            useBasicStyles
            chakraStyles={{
              control: (provided) => ({
                ...provided,
                width: "10vw",
                fontFamily: "Causten, sans-serif",
                fontWeight: "bold",
                fontSize: "1em",
                cursor: "pointer",
                background: "#eeeeee",
                borderRadius: "10px",
              }),
              option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? "white" : state.data.color,
                fontFamily: "Causten, sans-serif",
                fontSize: "1em",
                cursor: "pointer",
                "&:hover": {
                  background: "#5090F0",
                  color: "white",
                },
              }),
            }}
          />
        </div>
      </div>
    );
  };

  const renderGraph = () => {
    return (
      <div className="stats_graph_container">
        <div style={{ width: "90vw", height: "80vh", margin: "auto" }}>
          <BOChart
            data={activeButton === "access" ? loginsChartData : bundleChartData}
            startDate={startDate}
            endDate={endDate}
            chartTitle={activeButton}
            view={selectedView?.value || "daily"}
          />
        </div>
        {noData && (
          <div className="no_data_text">
            {activeButton === "access"
              ? "No hay datos de logins para mostrar con los filtros seleccionados"
              : "No hay datos de situaciones para mostrar con los filtros seleccionados"}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="stats_general_container">
      {renderButtons()}
      {renderMiddle()}
      {renderGraph()}
      {activeButton === "scenarios" && renderCompletionRate()}
    </div>
  );
};

export default BVStats;
