import "./ProfessorEvaluationScenario.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { m, LazyMotion } from "framer-motion";
import _ from "lodash";
import { simpleGlobalCompetences } from "../../../../constants";
import Loading from "../../../loading_screen/LoadingDots";
import BackArrow from "../../../../assets/exercises/back_arrow";
import teacherHint from "../../../../assets/professor/teacher_hint.svg";
import teacherMistake from "../../../../assets/professor/teacher_mistake.svg";
import { setEvaluationMenu } from "../../../../reducers/teacherSlice";
import {
  CourseType,
  GetCourseEvaluationCriteriaAPI,
  GetCourseSpecificCompetencesAPI,
} from "../../../../_newapios/content/course.ts";
import {
  globalCompetencesBooleanArray,
  prettifyCourseAcronyms,
  specificCompetencesBooleanArray,
} from "../../../../utils/competences.ts";
import { render } from "react-dom";
import {
  GetMyStudentScenarioProgressAPI,
  ScenarioProgressType,
} from "../../../../_newapios/progress/student_scenario.ts";
import { StudentType } from "../../../../_newapios/user/student.ts";
import {
  GetScenarioAPI,
  ModifiedScenarioType,
} from "../../../../_newapios/content/scenario.ts";
import {
  EvaluationCriterionType,
  SpecificCompetenceType,
} from "../../../../_newapios/content/unit.ts";
import exp from "constants";
import {
  GetResearchAPI,
  ResearchType,
} from "../../../../_newapios/content/research.ts";
import TheoryIcon from "../../../../assets/exercises/theory_icon.tsx";
import MediaIcon from "../../../../assets/exercises/media_icon.tsx";
import DefinitionIcon from "../../../../assets/exercises/definition_icon.tsx";

const ProfessorEvaluationScenario = ({ ...props }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const professorState = useAppSelector((state) => state.teacher);
  const mainState = useAppSelector((state) => state.main);

  const loadFeatures = () =>
    import("../../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const globalCompetences = mainState.globalCompetences;

  const multiplier = 20;

  const scenarioId = professorState.selectedScenarioId as string;
  const [scenarioProgress, setScenarioProgress] =
    useState<ScenarioProgressType>();

  const [research, setResearch] = useState<ResearchType>();
  const [scenario, setScenario] = useState<ModifiedScenarioType>();

  const [evaluationCriteria, setEvaluationCriteria] = useState<
    EvaluationCriterionType[]
  >([]);

  const [specificCompetences, setSpecificCompetences] =
    useState<SpecificCompetenceType[]>();
  const [selectedSpecificCompetences, setSelectedSpecificCompetences] =
    useState<boolean[]>([]);

  const [loading, setLoading] = useState(false);

  const selectedStudent = professorState.selectedStudent as StudentType;

  const selectedCourse = mainState.courses.find(
    (course) => course.id === mainState.selectedCourseId
  ) as CourseType;
  const selectedColor = selectedCourse.color;

  useEffect(() => {
    loadCurricularContent();
  }, []);

  useEffect(() => {
    if (specificCompetences !== undefined && scenario !== undefined) {
      let newArray = specificCompetencesBooleanArray(
        scenario.specific_competences_ids,
        specificCompetences
      );
      setSelectedSpecificCompetences(newArray);
    }
  }, [specificCompetences, scenario?.specific_competences_ids]);

  const loadCurricularContent = async () => {
    setLoading(true);
    var newScenario = await GetScenarioAPI(scenarioId);
    var promises = [
      setScenario(newScenario),
      GetMyStudentScenarioProgressAPI(selectedStudent.id, scenarioId).then(
        (prog) => setScenarioProgress(prog)
      ),
      GetCourseSpecificCompetencesAPI(selectedCourse.id).then((specComp) =>
        setSpecificCompetences(specComp)
      ),
      GetCourseEvaluationCriteriaAPI(selectedCourse.id).then((evalCrit) =>
        setEvaluationCriteria(evalCrit)
      ),
      GetResearchAPI(newScenario.research.id).then((research) =>
        setResearch(research)
      ), // I really don't like having to import research just for some numbers but whatever
    ];

    Promise.all(promises).then(() => {
      setLoading(false);
    });
  };

  const calcBetterAttempts = () => {
    const experimentProgresses = _.sortBy(
      scenarioProgress?.experiment_progress,
      (exp) => exp.completed_on
    );
    let betterAttempts = -1;
    let bestPoints = 0;
    experimentProgresses?.forEach((expProg) => {
      const points = expProg.priority_index;
      if (points > bestPoints) {
        bestPoints = points;
        betterAttempts++;
      }
    });
    return betterAttempts < 0 ? 0 : betterAttempts;
  };

  const calcWorseAttempts = () => {
    const experimentProgresses = _.sortBy(
      scenarioProgress?.experiment_progress,
      (exp) => exp.completed_on
    );
    let worseAttempts = 0;
    let bestPoints = 0;
    experimentProgresses?.forEach((expProg) => {
      const points = expProg.priority_index;
      if (points > bestPoints) {
        bestPoints = points;
      }
      if (points < bestPoints) {
        worseAttempts++;
      }
    });
    return worseAttempts;
  };

  const selectedGlobalCompetences = globalCompetencesBooleanArray(
    scenario?.global_competences_ids || [],
    globalCompetences
  );

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const backHandler = () => {
    dispatch(setEvaluationMenu("curricularContent"));
  };

  const courseLetter = prettifyCourseAcronyms(selectedCourse.acronym);
  const experimentProgress = scenarioProgress?.experiment_progress
    ? scenarioProgress?.experiment_progress[0]
    : undefined;

  const researchPoints =
    scenarioProgress?.research_progress?.completed_on !== null &&
    scenarioProgress?.research_progress?.completed_on !== undefined
      ? multiplier
      : 0;
  const researchTotalPoints = multiplier;

  const researchErrors = scenarioProgress?.research_progress?.exercise_progress
    .map((exprog) => exprog.attempts)
    .reduce((a, b) => a + b, 0);

  const researchHints = scenarioProgress?.research_progress?.exercise_progress
    .map((exp) => exp.used_hint)
    .reduce((a, b) => (a = a + (b ? 1 : 0)), 0);

  const experimentCorrectArray =
    experimentProgress?.exercise_progress.map((exprog) => exprog.correct) ||
    Array(4).fill(false);

  const experimentPoints =
    experimentCorrectArray.filter((x) => x == true).length * multiplier;
  const experimentTotalPoints = experimentCorrectArray.length * multiplier;

  const scenarioPoints = researchPoints + experimentPoints;
  const scenarioTotalPoints = researchTotalPoints + experimentTotalPoints;

  const renderTop = () => {
    return (
      <div className="professor_evaluation_scenario_top">
        <LazyMotion features={loadFeatures}>
          <m.div
            className="professor_evaluation_scenario_top_left"
            onClick={() => backHandler()}
            whileHover={{
              scale: 1.02,
              textShadow: selectedColor + " 1px 0px 10px",
            }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
          >
            <BackArrow color={selectedColor} size={"50"} />
            <div className="text_black_medium_bold">{scenario?.name}</div>
          </m.div>
        </LazyMotion>
        <div className="professor_evaluation_scenario_top_right">
          <div
            className="text_black_very_small_bold"
            style={{ marginRight: "5vw" }}
          >
            {t("research") + " | " + t("experiment")}
          </div>
          <div className="text_black_very_small">
            {t("created_by") + " "}
            <b>{"Eutopia"}</b>
          </div>
        </div>
      </div>
    );
  };

  const renderPointsComps = () => {
    return (
      <div className="professor_evaluation_scenario_points_comps">
        {renderPoints()}
        {renderComps()}
      </div>
    );
  };

  const renderPoints = () => {
    return (
      <div className="professor_evaluation_scenario_points">
        <div className="professor_evaluation_scenario_points_total">
          <div className="text_blue_medium_bold">
            <b style={{ fontSize: "2em" }}>{scenarioPoints}</b>
            {" / "}
            <b
              style={{
                backgroundColor: "#EFEFEF",
                borderRadius: "5px",
                padding: "0.1em 0.3em",
              }}
            >
              {scenarioTotalPoints}
            </b>
          </div>
          <div className="text_light_grey_small">{t("obtained_points")}</div>
        </div>
        <div className="professor_evaluation_scenario_points_research">
          <div className="text_light_grey_small">{t("research")}</div>
          <div className="text_blue_medium_bold">
            <b style={{ fontSize: "2em" }}>{researchPoints}</b>
            {" / "}
            <b
              style={{
                backgroundColor: "#EFEFEF",
                borderRadius: "5px",
                padding: "0.1em 0.3em",
              }}
            >
              {multiplier}
            </b>
          </div>
        </div>
        <div className="professor_evaluation_scenario_points_experiment">
          <div className="text_light_grey_small">{t("experiment")}</div>
          <div className="text_blue_medium_bold">
            <b style={{ fontSize: "2em" }}>{experimentPoints}</b>
            {" / "}
            <b
              style={{
                backgroundColor: "#EFEFEF",
                borderRadius: "5px",
                padding: "0.1em 0.3em",
              }}
            >
              {experimentTotalPoints}
            </b>
          </div>
        </div>
      </div>
    );
  };

  const renderComps = () => {
    return (
      <div className="professor_evaluation_scenario_comps">
        {renderEvalCriteria()}
        {renderSpecificComps()}
        {renderGlobalComps()}
      </div>
    );
  };

  const renderEvalCriteria = () => {
    return (
      <div className="professor_evaluation_scenario_comps_criteria">
        {scenario?.evaluation_criteria_ids.map((criterion, index) => {
          var margin = Math.max(
            3 - 0.3 * scenario.evaluation_criteria_ids.length,
            0.2
          );
          const evaluationCriterion = evaluationCriteria.find(
            (evalCrit) => evalCrit.id === criterion
          );

          const specificCompetence = specificCompetences?.find(
            (speComp) =>
              speComp.id === evaluationCriterion?.specific_competence_id
          );
          return (
            <div
              className="center_container"
              style={{
                fontFamily: "Causten",
                fontSize: "1.2vh",
                backgroundColor: "rgba(41, 47, 76, 1)",
                borderRadius: "100px",
                color: "white",
                textAlign: "center",
                marginLeft: index !== 0 ? margin + "em" : undefined,
              }}
              key={index}
            >
              {courseLetter +
                (specificCompetence?.number || 0) +
                "." +
                (evaluationCriterion?.number || 0)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderSpecificComps = () => {
    return (
      <div className="professor_evaluation_scenario_comps_spec">
        {selectedSpecificCompetences.map((competence, index) => {
          var margin = Math.max(
            3 - 0.3 * selectedSpecificCompetences.length,
            0.2
          );
          return (
            <div
              className="center_container"
              style={{
                fontFamily: "Causten",
                fontSize: "1.2vh",
                backgroundColor: competence
                  ? "rgba(41, 47, 76, 1)"
                  : "rgba(41, 47, 76, 0.25)",
                borderRadius: "100px",
                color: "white",
                textAlign: "center",
                marginLeft: index !== 0 ? margin + "em" : undefined,
              }}
            >
              {courseLetter + (index + 1).toString()}
            </div>
          );
        })}
      </div>
    );
  };

  const renderGlobalComps = () => {
    return (
      <div className="professor_evaluation_scenario_comps_key">
        {simpleGlobalCompetences.map((competence, index) => {
          const nonSelectedColor =
            competence[1].substr(0, competence[1].length - 2) + "0.25)";
          var margin = 3 - 0.3 * simpleGlobalCompetences.length;
          return (
            <div
              className="center_container"
              style={{
                fontFamily: "Causten",
                fontSize: "1.2vh",
                backgroundColor: selectedGlobalCompetences[index]
                  ? competence[1]
                  : nonSelectedColor,
                borderRadius: "100px",
                color: "white",
                textAlign: "center",
                marginLeft: index !== 0 ? margin + "em" : undefined,
              }}
            >
              {competence[0]}
            </div>
          );
        })}
      </div>
    );
  };

  const renderResearch = () => {
    var levelIndex = 1;
    return (
      <div className="professor_evaluation_scenario_research_container">
        <div className="professor_evaluation_scenario_research">
          <div className="left_container">
            <div
              className="text_light_grey_medium"
              style={{ fontWeight: "bold" }}
            >
              {t("research")}
            </div>
          </div>
          <div className="professor_evaluation_scenario_research_points">
            <div
              className="text_blue_small"
              style={{ display: "flex", marginRight: "2em" }}
            >
              <b style={{ marginRight: "0.3em" }}>{researchPoints}</b>
              {t("points")}
            </div>
            <div className="text_black_small">=</div>
            <div className="professor_evaluation_scenario_research_points_element">
              <div
                className="text_light_grey_small"
                style={{ marginLeft: "2em", marginRight: "1em" }}
              >
                {t("completed")}
              </div>
              <div
                className="text_light_green_small"
                style={{ display: "flex" }}
              >
                <b style={{ marginRight: "0.3em" }}>+{researchPoints}</b>{" "}
                {t("points")}
              </div>
            </div>
            <div className="professor_evaluation_scenario_research_points_element">
              <div
                className="text_light_grey_small"
                style={{ marginLeft: "2em", marginRight: "1em" }}
              >
                {t("mistakes") + ":"}
              </div>
              <div className="text_red_small" style={{ display: "flex" }}>
                <b style={{ marginRight: "0.3em" }}>{researchErrors}</b>{" "}
                {researchErrors === 1 ? t("mistake") : t("mistakes")}
              </div>
            </div>
            <div className="professor_evaluation_scenario_research_points_element">
              <div
                className="text_light_grey_small"
                style={{ marginLeft: "2em", marginRight: "1em" }}
              >
                {t("clues") + ":"}
              </div>
              <div className="text_red_small" style={{ display: "flex" }}>
                <b style={{ marginRight: "0.3em" }}>{researchHints}</b>{" "}
                {researchHints === 1 ? t("clue") : t("clues")}
              </div>
            </div>
          </div>
          <div className="professor_evaluation_scenario_research_exercises_container">
            <div
              className="professor_evaluation_scenario_research_exercises"
              style={{
                gridTemplateColumns:
                  "repeat(" +
                  (research?.exercises.length || 1) +
                  ", " +
                  100 / (research?.exercises.length || 1) +
                  "%)",
              }}
            >
              {research?.exercises.map((exercise, exerciseIndex) => {
                if (exercise.type === "theory") {
                  return renderTheoryIcon(exerciseIndex);
                } else if (exercise.type === "definition") {
                  return renderDefinitionIcon(exerciseIndex);
                } else if (exercise.type === "media") {
                  return renderMediaIcon(exerciseIndex);
                } else {
                  var copyLevelIndex = levelIndex;
                  levelIndex++;
                  return renderLevelButton(copyLevelIndex, exerciseIndex);
                }
              })}
            </div>
            <div
              className="professor_evaluation_scenario_research_exercises"
              style={{
                gridTemplateColumns:
                  "repeat(" +
                  (research?.exercises.length || 1) +
                  ", " +
                  100 / (research?.exercises.length || 1) +
                  "%)",
              }}
            >
              {research?.exercises.map((exercise, exerciseIndex) => {
                const hint =
                  scenarioProgress?.research_progress?.exercise_progress[
                    exerciseIndex
                  ]?.used_hint || false;
                const mistake =
                  scenarioProgress?.research_progress?.exercise_progress[
                    exerciseIndex
                  ]?.attempts || 0;
                return (
                  <div
                    className="professor_evaluation_scenario_research_exercises_bottom"
                    key={exerciseIndex}
                  >
                    <div className="center_container">
                      {hint && (
                        <img
                          src={teacherHint}
                          alt="teacher hint"
                          style={{ width: "1.5em", height: "1.5em" }}
                        />
                      )}
                      {mistake > 0 && (
                        <div
                          className="text_red_medium_bold"
                          style={{ marginLeft: "0.5em", marginRight: "0.2em" }}
                        >
                          {mistake}
                        </div>
                      )}
                      {mistake > 0 && (
                        <img
                          src={teacherMistake}
                          alt="teacher mistake"
                          style={{ width: "1.5em", height: "1.5em" }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTheoryIcon = (exerciseIndex: number) => {
    return (
      <div
        className="professor_evaluation_scenario_research_exercises_element"
        key={exerciseIndex}
      >
        <div className="center_container">
          <TheoryIcon color={"black"} size={25} />
        </div>
      </div>
    );
  };

  const renderMediaIcon = (exerciseIndex: number) => {
    return (
      <div
        className="professor_evaluation_scenario_research_exercises_element"
        key={exerciseIndex}
      >
        <div className="center_container">
          <MediaIcon color={"black"} size={25} />
        </div>
      </div>
    );
  };

  const renderLevelButton = (level: number, exerciseIndex: number) => {
    return (
      <div
        className="professor_evaluation_scenario_research_exercises_element"
        key={exerciseIndex}
      >
        <div className="center_container">
          <div className={"text_black_medium_bold"}>{level}</div>
        </div>
      </div>
    );
  };

  const renderDefinitionIcon = (exerciseIndex: number) => {
    return (
      <div className="center_container">
        <DefinitionIcon color={"black"} size={25} />
      </div>
    );
  };

  const renderExperiment = () => {
    return (
      <div className="professor_evaluation_scenario_experiment_container">
        <div className="professor_evaluation_scenario_experiment">
          <div className="left_container">
            <div
              className="text_light_grey_medium"
              style={{ fontWeight: "bold" }}
            >
              {t("experiment")}
            </div>
          </div>
          <div className="professor_evaluation_scenario_experiment_points">
            <div
              className="text_blue_small"
              style={{ display: "flex", marginRight: "2em" }}
            >
              <b style={{ marginRight: "0.3em" }}>{experimentPoints}</b>
              {t("points")}
            </div>
            <div className="text_black_small">=</div>
            <div className="professor_evaluation_scenario_experiment_points_map">
              {experimentCorrectArray?.map(
                (progress: boolean, index: number) => {
                  return (
                    <div
                      className="professor_evaluation_scenario_experiment_points_map_element"
                      key={index}
                    >
                      <div
                        className="text_light_grey_small"
                        style={{ marginLeft: "2em", marginRight: "1em" }}
                      >
                        {t("screen") + " "} {index + 1}
                      </div>
                      {progress ? (
                        <div className="text_light_green_small">
                          {" "}
                          <b>+{multiplier}</b> {t("points")}
                        </div>
                      ) : (
                        <div className="text_red_small">
                          {" "}
                          <b>+0</b> {t("points")}
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="professor_evaluation_scenario_experiment_exercises">
            {experimentCorrectArray?.map((progress: boolean, index: number) => {
              return (
                <div
                  className="professor_evaluation_scenario_experiment_exercises_element"
                  key={index}
                >
                  <div className="center_container">
                    <div
                      className="professor_evaluation_scenario_experiment_exercises_element_circle"
                      style={{
                        backgroundColor: progress ? "#6EE6A7" : "#F0684F",
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="left_container">
            <div className="text_black_very_small">
              {t("experiment_tries_text_1")}
              <text style={{ color: "#5090F0" }}>
                {(scenarioProgress?.experiment_progress?.length || 0) +
                  t("experiment_tries_text_2")}
              </text>
              {t("experiment_tries_text_3")}
              <text style={{ color: "#5090F0" }}>
                {calcBetterAttempts() + t("experiment_tries_text_4")}
              </text>
              {t("experiment_tries_text_5")}
              <text style={{ color: "#5090F0" }}>
                {calcWorseAttempts() + t("experiment_tries_text_6")}
              </text>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div
        className="professor_evaluation_scenario_background"
        style={{ boxShadow: "0px 0px 10px 1px " + selectedColor + "80" }}
      >
        {renderTop()}
        {renderPointsComps()}
        {renderResearch()}
        {renderExperiment()}
      </div>
    );
  };

  return (
    <>
      {!loading && scenario !== undefined && scenario !== null && renderMain()}
      {loading && <Loading />}
    </>
  );
};

export default ProfessorEvaluationScenario;
