import { GetMyStudentProgressAPI } from "./../_newapios/progress/student_scenario";
import { StudentType } from "../_newapios/user/student";
import { GetStudentPowerAllAPI } from "../_newapios/progress/student_power";
import { GetStudentHouseAllAPI } from "../_newapios/progress/student_house";
import {
  GetStudentActiveItemsAPI,
  GetStudentBiodiversityAPI,
  GetStudentPointsAPI,
} from "../_newapios/progress/student_game_metrics";
import { GetStudentExplorerAllAPI } from "../_newapios/progress/student_explorer";
import { GetAllStudentEutonsAPI } from "../_newapios/progress/student_euton";

export const calcTutorialPart = async (
  student: StudentType | undefined,
  localMapStudentId: string | undefined,
  end: boolean,
  theme_id: string | undefined,
  studentMenu: string
) => {
  if (
    student === undefined ||
    localMapStudentId !== undefined ||
    theme_id === undefined
  ) {
    return "none";
  }

  if (student.plot_id === null) {
    return "choose_plot";
  }

  if (end) {
    return "end";
  }

  const scenarioProgresses = await GetMyStudentProgressAPI("me");
  const scenarioProgress = scenarioProgresses.find((progress) => {
    return progress.experiment_progress.length > 0;
  });
  console.log(scenarioProgresses, scenarioProgress);
  if (
    scenarioProgresses.length === 0 ||
    (scenarioProgress !== undefined &&
      scenarioProgress.experiment_progress.length === 0)
  ) {
    return "campus";
  }

  if (theme_id === "theme_id_2") {
    const researchPoints = await GetStudentPointsAPI("me");
    const myEutons = await GetAllStudentEutonsAPI("me");
    if (myEutons.length === 0) {
      if (researchPoints < 1) {
        return "repeat_campus";
      } else {
        return "energy_lab";
      }
    }
    let anyEutonUnlocked = false;

    myEutons.forEach((euton) => {
      if (euton.euton_unlocked) {
        anyEutonUnlocked = true;
      }
    });

    if (!anyEutonUnlocked) {
      return "workshop";
    }
  } else {
    const researchPoints = await GetStudentPointsAPI("me");
    const powerProgress = await GetStudentPowerAllAPI("me");

    if (powerProgress.length === 0) {
      if (researchPoints < 2) {
        return "repeat_campus";
      } else {
        if (studentMenu === "campus") {
          return "close_campus";
        } else {
          return "energy_lab";
        }
      }
    }

    const houseProgress = await GetStudentHouseAllAPI("me");
    if (houseProgress.length === 0) {
      return "workshop";
    }
  }

  return "none";
};
