const Loading = () => {
  return (
    <div style={{ margin: "auto" }}>
      <div
        className="loading-floater"
        style={{ backgroundColor: "#F0684F" }}
      ></div>
      <div
        className="loading-floater"
        style={{ backgroundColor: "#FDA844", animationDelay: "0.25s" }}
      ></div>
      <div
        className="loading-floater"
        style={{ backgroundColor: "#FECF3C", animationDelay: "0.5s" }}
      ></div>
      <div
        className="loading-floater"
        style={{ backgroundColor: "#87D836", animationDelay: "0.75s" }}
      ></div>
      <div
        className="loading-floater"
        style={{ backgroundColor: "#6DABF2", animationDelay: "1s" }}
      ></div>
      <div
        className="loading-floater"
        style={{ backgroundColor: "#C06DF2", animationDelay: "1.25s" }}
      ></div>
      <div
        className="loading-floater"
        style={{ backgroundColor: "#EC6ED8", animationDelay: "1.5s" }}
      ></div>
      <div
        className="loading-floater"
        style={{ backgroundColor: "#964B00", animationDelay: "1.75s" }}
      ></div>
    </div>
  );
};

export default Loading;
