import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces";

const MejoraVegetacionMountain = ({ ...props }) => {
  const treeSpruce = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/tree_spruce_orange.gltf"
  ) as DreiGLTF;
  const treeForest = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/tree_forest_orange.gltf"
  ) as DreiGLTF;
  const treeBeech = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/tree_beech_orange.gltf"
  ) as DreiGLTF;
  const treeBirch = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/tree_birch_orange.gltf"
  ) as DreiGLTF;
  const arbusto01 = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/arbusto01.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirch.nodes["tree-birch004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.09, 0.36, 9.71]}
        rotation={[0, 0.02, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirch.nodes["tree-birch004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.64, 0.36, -1.39]}
        rotation={[0, -1.03, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.33, 0.45, 9.79]}
        rotation={[0, -0.91, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[2.86, 0.45, 9.16]}
        rotation={[0, -0.91, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-0.54, 0.45, 10.89]}
        rotation={[0, -0.91, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.8, 0.45, 11.96]}
        rotation={[0, -0.56, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-7.79, 0.45, 6.35]}
        rotation={[0, -0.56, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.82, 0.34, 4.54]}
        rotation={[0, 1.51, 0]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-12.04, 0.44, 0.29]}
        rotation={[0, -1.11, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-14.63, 0.39, -2.37]}
        rotation={[0, 1.17, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.94, 0.34, -3.62]}
        rotation={[0, -0.24, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.47, 0.34, -4.33]}
        rotation={[0, -0.59, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.27, 0.34, 0.6]}
        rotation={[0, -0.59, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.79, 0.34, -5.2]}
        rotation={[0, 1.33, 0]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.93, 0.34, -4.13]}
        rotation={[0, -0.59, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.77, 0.34, -0.57]}
        rotation={[0, 1.2, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.24, 0.34, 0.43]}
        rotation={[0, 0.97, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.54, 0.34, -1.49]}
        rotation={[0, -0.59, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.29, 0.34, -5.43]}
        rotation={[0, 1.33, 0]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.46, 0.34, -5.29]}
        rotation={[Math.PI, -1.11, Math.PI]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.26, 0.34, -4.16]}
        rotation={[0, -0.59, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[6.48, 0.34, 5.24]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[8.34, 0.57, 6.93]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.82, 0.43, 5.37]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[8.13, 0.34, 3.79]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.39, 0.34, -5.51]}
        rotation={[0, -1.18, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.36, 0.46, -5.74]}
        rotation={[-Math.PI, 0.46, -Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.34, 0.4, -3.41]}
        rotation={[-Math.PI, 1.51, -Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBeech.nodes["tree-beech_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.19, 0.4, -3.33]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[3.04, 0.35, 7.51]}
        rotation={[0, -0.37, 0]}
        scale={0.23}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[3.08, 0.56, 10.27]}
        rotation={[Math.PI, -0.27, Math.PI]}
        scale={0.23}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[0.28, 0.54, 11.34]}
        rotation={[0, 0.86, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.07, 0.44, 11.26]}
        rotation={[Math.PI, -0.19, Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.61, 0.35, 6.55]}
        rotation={[0, -0.23, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.78, 0.4, 1.61]}
        rotation={[0, -0.95, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-13.48, 0.35, -2.94]}
        rotation={[0, 0.82, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.02, 0.36, 1.69]}
        rotation={[0, 1.22, 0]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.88, 0.37, 1.42]}
        rotation={[Math.PI, -0.17, Math.PI]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.93, 0.35, -0.13]}
        rotation={[0, -0.35, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.62, 0.35, -0.19]}
        rotation={[0, -0.8, 0]}
        scale={0.17}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.19, 0.35, -1.15]}
        rotation={[Math.PI, -0.14, Math.PI]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.68, 0.35, -2.09]}
        rotation={[0, -0.27, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.41, 0.35, -2.71]}
        rotation={[Math.PI, -0.31, Math.PI]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-7.16, 0.35, -5.82]}
        rotation={[Math.PI, -0.56, Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.92, 0.35, -3.69]}
        rotation={[-Math.PI, 0.03, -Math.PI]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.98, 0.35, -3.11]}
        rotation={[-Math.PI, 0.03, -Math.PI]}
        scale={0.16}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.49, 0.35, -6.98]}
        rotation={[Math.PI, -0.66, Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.83, 0.35, -6.33]}
        rotation={[0, -0.01, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.3, 0.35, -5.38]}
        rotation={[-Math.PI, 0.19, -Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.08, 0.35, -3.89]}
        rotation={[0, 0.21, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.9, 0.35, -2.25]}
        rotation={[-Math.PI, 0.03, -Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.39, 0.35, -3.23]}
        rotation={[0, -0.4, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.35, 0.35, -1.72]}
        rotation={[Math.PI, -0.27, Math.PI]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-1.92, 0.35, 0.36]}
        rotation={[0, -0.27, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.49, 0.35, -1.47]}
        rotation={[0, 0.96, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-1.68, 0.35, -2.24]}
        rotation={[0, -0.69, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.59, 0.35, -4.71]}
        rotation={[0, -0.69, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-1.7, 0.35, -5.94]}
        rotation={[0, -0.18, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[0.63, 0.35, -7.55]}
        rotation={[0, -0.18, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[9.47, 0.53, 6.42]}
        rotation={[0, 0.15, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.23, 0.35, 3.15]}
        rotation={[0, -0.83, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[9.1, 0.36, 3.76]}
        rotation={[0, -0.13, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.77, 0.38, -2.67]}
        rotation={[0, -0.65, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.23, 0.35, -0.33]}
        rotation={[0, -0.65, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.3, 0.37, -1.98]}
        rotation={[0, 0.07, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[4.6, 0.42, -4.03]}
        rotation={[0, -0.18, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[6.31, 0.44, -6.06]}
        rotation={[0, -0.8, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest_orange004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.51, 0.4, 4.62]}
        rotation={[0, 0.38, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce_orange"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.29, 0.35, -1.97]}
        rotation={[0, 0.38, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbusto01.nodes["shrub-round029"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.44, 0.32, 9.25]}
        rotation={[0, 0.45, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbusto01.nodes["shrub-round029"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.63, 0.32, -5.45]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbusto01.nodes["shrub-round029"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.89, 0.32, -4.74]}
        rotation={[0, 0.26, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbusto01.nodes["shrub-round029"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.59, 0.27, -1.87]}
        rotation={[Math.PI, -0.35, Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbusto01.nodes["shrub-round029"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.3, 0.27, -1.14]}
        rotation={[-Math.PI, 0.65, -Math.PI]}
        scale={0.34}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/tree_spruce_orange.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/tree_forest_orange.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/tree_beech_orange.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/tree_birch_orange.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/arbusto01.gltf"
);

export default MejoraVegetacionMountain;
