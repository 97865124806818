import { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { Group } from "three";
import { AssetType } from "../../../../_newapios/gamecontent/house_upgrades.ts";

import { DreiGLTF } from "../../../../interfaces.ts";
import { StaticEutonProps } from "../Biospheres/EutonStatic.tsx";

const DrInkariusStatic = ({
  groupProps,
  propsAsset,
  renderShadow,
}: StaticEutonProps) => {
  const [asset, setAsset] = useState<AssetType>(propsAsset);
  const DrInkarius = useGLTF(asset.gltf.url) as DreiGLTF;
  const groupRef = useRef<Group>(null);
  const { actions } = useAnimations(DrInkarius.animations, groupRef);

  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      for (let i = 0; i < Object.keys(actions).length; i++) {
        const action = Object.keys(actions)[i];
        actions[action]?.reset().play();
      }
    }
  }, [actions]);

  return (
    <group ref={groupRef} dispose={null}>
      <group
        name="Scene"
        position={groupProps?.position}
        rotation={groupProps?.rotation}
      >
        <group
          name="flotador_static_gltf"
          position={[-0.001, 0.129, 0.045]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0.238}
        >
          <mesh
            name="flotador"
            castShadow
            receiveShadow
            geometry={DrInkarius.nodes.flotador.geometry}
            material={DrInkarius.materials.flotador_bajo}
            morphTargetDictionary={
              DrInkarius.nodes.flotador.morphTargetDictionary
            }
            morphTargetInfluences={
              DrInkarius.nodes.flotador.morphTargetInfluences
            }
          />
          <mesh
            name="flotador_1"
            castShadow
            receiveShadow
            geometry={DrInkarius.nodes.flotador_1.geometry}
            material={DrInkarius.materials.flotador_alto}
            morphTargetDictionary={
              DrInkarius.nodes.flotador_1.morphTargetDictionary
            }
            morphTargetInfluences={
              DrInkarius.nodes.flotador_1.morphTargetInfluences
            }
          />
        </group>
        <group
          name="octopus_static_gltf"
          position={[-0.059, 0.133, 0.09]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0.789}
        >
          <mesh
            name="octopus"
            castShadow
            receiveShadow
            geometry={DrInkarius.nodes.octopus.geometry}
            material={DrInkarius.materials.violeta_cuerpo}
            morphTargetDictionary={
              DrInkarius.nodes.octopus.morphTargetDictionary
            }
            morphTargetInfluences={
              DrInkarius.nodes.octopus.morphTargetInfluences
            }
          />
          <mesh
            name="octopus_1"
            castShadow
            receiveShadow
            geometry={DrInkarius.nodes.octopus_1.geometry}
            material={DrInkarius.materials.textura_cuerpo}
            morphTargetDictionary={
              DrInkarius.nodes.octopus_1.morphTargetDictionary
            }
            morphTargetInfluences={
              DrInkarius.nodes.octopus_1.morphTargetInfluences
            }
          />
          <mesh
            name="octopus_2"
            castShadow
            receiveShadow
            geometry={DrInkarius.nodes.octopus_2.geometry}
            material={DrInkarius.materials.metal}
            morphTargetDictionary={
              DrInkarius.nodes.octopus_2.morphTargetDictionary
            }
            morphTargetInfluences={
              DrInkarius.nodes.octopus_2.morphTargetInfluences
            }
          />
          <mesh
            name="octopus_3"
            castShadow
            receiveShadow
            geometry={DrInkarius.nodes.octopus_3.geometry}
            material={DrInkarius.materials.negro}
            morphTargetDictionary={
              DrInkarius.nodes.octopus_3.morphTargetDictionary
            }
            morphTargetInfluences={
              DrInkarius.nodes.octopus_3.morphTargetInfluences
            }
          />
          <mesh
            name="octopus_4"
            castShadow
            receiveShadow
            geometry={DrInkarius.nodes.octopus_4.geometry}
            material={DrInkarius.materials.metal}
            morphTargetDictionary={
              DrInkarius.nodes.octopus_4.morphTargetDictionary
            }
            morphTargetInfluences={
              DrInkarius.nodes.octopus_4.morphTargetInfluences
            }
          />
          <mesh
            name="octopus_5"
            castShadow
            receiveShadow
            geometry={DrInkarius.nodes.octopus_5.geometry}
            material={DrInkarius.materials.cristal_cabeza}
            morphTargetDictionary={
              DrInkarius.nodes.octopus_5.morphTargetDictionary
            }
            morphTargetInfluences={
              DrInkarius.nodes.octopus_5.morphTargetInfluences
            }
          />
          <mesh
            name="octopus_6"
            castShadow
            receiveShadow
            geometry={DrInkarius.nodes.octopus_6.geometry}
            material={DrInkarius.materials.fondo_ojos}
            morphTargetDictionary={
              DrInkarius.nodes.octopus_6.morphTargetDictionary
            }
            morphTargetInfluences={
              DrInkarius.nodes.octopus_6.morphTargetInfluences
            }
          />
          <mesh
            name="octopus_7"
            castShadow
            receiveShadow
            geometry={DrInkarius.nodes.octopus_7.geometry}
            material={DrInkarius.materials.pupilas_ojos}
            morphTargetDictionary={
              DrInkarius.nodes.octopus_7.morphTargetDictionary
            }
            morphTargetInfluences={
              DrInkarius.nodes.octopus_7.morphTargetInfluences
            }
          />
        </group>
      </group>
    </group>
  );
};

export default DrInkariusStatic;
