import env from "../../config/env.json";
import APIFetch from "../../utils/APIFetch";
import { BundleType, ModifiedBundleType } from "../user/class";
import { UnitType } from "./unit";

export interface BundleCompletionBusinessType {
  bundle_id: string;
  school_id: string;
  license_name: string;
  start_date: Date;
  assigned: number;
  completed: number;
}

export interface BundleBusinessType {
  id: string;
  class: {
    school_id: string;
  };
}

// export const CreateBundleAPI = async (
//   bundle: BundleType
// ): Promise<BundleType> => {
//   var req = await fetch(env.backend_host + "/bundle", {
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//     method: "POST",
//     body: JSON.stringify(bundle),
//     mode: "cors",
//     credentials: "include",
//   });
//   var data = await req.json();

//   return data;
// };

export const CreateBundleAPI = async (
  bundle: BundleType
): Promise<BundleType> => {
  const data = APIFetch(env.backend_host + "/bundle", {
    method: "POST",
    body: JSON.stringify(bundle),
  });
  return data;
};

export const UpdateBundleAPI = async (
  bundle: BundleType
): Promise<BundleType> => {

  const data = APIFetch(env.backend_host + "/bundle/" + bundle.id, {
    method: "POST",
    body: JSON.stringify(bundle),
  });

  return data;
};

export const DeleteBundleAPI = async (id: string): Promise<void> => {
  // var req = await fetch(env.backend_host + "/bundle/" + id, {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "DELETE",
  //   mode: "cors",
  //   credentials: "include",
  // });
  const data = APIFetch(env.backend_host + "/bundle/" + id, {
    method: "DELETE",
  });
};

export const GetBundleAPI = async (id: string): Promise<BundleBusinessType> => {
  // var req = await fetch(env.backend_host + "/bundle/" + id, {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  const data = APIFetch(env.backend_host + "/bundle/" + id, {
    method: "GET",
  });
  return data;
};

export const GetStudentBundlesAPI = async (
  id: string
): Promise<ModifiedBundleType[]> => {
  // var req = await fetch(env.backend_host + "/student/" + id + "/bundles", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  const data = APIFetch(env.backend_host + "/student/" + id + "/bundles", {
    method: "GET",
  });
  return data;
};

export const GetAllCompletionsBusiness = async (
  startDate: string,
  endDate: string
): Promise<BundleCompletionBusinessType[]> => {
  // var req = await fetch(env.backend_host + "/bundle/all_completions", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "POST",
  //   mode: "cors",
  //   body: JSON.stringify({
  //     start_date: startDate,
  //     end_date: endDate,
  //   }),
  //   credentials: "include",
  // });
  // var data = await req.json();
  const data = APIFetch(env.backend_host + "/bundle/all_completions", {
    method: "POST",
    body: JSON.stringify({
      start_date: startDate,
      end_date: endDate,
    }),
  });
  return data;
};
