import "./StudentRadarChart.css";
import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const StudentRadarChart = ({ ...props }) => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  var labelNames = [];
  var labelColors = [];

  for (let i = 0; i < props.labels.length; i++) {
    labelNames.push(props.labels[i].name);
    labelColors.push(props.labels[i].color);
  }

  const data = {
    labels: labelNames,
    datasets: [
      {
        data: props.studentData,
        backgroundColor: "rgb(255, 255, 255, 50%)",
        borderColor: "rgb(255, 255, 255, 100%)",
        borderWidth: 2,
      },
    ],
  };

  const options: any = {
    scales: {
      r: {
        ticks: {
          display: false,
          stepSize: props.max / 4,
        },
        beginAtZero: true,
        min: -props.max / 4,
        max: props.max,
        pointLabels: {
          font: {
            size: Math.floor(screenSize.dynamicWidth * 0.018),
            weight: "bold",
            style: "italic",
            family: "Causten",
          },
          color: labelColors,
        },
        angleLines: {
          display: false,
        },
        grid: {
          lineWidth: [3, 1, 1, 1, 1],
          color: ["#FFDD66", "#292F4C", "#292F4C", "#292F4C", "#292F4C"],
        },
        backgroundColor: "#6A798F",
      },
    },

    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div
      className={
        props.full ? "student_radar_chart_full" : "student_radar_chart"
      }
    >
      <Radar data={data} options={options} />
    </div>
  );
};

export default StudentRadarChart;
