import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces";

const BiosphereVolcano = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_volcan_op.glb"
  ) as DreiGLTF;
  return (
    <group {...props} dispose={null}>
      <group
        position={[10.518, 0.651, 32.066]}
        rotation={[Math.PI, -0.177, Math.PI]}
        scale={0.177}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_volcan_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_volcan_2.geometry}
          material={materials.gris_puertas}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_volcan_3.geometry}
          material={materials.blanco_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_volcan_4.geometry}
          material={materials.naranja_volcan_puertas}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_volcan_5.geometry}
          material={materials.naranja_volcan_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_volcan_6.geometry}
          material={materials.naranja_volcan_emision}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_volcan_7.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
        />
      </group>
      <group position={[10.556, 1.796, 32.277]} scale={0.177}>
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_volcan_1.geometry}
          material={materials.naranja_volcan_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_volcan_2.geometry}
          material={materials.logo_bio_volcan}
        />
      </group>
      <group
        position={[10.556, 1.226, 32.277]}
        rotation={[Math.PI, -0.177, Math.PI]}
        scale={0.177}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_volcan_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_volcan_2.geometry}
          material={materials.naranja_volcan_cristales}
        />
      </group>
      <group
        position={[9.701, -0.05, 27.413]}
        rotation={[0, 0.176, 0]}
        scale={[0.138, 0.537, 5.467]}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_volcan_1.geometry}
          material={materials.camino_bordes}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_volcan_2.geometry}
          material={materials.naranja_volcan_metal}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_volcan_op.glb"
);

export default BiosphereVolcano;
