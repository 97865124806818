const LogButton = (
  props: {
    color?: string;
    size?: number; // Adding size as an optional number prop
  } = {
      color: "black",
      size: 42
    }
) => {
  const { color, size } = props; // Destructuring props
  return (

    <svg width={size}
      height={size} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="user">
        <path id="Path 4" d="M9.625 34.125C9.625 34.125 15.75 31.5 17.5 29.75C19.25 28 14 28 14 19.25C14 10.5 21 10.5 21 10.5C21 10.5 28 10.5 28 19.25C28 28 22.75 28 24.5 29.75C26.25 31.5 32.375 34.125 32.375 34.125" stroke={color} stroke-width="2" stroke-linecap="round" />
        <path id="Oval" fill-rule="evenodd" clip-rule="evenodd" d="M21 38.5C30.665 38.5 38.5 30.665 38.5 21C38.5 11.335 30.665 3.5 21 3.5C11.335 3.5 3.5 11.335 3.5 21C3.5 30.665 11.335 38.5 21 38.5Z" stroke={color} stroke-width="2" />
      </g>
    </svg>

  );
};

export default LogButton;