import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../interfaces";

const SavanahMainland = ({ ...props }) => {
  const grassClumb = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/grass_clumb_orange_sabana.gltf"
  ) as DreiGLTF;
  const grassTall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/grass_tall_sabana.gltf"
  ) as DreiGLTF;
  const stoneRound = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/stone_round_sabana.gltf"
  ) as DreiGLTF;
  const treeBaobabYellow = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/tree_baobab_yellow_sabana.gltf"
  ) as DreiGLTF;
  const treeBaobabOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/tree_birch_orange_sabana.gltf"
  ) as DreiGLTF;
  const treeBirch = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/tree_birch_sabana.gltf"
  ) as DreiGLTF;
  const treeBirchTall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/tree_birch_tall_orange_sabana.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirch.nodes["tree-birch_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.2, 0.04, 7.74]}
        rotation={[0, 0.63, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirchTall.nodes["tree-birch-tall_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.03, -0.03, 7.06]}
        rotation={[0, -0.22, 0]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirchTall.nodes["tree-birch-tall_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[1.32, -0.27, 6.5]}
        rotation={[0, -0.56, 0]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirchTall.nodes["tree-birch-tall_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[0.66, 0, 6.13]}
        rotation={[0, 0.24, 0]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobabYellow.nodes["tree-baobab_yellow001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.78, 0.41, 4.96]}
        rotation={[0, 1.11, 0]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobabYellow.nodes["tree-baobab_yellow001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.17, 0.1, 5.02]}
        rotation={[0, 0.64, 0]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobabOrange.nodes["tree-baobab_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[1.22, 0.18, 3.98]}
        rotation={[0, 0.21, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobabOrange.nodes["tree-baobab_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.34, 0.16, 3.9]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneRound.nodes["stone-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-1.07, 0.13, 3.68]}
        rotation={[1.76, -0.59, 1.1]}
        scale={[0.53, 0.76, 0.53]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneRound.nodes["stone-round001"].geometry}
        material={props.bakedMaterial1}
        position={[1.55, 0.13, 8.91]}
        rotation={[1.31, -0.16, 1.03]}
        scale={[0.28, 0.41, 0.28]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneRound.nodes["stone-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.25, 0.13, 5.97]}
        rotation={[0, -0.85, 0]}
        scale={[0.36, 0.52, 0.36]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassTall.nodes["grass-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-0.01, 0.19, 9.89]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassTall.nodes["grass-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-1.36, 0.08, 5.22]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassTall.nodes["grass-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-6.34, 0.08, 5.16]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-1.41, 0.16, 7.52]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.99, 0.09, 9.22]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirchTall.nodes["tree-birch-tall_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-6.98, -0.3, 5.49]}
        rotation={[0, -0.73, 0]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassTall.nodes["grass-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.9, 0.08, 5.14]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.66, 0.09, 9.82]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirchTall.nodes["tree-birch-tall_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-7.72, -0.03, 5.46]}
        rotation={[0, 0.42, 0]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassTall.nodes["grass-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-8.4, 0.08, 4.73]}
        rotation={[0, -0.29, 0]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassTall.nodes["grass-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-10.96, 0.81, 4.28]}
        rotation={[0, -0.29, 0]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirchTall.nodes["tree-birch-tall_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-2.55, -0.03, 7.07]}
        rotation={[0, 0.25, 0]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirch.nodes["tree-birch_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-0.97, 0.04, 6.08]}
        rotation={[0, 0.63, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.51, 0.21, 6.65]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[0.17, 0.09, 5.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[2.12, 0.09, 5.88]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.03, 0.09, 3.53]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.38, 0.48, 6.33]}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/grass_clumb_orange_sabana.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/grass_tall_sabana.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/stone_round_sabana.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/tree_baobab_yellow_sabana.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/tree_birch_orange_sabana.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_sabana/tree_birch_tall_orange_sabana.gltf"
);

export default SavanahMainland;
