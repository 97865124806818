const HeartIcon = (
  props: {
    text?: number;
    width?: number;
    height?: number | string;
  } = {
      text: undefined,
      width: 179,
      height: 156
    }
) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 179 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >

      {props.text === undefined ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#F0684F" />

        </>
      ) : props.text >= 100 ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#F0684F" />
          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      ) : props.text > 90 ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#D9D9D9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2029 16C4.57785 24.1831 0 34.9966 0 46.9919C0 78.9869 30.3801 105.062 76.409 144.568L76.5225 144.665L89.5 155.847L102.477 144.75L102.831 144.446C148.725 104.971 179 78.9312 179 46.9919C179 34.9966 174.422 24.1831 166.797 16H91.509C90.8174 16.6835 90.1473 17.3855 89.5 18.1049C88.8527 17.3855 88.1826 16.6835 87.491 16H12.2029Z" fill="#F0684F" />
          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      ) : props.text > 80 ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#D9D9D9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.65885 31C0.933245 35.8008 0 40.9759 0 46.3919C0 78.3869 30.3801 104.462 76.409 143.968L76.5225 144.065L89.5 155.247L102.477 144.15L102.831 143.846C148.725 104.371 179 78.3313 179 46.3919C179 40.9759 178.067 35.8008 176.341 31H2.65885Z" fill="#F0684F" />

          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      ) : props.text > 70 ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#D9D9D9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 46C0.131459 77.9001 30.4795 103.947 76.4086 143.368L76.5221 143.465L89.4996 154.647L102.477 143.55L102.83 143.246C148.625 103.857 178.868 77.8445 178.999 46H0Z" fill="#F0684F" />
          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      ) : props.text > 60 ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#D9D9D9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 63C10.1837 88.3841 37.6271 111.939 75.8769 144.768L75.9904 144.865L88.9679 156.047L101.945 144.95L102.299 144.646C140.424 111.854 167.77 88.3332 175.936 63H2Z" fill="#F0684F" />
          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      ) : props.text > 50 ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#D9D9D9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 78C22.5212 97.9149 46.0652 118.123 76.4109 144.168L76.5244 144.265L89.5019 155.447L102.479 144.35L102.833 144.046C133.064 118.043 156.518 97.8699 169.006 78H10Z" fill="#F0684F" />
          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      ) : props.text > 40 ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#D9D9D9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22 94C35.3009 109.153 54.062 125.256 76.5626 144.568L76.6761 144.665L89.6536 155.847L102.631 144.75L102.984 144.446C125.384 125.18 144.063 109.114 157.317 94H22Z" fill="#F0684F" />

          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      ) : props.text > 30 ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#D9D9D9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M37 110C48.1567 120.678 61.4782 132.112 76.4568 144.968L76.5703 145.065L89.5478 156.247L102.525 145.15L102.879 144.846C117.772 132.036 131.02 120.641 142.123 110H37Z" fill="#F0684F" />

          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      ) : props.text > 20 ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#D9D9D9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M54 125C60.9405 131.122 68.4237 137.545 76.3737 144.368L76.4871 144.465L89.4646 155.647L102.442 144.55L102.795 144.246C110.677 137.467 118.098 131.084 124.983 125H54Z" fill="#F0684F" />

          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      ) : props.text > 10 ? (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#D9D9D9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M72 141C73.4465 142.243 74.9096 143.498 76.3888 144.768L76.5023 144.865L89.4798 156.047L102.457 144.95L102.811 144.646C104.239 143.418 105.651 142.203 107.049 141H72Z" fill="#F0684F" />
          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      ) : (
        <>
          <path d="M89.5 155.447L76.5225 144.265C30.43 104.705 0 78.6132 0 46.5919C0 20.5004 21.659 0 49.225 0C64.798 0 79.7445 6.86171 89.5 17.7049C99.2555 6.86171 114.202 0 129.775 0C157.341 0 179 20.5004 179 46.5919C179 78.6132 148.57 104.705 102.477 144.35L89.5 155.447Z" fill="#D9D9D9" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M72 141C73.4465 142.243 74.9096 143.498 76.3888 144.768L76.5023 144.865L89.4798 156.047L102.457 144.95L102.811 144.646C104.239 143.418 105.651 142.203 107.049 141H72Z" fill="#F0684F" />
          <text
            x="50%"
            y="50%"
            fill="white"
            fontFamily="Causten"
            fontSize="3.5em"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.text.toString()}
          </text>
        </>
      )}

    </svg>
  );
};

export default HeartIcon;
