import { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";
import { Group } from "three";
import { Text3D, Center } from "@react-three/drei";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../hooks/hooks.ts";

const Shuttle = ({ ...props }) => {
  const group = useRef<Group>(null);
  const { t } = useTranslation();
  const { nodes, materials, animations } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Lanzadera/lanzadera.glb"
  ) as DreiGLTF;
  const { actions } = useAnimations(animations, group);
  const studentState = useAppSelector((state) => state.student);
  const theme = studentState.theme;

  const tutorialPart = props.tutorial;

  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      for (let i = 0; i < Object.keys(actions).length; i++) {
        const action = Object.keys(actions)[i];
        if (props.renderAnimation()) {
          actions[action]?.reset().play();
        } else {
          actions[action]?.stop();
        }
      }
    }
  }, [actions, props.renderAnimation()]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        {theme?.id !== "theme_id" && (
          <>
            <Center
              position={[-2.993, 6, 19.838]}
              rotation={[Math.PI / 8, 2.5, -Math.PI / 12]}
            >
              <Text3D
                font="./Causten Bold_Regular.json"
                size={0.5}
                height={0.01}
                curveSegments={12}
                bevelEnabled
                bevelThickness={0.02}
                bevelSize={0.02}
                bevelOffset={0}
                bevelSegments={5}
              >
                {t("shuttle")}
                <meshStandardMaterial
                  color={tutorialPart === "workshop" ? "#fb9e12" : "#4da7FF"}
                />
              </Text3D>
            </Center>
          </>
        )}
        <group
          name="lanzadera"
          position={[-2.993, 0.404, 19.838]}
          rotation={[0, 1.571, 0]}
          scale={0.143}
        >
          <mesh
            name="lanzadera_1"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.lanzadera_1.geometry}
            material={materials["LOWPOLY-COLORS.004"]}
          />
          <mesh
            name="lanzadera_2"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.lanzadera_2.geometry}
            material={nodes.lanzadera_2.material}
          />
        </group>
        <group
          name="specimen_tube_lanzadera"
          position={[-2.982, 2.607, 19.828]}
          rotation={[0, 1.571, 0]}
          scale={0.591}
        >
          <mesh
            name="specimen_tube_lanzadera_1"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.specimen_tube_lanzadera_1.geometry}
            material={materials["LOWPOLY-COLORS.004"]}
          />
          <mesh
            name="specimen_tube_lanzadera_2"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.specimen_tube_lanzadera_2.geometry}
            material={materials.glass}
          />
          <mesh
            name="specimen_tube_lanzadera_3"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.specimen_tube_lanzadera_3.geometry}
            material={materials.liquid}
          />
        </group>
        <group
          name="dna_lanzadera"
          position={[-2.982, 4.877, 19.828]}
          rotation={[-Math.PI, (-Math.PI * 3) / 8, -Math.PI]}
          scale={0.03}
        >
          <mesh
            name="dna_lanzadera_1"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.dna_lanzadera_1.geometry}
            material={materials.dna_metal}
          />
          <mesh
            name="dna_lanzadera_2"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.dna_lanzadera_2.geometry}
            material={materials.dna_emision}
          />
          <mesh
            name="floating_boxes01_lanzadera"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.floating_boxes01_lanzadera.geometry}
            material={materials["LOWPOLY-COLORS.004"]}
            position={[0.207, -89.119, -38.633]}
            scale={6.608}
          />
          <mesh
            name="floating_boxes02_lanzadera"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.floating_boxes02_lanzadera.geometry}
            material={materials["LOWPOLY-COLORS.004"]}
            position={[-38.803, -89.119, 0.377]}
            scale={6.608}
          />
          <mesh
            name="floating_boxes03_lanzadera"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.floating_boxes03_lanzadera.geometry}
            material={materials["LOWPOLY-COLORS.004"]}
            position={[0.207, -89.119, 39.388]}
            scale={6.608}
          />
          <mesh
            name="floating_boxes04_lanzadera"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.floating_boxes04_lanzadera.geometry}
            material={materials["LOWPOLY-COLORS.004"]}
            position={[39.218, -89.119, 0.377]}
            scale={6.608}
          />
        </group>
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Lanzadera/lanzadera.glb"
);

export default Shuttle;
