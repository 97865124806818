import { Line, Chart } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-moment";
import { m } from "framer-motion";
import { useEffect, useState } from "react";
import { BOChartData } from "../../../utils/backoffice";

const BOChart = ({ ...props }) => {
  const [view, setView] = useState("daily");
  useEffect(() => {
    if (props.view) setView(props.view);
  }, [props.view]);
  const datasets = props.data.map((dataset: BOChartData) => ({
    type: dataset.type,
    label: dataset.label,
    data: dataset.data,
    borderColor: dataset.borderColor,
    backgroundColor: dataset.backgroundColor,
  }));

  return (
    <div className="center_container">
      <Chart
        type="line"
        data={{
          datasets,
        }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: true,
              labels: {
                font: {
                  family: "Causten",
                  size: 12,
                },
              },
            },

            tooltip: {
              bodyFont: {
                family: "Causten",
              },
              titleFont: {
                family: "Causten",
              },
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: view === "daily" ? "day" : "month",
                displayFormats: {
                  day: "dd/MM/yyyy",
                  month: "MM yyyy",
                },
              },
              title: {
                display: true,
                text: "Fechas",
                font: {
                  family: "Causten",
                  size: 14,
                },
              },
              ticks: {
                font: {
                  family: "Causten",
                },
                callback: function (value: any) {
                  if (view === "daily") {
                    return new Date(value).toLocaleDateString("es-ES", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    });
                  } else {
                    return new Date(value).toDateString().slice(4, 7);
                  }
                },
              },
              min:
                view === "daily"
                  ? props.startDate.toDateString()
                  : datasets[0].data[0].x,
              max:
                view === "daily"
                  ? props.endDate.toDateString()
                  : datasets[0].data[datasets[0].data.length - 1].x,
            },
            y: {
              title: {
                display: true,
                text:
                  props.chartTitle === "scenarios"
                    ? "Situaciones completadas"
                    : "Accesos",
                font: {
                  family: "Causten",
                  size: 14,
                },
              },
              beginAtZero: true,
              ticks: {
                font: {
                  family: "Causten",
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default BOChart;
