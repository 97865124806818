import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";
import { useMemo } from "react";
import * as THREE from "three";

const Bridges = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caminos+T02/puentes_t02.glb"
  ) as DreiGLTF;

  const geometries = useMemo(() => {
    return {
      puente_01_1: new THREE.BufferGeometry().copy(nodes.puente_01_1.geometry),
      puente_01_2: new THREE.BufferGeometry().copy(nodes.puente_01_2.geometry),
      puente_02_1: new THREE.BufferGeometry().copy(nodes.puente_02_1.geometry),
      puente_02_2: new THREE.BufferGeometry().copy(nodes.puente_02_2.geometry),
    };
  }, [nodes]);

  return (
    <group {...props} dispose={null}>
      <group
        position={[-7.719, 0.704, 17.082]}
        rotation={[-Math.PI / 2, -1.227, -Math.PI / 2]}
        scale={0.515}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometries.puente_01_1}
          material={materials.camino_bordes}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometries.puente_01_2}
          material={materials.paredes_tunel}
        />
      </group>
      <group
        position={[-7.719, 0.698, 23.41]}
        rotation={[-Math.PI / 2, -1.227, -Math.PI / 2]}
        scale={0.515}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometries.puente_02_1}
          material={materials.camino_bordes}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometries.puente_02_2}
          material={materials.paredes_tunel}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caminos+T02/puentes_t02.glb"
);

export default Bridges;
