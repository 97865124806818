const MediaIcon = (
  props: {
    color: string;
    size?: number; // Adding size as an optional number prop
  } = {
    color: "#000000",
    size: 48, // Default size set to 48
  }
) => {
  const { color, size } = props; // Destructuring props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 20.1733L42 17.017L42 33.0104L32.0036 29.8553"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32 14H6L6 36H32L32 14Z"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default MediaIcon;
