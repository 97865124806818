import { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { Group } from "three";
import { AssetType } from "../../../../_newapios/gamecontent/house_upgrades.ts";

import { DreiGLTF } from "../../../../interfaces.ts";
import { StaticEutonProps } from "../Biospheres/EutonStatic.tsx";

const SannyStatic = ({
  groupProps,
  propsAsset,
  renderShadow,
}: StaticEutonProps) => {
  const [asset, setAsset] = useState<AssetType>(propsAsset);
  const Sanny = useGLTF(asset.gltf.url) as DreiGLTF;
  const groupRef = useRef<Group>(null);
  const { actions } = useAnimations(Sanny.animations, groupRef);

  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      for (let i = 0; i < Object.keys(actions).length; i++) {
        const action = Object.keys(actions)[i];
        actions[action]?.reset().play();
      }
    }
  }, [actions]);

  return (
    <group ref={groupRef} dispose={null}>
      <group
        name="Scene"
        position={groupProps?.position}
        rotation={groupProps?.rotation}
      >
        <group
          name="seagull_static_gltf"
          position={[-0.112, 0.101, 0.04]}
          scale={0.667}
        >
          <mesh
            name="seagull"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.seagull.geometry}
            material={Sanny.materials.cuerpo}
            morphTargetDictionary={Sanny.nodes.seagull.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.seagull.morphTargetInfluences}
          />
          <mesh
            name="seagull_1"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.seagull_1.geometry}
            material={Sanny.materials.cuerpo02}
            morphTargetDictionary={Sanny.nodes.seagull_1.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.seagull_1.morphTargetInfluences}
          />
          <mesh
            name="seagull_2"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.seagull_2.geometry}
            material={Sanny.materials.patas}
            morphTargetDictionary={Sanny.nodes.seagull_2.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.seagull_2.morphTargetInfluences}
          />
          <mesh
            name="seagull_3"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.seagull_3.geometry}
            material={Sanny.materials.patas02}
            morphTargetDictionary={Sanny.nodes.seagull_3.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.seagull_3.morphTargetInfluences}
          />
          <mesh
            name="seagull_4"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.seagull_4.geometry}
            material={Sanny.materials["ojos.001"]}
            morphTargetDictionary={Sanny.nodes.seagull_4.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.seagull_4.morphTargetInfluences}
          />
          <mesh
            name="seagull_5"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.seagull_5.geometry}
            material={Sanny.materials.sombra}
            morphTargetDictionary={Sanny.nodes.seagull_5.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.seagull_5.morphTargetInfluences}
          />
          <mesh
            name="seagull_6"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.seagull_6.geometry}
            material={Sanny.materials.bandana01}
            morphTargetDictionary={Sanny.nodes.seagull_6.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.seagull_6.morphTargetInfluences}
          />
          <mesh
            name="seagull_7"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.seagull_7.geometry}
            material={Sanny.materials["bandana01.2"]}
            morphTargetDictionary={Sanny.nodes.seagull_7.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.seagull_7.morphTargetInfluences}
          />
          <mesh
            name="seagull_8"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.seagull_8.geometry}
            material={Sanny.materials.bandana02}
            morphTargetDictionary={Sanny.nodes.seagull_8.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.seagull_8.morphTargetInfluences}
          />
          <mesh
            name="seagull_9"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.seagull_9.geometry}
            material={Sanny.materials.bandana03}
            morphTargetDictionary={Sanny.nodes.seagull_9.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.seagull_9.morphTargetInfluences}
          />
        </group>
        <group
          name="sunscreen_static_gltf"
          position={[-0.05, 0.348, -0.285]}
          scale={0.831}
        >
          <mesh
            name="sunscreen"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.sunscreen.geometry}
            material={Sanny.materials.sombrilla}
            morphTargetDictionary={Sanny.nodes.sunscreen.morphTargetDictionary}
            morphTargetInfluences={Sanny.nodes.sunscreen.morphTargetInfluences}
          />
          <mesh
            name="sunscreen_1"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.sunscreen_1.geometry}
            material={Sanny.materials.palo}
            morphTargetDictionary={
              Sanny.nodes.sunscreen_1.morphTargetDictionary
            }
            morphTargetInfluences={
              Sanny.nodes.sunscreen_1.morphTargetInfluences
            }
          />
          <mesh
            name="sunscreen_2"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.sunscreen_2.geometry}
            material={Sanny.materials.sombrilla_interior}
            morphTargetDictionary={
              Sanny.nodes.sunscreen_2.morphTargetDictionary
            }
            morphTargetInfluences={
              Sanny.nodes.sunscreen_2.morphTargetInfluences
            }
          />
          <mesh
            name="sunscreen_3"
            castShadow
            receiveShadow
            geometry={Sanny.nodes.sunscreen_3.geometry}
            material={Sanny.materials.sombrilla_sombra}
            morphTargetDictionary={
              Sanny.nodes.sunscreen_3.morphTargetDictionary
            }
            morphTargetInfluences={
              Sanny.nodes.sunscreen_3.morphTargetInfluences
            }
          />
        </group>
      </group>
    </group>
  );
};

export default SannyStatic;
