const Plus = ({ ...props }) => {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12H4"
        stroke={props.color || "#000000"}
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M12 4V20"
        stroke={props.color || "#000000"}
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Plus;
