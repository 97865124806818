import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";

const HutHippie = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caba%C3%B1as/cabanha01.glb"
  ) as DreiGLTF;
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.cabanha_01.geometry}
        material={materials["LOWPOLY-COLORS.004"]}
        position={[-15.039, 0.244, 17.029]}
        rotation={[0, 1.315, 0]}
        scale={0.195}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.escalera_cabanha01.geometry}
        material={materials["LOWPOLY-COLORS.004"]}
        position={[-14.451, 0.587, 17.129]}
        rotation={[0.233, 0.178, 0.13]}
        scale={0.195}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caba%C3%B1as/cabanha01.glb"
);

export default HutHippie;
