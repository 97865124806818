import env from "../../config/env.json";
import APIFetch from "../../utils/APIFetch";

export interface StudentType {
  id: string;
  username: string;
  password: string;
  class_id: string;
  plot_id: string | null;
  inactive?: string;
}

export interface BusinessStudentType {
  id: string;
  username: string;
  class: {
    id: string;
    school_id: string;
  };
  student_logins: {
    id: string;
    timestamp: string;
  }[];
}

export const CreateStudentAPI = async (
  username: string,
  password: string,
  pin: string
): Promise<StudentType> => {
  // Goofy ahh request
  // var req = await fetch(env.backend_host + "/student", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   body: JSON.stringify({
  //     username,
  //     password,
  //     pin,
  //   }),
  //   method: "POST",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  var data = APIFetch(env.backend_host + "/student", {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
      pin,
    }),
  });
  return data;
};

export const DeleteStudentAPI = async (studentId: string): Promise<void> => {
  // var req = await fetch(env.backend_host + "/student/delete/" + studentId, {
  //   method: "POST",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // return;
  var data = APIFetch(env.backend_host + "/student/delete/" + studentId, {
    method: "POST",
  });
};

export const GetAllStudentsAPI = async (): Promise<BusinessStudentType[]> => {
  // var req = await fetch(env.backend_host + "/student/all", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  var data = APIFetch(env.backend_host + "/student/all", {
    method: "GET",
  });
  return data;
};
