import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces";

const BiosphereGrasslandSnow = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_nieve_pradera_op.glb"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <group
        position={[3.295, 0.651, 31.754]}
        rotation={[-Math.PI, 0.596, -Math.PI]}
        scale={0.177}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_nieve_pradera_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_nieve_pradera_2.geometry}
          material={materials.gris_puertas}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_nieve_pradera_3.geometry}
          material={materials.blanco_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_nieve_pradera_4.geometry}
          material={materials.rosa_pradera_puertas}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_nieve_pradera_5.geometry}
          material={materials.rosa_pradera_emision}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_nieve_pradera_6.geometry}
          material={materials.rosa_pradera_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_nieve_pradera_7.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
        />
      </group>
      <group position={[3.175, 1.796, 31.931]} scale={0.177}>
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_nieve_pradera_1.geometry}
          material={materials.rosa_pradera_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_nieve_pradera_2.geometry}
          material={materials.logo_bio_pradera}
        />
      </group>
      <group
        position={[3.175, 1.226, 31.931]}
        rotation={[-Math.PI, 0.596, -Math.PI]}
        scale={0.177}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_nieve_pradera_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_nieve_pradera_2.geometry}
          material={materials.rosa_pradera_cristales}
        />
      </group>
      <group
        position={[6.272, -0.055, 27.346]}
        rotation={[0, -0.591, 0]}
        scale={[0.138, 0.537, 5.467]}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_nieve_pradera_1.geometry}
          material={materials.camino_bordes}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_nieve_pradera_2.geometry}
          material={materials.rosa_pradera_metal}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_nieve_pradera_op.glb"
);

export default BiosphereGrasslandSnow;
