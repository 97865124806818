const BackCross = ({ ...props }) => {
  return (
    <svg
      width={props.size !== undefined ? props.size : "50"}
      height={props.size !== undefined ? props.size : "50"}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.7281 39.7285L14.2723 14.2726"
        stroke={props.color !== undefined ? props.color : "black"}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M39.7277 14.2726L14.2719 39.7285"
        stroke={props.color !== undefined ? props.color : "black"}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BackCross;
