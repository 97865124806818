import { ExerciseType, GlobalCompetenceType, SpecificCompetenceType } from "../_newapios/content/unit.ts"
import { ExerciseProgress, ExperimentProgressType, ResearchProgressType, ScenarioProgressType } from "../_newapios/progress/student_scenario.ts"
import { simpleGlobalCompetences } from "../constants.ts"

export const getExerciseProgressPoints = (exerciseProgressList: ExerciseProgress[] | undefined): number => {
    if (exerciseProgressList === undefined) return 0
    const scenarioPoints = exerciseProgressList.reduce((acc, exerciseProgress) => {
        if (exerciseProgress.correct) {
            return acc + 1;
        }
        return acc;
    }, 0);
    return scenarioPoints;
}

export const getUnitProgressPoints = (scenarioProgressList: ScenarioProgressType[]): number => {
    const unitPoints = scenarioProgressList.reduce((acc, scenarioProgress) => {
        return acc + (scenarioProgress.experiment_progress[0] !== undefined ? getExerciseProgressPoints(scenarioProgress.experiment_progress[0].exercise_progress) : 0)   // XXX - assuming that experiment_progress are sorted
    }, 0)
    return unitPoints;
}

export const getExerciseProgressPercentage = (researchProgress: ResearchProgressType | null, exercises: ExerciseType[]) => {
    if (researchProgress === null || researchProgress.exercise_progress.length === 0 || exercises.length === 0) return 0
    if (researchProgress.completed_on !== null) return 100
    return getExerciseProgressPoints(researchProgress.exercise_progress) / exercises.length * 100
    //return getExerciseProgressPoints(exerciseProgressList) / exerciseProgressList.length * 100
}