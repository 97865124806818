const ToothedCicleIcon = (
  props: {
    text?: string;
    width?: number;
    height?: number | string;
    color?: string;
  } = {
      text: "",
      width: 109,
      height: 109,
      color: "#FFFFFF"
    }
) => {
  const { text, width, height } = props;
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 109 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0003 15.9998L29.5469 18.8256L31.5245 5.12935L43.2871 12.4174L49.8299 0.22358L58.3905 11.0963L68.709 1.87576L73.035 15.0204L85.885 9.88501L85.4537 23.7172L99.2859 23.2859L94.1505 36.1359L107.295 40.4619L98.0746 50.7805L108.947 59.341L96.7535 65.8838L104.042 77.6464L90.3453 79.624L93.1711 93.1706L79.6245 90.3448L77.6469 104.041L65.8843 96.753L59.3415 108.947L50.7809 98.0742L40.4624 107.295L36.1364 94.15L23.287 99.287L23.7184 85.4548L9.88615 85.8862L15.0215 73.0361L1.87692 68.7102L11.0974 58.3916L0.224737 49.831L12.4186 43.2882L5.13051 31.5257L18.8268 29.548L16.0003 15.9998Z"
        fill={props.color}
      />

      <text
        x="50%"
        y="50%"
        fill="white"
        font-family="Causten"
        font-size="2em"
        text-anchor="middle"
        dominant-baseline="middle"
      >
        {props.text}
      </text>
    </svg>
  );
};

export default ToothedCicleIcon;
