import "./CalendarMonth.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { colors } from "../../../constants.ts";
import { BundleType } from "../../../_newapios/user/class.ts";
import { useAppSelector } from "../../../hooks/hooks.ts";

const CalendarMonth = ({ ...props }) => {
  const [previousDates, setPreviousDates] = useState<number[]>([]);
  const [dates, setDates] = useState<Date[]>([]);
  const [afterDates, setAfterDates] = useState<number[]>([]);
  const [bundles, setBundles] = useState<BundleType[]>();
  const [maxDay, setMaxDay] = useState<number>(0);

  const { t } = useTranslation();

  const courses = useAppSelector((state) => state.main.courses);

  useEffect(() => {
    setDates(getAllDaysInMonth(props.year, props.month));
    var firstDay = new Date(props.year, props.month, 1).getDay() - 1;
    if (firstDay === -1) firstDay = 6;
    var myPreviousDays = [];
    for (var i = 0; i < firstDay; i++) {
      myPreviousDays.push(i);
    }
    setPreviousDates(myPreviousDays);
    var lastDay =
      getAllDaysInMonth(props.year, props.month).length + myPreviousDays.length;
    var remainingDays =
      7 -
      ((getAllDaysInMonth(props.year, props.month).length +
        myPreviousDays.length) %
        7);
    if (remainingDays === 7) remainingDays = 0;
    var maxDay = lastDay + remainingDays;
    setMaxDay(maxDay);
    var myAfterDays = [];
    for (var i = lastDay; i < maxDay; i++) {
      myAfterDays.push(i);
    }
    setAfterDates(myAfterDays);
  }, [props.year, props.month]);

  useEffect(() => {
    setBundles(props.bundles);
  }, [props.bundles]);

  const weekDaysTitles = [
    { name: t("monday_abreviation") },
    { name: t("tuesday_abreviation") },
    { name: t("wednesday_abreviation") },
    { name: t("thursday_abreviation") },
    { name: t("friday_abreviation") },
    { name: t("saturday_abreviation") },
    { name: t("sunday_abreviation") },
  ];

  const getAllDaysInMonth = (year: number, month: number) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };

  const sendBundles = (topicsInDay: string[] | null) => {
    props.getHoveredDayBundles(topicsInDay);
  };

  const renderWeekDayTitles = (
    weekDay: { name: string },
    weekDayIndex: number
  ) => {
    return (
      <div
        key={weekDayIndex}
        className="center_container"
        style={{
          borderTop: "1px solid #B9B9B9",
          borderLeft: "1px solid #B9B9B9",
          borderRight: weekDayIndex === 6 ? "1px solid #B9B9B9" : "",
        }}
      >
        <div className="text_light_grey_small">{weekDay.name}</div>
      </div>
    );
  };

  const renderPreviousDay = (positionY: number, positionX: number) => {
    return (
      <div
        key={positionX}
        className="center_container"
        style={{
          gridArea: "day" + positionY.toString() + positionX.toString(),
          borderTop: "1px solid #B9B9B9",
          borderLeft: "1px solid #B9B9B9",
          borderRight: positionX === 6 ? "1px solid #B9B9B9" : "",
          borderBottom:
            positionY === Math.ceil(maxDay / 7) - 1 ? "1px solid #B9B9B9" : "",
        }}
      />
    );
  };

  const renderDay = (
    monthDay: Date,
    monthDayIndex: number,
    positionY: number,
    positionX: number,
    bundles: BundleType[]
  ) => {
    var dayColor = "#ffffff";
    var bundlesInDay: string[] = [];
    if (bundles !== undefined && bundles !== null && bundles.length > 0) {
      var percentage = 100 / bundles.length;
      var currentPercentage = 0;
      dayColor = "linear-gradient(to bottom";
      for (var i = 0; i < bundles.length; i++) {
        bundlesInDay.push(bundles[i].id);
        var scenarioCourse = courses.find(
          (course) => course.id === bundles[i].scenario?.course_id
        );
        dayColor +=
          ", " + scenarioCourse?.color + "75" + " " + currentPercentage + "%,";
        currentPercentage += percentage;
        dayColor += scenarioCourse?.color + "75" + " " + currentPercentage + "%";
      }
      dayColor += ")";
    }
    const today = new Date();
    let isToday =
      monthDay.getDate() === today.getDate() &&
      monthDay.getMonth() === today.getMonth() &&
      monthDay.getFullYear() === today.getFullYear();
    return (
      <div
        key={monthDayIndex}
        className="center_container"
        style={{
          gridArea: "day" + positionY.toString() + positionX.toString(),
          borderTop: "1px solid #B9B9B9",
          borderLeft: "1px solid #B9B9B9",
          borderRight: positionX === 6 ? "1px solid #B9B9B9" : "",
          borderBottom:
            positionY === Math.ceil(maxDay / 7) - 1 ? "1px solid #B9B9B9" : "",
        }}
        onMouseEnter={() => {
          sendBundles(bundlesInDay);
        }}
        onMouseLeave={() => {
          sendBundles(null);
        }}
      >
        <div
          className="center_container"
          style={{
            height: "99%",
            width: "99%",
            top: "1%",
            left: "1%",
            background: dayColor,
            border: isToday ? "2px solid #000000" : "2px solid #ffffff",
          }}
        >
          <div className="text_dark_grey_small">{monthDay.getDate()}</div>
        </div>
      </div>
    );
  };

  var week = -1;

  return (
    <div className="calendar_month">
      {weekDaysTitles.map((weekDay, weekDayIndex) => {
        return renderWeekDayTitles(weekDay, weekDayIndex);
      })}
      {previousDates.map((day, dayIndex) => {
        return renderPreviousDay(0, day);
      })}
      {dates.map((monthDay, monthDayIndex) => {
        if (week === -1) {
          week = 0;
        } else if (monthDay.getDay() === 1) {
          week += 1;
        }
        let bundlesInSameDay: BundleType[] = [];
        if (bundles !== undefined && bundles !== null) {
          for (var i = 0; i < bundles.length; i++) {
            let startingTime = new Date(bundles[i].start_date);
            startingTime.setHours(0, 0, 0);
            let endingTime = new Date(bundles[i].end_date);
            endingTime.setHours(23, 59, 59);
            monthDay.setMinutes(monthDay.getMinutes(), 5);
            if (
              monthDay.getTime() >= startingTime.getTime() &&
              monthDay.getTime() <= endingTime.getTime()
            ) {
              bundlesInSameDay.push(bundles[i]);
            }
          }
        }
        return renderDay(
          monthDay,
          monthDayIndex,
          week,
          monthDay.getDay() === 0 ? 6 : monthDay.getDay() - 1,
          bundlesInSameDay
        );
      })}
      {afterDates.map((day, dayIndex) => {
        return renderPreviousDay(Math.ceil(maxDay / 7) - 1, day % 7);
      })}
    </div>
  );
};

export default CalendarMonth;
