const Calendar = ({ ...props }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 6.25H26.25V26.25H3.75V6.25Z"
        stroke={props.color !== undefined ? props.color : "black"}
        stroke-width="2.5"
        stroke-linejoin="round"
      />
      <path
        d="M26.25 11.25H3.75"
        stroke={props.color !== undefined ? props.color : "black"}
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M8.75 6.25V3.75"
        stroke={props.color !== undefined ? props.color : "black"}
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M21.25 6.25V3.75"
        stroke={props.color !== undefined ? props.color : "black"}
        stroke-width="2.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Calendar;
