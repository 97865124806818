import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../interfaces";

const GrassLandIsland = ({ ...props }) => {
  const flowerPoisonous = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/flower_poisonous_pradera.gltf"
  ) as DreiGLTF;
  const rockSmall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/rock_small_pradera.gltf"
  ) as DreiGLTF;
  const shrubRound = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/shrub_round_pradera.gltf"
  ) as DreiGLTF;
  const stoneFlat = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/stone_flat_pradera.gltf"
  ) as DreiGLTF;
  const sunflower = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/sunflower_pradera.gltf"
  ) as DreiGLTF;
  const treeTall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/tree_tal_pradera.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-12.85, -0.06, 15.87]}
        rotation={[0, 1.47, 0]}
        scale={0.49}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-18.22, -0.22, 10.79]}
        rotation={[0, 1.47, 0]}
        scale={0.54}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-17.86, -0.22, 12.01]}
        rotation={[0, 1.47, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-20.99, 0.09, 15.79]}
        rotation={[0, 1.47, 0]}
        scale={0.45}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-20.49, 0.1, 17.13]}
        rotation={[0, 1.47, 0]}
        scale={0.45}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-21.21, 0.34, 17.07]}
        rotation={[0, 1.47, 0]}
        scale={0.65}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-20.28, -0.12, 16.19]}
        rotation={[0, 1.47, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedMaterial1}
        position={[-16.09, 0.23, 19.57]}
        rotation={[0, -0.43, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedMaterial1}
        position={[-14.48, -0.06, 14.86]}
        rotation={[0, -1.5, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockSmall.nodes["rocks-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-15.95, -0.02, 13.81]}
        rotation={[0, -1.2, 0]}
        scale={0.53}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockSmall.nodes["rocks-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-20.08, 0, 14.7]}
        rotation={[Math.PI, -1.39, Math.PI]}
        scale={0.53}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockSmall.nodes["rocks-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-20.58, 0, 12.38]}
        scale={0.53}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-21.02, -0.05, 8.27]}
        rotation={[0, -0.09, 0]}
        scale={0.54}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-20.65, 0.03, 8.39]}
        rotation={[0, 1.28, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-21.19, 0.06, 8.78]}
        rotation={[0, 0.59, 0]}
        scale={0.46}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-16.97, 0.06, 15.43]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-16.75, -0.02, 14.85]}
        rotation={[0, 0.59, 0]}
        scale={0.46}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-16.44, 0.01, 15.33]}
        rotation={[0, -1.11, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.12, -0.16, 15.49]}
        rotation={[0, -1.01, 0]}
        scale={0.65}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.29, 0.04, 14.71]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.22, -0.08, 15.11]}
        rotation={[0, 0.59, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-22.44, -0.08, 14.28]}
        rotation={[0, -1.05, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-22.58, -0.08, 13.1]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-22.39, 0.06, 13.6]}
        rotation={[0, 0.66, 0]}
        scale={0.76}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-21.43, 0.1, 15.35]}
        rotation={[0, 1.47, 0]}
        scale={0.65}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-20.79, -0.04, 13.65]}
        rotation={[0, 1.47, 0]}
        scale={0.65}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-21.68, 0.09, 12.94]}
        rotation={[0, 1.47, 0]}
        scale={0.65}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-21.51, 0.16, 14.13]}
        rotation={[0, 1.47, 0]}
        scale={0.65}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-18.91, -0.22, 11.9]}
        rotation={[0, 1.47, 0]}
        scale={0.54}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-22.02, -0.22, 10.87]}
        rotation={[0, 1.47, 0]}
        scale={0.54}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-21.66, -0.22, 10.02]}
        rotation={[0, 1.47, 0]}
        scale={0.54}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-19.13, -0.22, 15.31]}
        rotation={[0, 1.47, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-16.03, -0.22, 14.69]}
        rotation={[0, 1.47, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-17, -0.22, 12.89]}
        rotation={[0, 1.47, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-12.63, -0.06, 16.73]}
        rotation={[0, 1.47, 0]}
        scale={0.49}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-18.78, -0.07, 10.35]}
        rotation={[0, 1.28, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-21.52, 0.06, 12.07]}
        rotation={[0, 1.28, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-19.96, 0.06, 15.71]}
        rotation={[0, 1.28, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-16.75, 0.02, 16.33]}
        rotation={[0, -1.11, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-19.45, -0.01, 12.84]}
        rotation={[0, -1.11, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-18.13, -0.09, 11.49]}
        rotation={[0, 1.28, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.34, 0.06, 16.22]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-21.57, 0.06, 11.42]}
        rotation={[0, 1.28, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-19.57, 0.06, 11.96]}
        rotation={[0, 1.28, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-22.15, 0.06, 12.25]}
        rotation={[0, 0.66, 0]}
        scale={0.76}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-20.13, -0.11, 12.86]}
        rotation={[0, 0.66, 0]}
        scale={0.76}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-19.68, 0, 16.33]}
        rotation={[0, 0.66, 0]}
        scale={0.76}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-23.02, 0, 17.53]}
        scale={0.59}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-23.76, 0, 17.15]}
        scale={0.59}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.37, 0.31, 16.73]}
        rotation={[0, 0.96, 0]}
        scale={0.59}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.72, 0.31, 16.03]}
        rotation={[0, -0.84, 0]}
        scale={0.59}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.9, 0.22, 15.29]}
        rotation={[0, 1.16, 0]}
        scale={0.59}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-25.05, 0.1, 14.57]}
        scale={0.59}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-23.81, -0.15, 16.48]}
        rotation={[-Math.PI, 1.49, -Math.PI]}
        scale={0.49}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-24.12, -0.1, 16.03]}
        rotation={[-Math.PI, 1.49, -Math.PI]}
        scale={0.49}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-23.38, -0.24, 17.06]}
        rotation={[-Math.PI, 1.49, -Math.PI]}
        scale={0.49}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-17.59, 0.2, 19.95]}
        rotation={[Math.PI, -0.13, Math.PI]}
        scale={0.59}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-21.44, -0.15, 19.71]}
        rotation={[0, -0.43, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-20.87, 0.07, 20.19]}
        rotation={[Math.PI, -0.62, Math.PI]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-20.08, 0.34, 20.75]}
        rotation={[-Math.PI, 0.84, -Math.PI]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-19.32, 0.3, 20.91]}
        rotation={[Math.PI, -0.79, Math.PI]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-18.65, -0.08, 20.95]}
        rotation={[Math.PI, -0.12, Math.PI]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-17.96, -0.37, 20.76]}
        rotation={[0, 1.42, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-18.3, 0.55, 19.88]}
        rotation={[0, 1.15, 0]}
        scale={0.59}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-17.09, -0.02, 20.26]}
        rotation={[0, 0.62, 0]}
        scale={0.59}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-20.18, -0.33, 19.92]}
        rotation={[Math.PI, -0.2, Math.PI]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-19.52, -0.14, 20.21]}
        rotation={[0, -0.8, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-18.92, 0.87, 19.74]}
        rotation={[0, -0.91, 0]}
        scale={0.59}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedMaterial1}
        position={[-19.51, 1.04, 19.28]}
        rotation={[0, 1.18, 0]}
        scale={0.59}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/flower_poisonous_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/rock_small_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/shrub_round_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/stone_flat_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/sunflower_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pradera/tree_tal_pradera.gltf"
);

export default GrassLandIsland;
