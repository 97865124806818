import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserType } from "../_newapios/user/user";

export interface LoginState {
  id: string;
  username: string;
  type: "teacher" | "student" | "admin" | undefined;
  impersonating: string | undefined;
  loggedIn: boolean;
}

const initialState: LoginState = {
  id: "",
  username: "user_name",
  type: undefined,
  impersonating: undefined,
  loggedIn: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<Omit<UserType, "password">>) => {
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.type = action.payload.type;
      state.loggedIn = true;
    },
    updateLogin: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    updateImpersonating: (state, action: PayloadAction<string | undefined>) => {
      state.impersonating = action.payload;
    },
  },
});

export const { updateUser, updateLogin, updateImpersonating } =
  loginSlice.actions;

export default loginSlice.reducer;
