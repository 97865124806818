import env from "../../config/env.json";
import { ExerciseAnswerType } from '../../components/exercises/ExercisesExperiment'

export const CreateExperimentProgressAPI = async (scenario_id: string, exercise_answers: ExerciseAnswerType[]): Promise<number> => {
    var req = await fetch(env.backend_host + "/student_experiment/" + scenario_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "body": JSON.stringify({ exercise_answers }),
        "mode": "cors",
        "credentials": "include"
    });

    if (req.status !== 200) {
        throw new Error("Failed to create experiment progress");
    }
    return req.status;
}