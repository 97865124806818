import { useTranslation } from "react-i18next";
import { useGLTF, useCursor, Center, Text3D } from "@react-three/drei";
import {
  Selection,
  Select,
  EffectComposer,
  Outline,
} from "@react-three/postprocessing";
import * as THREE from "three";
import { useState } from "react";
import { DreiGLTF } from "../../../../../interfaces";

const PuestosFeria = ({ ...props }) => {
  const { t } = useTranslation();
  const [hovered, hover] = useState(false);
  useCursor(
    hovered,
    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
    'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
  );

  const puestosFeriaGLTF = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/puestos_feria.gltf"
  ) as DreiGLTF;

  const bakedAssetsMaterial = new THREE.MeshPhongMaterial({
    map: props.bakedAssetsTexture1,
    emissiveMap: props.bakedAssetsTexture3,
    emissive: new THREE.Color(0xff0000),
    specularMap: props.bakedAssetsTexture2,
    emissiveIntensity: 1,
    specular: 0xff0000,
    fog: true,
  });

  return (
    <group {...props} dispose={null}>
      {/* <Selection>
        <EffectComposer multisampling={8} autoClear={false}>
          <Outline
            blur
            visibleEdgeColor={0xffffff}
            edgeStrength={100}
            width={hovered ? 1000 : 1500}
          />
        </EffectComposer>
        <Select enabled={true}>
          <Center position={[-5.54, 2.8, 9.58]} rotation={[-0.03, 2.5, -0.03]}>
            <Text3D
              font="./Causten Bold_Regular.json"
              size={0.5}
              height={0.01}
              curveSegments={12}
              bevelEnabled
              bevelThickness={0.02}
              bevelSize={0.02}
              bevelOffset={0}
              bevelSegments={5}
            >
              {t("research_convention")}
              <meshStandardMaterial color={"#4da7FF"} />
            </Text3D>
          </Center> */}
      <group
        position={[-5.54, 0.3, 9.58]}
        rotation={[0, -0.15, 0]}
        scale={0.09}
        //onClick={() => props.openChallenges()}
        //onPointerOver={() => hover(true)}
        //onPointerOut={() => hover(false)}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["building-lotus-india001"].geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["building-lotus-india001_1"].geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      {/* </Select>
      </Selection> */}
      <group
        position={[-3.15, 0.54, 8.72]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[-2.25, 0.54, 8.74]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[-1.41, 0.58, 8.73]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[-3.15, 0.52, 7.53]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[-2.28, 0.48, 7.54]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[-1.4, 0.5, 7.53]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[0.51, 0.51, 7.58]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[1.35, 0.52, 7.59]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[2.13, 0.53, 7.6]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[0.5, 0.57, 8.59]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[1.33, 0.57, 8.59]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[2.14, 0.56, 8.58]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[0.49, 0.6, 9.2]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[1.33, 0.61, 9.2]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[2.14, 0.62, 9.19]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[2.14, 0.66, 10.25]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[1.34, 0.66, 10.26]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[0.5, 0.64, 10.26]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      {/* <group
        position={[-0.46, 0.59, 10.26]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group> */}
      <group
        position={[-1.4, 0.57, 10.26]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[-2.29, 0.56, 10.26]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
      <group
        position={[-3.18, 0.54, 10.26]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.005}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_1.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_2.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_3.geometry}
          material={bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={puestosFeriaGLTF.nodes.Cube057_4.geometry}
          material={bakedAssetsMaterial}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/puestos_feria.gltf"
);

export default PuestosFeria;
