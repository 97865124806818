import { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { Group } from "three";
import { AssetType } from "../../../../_newapios/gamecontent/house_upgrades.ts";

import { DreiGLTF } from "../../../../interfaces.ts";
import { StaticEutonProps } from "../Biospheres/EutonStatic.tsx";

const AmalteaStatic = ({
  groupProps,
  propsAsset,
  renderShadow,
}: StaticEutonProps) => {
  const [asset, setAsset] = useState<AssetType>(propsAsset);
  const Amaltea = useGLTF(asset.gltf.url) as DreiGLTF;
  const groupRef = useRef<Group>(null);
  const { actions } = useAnimations(Amaltea.animations, groupRef);

  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      for (let i = 0; i < Object.keys(actions).length; i++) {
        const action = Object.keys(actions)[i];
        actions[action]?.reset().play();
      }
    }
  }, [actions]);

  return (
    <group ref={groupRef} dispose={null}>
      <group
        name="Scene"
        position={groupProps?.position}
        rotation={groupProps?.rotation}
      >
        <mesh
          name="piercing_static_gltf"
          castShadow
          receiveShadow
          geometry={Amaltea.nodes.piercing_static_gltf.geometry}
          material={Amaltea.materials.dorado_aro}
          morphTargetDictionary={
            Amaltea.nodes.piercing_static_gltf.morphTargetDictionary
          }
          morphTargetInfluences={
            Amaltea.nodes.piercing_static_gltf.morphTargetInfluences
          }
          position={[0.05, 0.284, 0.162]}
          scale={0.296}
        />
        <group
          name="goat_static_glt"
          position={[-0.37, 0.171, 0.073]}
          scale={0.602}
        >
          <mesh
            name="goat"
            castShadow
            receiveShadow
            geometry={Amaltea.nodes.goat.geometry}
            material={Amaltea.materials.ojos}
            morphTargetDictionary={Amaltea.nodes.goat.morphTargetDictionary}
            morphTargetInfluences={Amaltea.nodes.goat.morphTargetInfluences}
          />
          <mesh
            name="goat_1"
            castShadow
            receiveShadow
            geometry={Amaltea.nodes.goat_1.geometry}
            material={Amaltea.materials.cuernos_cabra}
            morphTargetDictionary={Amaltea.nodes.goat_1.morphTargetDictionary}
            morphTargetInfluences={Amaltea.nodes.goat_1.morphTargetInfluences}
          />
          <mesh
            name="goat_2"
            castShadow
            receiveShadow
            geometry={Amaltea.nodes.goat_2.geometry}
            material={Amaltea.materials.cabra_cuerpo}
            morphTargetDictionary={Amaltea.nodes.goat_2.morphTargetDictionary}
            morphTargetInfluences={Amaltea.nodes.goat_2.morphTargetInfluences}
          />
          <mesh
            name="goat_3"
            castShadow
            receiveShadow
            geometry={Amaltea.nodes.goat_3.geometry}
            material={Amaltea.materials.cesped}
            morphTargetDictionary={Amaltea.nodes.goat_3.morphTargetDictionary}
            morphTargetInfluences={Amaltea.nodes.goat_3.morphTargetInfluences}
          />
          <mesh
            name="goat_4"
            castShadow
            receiveShadow
            geometry={Amaltea.nodes.goat_4.geometry}
            material={Amaltea.materials.patas_cabra}
            morphTargetDictionary={Amaltea.nodes.goat_4.morphTargetDictionary}
            morphTargetInfluences={Amaltea.nodes.goat_4.morphTargetInfluences}
          />
          <mesh
            name="goat_5"
            castShadow
            receiveShadow
            geometry={Amaltea.nodes.goat_5.geometry}
            material={Amaltea.materials.orejas_cabra}
            morphTargetDictionary={Amaltea.nodes.goat_5.morphTargetDictionary}
            morphTargetInfluences={Amaltea.nodes.goat_5.morphTargetInfluences}
          />
          <mesh
            name="goat_6"
            castShadow
            receiveShadow
            geometry={Amaltea.nodes.goat_6.geometry}
            material={Amaltea.materials.boca_cabra}
            morphTargetDictionary={Amaltea.nodes.goat_6.morphTargetDictionary}
            morphTargetInfluences={Amaltea.nodes.goat_6.morphTargetInfluences}
          />
          <mesh
            name="goat_7"
            castShadow
            receiveShadow
            geometry={Amaltea.nodes.goat_7.geometry}
            material={Amaltea.materials.ojos_cabra}
            morphTargetDictionary={Amaltea.nodes.goat_7.morphTargetDictionary}
            morphTargetInfluences={Amaltea.nodes.goat_7.morphTargetInfluences}
          />
        </group>
      </group>
    </group>
  );
};

export default AmalteaStatic;
