import { useTranslation } from "react-i18next";
import latoBold from "../../../fonts/Lato/Lato-Bold.ttf";
import latoThin from "../../../fonts/Lato/Lato-Thin.ttf";
import latoLight from "../../../fonts/Lato/Lato-Light.ttf";
import latoRegular from "../../../fonts/Lato/Lato-Regular.ttf";
import latoMedium from "../../../fonts/Lato/Lato-Medium.ttf";
import latoSemiBold from "../../../fonts/Lato/Lato-SemiBold.ttf";
import latoExtraBold from "../../../fonts/Lato/Lato-ExtraBold.ttf";
import { getGlobalCompetenceAggregates } from "../../utils/evaluation.ts";
import { simpleGlobalCompetences } from "../../../constants";
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Font,
  Link,
} from "@react-pdf/renderer";
import _ from "lodash";
import { CourseType } from "../../../_newapios/content/course.ts";
import { useEffect, useState } from "react";
import { ModifiedBundleType } from "../../../_newapios/user/class.ts";
import { ModifiedUnitType } from "../../../_newapios/content/unit.ts";
import { RegulationType } from "../../../_newapios/content/regulation.ts";
import { SpecificCompetencePoints } from "../../../_newapios/progress/student_specific_competences.ts";
import {
  prettifyCourseAcronyms,
  specificCompetencesBooleanArray,
} from "../../../utils/competences.ts";

//Import assets
import logo from "../../../assets/professor/Eutopia_Logo_FInal_2 1.png";
import { ModifiedScenarioType } from "../../../_newapios/content/scenario.ts";

// Register font

Font.register({
  family: "Lato",
  fonts: [
    {
      src: latoRegular,
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: latoBold,
      fontStyle: "normal",
      fontWeight: "bold",
    },
    {
      src: latoLight,
      fontStyle: "normal",
      fontWeight: "light",
    },
    {
      src: latoThin,
      fontStyle: "normal",
      fontWeight: "thin",
    },
    {
      src: latoSemiBold,
      fontStyle: "normal",
      fontWeight: "semibold",
    },
    {
      src: latoExtraBold,
      fontStyle: "normal",
      fontWeight: "ultrabold",
    },
    {
      src: latoMedium,
      fontStyle: "normal",
      fontWeight: "medium",
    },
  ],
});

// Create styles
const globalStyles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  page: {
    backgroundColor: "white",
    color: "black",
    // marginBottom: 10,
    paddingTop: 25,
    paddingBottom: 45,
  },
  section: {
    margin: 10,
    paddingLeft: 30,
    paddingTop: 15,
    height: "5%",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  title: {
    fontFamily: "Lato",
    fontSize: 18,
    fontWeight: "bold",
    color: "#000000",
    paddingLeft: 20,
    top: 8,
  },
  titleNumber: {
    fontSize: 20,
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#5090F0",
    marginRight: 5,
  },
  underlinedSubtitle: {
    fontSize: 15,
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#000000",
    textDecoration: "underline",
  },
  textSmall: {
    fontSize: 10,
    fontFamily: "Lato",
    color: "#000000",
  },
  textSuperSmall: {
    fontSize: 8,
    fontFamily: "Lato",
    color: "#000000",
  },
  textSuperSmallGrey: {
    fontSize: 8,
    fontFamily: "Lato",
    color: "#BBBBBB",
  },
  sectionTitle: {
    margin: 10,
    paddingLeft: 30,
  },
  textSmallBold: {
    fontSize: 10,
    fontFamily: "Lato",
    color: "#000000",
    fontWeight: "bold",
  },
  textSmallBoldBlue: {
    fontSize: 10,
    fontFamily: "Lato",
    color: "#5090F0",
    fontWeight: "bold",
  },
  textSmallBoldGreen: {
    fontSize: 10,
    fontFamily: "Lato",
    color: "#6EE6A7",
    fontWeight: "bold",
  },
  textSuperSmallBold: {
    fontSize: 8,
    fontFamily: "Lato",
    color: "#000000",
    fontWeight: "bold",
  },
  textSmallBoldWhite: {
    fontSize: 10,
    fontFamily: "Lato",
    color: "white",
    fontWeight: "bold",
  },
  textSuperSmallBoldWhite: {
    fontSize: 8,
    fontFamily: "Lato",
    color: "white",
    fontWeight: "bold",
  },
  textSuperSmallBoldBlue: {
    fontSize: 8,
    fontFamily: "Lato",
    color: "#5090F0",
    fontWeight: "bold",
  },
  textBig: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#000000",
    fontWeight: "normal",
  },
  textBigSemiBold: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#000000",
    fontWeight: "bold",
  },
  textMediumBold: {
    fontSize: 12,
    fontFamily: "Lato",
    color: "#000000",
    fontWeight: "bold",
  },
  textMediumBoldWhite: {
    fontSize: 12,
    fontFamily: "Lato",
    color: "white",
    fontWeight: "bold",
  },
  textBigBoldGreen: {
    fontSize: 15,
    fontFamily: "Lato",
    color: "#6EE6A7",
    fontWeight: "bold",
  },
  headerImage: {
    position: "absolute",
    right: 35,
    width: 110,
    top: -10,
  },
  header: {
    // top: 10,
    left: 20,
    height: 60,
  },
  pageNumber: {
    position: "absolute",
    fontFamily: "Lato",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const page1Styles = StyleSheet.create({
  studentInfoSection: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    width: "80%",
  },
  studentInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "50%",
  },
  studentInfoWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  underlinedSection: {
    marginLeft: 10,
    paddingLeft: 10,
  },
  radarChartContainer: {
    margin: 10,
    paddingLeft: 30,
    height: "35%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  curricularContent: {
    marginRight: 10,
    marginLeft: 10,
    marginTop: 10,
    paddingLeft: 30,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    // height: "25%"
  },
  curricularContentBox: {
    width: "30%", // 3 items per row
    margin: "1%",
    height: 100,
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    borderWidth: 1,
    borderColor: "#9fc2f5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bannerStyle: {
    position: "absolute",
    height: "40%",
    top: 0,
    left: 0,
    right: 0,
  },
  nameCurricularContent: {
    paddingLeft: "2",
    paddingRight: "2",
    paddingTop: "2",
    height: "20%",
    position: "absolute",
    top: "45%",
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  courseAcronym: {
    borderRadius: "10px",
    height: "auto",
    justifyContent: "center",
    // alignItems: "center",
    marginLeft: 5,
    width: "35%",
  },
  activitiesRegulationsContainer: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 2,
    marginTop: 2,
    height: 25,
    top: "65%",
    position: "absolute",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  globalCompetencesRightElementLineContainer: {
    position: "relative",
    height: 35,
    width: "90%",
    left: "5%",
    padding: 5,
    flexDirection: "row",
    borderRadius: "5px",
    borderWidth: 1,
    borderColor: "#9fc2f5",
    marginBottom: 5,
    alignItems: "center", // Center items vertically
    justifyContent: "space-between", // Space between items
  },
  globalCompetencesRightElementLine: {
    position: "absolute",
    height: "35%",
    width: "35%",
    top: "55%",
    borderRadius: "25px",
    backgroundColor: "#5090F025",
    left: "55%",
  },
  globalCompetencesRightElementStudent: {
    position: "absolute",
    height: "35%",
    top: "55%",
    borderRadius: "25px",
    backgroundColor: "#5090F0",
    left: "55%",
  },

  globalCompetencesRightElementSchool: {
    position: "absolute",
    height: "37.5%",
    width: "1%",
    top: "55%",
    backgroundColor: "#6EE6A7",
    // marginLeft: '55%'
  },
  percentageContainer: {
    right: "10%",
    width: "10%",
    height: "70%",
    backgroundColor: "#EFEFEF",
    borderRadius: "5px",
  },

  globalCompetencesLeftTextLine: {
    position: "absolute",
    height: "100%",
    width: "50%",
  },

  globalCompetencesPointsContainer: {
    position: "relative",
    height: "100%",
    width: "50%",
  },
  radarChartContainerLeft: {
    position: "relative",
    height: "100%",
    width: "50%",
  },
  studentDot: {
    width: "8",
    height: "8",
    marginTop: 2,
    backgroundColor: "#5090F0",
    borderRadius: "50%",
    marginRight: "5px",
  },
  schoolDot: {
    width: "8",
    height: "8",
    marginTop: 2,
    backgroundColor: "#6EE6A7",
    borderRadius: "50%",
    marginRight: "5px",
  },
  pointsContainer: {
    position: "absolute",
    bottom: 10,
    right: 10,
    flexDirection: "row",
  },
});

const page2Styles = StyleSheet.create({
  tableCompetencesContainer: {
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 30,
    height: "35%",
  },
  recomendationsContainer: {
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 30,
    paddingRight: 20,
    paddingBottom: 20,
  },
  textWithDotContainer: {
    position: "relative",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingTop: 3,
    paddingBottom: 3,
  },
  redDot: {
    width: "8",
    height: "8",
    marginTop: 2,
    backgroundColor: "#f0684f",
    borderRadius: "50%",
    marginRight: "5px",
  },
  greenDot: {
    width: "8",
    height: "8",
    marginTop: 2,
    backgroundColor: "#6EE6A7",
    borderRadius: "50%",
    marginRight: "5px",
  },
  badge: {
    backgroundColor: "#DDEBF7",
    borderRadius: "5px",
    padding: 10,
    margin: 5,
  },
  badgeRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: "auto",
    marginLeft: "auto",
  },
});

const globalCompTable = StyleSheet.create({
  table: {
    display: "flex",
    width: "auto",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "33%",
    backgroundColor: "#5090F0",
    padding: 5,
  },
  tableCol: {
    width: "33%",
    padding: 5,
  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
  },
  firstColumn: {
    backgroundColor: "#f0f8ff",
  },
});

const page3Styles = StyleSheet.create({
  radarChartContainer: {
    margin: 10,
    paddingLeft: 30,
    flexDirection: "row",
  },
  curricularContentContainer: {
    marginRight: 10,
    marginLeft: 10,
    paddingLeft: 30,
    height: "2.5%",
  },
  courseBadge: {
    padding: 3,
    width: "25%",
    height: "37.5%",
    borderRadius: 5,
    top: -12.5,
    left: "40%",
  },
  globalCompetencesPointsContainer: {
    position: "relative",
    width: "50%",
    flex: 1,
    flexDirection: "column",
  },
});

const curricularContentSubject = StyleSheet.create({
  unitGeneralContainer: {
    marginTop: 10,
    marginRight: 20,
    marginLeft: 40,
    paddingLeft: 10,
    // height: "30%",
    backgroundColor: "#e8e8e8",
    borderRadius: 10,
  },
  unitNameContainer: {
    position: "relative",
    flexDirection: "row",
    height: 35,
    borderBottomWidth: 2,
    borderBottomColor: "#5090F0",
    width: "98%",
    justifyContent: "space-between",
  },
  scenariosContainer: {
    width: "98%",
    position: "relative",
    height: "100%",
    paddingTop: 7.5,
    paddingBottom: 10,
  },
  singleScenarioContainer: {
    position: "relative",
    flexDirection: "row",
    height: 42.5,
    backgroundColor: "white",
    borderRadius: 5,
    marginTop: 10,
  },
  specificCompBadge: {
    borderRadius: 10,
    position: "relative",
    height: "80%",
    marginLeft: 2,
    marginRight: 2,
    width: 40,
  },
  scenarioPoints: {
    position: "absolute",
    bottom: 12.5,
    right: 35,
  },
});

const anexo1 = StyleSheet.create({
  competenceContainer: {
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 30,
    paddingRight: 20,
    paddingBottom: 20,
  },
  globalCompetencesRightElementLine: {
    position: "absolute",
    height: "35%",
    width: "35%",
    top: "35%",
    borderRadius: "25px",
    backgroundColor: "#5090F025",
    left: "45%",
  },
  globalCompetencesRightElementStudent: {
    position: "absolute",
    height: "35%",
    top: "35%",
    borderRadius: "25px",
    backgroundColor: "#5090F0",
    left: "45%",
  },

  globalCompetencesRightElementSchool: {
    position: "absolute",
    height: "50%",
    width: "1%",
    top: "25%",
    backgroundColor: "#6EE6A7",
    // marginLeft: '55%'
  },
});

const setLanguage = (language: string) => {
  switch (language) {
    case "es":
      return "spanish";
    case "en":
      return "english";
    case "fr":
      return "french";
    case "de":
      return "german";
    case "it":
      return "italian";
    default:
      return "spanish";
  }
};

// Create Document Component
function BasicDocument({ ...props }) {
  const { t } = useTranslation(["common", "evaluation", "pdf"]);

  const [studentCourses, setStudentCourses] = useState<CourseType[]>(
    props.studentCourses
  );
  const [studentBundles, setStudentBundles] = useState<ModifiedBundleType[]>(
    props.studentBundles
  );
  const [scenarioData, setScenarioData] = useState<ModifiedScenarioType[]>(
    props.scenarioData
  ); // State to store unit data

  const [studentSpecificCompetences, setStudentSpecificCompetences] = useState<{
    [key: string]: SpecificCompetencePoints;
  }>({});

  const [studentData, setStudentData] = useState<{ [key: string]: number[] }>(
    props.studentData
  );
  const [classData, setClassData] = useState<{ [key: string]: number[] }>(
    props.classData
  );
  const [labels, setLabels] = useState<{ [key: string]: string[] }>(
    props.labels
  );

  const itemsPerRow = 3; // Adjust as necessary
  const itemHeight = 100; // Adjust to match curricularContentBox height
  const margin = 1; // Adjust to match curricularContentBox margin
  const totalItems = studentBundles.length;
  const isNotFP =
    props.selectedClass.regulation_id !== "regulation_GB" &&
    props.selectedClass.regulation_id !== "regulation_GMyS";

  const [achievedSubCompetences, setAchievedSubCompetences] = useState<any[]>(
    []
  );
  const [achievedSubCompetencesAnexo, setAchievedSubCompetencesAnexo] =
    useState<any[]>([]);

  useEffect(() => {
    setStudentSpecificCompetences(props.studentSpecificCompetences);
  }, [props.student]);

  const mainLanguage = t(setLanguage(props.selectedClass?.primary_language));
  const foreignLanguage = t(
    setLanguage(props.selectedClass?.secondary_language)
  );

  // For global competence data
  const acronyms = !_.isEmpty(props.globalCompetences)
    ? Object.keys(getGlobalCompetenceAggregates(props.globalCompetences)).map(
        (str) => str
      )
    : simpleGlobalCompetences.map((gc) =>
        t(gc[0] + "_name", { ns: "evaluation" })
      );

  const globalCompPoints = !_.isEmpty(props.globalCompetences)
    ? Object.values(getGlobalCompetenceAggregates(props.globalCompetences))
    : _.fill(Array(simpleGlobalCompetences.length), 0);

  const schoolData = !_.isEmpty(props.schoolCompetences)
    ? Object.values(getGlobalCompetenceAggregates(props.schoolCompetences))
    : _.fill(Array(simpleGlobalCompetences.length), 0);

  const determineBackgroundColorAndText = (points: number) => {
    if (points >= 2400) {
      return { backgroundColor: "rgba(80, 240, 192, 0.3)", text: "Adquirida" };
    } else {
      return { backgroundColor: "#FBD2CA", text: "No adquirida" };
    }
  };

  const determineGradeAndStyle = (points: number) => {
    let grade;
    let backgroundColor2;
    switch (true) {
      case points >= 4000:
        grade = t("high_level", { ns: "pdf" });
        backgroundColor2 = "#EAFCD9";
        break;
      case points >= 3200:
        grade = t("medium_level", { ns: "pdf" });
        backgroundColor2 = "#FDF2CF";
        break;
      case points >= 2400:
        grade = t("low_level", { ns: "pdf" });
        backgroundColor2 = "#FBD2CA";
        break;
      default:
        grade = t("NA_level", { ns: "pdf" });
        backgroundColor2 = "#f3f3f3";
    }
    return { grade, backgroundColor2 };
  };

  const renderCompetencesRight = (
    acronym: string,
    acronymIndex: number,
    baseline: number
  ) => {
    const maximum = 12500; // Set the maximum baseline value

    // Ensure the values from globalCompPoints and schoolData do not exceed the baseline
    const adjustedStudentValue = Math.min(
      globalCompPoints[acronymIndex],
      maximum
    );
    const adjustedClassValue = Math.min(schoolData[acronymIndex], maximum);

    // Calculate percentages based on the adjusted values
    const studentPercentage = (adjustedStudentValue * 100) / maximum;
    const schoolPercentage = (adjustedClassValue * 100) / maximum;

    // const studentPercentage = (globalCompPoints[acronymIndex] * 100) / baseline;
    // const schoolPercentage = (schoolData[acronymIndex] * 100) / baseline;

    return (
      <View
        style={page1Styles.globalCompetencesRightElementLineContainer}
        key={acronymIndex}
      >
        <Text style={globalStyles.textSuperSmallBold}>
          {t(acronym + "_name", { ns: "evaluation" })}
        </Text>

        <View style={page1Styles.globalCompetencesRightElementLine} />
        <View
          style={[
            page1Styles.globalCompetencesRightElementStudent,
            { width: `${studentPercentage * 0.35}%` },
          ]}
        />
        <View
          style={[
            page1Styles.globalCompetencesRightElementSchool,
            { left: `${schoolPercentage * 0.35 + 55}%` },
          ]}
        />
        <View style={[page1Styles.percentageContainer]}>
          <Text
            style={[globalStyles.textSuperSmallBoldBlue, { margin: "auto" }]}
          >
            {Math.round(studentPercentage)}%
          </Text>
        </View>
      </View>
    );
  };

  const lowOrNAGrades = acronyms.filter((_, index) => {
    const { grade } = determineGradeAndStyle(globalCompPoints[index]);
    return (
      grade === t("NA_level", { ns: "pdf" }) ||
      grade === t("low_level", { ns: "pdf" })
    );
  });

  const calculateCurricularContentHeight = (
    totalItems: number,
    itemsPerRow: number,
    itemHeight: number,
    margin: number
  ) => {
    const rows = Math.ceil(totalItems / itemsPerRow);
    return rows * (itemHeight + margin * 2);
  };

  const renderSpCompRight = (course: CourseType, labels: string[]) => {
    return labels.map((label, labelIndex) => {
      const studentValue = studentData[course.id][labelIndex] || 0;
      const classValue = classData[course.id][labelIndex] || 0;

      // const baseline = Math.max(750, studentValue, classValue);

      // const studentPercentage = (studentValue * 100) / baseline;
      // const schoolPercentage = (classValue * 100) / baseline;

      const baseline = 750; // Set the maximum baseline value

      // Ensure studentValue and classValue do not exceed the baseline
      const adjustedStudentValue = Math.min(studentValue, baseline);
      const adjustedClassValue = Math.min(classValue, baseline);

      // Calculate percentages based on the adjusted values
      const studentPercentage = (adjustedStudentValue * 100) / baseline;
      const schoolPercentage = (adjustedClassValue * 100) / baseline;

      return (
        <View
          style={page1Styles.globalCompetencesRightElementLineContainer}
          key={labelIndex}
        >
          <View style={{ width: "45%" }}>
            <Text
              style={[
                globalStyles.textSuperSmallBold,
                { textOverflow: "ellipsis" },
              ]}
            >
              {label}
            </Text>
          </View>
          <View style={page1Styles.globalCompetencesRightElementLine} />
          <View
            style={[
              page1Styles.globalCompetencesRightElementStudent,
              { width: `${studentPercentage * 0.35}%` },
            ]}
          />
          <View
            style={[
              page1Styles.globalCompetencesRightElementSchool,
              { left: `${schoolPercentage * 0.35 + 55}%` },
            ]}
          />
          <View style={[page1Styles.percentageContainer]}>
            <Text
              style={[globalStyles.textSuperSmallBoldBlue, { margin: "auto" }]}
            >
              {Math.round(studentPercentage)}%
            </Text>
          </View>
        </View>
      );
    });
  };

  const renderCurricularContent = (
    course: CourseType,
    studentBundles: ModifiedBundleType[]
  ) => {
    return (
      <>
        {studentBundles
          .filter((bun) => bun.scenario?.course_id === course.id)
          .map((studentBundle, i) => {
            var points = getScenarioPoints(studentBundle.scenario_id);
            const unitName = studentBundle.scenario?.main_know_how;
            const scenario = scenarioData?.find(
              (scenario) => scenario.id === studentBundle.scenario_id
            );
            if (!scenario) {
              // Handle case where unit is not found
              return (
                <View key={i}>
                  <Text></Text>
                </View>
              );
            }
            const bannerUrl = scenario.banner.url;
            const tutorialUrl =
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Tutorial/Tutorial+-+Banner.jpg";
            const scenarioRegulation: RegulationType = props.regulations.find(
              (regulation: RegulationType) =>
                regulation.id === scenario?.course.regulation_id
            );
            const scenarios = [studentBundle.scenario];
            const url =
              _.isUndefined(bannerUrl) || bannerUrl === ""
                ? tutorialUrl
                : bannerUrl;
            const activityText = t("activity_lc");
            return (
              <View style={page1Styles.curricularContentBox} key={i}>
                <View style={page1Styles.bannerStyle}>
                  <Image
                    src={{
                      uri: url,
                      method: "GET",
                      headers: { "Cache-Control": "no-cache" },
                      body: "",
                    }}
                    style={{
                      height: "100%",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                </View>
                <View style={page1Styles.nameCurricularContent}>
                  <View style={{ width: "65%", paddingLeft: 1, paddingTop: 3 }}>
                    <Text
                      style={[
                        globalStyles.textSmallBold,
                        { textOverflow: "ellipsis" },
                      ]}
                    >
                      {" "}
                      {unitName}{" "}
                    </Text>
                  </View>
                  <View
                    style={[
                      { backgroundColor: course?.color },
                      page1Styles.courseAcronym,
                    ]}
                  >
                    <Text
                      style={[
                        globalStyles.textSuperSmallBoldWhite,
                        { margin: "auto" },
                      ]}
                    >
                      {" "}
                      {t(course?.acronym + "_short", { ns: "evaluation" })}
                    </Text>
                  </View>
                </View>
                <View style={page1Styles.activitiesRegulationsContainer}>
                  <Text style={globalStyles.textSuperSmallGrey}>
                    {" "}
                    {"1 " + activityText}{" "}
                  </Text>

                  <View
                    style={{
                      borderRadius: "10px",
                      height: "auto",
                      width: "35%",
                      backgroundColor: "#5090F0",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={[
                        globalStyles.textSuperSmallBoldWhite,
                        { margin: "auto" },
                      ]}
                    >
                      {" "}
                      {scenarioRegulation?.name}
                    </Text>
                  </View>
                </View>
                <View style={page1Styles.pointsContainer}>
                  <Text style={[globalStyles.textBigBoldGreen, { right: 1 }]}>
                    {points}
                  </Text>
                  <Text style={[globalStyles.textSmallBoldBlue, { top: 4 }]}>
                    {"/"}
                  </Text>
                  <View
                    style={{
                      borderRadius: "5px",
                      height: 12.5,
                      width: scenarios.length === 0 ? 10 : 20,
                      top: 4,
                      left: 1,
                      backgroundColor: "#e8e8e8",
                    }}
                  >
                    <Text
                      style={[
                        globalStyles.textSmallBoldBlue,
                        { margin: "auto" },
                      ]}
                    >
                      {scenarios.length * 100}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
      </>
    );
  };

  const renderCompetencesTable = () => {
    return (
      <View style={globalCompTable.table}>
        <View style={globalCompTable.tableRow}>
          <View
            style={[globalCompTable.tableColHeader, { borderTopLeftRadius: 5 }]}
          >
            <Text style={[globalStyles.textMediumBoldWhite]}>
              {t("key_competences", { ns: "pdf" })}
            </Text>
          </View>
          <View style={globalCompTable.tableColHeader}>
            <Text
              style={[globalStyles.textMediumBoldWhite, { margin: "auto" }]}
            >
              {t("acquired", { ns: "pdf" })}
            </Text>
          </View>
          <View
            style={[
              globalCompTable.tableColHeader,
              { borderTopRightRadius: 5 },
            ]}
          >
            <Text
              style={[globalStyles.textMediumBoldWhite, { margin: "auto" }]}
            >
              {t("grade_acquisition", { ns: "pdf" })}
            </Text>
          </View>
        </View>
        {acronyms.map((acronym, index) => {
          const { backgroundColor, text } = determineBackgroundColorAndText(
            globalCompPoints[index]
          );
          const { grade, backgroundColor2 } = determineGradeAndStyle(
            globalCompPoints[index]
          );

          return (
            <View
              key={index}
              style={[globalCompTable.tableRow, { marginTop: 5 }]}
            >
              <View
                style={[
                  globalCompTable.tableCol,
                  { backgroundColor: "rgba(80, 144, 240, 0.3)" },
                ]}
              >
                <Text
                  style={[
                    globalStyles.textSmallBold,
                    {
                      borderBottomLeftRadius:
                        index === acronyms.length - 1 ? "10px" : "0",
                    },
                  ]}
                >
                  {t(acronym + "_name", { ns: "evaluation" })}
                </Text>
              </View>
              <View
                style={[
                  globalCompTable.tableCol,
                  { backgroundColor: backgroundColor },
                ]}
              >
                <Text style={[globalStyles.textSmallBold, { margin: "auto" }]}>
                  {text}
                </Text>
              </View>
              <View
                style={[
                  globalCompTable.tableCol,
                  { backgroundColor: backgroundColor2 },
                ]}
              >
                <Text
                  style={[
                    globalStyles.textSmallBold,
                    {
                      margin: "auto",
                      borderBottomRightRadius:
                        index === acronyms.length - 1 ? "10px" : "0",
                    },
                  ]}
                >
                  {grade}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  const getScenarioPoints = (scenarioId: string) => {
    if (
      _.isEmpty(props.scenarioPoints) &&
      props.scenarioPoints[scenarioId] === undefined
    )
      return 0;
    var scenarioPoints: number = props.scenarioPoints[scenarioId];
    if (scenarioPoints === undefined) return 0;
    return scenarioPoints;
  };

  const renderSubjectAnalysis = (course: CourseType) => {
    const labels = course.specific_competences.map((sc) =>
      t(course.acronym + sc.number + "_label", { ns: "evaluation" })
    );

    return (
      <Page size="A4" style={globalStyles.page}>
        <View style={[globalStyles.header, { top: -10 }]}>
          <Text style={globalStyles.title}>
            {" "}
            <Text style={globalStyles.titleNumber}> 2/ </Text>
            {t("subject_analysis", { ns: "pdf" })}
          </Text>

          <View
            style={[page3Styles.courseBadge, { backgroundColor: course.color }]}
          >
            {/* <View style={{ width: "90%" }}> */}
            <Text
              style={[
                globalStyles.textMediumBoldWhite,
                {
                  textDecoration: "underline",
                  margin: "auto",
                  textOverflow: "ellipsis",
                },
              ]}
            >
              {t(course.acronym + "_name", { ns: "evaluation" })}
            </Text>
            {/* </View> */}
          </View>
          <Image style={globalStyles.headerImage} fixed src={logo} />
        </View>
        <View style={globalStyles.sectionTitle}>
          <Text style={globalStyles.underlinedSubtitle}>
            {t("specific_competences")}
          </Text>
        </View>
        <View
          style={[
            page3Styles.radarChartContainer,
            { height: labels.length >= 8 ? labels.length * 4.4 + "%" : "35%" },
          ]}
        >
          <View style={page1Styles.radarChartContainerLeft}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-evenly" }}
            >
              <View style={{ flexDirection: "row" }}>
                <View style={page1Styles.studentDot} />
                <Text style={globalStyles.textSmallBoldBlue}>
                  {props.student?.username}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={page1Styles.schoolDot} />
                <Text style={globalStyles.textSmallBoldGreen}>
                  {t("center_mean")}
                </Text>
              </View>
            </View>
            <Image
              src={{
                uri: props.radarChartUrl[course.id],
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
              style={{
                width: "100%",
                marginTop: "5%",
              }}
            />
          </View>

          <View
            style={[
              page3Styles.globalCompetencesPointsContainer,
              { paddingRight: 20 },
            ]}
          >
            {renderSpCompRight(course, labels)}
          </View>
        </View>

        <View style={globalStyles.sectionTitle}>
          <Text style={globalStyles.underlinedSubtitle}>
            {t("curricular_content_subject", { ns: "pdf" })}
          </Text>
        </View>
        <View style={page3Styles.curricularContentContainer}>
          <Text style={[globalStyles.textSuperSmallGrey, { width: "100%" }]}>
            {" "}
            {t("curricular_content_subject_subtext", { ns: "pdf" })}
          </Text>
        </View>

        {studentBundles
          .filter((bun) => bun.scenario?.course_id === course.id)
          .map((studentBundle, i) => {
            const unitName = studentBundle.scenario?.main_know_how ?? "Unidad";
            var startDate = new Date(studentBundle.start_date);
            var endDate = new Date(studentBundle.end_date);
            var points = getScenarioPoints(studentBundle.scenario?.id || "");
            const scenarios = [studentBundle.scenario];
            return (
              <View
                style={[
                  curricularContentSubject.unitGeneralContainer,
                  {
                    height:
                      scenarios.length === 2
                        ? "20%"
                        : scenarios.length <= 1
                        ? "15%"
                        : scenarios.length * 8 + "%",
                  },
                ]}
                key={studentBundle.id}
                wrap={false}
              >
                <View style={curricularContentSubject.unitNameContainer}>
                  <View style={{ width: "37.5%" }}>
                    <Text
                      style={[globalStyles.textMediumBold, { paddingTop: 10 }]}
                    >
                      {unitName}
                    </Text>
                  </View>
                  <Text
                    style={[
                      globalStyles.textSuperSmallGrey,
                      { paddingTop: 12.5 },
                    ]}
                  >
                    {startDate.getDate() +
                      "/" +
                      (startDate.getMonth() + 1) +
                      "/" +
                      startDate.getFullYear() +
                      " - " +
                      endDate.getDate() +
                      "/" +
                      (endDate.getMonth() + 1) +
                      "/" +
                      endDate.getFullYear()}
                  </Text>
                  <Text
                    style={[globalStyles.textSmallBoldBlue, { paddingTop: 10 }]}
                  >
                    {" "}
                    {points + "/" + scenarios.length * 100}{" "}
                  </Text>
                  <Text
                    style={[globalStyles.textSmallBold, { paddingTop: 10 }]}
                  >
                    {" "}
                    {t("research") + " | " + t("experiment")}
                  </Text>
                </View>

                {/* renderScenarios */}
                <View style={curricularContentSubject.scenariosContainer}>
                  {scenarios.map((scenario) => {
                    if (!scenario) {
                      return (
                        <View key={i}>
                          <Text></Text>
                        </View>
                      );
                    }
                    const selectedSpecificCompetences =
                      specificCompetencesBooleanArray(
                        scenario.specific_competences_ids,
                        course.specific_competences
                      );
                    const tutorialImage =
                      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Tutorial/Tutorial+-+ICON.jpg";
                    const scenarioImage = scenario.image?.url || tutorialImage;
                    // const scenarioImage = "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/GeH/1+ESO/Imagenes+estaticas-+Historia+de+la+religion/HISTORIA+Y+CULTURA+DE+LAS+RELIGIONES/icon1.jpg"
                    return (
                      <View
                        style={curricularContentSubject.singleScenarioContainer}
                      >
                        <Image
                          src={{
                            uri: scenarioImage,
                            method: "GET",
                            headers: { "Cache-Control": "no-cache" },
                            body: "",
                          }}
                          style={{
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 5,
                            marginTop: 5,
                          }}
                        />
                        <View style={{ height: 20, flexDirection: "column" }}>
                          <View
                            style={{
                              width: "85%",
                              marginTop: 5,
                              marginLeft: 1,
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={[globalStyles.textSmall, { width: "85%" }]}
                            >
                              {" "}
                              <Text style={globalStyles.textSmallBold}>
                                {" "}
                                {scenario.name}
                              </Text>
                              {" | " + scenario.main_know_how}
                            </Text>
                          </View>
                          <View
                            style={{
                              position: "absolute",
                              height: 12.5,
                              flexDirection: "row",
                              top: 22.5,
                            }}
                          >
                            {selectedSpecificCompetences.map(
                              (competence: boolean, index: number) => {
                                return (
                                  <View
                                    style={[
                                      curricularContentSubject.specificCompBadge,
                                      {
                                        backgroundColor: competence
                                          ? "rgba(41, 47, 76, 1)"
                                          : "rgba(41, 47, 76, 0.25)",
                                      },
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        globalStyles.textSuperSmallBoldWhite,
                                        { margin: "auto" },
                                      ]}
                                    >
                                      {prettifyCourseAcronyms(course.acronym) +
                                        (index + 1).toString()}
                                    </Text>
                                  </View>
                                );
                              }
                            )}
                          </View>
                        </View>
                        {/* <View style={curricularContentSubject.scenarioPoints}>
                          <Text style={globalStyles.textSuperSmallBoldBlue}>
                            {points + "/100"}
                          </Text>
                        </View> */}
                      </View>
                    );
                  })}
                </View>
              </View>
            );
          })}
        <Text
          style={globalStyles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    );
  };

  useEffect(() => {
    // calculateAchievedSubcompetences()
    calculateAchievedSubcompetencesAnexo();
  }, []);

  const calculatedHeight = calculateCurricularContentHeight(
    totalItems,
    itemsPerRow,
    itemHeight,
    margin
  );

  const ITEMS_PER_PAGE_FIRST = 6;
  const ITEMS_PER_PAGE_REST = 15;
  const MAX_HEIGHT_PAGE1 = 273;
  const MAX_HEIGHT_OTHER_PAGES = 575;
  const totalBundles = studentBundles.length;
  // Calculate the total number of pages needed
  const totalPages =
    Math.ceil((totalBundles - ITEMS_PER_PAGE_FIRST) / ITEMS_PER_PAGE_REST) + 1;

  const renderPage = (pageIndex: number) => {
    const isFirstPage = pageIndex === 0;
    const itemsPerPage = isFirstPage
      ? ITEMS_PER_PAGE_FIRST
      : ITEMS_PER_PAGE_REST;
    const start = isFirstPage
      ? 0
      : ITEMS_PER_PAGE_FIRST + (pageIndex - 1) * ITEMS_PER_PAGE_REST;
    const end = start + itemsPerPage;
    const bundlesForPage = studentBundles.slice(start, end);
    let marginBottom;
    if (pageIndex < 1) {
      marginBottom = 0;
    } else if (pageIndex === 1) {
      marginBottom = 2;
    } else {
      marginBottom = 4;
    }
    return (
      <>
        {!isFirstPage && renderStudentInfo(pageIndex)}
        <View
          style={[
            page1Styles.curricularContent,
            {
              // height: isFirstPage ? MAX_HEIGHT_PAGE1 : MAX_HEIGHT_OTHER_PAGES,
              marginBottom: marginBottom,
            },
          ]}
          key={pageIndex}
          wrap={false}
        >
          {studentCourses.map((course) =>
            renderCurricularContent(course, bundlesForPage)
          )}
        </View>
      </>
    );
  };

  const renderStudentInfo = (pageIndex: number) => (
    <View
      style={{ height: 100, flexDirection: "column", top: -10 }}
      wrap={false}
      break={pageIndex !== 0 ? true : false}
    >
      <View style={[globalStyles.header, { flexDirection: "row" }]}>
        <Text style={globalStyles.title}>
          {" "}
          <Text style={globalStyles.titleNumber}> 1/ </Text>
          {t("evaluation_report", { ns: "pdf" })}
        </Text>

        <Image style={globalStyles.headerImage} fixed src={logo} />
      </View>
      <View style={globalStyles.row}>
        <ul style={{ marginLeft: 45 }}>
          <li style={globalStyles.textBig}>
            <Text>
              {t("student_xls")}
              <Text style={{ fontWeight: "light" }}>
                {" " + props.student?.username}
              </Text>
            </Text>
          </li>
          <li style={globalStyles.textBig}>
            <Text>
              {t("main_language_xls")}
              <Text style={{ fontWeight: "light" }}>{" " + mainLanguage}</Text>
            </Text>
          </li>
        </ul>
        <ul style={{ marginLeft: 35 }}>
          <li style={globalStyles.textBig}>
            <Text>
              {t("class_xls")}{" "}
              <Text style={{ fontWeight: "light" }}>
                {"  " + props.selectedClass?.name}
              </Text>
            </Text>
          </li>
          <li style={globalStyles.textBig}>
            <Text>
              {t("foreign_language_xls")}
              <Text style={{ fontWeight: "light" }}>
                {" " + foreignLanguage}
              </Text>
            </Text>
          </li>
        </ul>
      </View>
    </View>
  );

  // const calculateAchievedSubcompetences = () => {
  //   const achievedSubCompetences: string[] = [];

  //   for (let i = 0; i < lowOrNAGlobalSubcompetences.length; i++) {
  //     const subcompetence = lowOrNAGlobalSubcompetences[i];
  //     const subcompetenceAggregate = props.globalCompetences[subcompetence.acronym + subcompetence.number];
  //     if (subcompetenceAggregate >= 1000) {
  //       // TODO - Check if this number is enough
  //       achievedSubCompetences.push(subcompetence.id);
  //     }
  //   }
  //   setAchievedSubCompetences(achievedSubCompetences);

  // }

  const getLowestValueSubcompetences = () => {
    // Group the competences by their acronym
    const groupedByAcronym = props.trueGlobalCompetences.reduce(
      (acc: any, curr: any) => {
        if (!acc[curr.acronym]) {
          acc[curr.acronym] = [];
        }
        acc[curr.acronym].push(curr);
        return acc;
      },
      {}
    );

    // For each competence, find the subcompetence with the lowest value
    const lowestValueSubcompetences = Object.keys(groupedByAcronym).map(
      (acronym) => {
        const subcompetences = groupedByAcronym[acronym];
        return subcompetences.reduce(
          (lowest: any, current: any) =>
            current.value < lowest.value ? current : lowest,
          subcompetences[0]
        );
      }
    );

    return lowestValueSubcompetences;
  };

  const getFirstLowestSubcompetences = () => {
    const lowestSubcompetences = getLowestValueSubcompetences();

    // Sort the lowest subcompetences by their value in ascending order
    const sortedSubcompetences = lowestSubcompetences.sort(
      (a, b) => a.value - b.value
    );

    // Return the first 4 lowest subcompetences
    return sortedSubcompetences.slice(0, 3);
  };
  const firstLowestSubcompetences = getFirstLowestSubcompetences();

  const allGrades = acronyms.filter((_, index) => {
    const { grade } = determineGradeAndStyle(globalCompPoints[index]);
    return (
      grade === t("NA_level", { ns: "pdf" }) ||
      grade === t("low_level", { ns: "pdf" }) ||
      grade === t("medium_level", { ns: "pdf" }) ||
      grade === t("high_level", { ns: "pdf" })
    );
  });

  const allGlobalSubcompetences = props.trueGlobalCompetences.filter(
    (gc: any) => allGrades.includes(gc.acronym)
  );

  const calculateAchievedSubcompetencesAnexo = () => {
    const achievedSubCompetences: string[] = [];

    for (let i = 0; i < allGlobalSubcompetences.length; i++) {
      const subcompetence = allGlobalSubcompetences[i];
      const subcompetenceAggregate =
        props.globalCompetences[subcompetence.acronym + subcompetence.number];
      if (subcompetenceAggregate >= 1000) {
        // TODO - Check if this number is enough
        achievedSubCompetences.push(subcompetence.id);
      }
    }
    setAchievedSubCompetencesAnexo(achievedSubCompetences);
  };

  return (
    <Document>
      {/* Page 1 */}
      <Page size="A4" style={globalStyles.page}>
        {renderStudentInfo(0)}
        <View style={globalStyles.section}>
          <Text style={globalStyles.textBigSemiBold}> {t("end_profile")} </Text>
        </View>
        {isNotFP && (
          <View style={globalStyles.sectionTitle}>
            <Text style={globalStyles.underlinedSubtitle}>
              {t("competence_analysis", { ns: "pdf" })}
            </Text>
          </View>
        )}
        {isNotFP && (
          <View style={page1Styles.radarChartContainer}>
            {/* aqui va el Chart */}
            <View style={page1Styles.radarChartContainerLeft}>
              <View
                style={{ flexDirection: "row", justifyContent: "space-evenly" }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={page1Styles.studentDot} />
                  <Text style={globalStyles.textSmallBoldBlue}>
                    {props.student?.username}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <View style={page1Styles.schoolDot} />
                  <Text style={globalStyles.textSmallBoldGreen}>
                    Media de la clase
                  </Text>
                </View>
              </View>
              <Image
                src={{
                  uri: props.radarChartUrl["generalInfo"],
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
                style={{
                  width: "100%",
                }}
              />
            </View>
            <View style={page1Styles.globalCompetencesPointsContainer}>
              {acronyms.map((acronym, acronymIndex) => {
                const baseline = Math.max(
                  12500,
                  ...globalCompPoints,
                  ...schoolData
                );
                return renderCompetencesRight(acronym, acronymIndex, baseline);
              })}
            </View>
          </View>
        )}
        <View style={globalStyles.sectionTitle}>
          <Text style={globalStyles.underlinedSubtitle}>
            {t("curricular_content_worked", { ns: "pdf" })}
          </Text>
        </View>
        {Array.from({ length: totalPages }, (_, pageIndex) =>
          renderPage(pageIndex)
        )}
        <Text
          style={globalStyles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      {/* Page 2 */}
      {isNotFP && (
        <Page size="A4" style={[globalStyles.page, { paddingBottom: 10 }]}>
          {renderStudentInfo(0)}
          <View style={globalStyles.section}>
            <Text style={globalStyles.textBigSemiBold}>
              {" "}
              {t("competence_acquisition", { ns: "pdf" })}{" "}
            </Text>
          </View>
          <View style={page2Styles.tableCompetencesContainer}>
            <div style={{ position: "relative", height: "85%" }}>
              {renderCompetencesTable()}
            </div>
            <Text style={[globalStyles.textSuperSmallGrey, { width: "100%" }]}>
              {" "}
              {t("acquisition_competences_text", { ns: "pdf" })}{" "}
            </Text>
            <Text
              style={[
                globalStyles.textSuperSmallGrey,
                { top: 10, width: "100%" },
              ]}
            >
              {" "}
              {t("see_annex_1", { ns: "pdf" })}{" "}
            </Text>{" "}
          </View>
          <View style={globalStyles.section}>
            <Text style={globalStyles.textBigSemiBold}>
              {" "}
              {t("reinforcement_recommendation", { ns: "pdf" })}
            </Text>
          </View>
          <View style={page2Styles.recomendationsContainer}>
            <View>
              {lowOrNAGrades.length === 0 ? (
                <Text style={globalStyles.textSmall}>
                  {t("all_competences_recommendation", { ns: "pdf" })}
                </Text>
              ) : (
                <>
                  <Text style={globalStyles.textSmall}>
                    {" "}
                    {t("lowNA_recommendation", { ns: "pdf" })}{" "}
                  </Text>
                  {lowOrNAGrades
                    .reduce((rows: JSX.Element[][], acronym, index) => {
                      const rowIndex = Math.floor(index / 3); // Calculate the row index
                      if (!rows[rowIndex]) {
                        rows[rowIndex] = []; // Initialize row array if it doesn't exist
                      }
                      rows[rowIndex].push(
                        <View style={page2Styles.badge} key={index}>
                          <Text style={globalStyles.textSmallBold}>
                            {t(acronym + "_name", { ns: "evaluation" })}
                          </Text>
                        </View>
                      );

                      return rows;
                    }, [])
                    .map((row, rowIndex) => (
                      <View style={page2Styles.badgeRow} key={rowIndex}>
                        {row}
                      </View>
                    ))}

                  <Text style={globalStyles.textSmall}>
                    {" "}
                    {t("competences_recommendation", { ns: "pdf" })}{" "}
                  </Text>

                  {/* {lowOrNAGlobalSubcompetences
                  .filter((comp: any) => achievedSubCompetences.includes(comp.id))
                  .map((competence: any) => {
                    return (
                      <View key={competence.id} style={page2Styles.textWithDotContainer}>
                        <View style={page2Styles.greenDot} />
                        <Text style={globalStyles.textSuperSmall}>
                          {t(competence.acronym + competence.number + "_desc", {
                            ns: "evaluation",
                          })}
                        </Text>
                      </View>
                    )
                  })} */}

                  {/* {lowOrNAGlobalSubcompetences
                  .filter((comp: any) => !achievedSubCompetences.includes(comp.id))
                  .map((competence: any) => {
                    const regulation = props.selectedClass?.regulation_id.split('_');
                    const regulationEnd = regulation[regulation.length - 1]
                    return (
                      <View key={competence.id} style={page2Styles.textWithDotContainer}>
                        <View style={page2Styles.redDot} />
                        <Text style={globalStyles.textSmall}>
                          {t(competence.acronym + competence.number + "_" + regulationEnd + "_desc", {
                            ns: "pdf",
                          })}
                        </Text>
                      </View>
                    )
                  })} */}

                  {firstLowestSubcompetences.map((competence: any) => {
                    const regulation =
                      props.selectedClass?.regulation_id.split("_");
                    const regulationEnd = regulation[regulation.length - 1];
                    return (
                      <View
                        key={competence.id}
                        style={page2Styles.textWithDotContainer}
                      >
                        <View style={page2Styles.redDot} />
                        <Text style={globalStyles.textSmall}>
                          {t(
                            competence.acronym +
                              competence.number +
                              "_" +
                              regulationEnd +
                              "_desc",
                            {
                              ns: "pdf",
                            }
                          )}
                        </Text>
                      </View>
                    );
                  })}
                </>
              )}
            </View>
          </View>
          <Text
            style={globalStyles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      )}

      {/* Page 3 */}
      {studentCourses.map((course: CourseType) =>
        renderSubjectAnalysis(course)
      )}

      {isNotFP && (
        <Page size="A4" style={globalStyles.page}>
          <View style={[globalStyles.header, { top: -10 }]}>
            <Text style={globalStyles.title}>
              {" "}
              <Text style={globalStyles.titleNumber}> Anexo 1: </Text>
              {t("competence_analysis_title_annex", { ns: "pdf" })}
            </Text>

            <Image style={globalStyles.headerImage} fixed src={logo} />
          </View>

          {acronyms.map((acronym, acronymIndex) => {
            const maximum = 12500; // Set the maximum baseline value

            // Ensure the values from globalCompPoints and schoolData do not exceed the baseline
            const adjustedStudentValue = Math.min(
              globalCompPoints[acronymIndex],
              maximum
            );
            const adjustedClassValue = Math.min(
              schoolData[acronymIndex],
              maximum
            );

            // Calculate percentages based on the adjusted values
            const studentPercentage = (adjustedStudentValue * 100) / maximum;
            const schoolPercentage = (adjustedClassValue * 100) / maximum;

            const filteredCompetencesGreen = allGlobalSubcompetences.filter(
              (comp: any) =>
                comp.acronym === acronym &&
                achievedSubCompetencesAnexo.includes(comp.id)
            );

            const filteredCompetencesRed = allGlobalSubcompetences.filter(
              (comp: any) =>
                comp.acronym === acronym &&
                !achievedSubCompetencesAnexo.includes(comp.id)
            );
            return (
              <View style={anexo1.competenceContainer} wrap={false}>
                <View style={{ height: 20, flexDirection: "row" }}>
                  <View style={{ width: "40%", marginTop: 1 }}>
                    <Text
                      style={[
                        globalStyles.textMediumBold,
                        { textDecoration: "underline" },
                      ]}
                    >
                      {t(acronym + "_name", { ns: "evaluation" })}
                    </Text>
                  </View>
                  <View style={anexo1.globalCompetencesRightElementLine} />
                  <View
                    style={[
                      anexo1.globalCompetencesRightElementStudent,
                      { width: `${studentPercentage * 0.35}%` },
                    ]}
                  />
                  <View
                    style={[
                      anexo1.globalCompetencesRightElementSchool,
                      {
                        borderRadius: 5,
                        left: `${schoolPercentage * 0.35 + 45}%`,
                      },
                    ]}
                  />
                </View>
                <View wrap={false}>
                  {filteredCompetencesGreen.length > 0 && (
                    <Text
                      style={[
                        globalStyles.textSmallBold,
                        { marginTop: 5, marginBottom: 5 },
                      ]}
                    >
                      {" "}
                      {t("student_has_shown", { ns: "pdf" })}
                    </Text>
                  )}
                  {filteredCompetencesGreen.map((competence: any) => {
                    const regulation =
                      props.selectedClass?.regulation_id.split("_");
                    const regulationEnd = regulation[regulation.length - 1];
                    return (
                      <View
                        key={competence.id}
                        style={page2Styles.textWithDotContainer}
                      >
                        <View style={page2Styles.greenDot} />
                        <Text style={globalStyles.textSuperSmall}>
                          {t(
                            competence.acronym +
                              competence.number +
                              "_" +
                              regulationEnd +
                              "_desc",
                            {
                              ns: "pdf",
                            }
                          )}
                        </Text>
                      </View>
                    );
                  })}
                </View>

                <View wrap={false}>
                  {filteredCompetencesRed.length > 0 && (
                    <Text
                      style={[
                        globalStyles.textSmallBold,
                        { marginTop: 5, marginBottom: 5 },
                      ]}
                    >
                      {" "}
                      {t("student_could_improve", { ns: "pdf" })}
                    </Text>
                  )}
                  {filteredCompetencesRed.map((competence: any) => {
                    const regulation =
                      props.selectedClass?.regulation_id.split("_");
                    const regulationEnd = regulation[regulation.length - 1];
                    return (
                      <View
                        key={competence.id}
                        style={page2Styles.textWithDotContainer}
                      >
                        <View style={page2Styles.redDot} />
                        <Text style={globalStyles.textSuperSmall}>
                          {t(
                            competence.acronym +
                              competence.number +
                              "_" +
                              regulationEnd +
                              "_desc",
                            {
                              ns: "pdf",
                            }
                          )}
                        </Text>
                      </View>
                    );
                  })}
                </View>

                {acronymIndex < acronyms.length - 1 && (
                  <View
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: "#5090F0",
                      marginTop: 10,
                    }}
                  />
                )}
              </View>
            );
          })}

          <Text
            style={globalStyles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      )}
      {/* Page 4 */}
      {/* <Page size="A4" style={globalStyles.page}>
        <View style={globalStyles.header}>
          <div style={{ top: 20 }}>
            <Text style={globalStyles.title}> <Text style={globalStyles.titleNumber}> 3/ </Text>Análisis del proyecto jugable</Text>
          </div>
          <Image style={globalStyles.headerImage} fixed src={logo} />
        </View>
        <Text
          style={globalStyles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page> */}
    </Document>
  );
}
export default BasicDocument;
