import "./TutorialEutonsLocalMap.css";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { useTranslation, withTranslation } from "react-i18next";
import closeButton from "../../../assets/student/tutorial/close.svg";
import confirmButton from "../../../assets/student/tutorial/ok.svg";
import flechaLeft from "../../../assets/student/tutorial/FlechaTutLeft.svg";
import flechaRight from "../../../assets/student/tutorial/FlechaTutRight.svg";
import { LazyMotion, m } from "framer-motion";
import { increaseTutorialEutonsStep } from "../../../reducers/studentSlice.ts";

const TutorialEutonsLocalMap = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const studentState = useAppSelector((state) => state.student);
  const step = studentState.tutorialEutonsStep;

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const [showBubble, setShowBubble] = useState(true);

  useEffect(() => {
    if (!showBubble) {
      setShowBubble(true);
    }
  }, [step]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const advanceStepHandler = () => {
    dispatch(increaseTutorialEutonsStep());
  };

  const hideBubbleHandler = () => {
    setShowBubble(false);
  };

  const teacherIsRendered = () => {
    if (step >= 5 && step <= 8 && props.part === "campus") {
      return true;
    }
    if (props.part === "repeat_campus") {
      return true;
    }
    if (step >= 9 && step <= 12 && showBubble && props.part === "energy_lab") {
      return true;
    }
    if (step >= 13 && step <= 15 && showBubble && props.part === "workshop") {
      return true;
    }
    if (step >= 16 && step <= 18 && props.part === "end") {
      return true;
    }
    return false;
  };

  const renderProfessor = () => {
    return (
      <div className="tutorial_eutons_local_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            style={{ maxHeight: "50vh" }}
            src={
              window.innerWidth > 991
                ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
                : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderCampusBubble = () => {
    return (
      <div className="tutorial_eutons_local_bubble">
        <div className="center_container">
          <div className="text_black_big_bold">
            {step === 5 ? (
              <div>
                <p>{t("tutorial_text_eutons_4")}</p>
              </div>
            ) : null}
            {step === 6 ? (
              <div>
                <p>{t("tutorial_text_eutons_5")}</p>
              </div>
            ) : null}
            {step === 7 ? (
              <div>
                <p>{t("tutorial_text_eutons_6")}</p>
              </div>
            ) : null}
            {step === 8 ? (
              <div>
                <p>{t("tutorial_text_8")}</p>
              </div>
            ) : null}
          </div>
        </div>
        {step !== 8 && (
          <m.div
            className="tutorial_eutons_local_button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            transition={{ type: "spring", stiffness: 300, damping: 10 }}
            onClick={() => advanceStepHandler()}
          >
            <div className="text_white_big_bold">{t("confirm")}</div>
          </m.div>
        )}
      </div>
    );
  };

  const renderRepeatCampusBubble = () => {
    return (
      <div className="tutorial_eutons_local_bubble">
        <div className="center_container">
          <div className="text_black_big_bold">
            <div>
              <p>{t("tutorial_text_15")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  console.log(step);

  const renderHutsBubble = () => {
    return (
      <div className="tutorial_eutons_local_bubble">
        <div className="center_container">
          <div className="text_black_big_bold">
            {step === 9 ? (
              <div>
                <p>{t("tutorial_text_eutons_7")}</p>
              </div>
            ) : null}
            {step === 10 ? (
              <div>
                <p>{t("tutorial_text_eutons_8_1")}</p>
                <p>{t("tutorial_text_eutons_8_2")}</p>
              </div>
            ) : null}
            {step === 11 ? (
              <div>
                <p>{t("tutorial_text_eutons_9")}</p>
              </div>
            ) : null}
            {step === 12 ? (
              <div>
                <p>{t("tutorial_text_eutons_10")}</p>
              </div>
            ) : null}
          </div>
        </div>
        {step !== 9 && (
          <m.div
            className="tutorial_eutons_local_button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            transition={{ type: "spring", stiffness: 300, damping: 10 }}
            onClick={() => hideBubbleHandler()}
          >
            <div className="text_white_big_bold">{t("confirm")}</div>
          </m.div>
        )}
      </div>
    );
  };

  const renderShuttleBubble = () => {
    return (
      <div className="tutorial_eutons_local_bubble">
        <div className="center_container">
          <div className="text_black_big_bold">
            {step === 13 ? (
              <div>
                <p>{t("tutorial_text_eutons_11")}</p>
              </div>
            ) : null}
            {step === 14 ? (
              <div>
                <p>{t("tutorial_text_eutons_12")}</p>
              </div>
            ) : null}
            {step === 15 ? (
              <div>
                <p>{t("tutorial_text_eutons_13")}</p>
              </div>
            ) : null}
          </div>
        </div>
        {step !== 13 && (
          <m.div
            className="tutorial_eutons_local_button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            transition={{ type: "spring", stiffness: 300, damping: 10 }}
            onClick={
              step === 15
                ? () => hideBubbleHandler()
                : () => advanceStepHandler()
            }
          >
            <div className="text_white_big_bold">{t("confirm")}</div>
          </m.div>
        )}
      </div>
    );
  };

  const renderEndBubble = () => {
    return (
      <div className="tutorial_eutons_local_bubble">
        <div className="center_container">
          <div className="text_black_big_bold">
            {step === 16 ? (
              <div>
                <p>{t("tutorial_text_eutons_14")}</p>
              </div>
            ) : null}
            {step === 17 ? (
              <div>
                <p>{t("tutorial_text_eutons_15")}</p>
              </div>
            ) : null}
            {step === 18 ? (
              <div>
                <p>{t("tutorial_text_eutons_16")}</p>
              </div>
            ) : null}
          </div>
        </div>
        <m.div
          className="tutorial_eutons_local_button"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          transition={{ type: "spring", stiffness: 300, damping: 10 }}
          onClick={() => advanceStepHandler()}
        >
          <div className="text_white_big_bold">{t("confirm")}</div>
        </m.div>
      </div>
    );
  };

  console.log(props.part);
  console.log(step);

  return (
    <div>
      <LazyMotion features={loadFeatures}>
        {teacherIsRendered() && renderProfessor()}
        {step >= 5 &&
          step <= 8 &&
          props.part === "campus" &&
          renderCampusBubble()}
        {props.part === "repeat_campus" && renderRepeatCampusBubble()}
        {step >= 9 &&
          step <= 12 &&
          props.part === "energy_lab" &&
          showBubble &&
          renderHutsBubble()}
        {step >= 13 &&
          step <= 15 &&
          props.part === "workshop" &&
          showBubble &&
          renderShuttleBubble()}
        {step >= 16 && step <= 18 && props.part === "end" && renderEndBubble()}
      </LazyMotion>
    </div>
  );
};

export default TutorialEutonsLocalMap;
