import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../interfaces";

const SwampIsland = ({ ...props }) => {
  const grassSea = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/grass_sea_pantano.gltf"
  ) as DreiGLTF;
  const lotusLeaf = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/lotus_leaf_pantano.gltf"
  ) as DreiGLTF;
  const lotus = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/lotus_pantano.gltf"
  ) as DreiGLTF;
  const mushroomBrown = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/mushroom_brown_pantano.gltf"
  ) as DreiGLTF;
  const mushroomGreen = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/mushroom_green_pantano.gltf"
  ) as DreiGLTF;
  const mushroomRed = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/mushroom_red_pantano.gltf"
  ) as DreiGLTF;
  const treeSauce = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/sauce_pantano.gltf"
  ) as DreiGLTF;
  const treeDry = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/tree_dry_pantano.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[15.95, -0.11, 16.16]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[22.83, -0.31, 15.76]}
        rotation={[0, 0.75, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[23.12, -0.07, 15.3]}
        rotation={[Math.PI, -0.24, Math.PI]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[17.11, -0.19, 13.75]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomRed.nodes["mushroom-toadstool001"].geometry}
        material={props.bakedMaterial1}
        position={[23.67, -0.07, 12.65]}
        scale={0.52}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomRed.nodes["mushroom-toadstool001"].geometry}
        material={props.bakedMaterial1}
        position={[17.65, -0.07, 15.68]}
        scale={0.52}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomRed.nodes["mushroom-toadstool001"].geometry}
        material={props.bakedMaterial1}
        position={[16.14, -0.07, 12.81]}
        rotation={[0, 1.06, 0]}
        scale={0.52}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomRed.nodes["mushroom-toadstool001"].geometry}
        material={props.bakedMaterial1}
        position={[21.62, -0.07, 9.79]}
        scale={0.52}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomGreen.nodes["mushroom-toadstool-green001"].geometry}
        material={props.bakedMaterial1}
        position={[22.39, -0.09, 13.12]}
        rotation={[0, -0.57, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomGreen.nodes["mushroom-toadstool-green001"].geometry}
        material={props.bakedMaterial1}
        position={[22.33, -0.09, 9.82]}
        rotation={[-Math.PI, 0.44, -Math.PI]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomGreen.nodes["mushroom-toadstool-green001"].geometry}
        material={props.bakedMaterial1}
        position={[18.72, -0.09, 15.18]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomGreen.nodes["mushroom-toadstool-green001"].geometry}
        material={props.bakedMaterial1}
        position={[18.78, -0.09, 11.84]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomBrown.nodes["mushroom-boletus001"].geometry}
        material={props.bakedMaterial1}
        position={[23.52, -0.13, 13.69]}
        rotation={[0, 0.76, 0]}
        scale={0.83}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomBrown.nodes["mushroom-boletus001"].geometry}
        material={props.bakedMaterial1}
        position={[18.52, -0.13, 16.29]}
        scale={0.83}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomBrown.nodes["mushroom-boletus001"].geometry}
        material={props.bakedMaterial1}
        position={[19.73, -0.13, 11.22]}
        scale={0.83}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[20.34, -0.26, 14.22]}
        rotation={[0, 1.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[19.96, -0.22, 14.48]}
        rotation={[0, 0.91, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[20.1, -0.16, 14.28]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[19.53, -0.19, 12.67]}
        rotation={[0, -1.31, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[19.94, -0.21, 12.46]}
        rotation={[0, -1.27, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[19.74, -0.14, 12.54]}
        rotation={[0, 1.29, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[20.14, -0.23, 12.39]}
        rotation={[0, 0.03, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[20.34, -0.22, 12.28]}
        rotation={[0, 0.32, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[17.17, -0.23, 14.25]}
        rotation={[0, 1.21, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[17.57, -0.22, 14.65]}
        rotation={[0, 0.07, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[15.21, -0.07, 16.39]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[14.62, -0.07, 16.39]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[24.72, -0.07, 11.9]}
        rotation={[0, 1.41, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[24.87, -0.07, 11.46]}
        rotation={[0, 1.1, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[24.5, -0.92, 11.1]}
        rotation={[0, -0.57, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[24.98, -0.07, 10.93]}
        rotation={[0, 0.64, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[24.85, -0.07, 10.37]}
        rotation={[0, -1.51, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[24.5, -0.5, 10.65]}
        rotation={[0, 0.81, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomBrown.nodes["mushroom-boletus001"].geometry}
        material={props.bakedMaterial1}
        position={[20.09, -0.13, 15.07]}
        rotation={[0, -0.46, 0]}
        scale={0.83}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomGreen.nodes["mushroom-toadstool-green001"].geometry}
        material={props.bakedMaterial1}
        position={[16.3, -0.09, 13.27]}
        rotation={[-Math.PI, 1.11, -Math.PI]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomGreen.nodes["mushroom-toadstool-green001"].geometry}
        material={props.bakedMaterial1}
        position={[21.03, -0.09, 11.49]}
        rotation={[0, -1.5, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomGreen.nodes["mushroom-toadstool-green001"].geometry}
        material={props.bakedMaterial1}
        position={[19.96, -0.09, 10.35]}
        rotation={[-Math.PI, 0.44, -Math.PI]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomRed.nodes["mushroom-toadstool001"].geometry}
        material={props.bakedMaterial1}
        position={[18, -0.07, 11.96]}
        scale={0.52}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomBrown.nodes["mushroom-boletus001"].geometry}
        material={props.bakedMaterial1}
        position={[20.47, -0.13, 16.61]}
        rotation={[0, 0.76, 0]}
        scale={0.83}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSauce.nodes.sauce001.geometry}
        material={props.bakedMaterial1}
        position={[17.29, 0, 17.51]}
        rotation={[0, -0.35, 0]}
        scale={[0.26, 0.35, 0.53]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSauce.nodes.sauce001.geometry}
        material={props.bakedMaterial1}
        position={[23.17, 0.42, 18.27]}
        rotation={[0, 0.28, 0]}
        scale={[0.26, 0.35, 0.53]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSauce.nodes.sauce001.geometry}
        material={props.bakedMaterial1}
        position={[24.11, 0.41, 17.05]}
        rotation={[Math.PI, -0.8, Math.PI]}
        scale={[0.2, 0.27, 0.41]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSauce.nodes.sauce001.geometry}
        material={props.bakedMaterial1}
        position={[24.22, 0, 14.55]}
        rotation={[0, 0.73, 0]}
        scale={[0.26, 0.35, 0.53]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSauce.nodes.sauce001.geometry}
        material={props.bakedMaterial1}
        position={[24.79, -0.08, 13.35]}
        rotation={[Math.PI, -0.34, Math.PI]}
        scale={[0.2, 0.27, 0.41]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSauce.nodes.sauce001.geometry}
        material={props.bakedMaterial1}
        position={[21.72, 0.38, 18.77]}
        rotation={[-Math.PI, 0.29, -Math.PI]}
        scale={[0.2, 0.27, 0.41]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[21.29, -0.96, 17.83]}
        rotation={[0, -0.46, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[21.92, -0.13, 17.6]}
        rotation={[0, 0.75, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[19.84, -0.92, 17.57]}
        rotation={[Math.PI, -0.84, Math.PI]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[20.83, -0.13, 18]}
        rotation={[0, 0.75, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDry.nodes["tree-dry001"].geometry}
        material={props.bakedMaterial1}
        position={[20.26, -0.13, 17.88]}
        rotation={[0, 0.75, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomBrown.nodes["mushroom-boletus001"].geometry}
        material={props.bakedMaterial1}
        position={[20.26, 0.26, 18.96]}
        rotation={[0, 0.76, 0]}
        scale={0.83}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={mushroomGreen.nodes["mushroom-toadstool-green001"].geometry}
        material={props.bakedMaterial1}
        position={[18.66, 0.25, 18.13]}
        rotation={[Math.PI, -1.55, Math.PI]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={lotusLeaf.nodes["lotus-leaf001"].geometry}
        material={props.bakedMaterial1}
        position={[18.61, -0.11, 14.31]}
        rotation={[0, -0.69, 0]}
        scale={0.5}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={lotus.nodes.lotus001.geometry}
        material={props.bakedMaterial1}
        position={[18.63, -0.11, 14.37]}
        scale={0.5}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={lotusLeaf.nodes["lotus-leaf001"].geometry}
        material={props.bakedMaterial1}
        position={[18.21, -0.11, 14.37]}
        rotation={[0, -0.87, 0]}
        scale={0.5}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={lotusLeaf.nodes["lotus-leaf001"].geometry}
        material={props.bakedMaterial1}
        position={[18.99, -0.11, 14.29]}
        rotation={[0, 0.65, 0]}
        scale={0.5}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={lotusLeaf.nodes["lotus-leaf001"].geometry}
        material={props.bakedMaterial1}
        position={[17.89, -0.11, 14.11]}
        rotation={[Math.PI, -1.01, Math.PI]}
        scale={0.5}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={lotusLeaf.nodes["lotus-leaf001"].geometry}
        material={props.bakedMaterial1}
        position={[18.3, -0.11, 13.91]}
        rotation={[0, 0.25, 0]}
        scale={0.5}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={lotusLeaf.nodes["lotus-leaf001"].geometry}
        material={props.bakedMaterial1}
        position={[18.74, -0.11, 13.93]}
        rotation={[-Math.PI, 0.02, -Math.PI]}
        scale={0.5}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={lotusLeaf.nodes["lotus-leaf001"].geometry}
        material={props.bakedMaterial1}
        position={[19.22, -0.11, 13.56]}
        scale={0.5}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={lotus.nodes.lotus001.geometry}
        material={props.bakedMaterial1}
        position={[19.23, -0.11, 13.64]}
        scale={0.5}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/grass_sea_pantano.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/lotus_leaf_pantano.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/lotus_pantano.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/mushroom_brown_pantano.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/mushroom_green_pantano.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/mushroom_red_pantano.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/sauce_pantano.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_pantano/tree_dry_pantano.gltf"
);

export default SwampIsland;
