import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { StudentType } from "../../../_newapios/user/student";
import { ClassType, ModifiedBundleType } from "../../../_newapios/user/class";
import { chooseLanguageFromDB } from "../../../utils/content.ts";
import _, { indexOf } from "lodash";
import { CourseType } from "../../../_newapios/content/course.ts";
import {
  GetMyStudentProgressAPI,
  ScenarioProgressType,
} from "../../../_newapios/progress/student_scenario.ts";
import SubjectWorksheet from "./subjectWorksheet.ts";
import keyCompetencesWorksheet from "./keyCompetencesWorksheet.ts";
import {
  GetStudentSpecificCompetencesAPI,
  SpecificCompetencePoints,
} from "../../../_newapios/progress/student_specific_competences.ts";
import { GlobalCompetencePoints } from "../../../_newapios/progress/student_global_competences.ts";
import { getGlobalCompetenceAggregates } from "../../utils/evaluation.ts";
import { colors, simpleGlobalCompetences } from "../../../constants";

const ExportExcel = ({ ...props }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const professorState = useAppSelector((state) => state.teacher);
  const mainState = useAppSelector((state) => state.main);

  const student = professorState.selectedStudent as StudentType;
  const selectedClass = professorState.classes.find(
    (cl) => cl.id === student.class_id
  ) as ClassType;

  const courses = useAppSelector((state) => state.main.courses);

  const [courseBundles, setCourseBundles] = useState<ModifiedBundleType[]>([]);

  const [studentBundles, setStudentBundles] = useState<ModifiedBundleType[]>(
    []
  );
  const [studentCourses, setStudentCourses] = useState<CourseType[]>([]);
  const [scenarioProgress, setScenarioProgress] = useState<
    ScenarioProgressType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [globalCompetences, setGlobalCompetences] =
    useState<GlobalCompetencePoints>({});
  const [studentSpecificCompetences, setStudentSpecificCompetences] = useState<{
    [key: string]: SpecificCompetencePoints;
  }>({});

  useEffect(() => {
    setStudentBundles(props.studentBundles);
    setGlobalCompetences(props.globalCompetences);
    setStudentSpecificCompetences(props.studentSpecificCompetences);
    loadScenarios();
  }, [student]);

  const loadScenarios = async () => {
    const scenarios = await GetMyStudentProgressAPI(student.id);
    setScenarioProgress(scenarios);
  };

  // For global competence data
  const globalCompLabels = !_.isEmpty(globalCompetences)
    ? Object.keys(getGlobalCompetenceAggregates(globalCompetences)).map((str) =>
        t(str + "_label", { ns: "evaluation" })
      )
    : simpleGlobalCompetences.map((gc) =>
        t(gc[0] + "_label", { ns: "evaluation" })
      );

  const globalCompPoints = !_.isEmpty(globalCompetences)
    ? Object.values(getGlobalCompetenceAggregates(globalCompetences))
    : _.fill(Array(simpleGlobalCompetences.length), 0);

  useEffect(() => {
    findStudentCourses();
  }, [studentBundles]);

  useEffect(() => {
    setCourseBundles(props.courseBundles);
  }, []);

  const findStudentCourses = () => {
    if (studentBundles.length > 0) {
      var newStudentCourses: CourseType[] = [];
      for (let i = 0; i < studentBundles.length; i++) {
        var newStudentCourse = courses.find(
          (course) => course.id === studentBundles[i].scenario?.course_id
        );
        if (
          newStudentCourse !== undefined &&
          newStudentCourses.indexOf(newStudentCourse) === -1
        ) {
          newStudentCourses.push(newStudentCourse);
        }
      }
      setStudentCourses(newStudentCourses);
    }
  };

  const workbook = new ExcelJS.Workbook();

  if (
    selectedClass.regulation_id !== "regulation_GB" &&
    selectedClass.regulation_id !== "regulation_GMyS"
  ) {
    keyCompetencesWorksheet(
      workbook,
      t,
      student,
      selectedClass,
      globalCompPoints,
      globalCompLabels
    );
  }
  studentCourses.forEach((course) => {
    SubjectWorksheet(
      workbook,
      course.acronym, // Use course acronym as sheet name
      course,
      course.specific_competences,
      studentBundles,
      scenarioProgress,
      t,
      studentSpecificCompetences[course.id]
    );
  });
  // Iterate over the keys of studentSpecificCompetences

  // metrics(metricsSheet, t, metricsMockData);

  const downloadWorkbook = async () => {
    // Generate the Excel file buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;

    const filename = `Informe de evaluación - ${student.username}.xlsx`;
    link.download = filename;
    // Click the link to trigger the download
    link.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
  };

  const renderExportButton = () => {
    return (
      <div
        onClick={() => downloadWorkbook()}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          textDecoration: "none",
          display: "grid",
          gridTemplateColumns: "1fr 4fr",
          width: "80%",
          height: "70%",
          boxShadow: "0px 0px 5px 1px #6EE6A7",
          borderRadius: "5px",
          marginBottom: "5%",
        }}
      >
        <div className="center_container">
          <img
            className="icon"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Microsoft_Office_Excel_(2019%E2%80%93present)+1.svg"
            }
            alt="excel"
          />
        </div>
        <div className="center_container">
          <div className="text_black_super_small_bold">
            {t("export_to_excel")}
          </div>
        </div>
      </div>
    );
  };

  return renderExportButton();
};

export default ExportExcel;
