import "./WhatsappButton.css";
import whatsappIcon from "../../../assets/professor/whatsappIcon.svg";
import { m, LazyMotion } from "framer-motion";

const WhatsappButton = ({ ...props }) => {
  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  return (
    <LazyMotion features={loadFeatures}>
      <m.a
        href="https://wa.me/+34633759738?text=Necesito%20soporte%20con%20Eutop%C3%ADa"
        className="whatsapp_button"
        target="_blank"
        whileHover={{ scale: 1.1, boxShadow: "0px 0px 3px 0px #25d366" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        <img src={whatsappIcon} alt="whatsapp" className="whatsapp_icon" />
      </m.a>
    </LazyMotion>
  );
};

export default WhatsappButton;
