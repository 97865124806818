import "./ThemeSelection.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import danger from "../../../assets/professor/danger.svg";
import es from "date-fns/locale/es";

import UserMenu from "../UserMenu/UserMenu";
import Loading from "../../loading_screen/LoadingDots";
import {
  classes,
  menu,
  submenu,
  updateSelectedClass,
} from "../../../reducers/teacherSlice";
import { AbsoluteCenter, Box, Divider } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import BackArrow from "../../../assets/exercises/back_arrow";
import {
  GetThemesAPI,
  ThemeType,
  SetClassThemeAPI,
} from "../../../_newapios/content/theme";
import { ClassType } from "../../../_newapios/user/class";

const ThemeSelection = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const professorState = useAppSelector((state) => state.teacher);

  const [themes, setThemes] = useState<ThemeType[]>([]);

  const [selectedTheme, setSelectedTheme] = useState<ThemeType | null>(null);
  const [loading, setLoading] = useState(true);
  // const [triadOpen, setTriadOpen] = useState(false);

  const initialDates = [
    new Date(), // Today
    new Date(new Date().getTime() + 3 * 30 * 24 * 60 * 60 * 1000), // 3 months from now
    new Date(
      new Date().getTime() + 3 * 30 * 24 * 60 * 60 * 1000 + 24 * 60 * 60 * 1000
    ), // 3 months from now +1 day
    new Date(new Date().getTime() + 6 * 30 * 24 * 60 * 60 * 1000), // 6 months from now
    new Date(
      new Date().getTime() + 6 * 30 * 24 * 60 * 60 * 1000 + 24 * 60 * 60 * 1000
    ), // 6 months from now +1 day
    new Date(new Date().getTime() + 9 * 30 * 24 * 60 * 60 * 1000), // 9 months from now
  ];

  const [selectedDates, setSelectedDates] = useState(initialDates);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const sg_thumbnails = [
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_1.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_2.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_3.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_4.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_5.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_6.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_7.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_8.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_9.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_10.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_11.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_12.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_13.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_14.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_15.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_16.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_17.svg",
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_18.svg",
  ];

  useEffect(() => {
    loadThemes();
  }, []);

  const loadThemes = async () => {
    const provThemes = await GetThemesAPI();
    setThemes(provThemes);
    setLoading(false);
  };

  const handleThemeChange = (theme: ThemeType) => {
    if (selectedTheme === theme) {
      setSelectedTheme(null);
    } else {
      setSelectedTheme(theme);
    }
  };


  // const openTriadHandler = (theme: ThemeType) => {
  //   setTriadOpen(true);
  //   setSelectedTheme(theme);
  // };

  // const closeTriadHandler = () => {
  //   setTriadOpen(false);
  //   setSelectedTheme(null);
  // };

  const openTriadHandler = (theme: ThemeType) => {
    setSelectedTheme(theme);
  };

  const closeTriadHandler = () => {
    setSelectedTheme(null);
  };

  const SubmitHandler = async () => {
    if (
      selectedTheme !== null &&
      professorState.selectedClass?.id !== undefined
    ) {
      const newclass = await SetClassThemeAPI(
        professorState.selectedClass?.id,
        selectedTheme.id
      );
      if (newclass === null) {
        return;
      }

      // Update the class in the state
      const myclasses: ClassType[] = [...professorState.classes];
      myclasses[myclasses.findIndex((c) => c.id === newclass.id)] = {
        ...newclass,
        teacher_in_class: true,
      };

      dispatch(classes(myclasses));
      dispatch(updateSelectedClass(newclass));
      dispatch(menu("menus"));
      dispatch(submenu("activities"));
    }
  };


  //   const renderSustainableGoals = () => {
  //     return (
  //       <div className="theme_sustainable_goals">
  //         {sg_thumbnails.map((goal, index) =>
  //           renderGoal(index + 1, sg_thumbnails[index])
  //         )}
  //       </div>
  //     );
  //   };


  const renderTitle = () => {
    return (
      <div className="theme_creation_main_title">
        <div
          className="left_container"
          style={{ borderBottom: "2px solid rgba(188, 209, 239, 1)" }}
        >
          <div className="text_black_very_big_bold">
            {"2. " + t("class_creation_title_2")}
          </div>
        </div>
      </div>
    );
  };


  const renderTriads = () => {
    return (
      <div>
        {themes.map((theme: ThemeType, index: number) => (
          <>
            {renderTriad(theme, index)}
            {index === 0 && renderDivider()}
          </>
        ))}
      </div>
    )
  }

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderTriad = (
    theme: ThemeType,
    index: number
  ) => {
    const isSelected = selectedTheme === theme;
    const thumbnails = (index + 1 === 1)
      ? [6, 10, 11, 12, 16]
      : (index + 1 === 2)
        ? [2, 12, 13, 14, 16]
        : [];

    return (
      <motion.div
        className="triad_1_general_container"
        whileHover={{
          scale: 1.02,
          boxShadow:
            isSelected
              ? ((index + 1 === 1) ? "0px 0px 10px 1px #D4C6A0" : "0px 0px 10px 1px #BFCBBF")
              : ((index + 1 === 1) ? "0px 0px 10px 1px #FFF9E7" : "0px 0px 10px 1px #EEF8ED")
        }}
        animate={{
          scale: isSelected ? 1.02 : 1,
          boxShadow: isSelected
            ? ((index + 1 === 1) ? "0px 0px 10px 1px #D4C6A0" : "0px 0px 10px 1px #BFCBBF")
            : "none",
        }}
        whileTap={{ scale: isSelected ? 0.99 : 1 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        onClick={isSelected ? () => null : () => openTriadHandler(theme)}
        style={{
          cursor: isSelected
            ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
            : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          backgroundColor: isSelected
            ? (index + 1 === 1 ? "#D4C6A0" : "#BFCBBF")
            : (index + 1 === 1 ? "#FFF9E7" : "#EEF8ED"),
        }}
      >
        <motion.div
          className="triad_title_desc"
          // whileHover={
          //   isSelected
          //     ? { scale: 1.02 }
          //     : { scale: 1 }
          // }
          // whileTap={{ scale: isSelected ? 0.95 : 1 }}
          onClick={isSelected ? () => closeTriadHandler() : () => null}
          style={{
            cursor: isSelected
              ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto'
              : undefined,
          }}
        >
          <div className="theme_text_container">
            {/* <AnimatePresence>
              {isSelected && (
                <motion.div>
                  <BackArrow color={"black"} size={"35"} />
                </motion.div>
              )}
            </AnimatePresence> */}
            <div className="text_black_medium_bold" style={{ marginRight: "8px" }}>{t("theme") + " " + (index + 1) + " : "}</div>
            <div className="text_black_medium"> {t("theme_" + (index + 1) + "_title")}</div>
          </div>

          <div className="theme_area_description">
            <div className="text_black_very_small">{t("theme_" + (index + 1) + "_desc")}</div>
          </div>
        </motion.div>

        <div className="theme_right_container">
          <div className="theme_metrics_container">
            {theme.metric.map((metric, index) => (
              <div key={index} className="theme_metrics_item">
                <div className="center_container">
                  <div className="text_white_very_small_bold">{capitalizeFirstLetter(t(metric))}</div>
                </div>
              </div>
            ))}

          </div>
          <div className="sustainable_goals_container">
            {thumbnails.map((thumbnailIndex) => (
              <img
                key={thumbnailIndex}
                // className="image"
                src={sg_thumbnails[thumbnailIndex]}
                alt={`sustainable_goal_${thumbnailIndex}`}
                style={{
                  maxHeight: "12vh",
                  flexGrow: 1,
                  objectFit: "contain"
                }}
              />
            ))}
          </div>
        </div>

      </motion.div>
    );
  };

  const renderExplanationArea = () => {
    return (
      <div className="theme_explanation_area">
        <text className="text_black_very_small">{t("triad_explanation")}</text>
      </div>
    );
  };

  const renderDivider = () => {
    return (
      <Box position="relative" padding="8">
        <Divider
          borderColor="rgba(188, 209, 239, 1)"
          borderBottomWidth="2px"
          opacity={1}
        />
        <AbsoluteCenter bg="white" px="4">
          <div className="text_black_small">{t("or")}</div>
        </AbsoluteCenter>
      </Box>
    );
  };


  const renderConfirmButton = () => {
    return (
      <div className="center_container">
        <motion.div
          className="theme_submit_class_button"
          whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px 1px #6EE6A7" }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          onClick={() => SubmitHandler()}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <div className="center_container">
            <div className="text_white_small_bold">{t("confirm_project")}</div>
          </div>
        </motion.div>
      </div>
    );
  };

  return (
    <div
      className="theme_creation_main"
      style={loading ? { display: "flex" } : {}}
    >
      {loading && <Loading />}
      {!loading && renderTitle()}
      {!loading && renderExplanationArea()}
      {!loading && renderTriads()}
      {/* {!loading &&
        selectedTheme === null &&
        renderTriad(themes[0], "triad_1_title", "theme_1_title")}
      {!loading && selectedTheme === null && renderDivider()}
      {!loading &&
        selectedTheme === null &&
        renderTriad(themes[1], "triad_2_title", "theme_2_title")} */}
      {/* {!loading &&
        selectedTheme !== null &&
        renderTriad(
          selectedTheme,
          selectedTheme.name === "theme_1" ? "triad_1_title" : "triad_2_title",
          selectedTheme.name === "theme_1" ? "theme_1_title" : "theme_2_title"
        )} */}
      {!loading && selectedTheme !== null && renderConfirmButton()}
      {!loading && <UserMenu color={"black"} />}
    </div>
  );
};

export default ThemeSelection;
