import "./Professor.css";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import ProfessorSidebar from "./ProfessorSidebar";
import { useTranslation } from "react-i18next";
import ProfessorWelcome from "./ProfessorWelcome";
import MainSettings from "./class_creation/MainSettings";
import ProfessorMenus from "./menus/ProfessorMenus";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { GetAuthorsAPI } from "../../_newapios/user/teacher";
import { setAuthors } from "../../reducers/teacherSlice";
import { GetAllUnitsGPTAPI } from "../../_newapios/content/unit";
import ThemeSelection from "./class_creation/ThemeSelection";

import { GetAllEutonsAPI } from "../../_newapios/gamecontent/eutons";
import { setEutons } from "../../reducers/mainSlice";
import MarketPlaceMenus from "./marketplace/MarketPlaceMenus";
import WhatsappButton from "./WhatsappButton/WhatsappButton";

const ProfessorDashboard = () => {
  const { i18n } = useTranslation();

  const professorState = useAppSelector((state) => state.teacher);
  const dispatch = useAppDispatch();
  const cookies = new Cookies();

  const getScenariosForGPT = async () => {
    const unitsGPT = await GetAllUnitsGPTAPI();
    console.log(unitsGPT);
  };

  const loadAuthors = async () => {
    const authors = await GetAuthorsAPI();
    console.log(authors);
    dispatch(setAuthors(authors));
  };

  const loadEutons = async () => {
    const eutons = await GetAllEutonsAPI();
    dispatch(setEutons(eutons));
  };

  useEffect(() => {
    loadAuthors();
    loadEutons();
    getScenariosForGPT();
  }, []);

  return (
    <div
      className={
        professorState.sidebarOpen
          ? "professor_sidebar_open"
          : "professor_sidebar_closed"
      }
    >
      {professorState.sidebarOpen && <ProfessorSidebar />}
      {professorState.menu === "welcome" && <ProfessorWelcome />}
      {professorState.menu === "classCreate" &&
        professorState.createClassStep === 0 && <MainSettings />}
      {professorState.menu === "classCreate" &&
        professorState.createClassStep === 1 && <ThemeSelection />}
      {professorState.menu === "menus" && <ProfessorMenus />}
      {professorState.menu === "marketplace" && <MarketPlaceMenus />}
      <WhatsappButton />
    </div>
  );
};

export default ProfessorDashboard;
