import { useGLTF, useTexture } from "@react-three/drei";
import { MeshPhongMaterial, SRGBColorSpace } from "three";
import { DreiGLTF } from "../../../../interfaces";

const Model1 = ({ ...props }) => {
  const { nodes } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/GLOBAL_archipielago_mix.gltf"
  ) as DreiGLTF;
  const bakedTexture1 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/baked_global_organic_mix_op.jpg"
  );
  const bakedMaterial1 = new MeshPhongMaterial({ map: bakedTexture1 });
  bakedTexture1.flipY = false;
  bakedTexture1.colorSpace = SRGBColorSpace;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Area_playa1.geometry}
        material={bakedMaterial1}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Area_pradera_acantilado.geometry}
        material={bakedMaterial1}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Area_nevada1.geometry}
        material={bakedMaterial1}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Area_desierto1.geometry}
        material={bakedMaterial1}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Lago.geometry}
        material={bakedMaterial1}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Roca.geometry}
        material={bakedMaterial1}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Islote_pantano.geometry}
        material={bakedMaterial1}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Lago_2.geometry}
        material={bakedMaterial1}
        position={[20.45, 0, 13.64]}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Islote_jungla.geometry}
        material={bakedMaterial1}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/GLOBAL_archipielago_mix.gltf"
);

export default Model1;
