import "./ExplorersHut.css";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { useTranslation } from "react-i18next";
import closeButton from "../../../assets/student/close_button.svg";
import beachIcon from "../../../assets/student/T2/biome_icons/beach.svg";
import desertIcon from "../../../assets/student/T2/biome_icons/desert.svg";
import forestIcon from "../../../assets/student/T2/biome_icons/forest.svg";
import grasslandIcon from "../../../assets/student/T2/biome_icons/grassland.svg";
import jungleIcon from "../../../assets/student/T2/biome_icons/jungle.svg";
import mountainIcon from "../../../assets/student/T2/biome_icons/mountain.svg";
import savannahIcon from "../../../assets/student/T2/biome_icons/savannah.svg";
import snowIcon from "../../../assets/student/T2/biome_icons/snow.svg";
import swampIcon from "../../../assets/student/T2/biome_icons/swamp.svg";
import volcanoIcon from "../../../assets/student/T2/biome_icons/volcano.svg";
import HeartIcon from "../../../assets/student/T2/full_heart.tsx";
import close from "../../../assets/student/close_user_menu.svg";

import ToothedCircleIcon from "../../../assets/student/T2/toothed_circle.tsx";
import pointsIcon from "../../../assets/student/points_icon.svg";
import {
  increaseTutorialEutonsStep,
  menu,
  points,
  updateMetrics,
} from "../../../reducers/studentSlice.ts";
import { Ii18nStringObject, IscreenSize } from "../../../interfaces.ts";
import { motion } from "framer-motion";
import { BiomeNameType } from "../../../_newapios/gamecontent/plots.ts";
import ReactPlayer from "react-player";
import { BiomeType } from "../../../_newapios/gamecontent/biomes.ts";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import { calcBiomePoints } from "../../../utils/game_content.ts";
import DoughnutChart from "./DoughnutChart";
import {
  ExplorerType,
  GetAllExplorersAPI,
} from "../../../_newapios/gamecontent/explorers.ts";
import { SetStudentExplorerAPI } from "../../../_newapios/progress/student_explorer.ts";
import { calc_item_chances } from "../../../utils/euton.ts";
import { EutonType } from "../../../_newapios/gamecontent/eutons.ts";
import { use } from "i18next";
import { toast } from "../../../index.tsx";

const ExplorersHut = (props: { biome: BiomeNameType }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const mainState = useAppSelector((state) => state.main);
  const studentState = useAppSelector((state) => state.student);
  const loginState = useAppSelector((state) => state.login);

  const biomes = mainState.biomes;
  const eutons = mainState.eutons;
  const biomesHealth = studentState.biomesHealth;
  const [explorers, setExplorers] = useState<ExplorerType[]>([]);

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const [selectedBiome, setSelectedBiome] = useState<BiomeType | null>(null);
  const [explorerSelection, setExplorerSelection] = useState<boolean>(false);
  const [selectedExplorer, setSelectedExplorer] = useState<ExplorerType | null>(
    null
  );
  const [itemObtained, setItemObtained] = useState<EutonType | null>(null);
  const [noItemObtained, setNoItemObtained] = useState<boolean>(false);

  const [studentPoints, setStudentPoints] = useState<number>(
    studentState.points
  );

  const calcBiomeIcon = (biomeName: BiomeNameType) => {
    var biomeIcon = beachIcon;
    switch (biomeName) {
      case "beach":
        biomeIcon = beachIcon;
        break;
      case "desert":
        biomeIcon = desertIcon;
        break;
      case "forest":
        biomeIcon = forestIcon;
        break;
      case "grassland":
        biomeIcon = grasslandIcon;
        break;
      case "jungle":
        biomeIcon = jungleIcon;
        break;
      case "mountain":
        biomeIcon = mountainIcon;
        break;
      case "savannah":
        biomeIcon = savannahIcon;
        break;
      case "snow":
        biomeIcon = snowIcon;
        break;
      case "swamp":
        biomeIcon = swampIcon;
        break;
      case "volcano":
        biomeIcon = volcanoIcon;
        break;
    }
    return biomeIcon;
  };

  const selectBiomeHandler = (biome: BiomeType) => {
    if (
      studentState.tutorialEutonsStep >= 10 &&
      studentState.tutorialEutonsStep <= 12
    ) {
      if (biome.id !== studentState.studentBiome?.id) {
        return;
      } else {
        dispatch(increaseTutorialEutonsStep());
      }
    }
    setSelectedBiome(biome);
  };

  const loadExplorers = async () => {
    const newExplorers = await GetAllExplorersAPI();
    if (!newExplorers) return;
    setExplorers(newExplorers);
  };
  console.log(explorers);
  useEffect(() => {
    loadExplorers();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    loadProgress();
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const loadProgress = () => {
    dispatch(updateMetrics());
  };

  const closeButtonHandler = async () => {
    dispatch(menu("globalMap"));
  };

  const selectExplorerHandler = async () => {
    console.log(selectedBiome, selectedExplorer);
    if (selectedBiome === null || selectedExplorer === null) return;
    const usedPoints = calcBiomePoints(
      studentState.studentBiome,
      selectedBiome
    );
    console.log(usedPoints);
    console.log(studentPoints);
    if (studentPoints < usedPoints) return;
    const studentExplorer = await SetStudentExplorerAPI(
      selectedExplorer.id,
      selectedBiome.id
    );
    if (!studentExplorer) return;
    if (studentExplorer.student_euton) {
      const foundEuton = eutons.find(
        (euton) => euton.id === studentExplorer.student_euton.euton_id
      );
      if (foundEuton) {
        setItemObtained(foundEuton);
      } else {
        console.log("Euton not found");
      }
    } else {
      setNoItemObtained(true);
    }
    loadProgress();
    setSelectedExplorer(null);
    setSelectedBiome(null);
    setExplorerSelection(false);
  };

  const renderBanner = () => {
    return (
      <div className="explorers_hut_banner_container">
        <img
          className="banner"
          src={
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/banner_explorers_hut.png"
          }
          alt="banner"
        />
      </div>
    );
  };

  const renderCloseButton = () => {
    return (
      <motion.div
        className="explorers_hut_close_button"
        whileHover={{ scale: 1.2, rotate: 180 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        onClick={() => closeButtonHandler()}
        style={{
          borderRadius: "50%",
          backgroundColor: "white",
        }}
      >
        <div className="center_container">
          <img className="image" src={closeButton} alt="close" />
        </div>
      </motion.div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="explorers_hut_title">
        <div className="left_container">
          <div className="text_white_very_big_bold">{t("explorers_hut")}</div>
        </div>
      </div>
    );
  };

  const renderBackground = () => {
    return (
      <img
        className="explorers_hut_background_image"
        style={{
          filter: selectedBiome !== null ? "blur(20px)" : "none",
        }}
        src={
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/isla6.png"
        }
        alt="background"
      />
    );
  };

  const renderBackgroundFilter = () => {
    return <div className="explorers_hut_background_image_filter" />;
  };

  const renderBiomes = () => {
    return biomes.map((biome, index) => {
      let tutorialBan = false;
      if (
        studentState.tutorialEutonsStep >= 10 &&
        studentState.tutorialEutonsStep <= 12
      ) {
        if (biome.id !== studentState.studentBiome?.id) tutorialBan = true;
      }
      var biomeIcon = calcBiomeIcon(biome.name);
      const biomePoints = calcBiomePoints(studentState.studentBiome, biome);
      const biomeHealth = biomesHealth[biome.id];
      return (
        <motion.div
          key={index}
          className={"explorers_hut_biome_" + biome.name}
          style={{
            justifyContent: "space-between",
            padding: "0 1vw",
            filter: tutorialBan ? "brightness(0.5)" : "brightness(1)",
            cursor: tutorialBan
              ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
              : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          whileHover={
            tutorialBan
              ? {}
              : {
                scale: 1.05,
                boxShadow: "0px 0px 10px 2px " + biome.color,
              }
          }
          transition={{ type: "spring", stiffness: 400, damping: 30 }}
          onClick={
            tutorialBan
              ? () => {
                toast({
                  title: t("biome_banned_tutorial_text"),
                  status: "error",
                  variant: "subtle",
                  duration: 3000,
                  isClosable: true,
                  position: "bottom",
                });
              }
              : () => selectBiomeHandler(biome)
          }
        >
          <div className="explorers_hut_biome_icon_name">
            <img
              className="icon"
              src={biomeIcon}
              alt="biome"
              style={{ maxHeight: "2.5vh" }}
            />
            <div className="text_white_very_small_bold">
              {t(biome.name).toUpperCase()}
            </div>
          </div>
          <div className="explorers_hut_biome_health">
            <HeartIcon height={"2.5vh"} text={undefined} />
            <div className="text_white_very_small_bold">{biomeHealth}</div>
          </div>
          <div className="explorers_hut_biome_points">
            <img
              className="icon"
              style={{ height: "2.5vh" }}
              src={pointsIcon}
              alt="points"
            />
            <div
              className={"text_white_very_small_bold"}
              style={{ marginLeft: "0.5vw" }}
            >
              {"- " + biomePoints}
            </div>
            <div
              className="text_light_blue_really_small_bold"
              style={{ marginLeft: "0.2vw" }}
            >
              {t("points")}
            </div>
          </div>
        </motion.div>
      );
    });
  };

  const renderBiomeInfo = () => {
    if (selectedBiome === null) {
      return (
        <div className="explorers_hut_biome_info">
          <div className="text_black_very_small_bold">{t("select_biome")}</div>
        </div>
      );
    }
    const formattedBiomeName =
      t(selectedBiome.name).charAt(0).toUpperCase() +
      t(selectedBiome.name).slice(1);
    const biomeHealth = biomesHealth[selectedBiome.id];
    const biomeChances = calc_item_chances(biomeHealth);

    return (
      <div className="explorers_hut_biome_info">
        <motion.div
          className="explorers_hut_biome_info_name"
          onClick={() => setSelectedBiome(null)}
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 10px 2px " + "rgba(255, 255, 255, 0.6)",
          }}
          transition={{ type: "spring", stiffness: 400, damping: 30 }}
        >
          <BackArrow color={selectedBiome.color} size={"6vh"} />
          <img
            className="icon"
            src={calcBiomeIcon(selectedBiome.name)}
            alt="biome"
            style={{ height: "4vh", marginRight: "1vh" }}
          />
          <div className="text_black_small_bold">{formattedBiomeName}</div>
        </motion.div>
        <div className="explorers_hut_biome_info_items">
          <div className="explorers_hut_biome_info_graph_button">
            <DoughnutChart
              biomeHealth={biomeHealth}
              chances={calc_item_chances(biomeHealth)}
            />
            <motion.div
              className="explorers_hut_biome_info_button"
              whileHover={{
                scale: 1.1,
                boxShadow: "0px 0px 20px 2px #6EE6A7",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 30 }}
              onClick={() => {
                if (studentState.tutorialEutonsStep === 11) {
                  dispatch(increaseTutorialEutonsStep());
                }
                setExplorerSelection(true);
              }}
            >
              <div className="text_white_small">{t("send_explorer")}</div>
            </motion.div>
          </div>
          <div className="explorers_hut_biome_info_items_list">
            {eutons
              .filter((euton) => euton.biome_id === selectedBiome.id)
              .sort((a, b) => a.tier - b.tier)
              .map((euton, index) => {
                return (
                  <motion.div
                    key={index}
                    className="explorers_hut_biome_info_item"
                    whileHover={{
                      boxShadow:
                        "0px 0px 10px 2px " +
                        (euton.tier === 1
                          ? "#6EE6A7"
                          : euton.tier === 2
                            ? "#00ECE3"
                            : "#FECF3C"),
                    }}
                    transition={{ type: "spring", stiffness: 400, damping: 30 }}
                    style={{
                      borderLeft:
                        euton.tier === 1
                          ? "10px solid #6EE6A7"
                          : euton.tier === 2
                            ? "10px solid #00ECE3"
                            : "10px solid #FECF3C",
                    }}
                  >
                    <img
                      className="image"
                      src={euton.item_image?.url}
                      alt="euton_item"
                      style={{ maxHeight: "11vh" }}
                    />
                    <div
                      className="explorers_hut_biome_info_item_text"
                      style={
                        euton.tier === 1
                          ? { color: "#6EE6A7" }
                          : euton.tier === 2
                            ? { color: "#00ECE3" }
                            : { color: "#FECF3C" }
                      }
                    >
                      <b>
                        {t(
                          "c" +
                          euton.tier +
                          "_" +
                          euton.biome.name +
                          "_item_name",
                          { ns: "eutons" }
                        )}
                      </b>
                      {" - " + t("item_" + euton.tier)}
                    </div>
                    <div
                      className="explorers_hut_biome_info_item_chance"
                      style={{
                        backgroundColor:
                          euton.tier === 1
                            ? "#6EE6A7"
                            : euton.tier === 2
                              ? "#00ECE3"
                              : "#FECF3C",
                      }}
                    >
                      {biomeChances && (
                        <div className="text_white_big_bold">
                          {(euton.tier === 1
                            ? Math.round(biomeChances[0] * 100) / 100
                            : euton.tier === 2
                              ? Math.round(biomeChances[1] * 100) / 100
                              : Math.round(biomeChances[2] * 100) / 100) + "%"}
                        </div>
                      )}
                    </div>
                  </motion.div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  const renderExplorers = () => {
    if (selectedBiome === null) {
      return (
        <div className="explorers_hut_biome_info">
          <div className="text_black_very_small_bold">{t("select_biome")}</div>
        </div>
      );
    }
    const formattedBiomeName =
      t(selectedBiome.name).charAt(0).toUpperCase() +
      t(selectedBiome.name).slice(1);

    return (
      <div className="explorers_hut_explorers">
        <motion.div
          className="explorers_hut_biome_info_name"
          onClick={() => setExplorerSelection(false)}
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 10px 2px " + "rgba(255, 255, 255, 0.6)",
          }}
          transition={{ type: "spring", stiffness: 400, damping: 30 }}
        >
          <BackArrow color={selectedBiome.color} size={"6vh"} />
          <img
            className="icon"
            src={calcBiomeIcon(selectedBiome.name)}
            alt="biome"
            style={{ height: "4vh", marginRight: "1vh" }}
          />
          <div className="text_black_small_bold">{formattedBiomeName}</div>
        </motion.div>
        <div className="explorers_hut_explorers_items">
          {explorers.map((explorer, i) => (
            <div
              className="explorers_hut_explorers_container"
              key={explorer.id}
              style={{
                marginLeft: i === 0 ? "7.5%" : i === 1 ? "5%" : "2.5%",
                backgroundColor:
                  i === 0 ? "#2CC857" : i === 1 ? "#5BB7D3" : "#FECF3C",
              }}
            >
              <div className="center_container">
                <img
                  className="image"
                  src={explorer.image.url}
                  alt="explorer_image"
                  style={{
                    height: "38vh",
                    position: "absolute",
                    top: "-7vh",
                  }}
                />
              </div>
              <div className="left_container">
                <div className="text_white_small_bold">{explorer.name}</div>
                <div className="text_white_small" style={{ marginLeft: "2%" }}>
                  {t("explorer_" + (i + 1) + "_subtext", { ns: "eutons" })}
                </div>
              </div>
              <div className="left_container">
                <div className="text_white_very_small">
                  {t("explorer_" + (i + 1) + "_passive", { ns: "eutons" })}
                </div>
              </div>
              <div className="center_container">
                <motion.div
                  className="explorers_hut_explorers_send_button"
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 0px 10px 2px " + "rgba(110, 230, 167, 0.6)",
                  }}
                  transition={{ type: "spring", stiffness: 400, damping: 30 }}
                  onClick={() => {
                    if (studentState.tutorialEutonsStep === 12) {
                      dispatch(increaseTutorialEutonsStep());
                    }
                    setSelectedExplorer(explorer);
                  }}
                >
                  <div className="center_container">
                    <div className="text_green_small_bold">{t("choose")}</div>
                  </div>
                </motion.div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderExplorerInfo = () => {
    if (selectedBiome === null) {
      return (
        <div className="explorers_hut_biome_info">
          <div className="text_black_very_small_bold">{t("select_biome")}</div>
        </div>
      );
    }
    const formattedBiomeName =
      t(selectedBiome.name).charAt(0).toUpperCase() +
      t(selectedBiome.name).slice(1);

    const biomeHealth = biomesHealth[selectedBiome.id];
    const biomeChances = calc_item_chances(biomeHealth, selectedExplorer?.name);
    const biomeChancesExplorer1 = calc_item_chances(biomeHealth, "Hippie");
    console.log(biomeChances);
    console.log(biomeChancesExplorer1);
    return (
      <div className="explorers_hut_explorer_info_menu">
        <motion.div
          className="explorers_hut_biome_info_name"
          onClick={() => setSelectedExplorer(null)}
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 10px 2px " + "rgba(255, 255, 255, 0.6)",
          }}
          transition={{ type: "spring", stiffness: 400, damping: 30 }}
        >
          <BackArrow color={selectedBiome.color} size={"6vh"} />
          <img
            className="icon"
            src={calcBiomeIcon(selectedBiome.name)}
            alt="biome"
            style={{ height: "4vh", marginRight: "1vh" }}
          />
          <div className="text_black_small_bold">{formattedBiomeName}</div>
        </motion.div>
        <div className="explorers_hut_explorer_info_menu_container">
          <div className="explorers_hut_explorer_info_menu_title center_container">
            <div className="text_black_small_bold">
              {t("send_explorer_text_1") +
                selectedExplorer?.name +
                t("send_explorer_text_2") +
                t(selectedBiome.name.toLowerCase()) +
                "?"}
            </div>
          </div>
          <div className="explorers_hut_explorer_info_menu_middle">
            <div className="explorers_hut_explorer_info_menu_middle_items">
              {eutons
                .filter((euton) => euton.biome_id === selectedBiome.id)
                .sort((a, b) => a.tier - b.tier)
                .map((euton, index) => {
                  return (
                    <motion.div
                      key={index}
                      className="explorers_hut_biome_info_item"
                      whileHover={{
                        boxShadow:
                          "0px 0px 10px 2px " +
                          (euton.tier === 1
                            ? "#6EE6A7"
                            : euton.tier === 2
                              ? "#00ECE3"
                              : "#FECF3C"),
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 30,
                      }}
                      style={{
                        borderLeft:
                          euton.tier === 1
                            ? "10px solid #6EE6A7"
                            : euton.tier === 2
                              ? "10px solid #00ECE3"
                              : "10px solid #FECF3C",
                      }}
                    >
                      {selectedExplorer?.name === "Lara Croft" &&
                        biomeChances &&
                        biomeChancesExplorer1 && (
                          <div style={{ position: "absolute", right: "-15%" }}>
                            <ToothedCircleIcon
                              height={"10vh"}
                              text={
                                "+" +
                                (euton.tier === 1
                                  ? (
                                    Math.round(
                                      (biomeChances[0] -
                                        biomeChancesExplorer1[0]) *
                                      100
                                    ) / 100
                                  ).toString()
                                  : euton.tier === 2
                                    ? (
                                      Math.round(
                                        (biomeChances[1] -
                                          biomeChancesExplorer1[1]) *
                                        100
                                      ) / 100
                                    ).toString()
                                    : (
                                      Math.round(
                                        (biomeChances[2] -
                                          biomeChancesExplorer1[2]) *
                                        100
                                      ) / 100
                                    ).toString()) +
                                "%"
                              }
                              color={"#FEBC3C"}
                            />
                          </div>
                        )}
                      <img
                        className="image"
                        src={euton.item_image?.url}
                        alt="euton_item"
                      // style={{ height: "20%" }}
                      />
                      <div
                        className="explorers_hut_explorer_info_menu_item_text"
                        style={
                          euton.tier === 1
                            ? { color: "#6EE6A7" }
                            : euton.tier === 2
                              ? { color: "#00ECE3" }
                              : { color: "#FECF3C" }
                        }
                      >
                        <b>
                          {t(
                            "c" +
                            euton.tier +
                            "_" +
                            euton.biome.name +
                            "_item_name",
                            { ns: "eutons" }
                          )}
                        </b>
                        {" - " + t("item_" + euton.tier)}
                      </div>
                      <div
                        className="explorers_hut_biome_info_item_chance"
                        style={{
                          backgroundColor:
                            euton.tier === 1
                              ? "#6EE6A7"
                              : euton.tier === 2
                                ? "#00ECE3"
                                : "#FECF3C",
                        }}
                      >
                        {biomeChances && (
                          <div className="text_white_medium_bold">
                            {(euton.tier === 1
                              ? Math.round(biomeChances[0] * 100) / 100
                              : euton.tier === 2
                                ? Math.round(biomeChances[1] * 100) / 100
                                : Math.round(biomeChances[2] * 100) / 100) + "%"}
                          </div>
                        )}
                      </div>
                    </motion.div>
                  );
                })}
            </div>
            {selectedExplorer?.name === "Ohio Man" && (
              <div className="explorers_hut_explorer_info_menu_middle_extra_icon">
                <ToothedCircleIcon
                  height={"10vh"}
                  text={"+10%"}
                  color={"#FEBC3C"}
                />
              </div>
            )}
            {/* {selectedExplorer?.name === "Lara Croft" && (
              <div style={{
                position: "absolute",
                left: "40%",
                top: "12%", // CHANGE THIS STUFF
                height: "75%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
                <ToothedCircleIcon height={"10vh"} text={"+10%"} color={"#FEBC3C"} />

                <ToothedCircleIcon height={"10vh"} text={"+10%"} color={"#FEBC3C"} />

                <ToothedCircleIcon height={"10vh"} text={"+10%"} color={"#FEBC3C"} />

              </div>

            )} */}
            {selectedExplorer?.name !== "Hippie" ? (
              <div className="explorers_hut_explorer_info_menu_middle_hearts center_container">
                <HeartIcon height={"14vh"} text={biomeHealth} />
                <div style={{ transform: "rotate(270deg)" }}>
                  <BackArrow color={"#F0684F"} size={"7vh"} />
                </div>
                {selectedExplorer &&
                  selectedExplorer.destruction !== undefined && (
                    <HeartIcon
                      height={"14vh"}
                      text={biomeHealth - selectedExplorer?.destruction}
                    />
                  )}
              </div>
            ) : (
              renderHeartExplorer1(biomeHealth)
            )}
            <div className="explorers_hut_explorer_info_menu_middle_image">
              <div className="center_container">
                <img
                  className="image"
                  src={selectedExplorer?.image.url}
                  alt="explorer_image"
                />
              </div>
            </div>
          </div>
          <div className="explorers_hut_explorer_info_menu_bottom">
            <motion.div
              className="explorers_hut_biome_info_menu_button"
              whileHover={{
                scale: 1.1,
                boxShadow: "0px 0px 20px 2px #6EE6A7",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 30 }}
              onClick={() => selectExplorerHandler()}
            >
              <div className="text_white_small">{t("send_explorer")}</div>
            </motion.div>
          </div>
        </div>
      </div>
    );
  };

  const renderHeartExplorer1 = (biomeHealth: number) => {
    return (
      <div className="explorers_hut_explorer_info_menu_middle_hearts center_container">
        <HeartIcon height={"30vh"} text={biomeHealth} />
        <div style={{ position: "absolute", bottom: "65%", left: "68%" }}>
          <ToothedCircleIcon height={"10vh"} text={"-0"} color={"#FEBC3C"} />
        </div>
      </div>
    );
  };

  const renderItemObtainedWindow = () => {
    return (
      <div className="explorers_hut_item_obtained_background">
        <div className="center_container">
          <div className="explorers_hut_item_obtained_container">
            <div className="text_light_green_big_bold">
              {t("discovered_item")}
            </div>
            <img
              className="image"
              src={itemObtained?.item_image?.url}
              alt="euton_item"
              style={{ maxHeight: "25vh" }}
            />
            {/* <div className="text_light_green_small_bold">{
            itemObtained?.item_name
           }</div> */}
            <div className="text_light_green_small">
              <b>
                {t(
                  "c" +
                  itemObtained?.tier +
                  "_" +
                  itemObtained?.biome.name +
                  "_item_name",
                  { ns: "eutons" }
                )}
              </b>
              {" - " + t("item_" + itemObtained?.tier)}
            </div>
            <div className="explorers_hut_item_obtained_buttons">
              <div className="center_container">
                <motion.div
                  className="explorers_hut_item_obtained_button_ok"
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "0px 0px 20px 2px #6EE6A7",
                  }}
                  onClick={() => setItemObtained(null)}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  <div className="center_container">
                    <div className="text_white_small_bold">{t("accept")}</div>
                  </div>
                </motion.div>
              </div>
            </div>
            <div
              className="explorers_hut_item_obtained_close"
              onClick={() => setItemObtained(null)}
            >
              <motion.img
                className="icon"
                src={close}
                alt="close"
                whileHover={{ scale: 1.1, rotate: 180 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderNoItemObtainedWindow = () => {
    return (
      <div className="explorers_hut_item_obtained_background">
        <div className="center_container">
          <div className="explorers_hut_item_obtained_container">
            <div className="text_black_medium">
              {t("text_no_item_obtained")}
            </div>
            <div className="explorers_hut_item_obtained_buttons">
              <div className="center_container">
                <motion.div
                  className="explorers_hut_item_obtained_button_ok"
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "0px 0px 20px 2px #6EE6A7",
                  }}
                  onClick={() => setNoItemObtained(false)}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  <div className="center_container">
                    <div className="text_white_small_bold">{t("ok")}</div>
                  </div>
                </motion.div>
              </div>
            </div>
            <div
              className="explorers_hut_item_obtained_close"
              onClick={() => setNoItemObtained(false)}
            >
              <motion.img
                className="icon"
                src={close}
                alt="close"
                whileHover={{ scale: 1.1, rotate: 180 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="explorers_hut">
      {renderBanner()}
      <div className="explorers_hut_background" />
      {renderCloseButton()}
      {renderTitle()}
      {renderBackground()}
      {selectedBiome !== null && renderBackgroundFilter()}
      {!explorerSelection && selectedBiome === null && renderBiomes()}
      {!explorerSelection && selectedBiome !== null && renderBiomeInfo()}
      {explorerSelection && selectedExplorer === null && renderExplorers()}
      {explorerSelection && selectedExplorer !== null && renderExplorerInfo()}
      {itemObtained !== null && renderItemObtainedWindow()}
      {noItemObtained && renderNoItemObtainedWindow()}
    </div>
  );
};

export default ExplorersHut;
