import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../interfaces";

const BeachMainland = ({ ...props }) => {
  const coralBlue = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/coral_small_blue_playa.gltf"
  ) as DreiGLTF;
  const coralOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/coral_small_orange_playa.gltf"
  ) as DreiGLTF;
  const coralRed = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/coral_small_red_playa.gltf"
  ) as DreiGLTF;
  const grassSea = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/grass_sea_playa.gltf"
  ) as DreiGLTF;
  const palmBig = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/palm_big_playa.gltf"
  ) as DreiGLTF;
  const palmHigh = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/palm_high_playa.gltf"
  ) as DreiGLTF;
  const sandCastle = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/sand_castle_playa.gltf"
  ) as DreiGLTF;
  const starfish = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/starfish_playa.gltf"
  ) as DreiGLTF;
  const sunscreenClosed = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/sunscreen_closed_playa.gltf"
  ) as DreiGLTF;
  const sunscreen = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/sunscreen_playa.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreen.nodes.sunscreen001.geometry}
        material={props.bakedMaterial1}
        position={[7.52, 0, -10.01]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreen.nodes.sunscreen001.geometry}
        material={props.bakedMaterial1}
        position={[4.08, 0, -9.1]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClosed.nodes["sunscreen-closed001"].geometry}
        material={props.bakedMaterial1}
        position={[9.11, 0, -8.98]}
        scale={0.45}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClosed.nodes["sunscreen-closed001"].geometry}
        material={props.bakedMaterial1}
        position={[6.1, 0, -8.98]}
        scale={0.45}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClosed.nodes["sunscreen-closed001"].geometry}
        material={props.bakedMaterial1}
        position={[1.93, 0, -10.21]}
        scale={0.45}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreen.nodes.sunscreen001.geometry}
        material={props.bakedMaterial1}
        position={[9.84, 0, -7.88]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={starfish.nodes.starfish001.geometry}
        material={props.bakedMaterial1}
        position={[3.53, 0.04, -11.15]}
        rotation={[-0.08, -0.03, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={starfish.nodes.starfish001.geometry}
        material={props.bakedMaterial1}
        position={[2.56, 0.12, -10.26]}
        rotation={[-0.08, -0.03, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={starfish.nodes.starfish001.geometry}
        material={props.bakedMaterial1}
        position={[5.98, 0.04, -9.87]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={starfish.nodes.starfish001.geometry}
        material={props.bakedMaterial1}
        position={[7.84, 0.03, -9.04]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sandCastle.nodes["sand-castle001"].geometry}
        material={props.bakedMaterial1}
        position={[5.32, 0.2, -10.1]}
        rotation={[0, -0.33, 0]}
        scale={0.62}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sandCastle.nodes["sand-castle001"].geometry}
        material={props.bakedMaterial1}
        position={[6.19, 0.5, -6.11]}
        rotation={[0, -0.33, 0]}
        scale={1.96}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[8.68, -0.1, -4.2]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[8.84, -0.48, -6.26]}
        rotation={[-Math.PI, 1.28, -Math.PI]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[1.8, -0.66, -8.15]}
        rotation={[0, 0.8, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBig.nodes["palm-big001"].geometry}
        material={props.bakedMaterial1}
        position={[12.31, -0.27, -2.7]}
        rotation={[-Math.PI, 1.29, -Math.PI]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBig.nodes["palm-big001"].geometry}
        material={props.bakedMaterial1}
        position={[11.45, 0, -4.01]}
        rotation={[-Math.PI, 0.84, -Math.PI]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBig.nodes["palm-big001"].geometry}
        material={props.bakedMaterial1}
        position={[2.9, 0, -4.9]}
        rotation={[-Math.PI, 1.41, -Math.PI]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBig.nodes["palm-big001"].geometry}
        material={props.bakedMaterial1}
        position={[9.24, 0, -4.25]}
        rotation={[-Math.PI, 0.65, -Math.PI]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBig.nodes["palm-big001"].geometry}
        material={props.bakedMaterial1}
        position={[4.65, 0, -4.47]}
        rotation={[-Math.PI, 0.36, -Math.PI]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBig.nodes["palm-big001"].geometry}
        material={props.bakedMaterial1}
        position={[5.68, 0, -4.53]}
        rotation={[-Math.PI, 0.36, -Math.PI]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[13.41, -0.08, -4.05]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[10.29, -0.08, -8.81]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[4.88, -0.08, -11]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[6.8, -0.08, -10.36]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralRed.nodes["coral-small_red001"].geometry}
        material={props.bakedMaterial1}
        position={[9.52, 0.05, -9.46]}
        rotation={[0, -0.9, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralRed.nodes["coral-small_red001"].geometry}
        material={props.bakedMaterial1}
        position={[5.92, 0.05, -10.64]}
        rotation={[0, -0.9, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralOrange.nodes["coral-small_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[12.06, 0.11, -5.71]}
        rotation={[0, 0.29, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralOrange.nodes["coral-small_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[10.89, 0.11, -8.27]}
        rotation={[0, 0.29, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralBlue.nodes["coral-small_blue001"].geometry}
        material={props.bakedMaterial1}
        position={[12, 0.05, -7.07]}
        rotation={[0, 0.69, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralBlue.nodes["coral-small_blue001"].geometry}
        material={props.bakedMaterial1}
        position={[7.69, 0.05, -10.31]}
        rotation={[0, 0.69, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[11.76, 0.08, -3.21]}
        rotation={[0, -1.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[7.9, 0.28, -4.3]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[3.43, 0.3, -4.47]}
        rotation={[-Math.PI, 0.31, -Math.PI]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[3.99, 0.28, -3.89]}
        rotation={[0, -0.39, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[2.32, 0.08, -5.19]}
        rotation={[0, 0.63, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[2.04, -0.66, -5.65]}
        rotation={[0, -1.37, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClosed.nodes["sunscreen-closed001"].geometry}
        material={props.bakedMaterial1}
        position={[2.36, 0, -10]}
        scale={0.45}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClosed.nodes["sunscreen-closed001"].geometry}
        material={props.bakedMaterial1}
        position={[2.85, 0, -9.75]}
        scale={0.45}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClosed.nodes["sunscreen-closed001"].geometry}
        material={props.bakedMaterial1}
        position={[11.83, 0.11, -4.91]}
        scale={0.45}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralOrange.nodes["coral-small_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[10.8, 0.11, -7.47]}
        rotation={[0, -0.41, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralBlue.nodes["coral-small_blue001"].geometry}
        material={props.bakedMaterial1}
        position={[8.82, 0.05, -9.8]}
        rotation={[0, 1.57, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralRed.nodes["coral-small_red001"].geometry}
        material={props.bakedMaterial1}
        position={[12.44, 0.05, -6.39]}
        rotation={[0, -0.12, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralOrange.nodes["coral-small_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[13.48, 0.11, -3.43]}
        rotation={[0, -0.47, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralRed.nodes["coral-small_red001"].geometry}
        material={props.bakedMaterial1}
        position={[12.85, 0.05, -5]}
        rotation={[0, 0.9, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralBlue.nodes["coral-small_blue001"].geometry}
        material={props.bakedMaterial1}
        position={[12.74, 0.05, -4.36]}
        rotation={[0, -0.36, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={starfish.nodes.starfish001.geometry}
        material={props.bakedMaterial1}
        position={[10.67, 0.04, -7.06]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={starfish.nodes.starfish001.geometry}
        material={props.bakedMaterial1}
        position={[12.38, 0.04, -5.28]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={starfish.nodes.starfish001.geometry}
        material={props.bakedMaterial1}
        position={[11.64, 0.04, -7.58]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralRed.nodes["coral-small_red001"].geometry}
        material={props.bakedMaterial1}
        position={[4.18, 0.05, -10.75]}
        rotation={[0, -0.01, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralRed.nodes["coral-small_red001"].geometry}
        material={props.bakedMaterial1}
        position={[1.83, 0.05, -10.89]}
        rotation={[0, 0.42, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralBlue.nodes["coral-small_blue001"].geometry}
        material={props.bakedMaterial1}
        position={[5.02, 0.05, -10.61]}
        rotation={[-Math.PI, 0.82, -Math.PI]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralBlue.nodes["coral-small_blue001"].geometry}
        material={props.bakedMaterial1}
        position={[1.19, 0.05, -10.45]}
        rotation={[-Math.PI, 0.45, -Math.PI]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralOrange.nodes["coral-small_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[6.56, 0.11, -10.38]}
        rotation={[0, 0.29, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralOrange.nodes["coral-small_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[3.39, 0.11, -10.35]}
        rotation={[0, -0.43, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={coralOrange.nodes["coral-small_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[0.49, 0.11, -10.59]}
        rotation={[0, 0.42, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={starfish.nodes.starfish001.geometry}
        material={props.bakedMaterial1}
        position={[1.25, 0.04, -10.03]}
        rotation={[-0.08, -0.03, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={starfish.nodes.starfish001.geometry}
        material={props.bakedMaterial1}
        position={[8.34, 0.04, -9.95]}
        rotation={[-0.08, -0.03, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[4.04, -0.08, -10.54]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[2.86, -0.08, -10.95]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[5.53, -0.08, -10.74]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[8.36, -0.08, -9.96]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[9.63, -0.08, -8.79]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[11.1, -0.08, -7.9]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[4.67, -0.08, -9.71]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[3.31, -0.08, -9.71]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[1.39, -0.08, -11.06]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[1.42, -0.08, -9.56]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[0.12, -0.08, -10.84]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[12.71, -0.08, -5.31]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[11.32, -0.08, -6.99]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[13.64, -0.08, -2.75]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[8.35, -0.08, -9.31]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassSea.nodes["grass-sea001"].geometry}
        material={props.bakedMaterial1}
        position={[6.59, -0.08, -9.34]}
        rotation={[0.48, -1.42, 0.44]}
        scale={0.21}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/coral_small_blue_playa.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/coral_small_orange_playa.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/coral_small_red_playa.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/grass_sea_playa.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/palm_big_playa.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/palm_high_playa.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/sand_castle_playa.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/starfish_playa.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/sunscreen_closed_playa.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_playa/sunscreen_playa.gltf"
);

export default BeachMainland;
