import "./ExercisesExperimentBar.css";
import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { motion } from "framer-motion";
import TheoryIcon from "../../assets/exercises/theory_icon";
import DefinitionIcon from "../../assets/exercises/definition_icon";
import BackArrow from "../../assets/exercises/back_arrow";
import { ExerciseType } from "../../_newapios/content/unit";
import { useAppSelector } from "../../hooks/hooks";
import { CourseType } from "../../_newapios/content/course";
import { ExerciseAnswerType } from "./ExercisesExperiment";

const ExercisesExperimentBar = (props: {
  exerciseAnswers: ExerciseAnswerType[];
  exercises: ExerciseType[];
  selectedExercise: number;
  maxExercise: number;
  updateLevelBar: Dispatch<SetStateAction<number>>;
  backHandler: () => void;
}) => {
  const mainState = useAppSelector((state) => state.main);
  const [exercises, setExercises] = useState<number[]>([]);
  const [selectedExercise, setSelectedExercise] = useState(0);
  const [maxExercise, setMaxExercise] = useState(0);
  const [course, setCourse] = useState<CourseType>(mainState.courses[0]);

  const [exerciseAnswers, setExerciseAnswers] = useState<ExerciseAnswerType[]>(
    props.exerciseAnswers
  );

  useEffect(() => {
    var newLevels = [];
    for (var i = 1; i <= props.exercises.length; i++) {
      newLevels.push(i);
    }
    setExercises(newLevels);
  }, [props.exercises]);

  useEffect(() => {
    setSelectedExercise(props.selectedExercise);
  }, [props.selectedExercise]);

  useEffect(() => {
    setMaxExercise(props.maxExercise);
  }, [props.maxExercise]);

  useEffect(() => {
    const temp_course = mainState.courses.find(
      (course) => course.id === mainState.selectedCourseId
    );
    if (temp_course) setCourse(temp_course);
  }, [mainState.selectedCourseId]);

  useEffect(() => {
    setExerciseAnswers(props.exerciseAnswers);
  }, [props.exerciseAnswers]);

  const levelButtonHandler = (level: number) => {
    console.log(maxExercise);
    if (maxExercise >= level) {
      props.updateLevelBar(level);
    }
  };

  const renderBackButton = () => {
    return (
      <motion.div
        className="center_container"
        whileHover={{ scale: 1.4 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
        onClick={() => props.backHandler()}
      >
        <BackArrow color={course.color} size={"35"} />
      </motion.div>
    );
  };

  const renderLevels = () => {
    return (
      <div
        className="exercises_experiment_bar_levels"
        style={{
          gridTemplateColumns:
            "repeat(" + exercises.length + ", " + 100 / exercises.length + "%)",
        }}
      >
        {exercises.map((level, levelIndex) => {
          return renderLevelButton(levelIndex);
        })}
      </div>
    );
  };

  const renderLevelButton = (levelIndex: number) => {
    return (
      <div
        key={levelIndex}
        className="exercises_experiment_bar_level"
        onClick={() => null /*levelButtonHandler(level)*/}
        style={
          levelIndex <= maxExercise
            ? {
                backgroundColor:
                  selectedExercise === levelIndex
                    ? course.color + "80"
                    : course.color + "40",
              }
            : { backgroundColor: "#ffffff" }
        }
      >
        <div className="center_container">
          <motion.div
            className={
              levelIndex <= maxExercise
                ? levelIndex != maxExercise
                  ? exerciseAnswers[levelIndex].correct
                    ? "exercises_experiment_bar_point_completed"
                    : "exercises_experiment_bar_point_wrong"
                  : "exercises_experiment_bar_point_now"
                : "exercises_experiment_bar_point"
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="exercises_experiment_bar">
      {renderBackButton()}
      {renderLevels()}
    </div>
  );
};

export default ExercisesExperimentBar;
