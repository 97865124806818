import { ClassType } from "../_newapios/user/class";
import { userLicenseType } from "../_newapios/user/user_license";

export const calculateAccessCourse = (course_id: string, userLicense: userLicenseType, isStudentFree: boolean) => {
    let canAccess = false;
    const courseNumber = userLicense.license.course_evaluate_number;
    if (courseNumber === -1) {
      canAccess = true;
    }
    if (
      courseNumber === 2 &&
      userLicense.courses_allowed.length > 0
    ) {
      canAccess = userLicense.courses_allowed.some((c) => c.id === course_id);
    }
    if (courseNumber === 0 && isStudentFree) {
      canAccess = true;
    }
    return canAccess;
  };



  export const calculateCreateClass = ( userLicense: userLicenseType | null, classLength: number) => {
    if (userLicense === null) return false;
    let createClass = false;
    const classNumber = userLicense.license.class_number
    ;
    if (classNumber === -1) {
      createClass = true;
    }
    if (classLength < classNumber){
      createClass= true;
    }
    
    return createClass
  }

    


