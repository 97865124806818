import env from "../../config/env.json";
import APIFetch from "../../utils/APIFetch";

export interface TeacherType {
  id: string;
  username: string;
  fullname: string;
  phone?: string;
  admin: boolean;
  school_id: string;
  verification_code: string | null;
}

export interface BusinessTeacherType {
  id: string;
  fullname: string;
  username: string;
  phone?: string;
  admin: boolean;
  verification_code: string | null;
  school: {
    id: string;
    name: string;
    user_license: {
      license: {
        name: string;
      };
    }[];
  };
  back_office_logins: {
    id: string;
    timestamp: string;
  }[];
  classes: {
    id: string;
    name: string;
    students: {
      id: string;
      _count: {
        scenario_progress: number;
      };
    }[];
  }[];
}

export interface LoginsType {
  id: string;
  timestamp: string;
  teacher: {
    id: string;
    fullname: string;
    username: string;
  };
}

export const GetMyTeacherInfoAPI = async (): Promise<TeacherType> => {
  // var req = await fetch(env.backend_host + "/teacher/me/", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  var data = APIFetch(env.backend_host + "/teacher/me/", {
    method: "GET",
  });
  return data;
};

export const GetAuthorsAPI = async (): Promise<TeacherType[]> => {
  // var req = await fetch(env.backend_host + "/teacher/authors", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  var data = APIFetch(env.backend_host + "/teacher/authors", {
    method: "GET",
  });
  return data;
};

export const GetAllTeachersAPI = async (): Promise<BusinessTeacherType[]> => {
  // var req = await fetch(env.backend_host + "/teacher/all", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  var data = APIFetch(env.backend_host + "/teacher/all", {
    method: "GET",
  });
  return data;
};

export const GetAllLoginsAPI = async (): Promise<LoginsType[]> => {
  // var req = await fetch(env.backend_host + "/teacher/logins", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  var data = APIFetch(env.backend_host + "/teacher/logins", {
    method: "GET",
  });
  return data;
};
