import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../interfaces";

const SnowMainland = ({ ...props }) => {
  const snowman = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/snowman_nevado.gltf"
  ) as DreiGLTF;
  const stalags = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/stalags_nevado.gltf"
  ) as DreiGLTF;
  const treeDead = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/tree_dead_snow_nevado.gltf"
  ) as DreiGLTF;
  const treeLime = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/tree_lime_white_nevado.gltf"
  ) as DreiGLTF;
  const treeOak = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/tree_oak_winter_nevado.gltf"
  ) as DreiGLTF;
  const treeSpruce = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/tree_spruce_snow_nevado.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={snowman.nodes.snowman001.geometry}
        material={props.bakedMaterial1}
        position={[8.69, 2.58, -0.08]}
        rotation={[0, -0.96, 0]}
        scale={0.39}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeLime.nodes["tree-lime_white001"].geometry}
        material={props.bakedMaterial1}
        position={[3.71, 2.7, -1.36]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[1.52, 2.16, -1.95]}
        rotation={[Math.PI, -0.4, Math.PI]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[1.32, 1.95, -0.68]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[1.6, 1.4, 0.14]}
        rotation={[-Math.PI, 0.88, -Math.PI]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[2.29, 1.13, 1.56]}
        rotation={[0, -0.23, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[3.04, 2.18, 1.4]}
        rotation={[0, 1.3, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[11.68, 1.61, 1.02]}
        rotation={[-Math.PI, 1.06, -Math.PI]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[11.67, 2.23, -0.78]}
        rotation={[-Math.PI, 1.33, -Math.PI]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[10.44, 3.1, -1.16]}
        rotation={[0, -0.33, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[7.88, 2.57, -1.84]}
        rotation={[0, 0.73, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[6.23, 2.13, -2.16]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[5.48, 2.41, -2.06]}
        rotation={[-Math.PI, 0.95, -Math.PI]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[2.15, 2.5, -2.16]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[9.48, 2.85, -1.61]}
        rotation={[0, -0.13, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stalags.nodes.stalags001.geometry}
        material={props.bakedMaterial1}
        position={[5.76, 1.76, 1.03]}
        rotation={[0, 1.34, 0]}
        scale={0.77}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stalags.nodes.stalags001.geometry}
        material={props.bakedMaterial1}
        position={[3.59, 1.86, 1.17]}
        scale={0.77}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stalags.nodes.stalags001.geometry}
        material={props.bakedMaterial1}
        position={[12.57, 0.67, 0.06]}
        rotation={[0, 1.57, 0]}
        scale={0.77}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stalags.nodes.stalags001.geometry}
        material={props.bakedMaterial1}
        position={[4.41, 1.36, -1.79]}
        rotation={[0, 0.01, 0]}
        scale={0.77}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stalags.nodes.stalags001.geometry}
        material={props.bakedMaterial1}
        position={[2.43, 2.7, -1.34]}
        scale={0.77}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stalags.nodes.stalags001.geometry}
        material={props.bakedMaterial1}
        position={[7.29, 1.93, -1.96]}
        rotation={[-Math.PI, 0.78, -Math.PI]}
        scale={0.52}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stalags.nodes.stalags001.geometry}
        material={props.bakedMaterial1}
        position={[6.25, 2.94, 0.84]}
        rotation={[0, 0.92, 0]}
        scale={0.52}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[4.93, 2.52, 0.85]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[6.08, 2.54, -1.46]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[1.97, 1.76, 0.86]}
        rotation={[0, 0.8, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[8.71, 2.49, 0.96]}
        rotation={[0, -0.54, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeLime.nodes["tree-lime_white001"].geometry}
        material={props.bakedMaterial1}
        position={[3.57, 2.64, -1.49]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak_winter001"].geometry}
        material={props.bakedMaterial1}
        position={[1.48, 2.57, -1.39]}
        rotation={[Math.PI, -0.71, Math.PI]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeLime.nodes["tree-lime_white001"].geometry}
        material={props.bakedMaterial1}
        position={[2.36, 2.18, -0.26]}
        rotation={[0, -1.07, 0]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeLime.nodes["tree-lime_white001"].geometry}
        material={props.bakedMaterial1}
        position={[8.62, 2.93, -1.05]}
        rotation={[0, -0.16, 0]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[10.42, 1.93, 1.35]}
        rotation={[-3.08, -0.9, -3.14]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[8.25, 2.04, 1.75]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stalags.nodes.stalags001.geometry}
        material={props.bakedMaterial1}
        position={[8.84, 1.66, 1.6]}
        scale={0.52}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak_winter001"].geometry}
        material={props.bakedMaterial1}
        position={[9.92, 2.45, 1.25]}
        rotation={[0, 0.58, 0]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[6.18, 2.21, 1.52]}
        rotation={[0, 1.25, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[6.92, 2.18, 1.42]}
        rotation={[0, 0.67, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[11.84, 2.54, -0.39]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[11.04, 2.3, 0.8]}
        rotation={[0, 0.29, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeDead.nodes["tree-dead-snow001"].geometry}
        material={props.bakedMaterial1}
        position={[9.93, 3.09, -1.5]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stalags.nodes.stalags001.geometry}
        material={props.bakedMaterial1}
        position={[10.34, 3.87, 0.35]}
        rotation={[-Math.PI, 1.03, -Math.PI]}
        scale={0.52}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/snowman_nevado.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/stalags_nevado.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/tree_dead_snow_nevado.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/tree_lime_white_nevado.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/tree_oak_winter_nevado.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Prinicipal_nevado/tree_spruce_snow_nevado.gltf"
);

export default SnowMainland;
