import "./ExercisesResearchBar.css";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/hooks";
import { useState, useEffect, SetStateAction, Dispatch } from "react";
import TheoryIcon from "../../assets/exercises/theory_icon";
import DefinitionIcon from "../../assets/exercises/definition_icon";
import { motion } from "framer-motion";
import { colors } from "../../constants";
import BackArrow from "../../assets/exercises/back_arrow";
import { CourseType } from "../../_newapios/content/course";
import { ExerciseType } from "../../_newapios/content/unit";
import MediaIcon from "../../assets/exercises/media_icon";

const ExercisesResearchBar = (props: {
  exercises: ExerciseType[];
  selectedExercise: number;
  maxExercise: number;
  updateLevelBar: Dispatch<SetStateAction<number>>;
  backHandler: () => void;
}) => {
  const mainState = useAppSelector((state) => state.main);
  const [selectedExercise, setSelectedExercise] = useState(0);
  const [maxExercise, setMaxExercise] = useState(0);
  const [course, setCourse] = useState<CourseType>(mainState.courses[0]);

  useEffect(() => {
    setSelectedExercise(props.selectedExercise);
  }, [props.selectedExercise]);

  useEffect(() => {
    setMaxExercise(props.maxExercise);
  }, [props.maxExercise]);

  useEffect(() => {
    const temp_course = mainState.courses.find(
      (course) => course.id === mainState.selectedCourseId
    );
    if (temp_course) setCourse(temp_course);
  }, [mainState.selectedCourseId]);

  const levelButtonHandler = (level: number) => {
    if (maxExercise >= level) {
      props.updateLevelBar(level);
    }
  };

  const renderBackButton = () => {
    return (
      <motion.div
        className="center_container"
        whileHover={{
          scale: 1.4,
          textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
        onClick={() => props.backHandler()}
      >
        <BackArrow color={course.color} size={"35"} />
      </motion.div>
    );
  };

  const renderTheoryIcon = (exerciseIndex: number) => {
    return (
      <div
        className="center_container"
        style={
          maxExercise >= exerciseIndex
            ? {
                backgroundColor:
                  selectedExercise === exerciseIndex
                    ? course.color + "80"
                    : course.color + "40",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                zIndex: "9",
              }
            : { backgroundColor: "#ffffff" }
        }
        onClick={() => levelButtonHandler(exerciseIndex)}
      >
        <motion.div
          className="icon"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <TheoryIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={25}
          />
        </motion.div>
      </div>
    );
  };

  const renderMediaIcon = (exerciseIndex: number) => {
    return (
      <div
        className="center_container"
        style={
          maxExercise >= exerciseIndex
            ? {
                backgroundColor:
                  selectedExercise === exerciseIndex
                    ? course.color + "80"
                    : course.color + "40",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                zIndex: "9",
              }
            : { backgroundColor: "#ffffff" }
        }
        onClick={() => levelButtonHandler(exerciseIndex)}
      >
        <motion.div
          className="icon"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <MediaIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={25}
          />
        </motion.div>
      </div>
    );
  };

  const renderLevels = () => {
    var levelIndex = 1;
    return (
      <div
        className="exercises_research_bar_levels"
        style={{
          gridTemplateColumns:
            "repeat(" +
            props.exercises.length +
            ", " +
            100 / props.exercises.length +
            "%)",
        }}
      >
        {props.exercises.map((exercise, exerciseIndex) => {
          if (exercise.type === "theory") {
            return renderTheoryIcon(exerciseIndex);
          } else if (exercise.type === "definition") {
            return renderDefinitionIcon(exerciseIndex);
          } else if (exercise.type === "media") {
            return renderMediaIcon(exerciseIndex);
          } else {
            var copyLevelIndex = levelIndex;
            levelIndex++;
            return renderLevelButton(copyLevelIndex, exerciseIndex);
          }
        })}
      </div>
    );
  };

  const renderLevelButton = (level: number, exerciseIndex: number) => {
    return (
      <div
        key={level}
        className="exercises_research_bar_level"
        onClick={() => levelButtonHandler(exerciseIndex)}
        style={
          maxExercise >= exerciseIndex
            ? {
                backgroundColor:
                  selectedExercise === exerciseIndex
                    ? course.color + "80"
                    : course.color + "40",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                zIndex: "9",
              }
            : { backgroundColor: "#ffffff" }
        }
      >
        <div className="center_container">
          <motion.div
            className={
              selectedExercise === exerciseIndex
                ? "text_white_medium_bold"
                : "text_black_medium_bold"
            }
            whileHover={{
              scale: 1.2,
              textShadow: "rgba(0,0,0, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            {level}
          </motion.div>
        </div>
      </div>
    );
  };

  const renderDefinitionIcon = (exerciseIndex: number) => {
    return (
      <div
        className="center_container"
        style={
          maxExercise >= exerciseIndex
            ? {
                backgroundColor:
                  selectedExercise === exerciseIndex
                    ? course.color + "80"
                    : course.color + "40",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                zIndex: "9",
              }
            : { backgroundColor: "#ffffff" }
        }
        onClick={() => levelButtonHandler(exerciseIndex)}
      >
        <motion.div
          className="icon"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <DefinitionIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={25}
          />
        </motion.div>
      </div>
    );
  };

  return (
    <div className="exercises_research_bar">
      {renderBackButton()}
      {renderLevels()}
    </div>
  );
};

export default ExercisesResearchBar;
