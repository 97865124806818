import "./DragDropOptions.css";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Arrows,
  Background,
  ConfirmButton,
  ExerciseName,
  HelpMenu,
  MainTextBox,
  Professor,
  TextBox,
} from "./_components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";
import { useAppSelector } from "../../../hooks/hooks";

const DragDropOptions = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  const [answers, setAnswers] = useState<string[]>([]);

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const mainText = props.exercise.main_text as string;
  const options = props.exercise.options as string[];

  const [selectedExercise, setselectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setmaxExercise] = useState(props.maxExercise);
  const [research, setResearch] = useState(props.research);

  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [incorrectIndexes, setIncorrectIndexes] = useState<number[]>([]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    orderOptions();
  }, [selectedExercise, maxExercise]);

  useEffect(() => {
    setselectedExercise(props.exerciseIndex);
    setmaxExercise(props.maxExercise);
  }, [props.exerciseIndex]);

  const orderOptions = () => {
    if (research) {
      if (selectedExercise < maxExercise) {
        setAnswers(options);
      } else {
        setAnswers(_.shuffle(options));
      }
    } else {
      if (isTeacher) {
        setAnswers(options);
      } else {
        setAnswers(_.shuffle(options));
      }
    }
  };

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  const confirmHandler = () => {
    var correct = true;
    let wrongIndexes = [];

    for (var i = 0; i < options.length; i++) {
      if (options[i] !== answers[i]) {
        correct = false;
        wrongIndexes.push(i);
      }
    }
    if (research) {
      if (correct) {
        props.updateLevel("right", answers, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
        setIncorrectIndexes(wrongIndexes);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers, true);
      } else {
        props.updateLevel("right", answers, false);
      }
    }
  };

  const updateDashboard = (srcC: string, desC: string) => {
    let copiedDashboards = [...answers];
    if (srcC !== desC) {
      copiedDashboards.splice(parseInt(desC), 1, answers[parseInt(srcC)]);
      copiedDashboards.splice(parseInt(srcC), 1, answers[parseInt(desC)]);
    }
    setAnswers(copiedDashboards);
  };

  const renderDragDrop = () => {
    return (
      <div className="drag_drop_options_background">
        <DragDropContext
          onDragEnd={(param) => {
            const srcC = param.source.droppableId.slice(-1);
            const desC =
              param.destination === null || param.destination === undefined
                ? param.source.droppableId.slice(-1)
                : param.destination.droppableId.slice(-1);
            updateDashboard(srcC, desC);
          }}
        >
          <div
            className="drag_drop_options_background_grid"
            style={{
              gridTemplateColumns:
                "repeat(" + answers.length + ", " + 100 / answers.length + "%)",
            }}
          >
            {answers.map((column, columnIndex) => {
              return (
                <div
                  className="drag_drop_options_background_column"
                  key={"column" + columnIndex}
                >
                  <div className="drag_drop_options_background_column_left">
                    <div className="center_container">
                      <div className="text_white_medium">{columnIndex + 1}</div>
                    </div>

                    <Droppable
                      droppableId={"droppable-" + columnIndex}
                      key={"droppable-" + columnIndex}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="drag_drop_options_droppable"
                        >
                          <Draggable
                            key={columnIndex}
                            draggableId={"draggable-" + column + columnIndex}
                            index={0}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="drag_drop_options_draggable"
                                style={{
                                  top: 7 + "%",
                                  ...provided.draggableProps.style,
                                  backgroundColor: "",
                                  // incorrectFeedback === true
                                  //   ? incorrectIndexes.includes(columnIndex)
                                  //     ? "#F6A495"
                                  //     : ""
                                  //   : "",
                                }}
                              >
                                <motion.div
                                  className="center_container"
                                  whileHover={{
                                    scale: 1.02,
                                    boxShadow: "0px 0px 10px 1px #5090F0",
                                    borderRadius: "5px",
                                  }}
                                  transition={{
                                    type: "spring",
                                    stiffness: 400,
                                    damping: 10,
                                  }}
                                >
                                  <div
                                    className={
                                      column.length < 15
                                        ? "text_black_medium"
                                        : "text_black_small"
                                    }
                                  >
                                    {column}
                                  </div>
                                </motion.div>
                              </div>
                            )}
                          </Draggable>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  {columnIndex !== answers.length - 1 && (
                    <div className="drag_drop_options_background_column_right">
                      <div className="drag_drop_options_background_column_right_line" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </DragDropContext>
      </div>
    );
  };

  return (
    <div className="drag_drop_options">
      <Background
        incorrectFeedback={incorrectFeedback}
        setIncorrectFeedback={setIncorrectFeedback}
      />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={true} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={research}
      />
      <ConfirmButton confirmHandler={confirmHandler} />
      <MainTextBox mainText={mainText} />
      <HelpMenu helpText={helpText} tipHandler={props.tipHandler} />
      <ExerciseName type={props.exercise.type} smallMargin={true} imageExists={true} />
      {renderDragDrop()}
    </div>
  );
};

export default DragDropOptions;
