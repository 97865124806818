import { ResearchProgressType } from './../progress/student_scenario';
import env from "../../config/env.json";
import { ExerciseType } from "./unit";
import APIFetch from '../../utils/APIFetch';

export interface ResearchType {
    id: string,
    scenario_id: string,
    exercises: ExerciseType[]
    research_progress: ResearchProgressType[]
}

export const GetResearchAPI = async (id: string): Promise<ResearchType> => {
    // var req = await fetch(env.backend_host + "/research/" + id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();
    const data = APIFetch(env.backend_host + "/research/" + id, {
        method: "GET"
    });

    return data
}