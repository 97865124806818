export function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}
export function setCookie(name: string, value: string, days: number) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  const domain =
    window.location.host === "localhost"
      ? "; path=/"
      : "; domain=." +
        window.location.hostname.split(".").slice(-2).join(".") +
        "; path=/";
  document.cookie = name + "=" + (value || "") + expires + domain;
}
export function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return undefined;
}
export function eraseCookie(name: string) {
  const domain =
    window.location.host === "localhost"
      ? "; path=/"
      : "; domain=." +
        window.location.hostname.split(".").slice(-2).join(".") +
        "; path=/";
  document.cookie = name + "=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;" + domain;
}
