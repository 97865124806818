
const colors = [
    ["rgba(240, 104, 79, 1)", "rgba(240, 104, 79, 0.75)", "rgba(240, 104, 79, 0.5)", "rgba(240, 104, 79, 0.25)", "#F0684F"],
    ["rgba(253, 168, 68, 1)", "rgba(253, 168, 68, 0.75)", "rgba(253, 168, 68, 0.5)", "rgba(253, 168, 68, 0.25)", "#FDA844"],
    ["rgba(254, 207, 60, 1)", "rgba(254, 207, 60, 0.75)", "rgba(254, 207, 60, 0.5)", "rgba(254, 207, 60, 0.25)", "#FECF3C"],
    ["rgba(135, 216, 54, 1)", "rgba(135, 216, 54, 0.75)", "rgba(135, 216, 54, 0.5)", "rgba(135, 216, 54, 0.25)", "#87D836"],
    ["rgba(109, 171, 242, 1)", "rgba(109, 171, 242, 0.75)", "rgba(109, 171, 242, 0.5)", "rgba(109, 171, 242, 0.25)", "#6DABF2"],
    ["rgba(192, 109, 242, 1)", "rgba(192, 109, 242, 0.75)", "rgba(192, 109, 242, 0.5)", "rgba(192, 109, 242, 0.25)", "#C06DF2"],
    ["rgba(236, 110, 216, 1)", "rgba(236, 110, 216, 0.75)", "rgba(236, 110, 216, 0.5)", "rgba(236, 110, 216, 0.25)", "#EC6ED8"],
    ["rgba(150, 75, 0, 1)", "rgba(150, 75, 0, 0.75)", "rgba(150, 75, 0, 0.5)", "rgba(150, 75, 0, 0.25)", "#964B00"],
];

const simpleGlobalCompetences = [
    ["CCL", "rgba(243, 129, 225, 1)"],
    ["CP", "rgba(200, 129, 243, 1)"],
    ["STEM", "rgba(240, 104, 79, 1)"],
    ["CD", "rgba(243, 191, 129, 1)"],
    ["CPSAA", "rgba(129, 182, 243, 1)"],
    ["CC", "rgba(186, 243, 129, 1)"],
    ["CCEC", "rgba(249, 212, 95, 1)"],
    ["CE", "rgba(129, 243, 243, 1)"],
];



export { colors, simpleGlobalCompetences };