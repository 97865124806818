import "./Shuttle.css";
import { useState, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { useTranslation } from "react-i18next";
import closeButton from "../../../assets/student/close_button.svg";
import beachIcon from "../../../assets/student/T2/biome_icons/beach.svg";
import desertIcon from "../../../assets/student/T2/biome_icons/desert.svg";
import forestIcon from "../../../assets/student/T2/biome_icons/forest.svg";
import grasslandIcon from "../../../assets/student/T2/biome_icons/grassland.svg";
import jungleIcon from "../../../assets/student/T2/biome_icons/jungle.svg";
import mountainIcon from "../../../assets/student/T2/biome_icons/mountain.svg";
import savannahIcon from "../../../assets/student/T2/biome_icons/savannah.svg";
import snowIcon from "../../../assets/student/T2/biome_icons/snow.svg";
import swampIcon from "../../../assets/student/T2/biome_icons/swamp.svg";
import volcanoIcon from "../../../assets/student/T2/biome_icons/volcano.svg";
import leafIcon from "../../../assets/student/T2/leaf_icon.svg";
import okIcon from "../../../assets/student/T2/ok_trade.svg";
import closeIcon from "../../../assets/student/T2/close_trade.svg";
import tradeIcon from "../../../assets/student/T2/trade_icon.svg";
import eutopiaLogo from "../../../assets/professor/Eutopia_Logo_FInal_2 1.png";
import useSound from "use-sound";

import {
  increaseTutorialEutonsStep,
  menu,
  points,
  updateMetrics,
} from "../../../reducers/studentSlice.ts";
import { IscreenSize } from "../../../interfaces.ts";
import { motion } from "framer-motion";
import { BiomeNameType } from "../../../_newapios/gamecontent/plots.ts";
import { BiomeType } from "../../../_newapios/gamecontent/biomes.ts";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import { EutonType } from "../../../_newapios/gamecontent/eutons.ts";
import { gibberish_converter } from "../../../utils/euton.ts";
import {
  ConvertItemAPI,
  GetMyTradeRequestsAPI,
  GetTradableItemsAPI,
  RequestItemTradeAPI,
  StudentEutonTradeType,
  StudentEutonType,
  UnlockStudentEutonAPI,
} from "../../../_newapios/progress/student_euton.ts";
import ReactPlayer from "react-player";
import { StudentType } from "../../../_newapios/user/student.ts";
import { hexToRGB } from "../../../utils/general.ts";
import { set } from "lodash";

const Shuttle = (props: { biome: BiomeNameType }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const mainState = useAppSelector((state) => state.main);
  const studentState = useAppSelector((state) => state.student);
  const loginState = useAppSelector((state) => state.login);
  const biomes = mainState.biomes;
  const eutons = mainState.eutons;

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const [selectedBiome, setSelectedBiome] = useState<BiomeType | null>(
    biomes[0]
  );
  const [debouncedSelectedBiome, setDebouncedSelectedBiome] =
    useState<BiomeType | null>(null);

  const [studentBiome, setStudentBiome] = useState<BiomeType>();
  const [selectedEuton, setSelectedEuton] = useState<EutonType | null>();
  const [flip, setFlip] = useState(true);
  const [itemSelection, setItemSelection] = useState<
    "none" | "select" | "trade"
  >("none");

  const gibberishNames = useMemo(() => {
    return eutons.map((euton) => gibberish_converter(euton.name));
  }, [eutons]);

  const gibberishDescriptions = useMemo(() => {
    return eutons.map((euton) =>
      gibberish_converter(
        t("c" + euton.tier + "_" + euton.biome.name + "_desc", { ns: "eutons" })
      )
    );
  }, [eutons]);

  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [studentEutonsClass, setStudentEutonsClass] = useState<
    StudentEutonType[]
  >([]);

  const [selectedEutonTrade, setSelectedEutonTrade] =
    useState<EutonType | null>(null);
  const [selectedStudentTrade, setSelectedStudentTrade] =
    useState<StudentType | null>(null);

  const [tradeRequests, setTradeRequests] = useState<StudentEutonTradeType[]>(
    []
  );
  const [eutonSoundUrl, setEutonSoundUrl] = useState<string | null>(null);

  // Hook to play the sound; it won't play unless a valid soundUrl is passed
  const [play] = useSound(eutonSoundUrl || "", {
    volume: mainState.volumeSounds,
  });

  const closeVideoHandler = () => {
    setVideoUrl(null);
  };

  const openVideoHandler = (url: string) => {
    setVideoUrl(url);
  };

  const calcBiomeIcon = (biomeName: BiomeNameType) => {
    var biomeIcon = beachIcon;
    switch (biomeName) {
      case "beach":
        biomeIcon = beachIcon;
        break;
      case "desert":
        biomeIcon = desertIcon;
        break;
      case "forest":
        biomeIcon = forestIcon;
        break;
      case "grassland":
        biomeIcon = grasslandIcon;
        break;
      case "jungle":
        biomeIcon = jungleIcon;
        break;
      case "mountain":
        biomeIcon = mountainIcon;
        break;
      case "savannah":
        biomeIcon = savannahIcon;
        break;
      case "snow":
        biomeIcon = snowIcon;
        break;
      case "swamp":
        biomeIcon = swampIcon;
        break;
      case "volcano":
        biomeIcon = volcanoIcon;
        break;
    }
    return biomeIcon;
  };

  const calcBiomeUnlockEutons = (biome: BiomeType) => {
    const biomeEutons = eutons.filter((euton) => euton.biome_id === biome.id);
    const biomeUnlockEutons = biomeEutons.filter(
      (euton) => euton.student_euton[0]?.euton_unlocked === false
    );
    return biomeUnlockEutons.length;
  };

  const selectBiomeHandler = async (biome: BiomeType) => {
    setDebouncedSelectedBiome(biome);
    setTimeout(() => {
      setDebouncedSelectedBiome(null);
    }, 500);

    const element = document.querySelector(`.euton-0-${biome.name}`);
    if (element instanceof HTMLElement) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    loadStudentBiome();
  }, [mainState.students, loginState.id]);

  const loadTradeRequests = async () => {
    const tempTradeRequests = await GetMyTradeRequestsAPI();
    setTradeRequests(tempTradeRequests);
  };
  useEffect(() => {
    loadTradeRequests();
  }, []);

  const loadStudentBiome = () => {
    const student = mainState.students.find(
      (stud) => stud.id === loginState.id
    );
    if (!student) return;
    const studentPlot = mainState.plots.find(
      (plot) => plot.id === student.plot_id
    );
    if (!studentPlot) return;
    const newStudentBiome = mainState.biomes.find(
      (biome) => biome.id === studentPlot.biome_id
    );
    if (!newStudentBiome) return;
    setStudentBiome(newStudentBiome);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    loadProgress();
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  // To wait for the cards to load before showing them
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (selectedEuton) {
      const url = selectedEuton.card_image.url;
      setImageUrl(url);
    }
  }, [selectedEuton]);

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => setImageLoaded(true);
    }
  }, [imageUrl]);

  // Effect to load the correct sound when the selectedEuton changes
  useEffect(() => {
    if (selectedEuton && selectedEuton.sound) {
      setEutonSoundUrl(selectedEuton.sound);
    } else {
      setEutonSoundUrl(null);
    }
  }, [selectedEuton]);

  useEffect(() => {
    const options = {
      root: document.getElementsByClassName("shuttle_eutons")[0],
      rootMargin: "0% 0% -30% 0%",
      threshold: 0.8,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target instanceof HTMLElement) {
          const biomeName = entry.target.getAttribute("data-biome");
          if (biomeName) {
            const biome = biomes.find((b) => b.name === biomeName);
            if (biome && !debouncedSelectedBiome) {
              setSelectedBiome(biome);
            }
          }
        }
      });
    }, options);

    document.querySelectorAll(".shuttle_euton ").forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, [biomes, debouncedSelectedBiome]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const loadProgress = async () => {
    dispatch(updateMetrics());
  };

  const closeButtonHandler = async () => {
    dispatch(menu("globalMap"));
  };

  const unlockEutonHandler = async (euton: EutonType) => {
    const studentEuton = euton.student_euton[0];
    if (!studentEuton) return;

    const items_available =
      studentEuton.items_obtained +
      studentEuton.items_purchased -
      (studentEuton.euton_unlocked ? 1 : 0) -
      studentEuton.items_converted -
      studentEuton.items_sold;

    if (items_available > 0 && !studentEuton.euton_unlocked) {
      await UnlockStudentEutonAPI(studentEuton.id);
      dispatch(updateMetrics());
      dispatch(increaseTutorialEutonsStep());

      openVideoHandler(euton.unlock_video.url);
    }
  };

  const restoreItemHandler = async () => {
    if (!selectedEuton) return;
    const studentEuton = selectedEuton.student_euton[0];
    if (!studentEuton) return;
    const items_available =
      studentEuton.items_obtained +
      studentEuton.items_purchased -
      (studentEuton.euton_unlocked ? 1 : 0) -
      studentEuton.items_converted -
      studentEuton.items_sold;
    if (items_available > 0) {
      await ConvertItemAPI(studentEuton.id);
      openVideoHandler(selectedEuton.biome.restore_video.url);
      setItemSelection("none");
      dispatch(updateMetrics());
    }
  };

  const openTradeMenuHandler = async () => {
    if (!selectedEuton) return;
    const studentEuton = selectedEuton.student_euton[0];
    if (!studentEuton) return;
    const items_available =
      studentEuton.items_obtained +
      studentEuton.items_purchased -
      (studentEuton.euton_unlocked ? 1 : 0) -
      studentEuton.items_converted -
      studentEuton.items_sold;
    if (items_available > 0) {
      const tempTradeableItems =
        (await GetTradableItemsAPI(studentEuton.id)) || [];
      setStudentEutonsClass(tempTradeableItems);
      setItemSelection("trade");
    }
  };

  const tradeItemHandler = async (studentId: string, eutonId: string) => {
    if (!selectedEuton) return;
    const studentEuton = selectedEuton.student_euton[0];
    if (!studentEuton) return;
    const itemsAvailable =
      studentEuton.items_obtained +
      studentEuton.items_purchased -
      (studentEuton.euton_unlocked ? 1 : 0) -
      studentEuton.items_converted -
      studentEuton.items_sold;

    const tradableEuton = studentEutonsClass.find(
      (se) => se.student_id === studentId && se.euton_id === eutonId
    );
    if (!tradableEuton) return;
    const tradeableItemsAvailable =
      tradableEuton.items_obtained +
      tradableEuton.items_purchased -
      (tradableEuton.euton_unlocked ? 1 : 0) -
      tradableEuton.items_converted -
      tradableEuton.items_sold;

    if (itemsAvailable > 0 && tradeableItemsAvailable > 0) {
      await RequestItemTradeAPI(studentEuton.id, tradableEuton.id);
    }
  };

  const handleConfirmTrade = (studentId: string, eutonId: string) => {
    tradeItemHandler(studentId, eutonId);
    setSelectedStudentTrade(null);
    setSelectedEutonTrade(null);
  };

  const handleCloseTrade = () => {
    setSelectedStudentTrade(null);
    setSelectedEutonTrade(null);
  };



  const getItemsObtained = (studentId: string, eutonId: string) => {
    const studentEuton = studentEutonsClass.find(
      (se) => se.student_id === studentId && se.euton_id === eutonId
    );
    if (!studentEuton) return;
    const items_available =
      studentEuton.items_obtained +
      studentEuton.items_purchased -
      (studentEuton.euton_unlocked ? 1 : 0) -
      studentEuton.items_converted -
      studentEuton.items_sold;
    return studentEuton ? items_available : 0;
  };
  const renderConfirmTradeMenu = (student: StudentType, euton: EutonType) => {

    const itemsAvailable = getItemsObtained(student.id, euton.id) || 0;
    const studentEuton = euton.student_euton[0];
    const itemsObtained = studentEuton ? studentEuton.items_obtained : 0;
    return (
      <div
        className="shuttle_trade_menu_confirm_container"
        onClick={() => handleCloseTrade()}
      >
        <div className="shuttle_trade_menu_confirm_container_grid">
          <div className="shuttle_trade_menu_confirm_header_text">
            <div className="text_white_mediumish_bold">
              {t("propose_exchange")}
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "1vh" }}>
              <div className="text_white_small_bold">{t("exchange_with")}</div>
              <div className="text_yellow_small_bold">{student.username}</div>
            </div>
          </div>

          <div className="shuttle_trade_menu_confirm_items_grid">
            <div className="shuttle_trade_menu_confirm_items_offer">
              <div className="text_yellow_very_small_bold">
                {t("exchange_requests_header_4")}
              </div>
              <div
                className="shuttle_trade_menu_confirm_items_offer_badge"
                style={{ backgroundColor: selectedEuton?.biome.color + "CC" }}
              >
                <img
                  src={selectedEuton?.item_image.url}
                  alt="item_image"
                  style={{ maxHeight: "8vh" }}
                />
                <div
                  className="text_white_very_small_bold"
                  style={{ marginLeft: "5%" }}
                >
                  {t(
                    "c" +
                    selectedEuton?.tier +
                    "_" +
                    selectedEuton?.biome.name +
                    "_item_name",
                    { ns: "eutons" }
                  )}

                </div>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1vh" }}
              >
                <div className="text_white_very_small_bold">
                  {t("you_have")}
                </div>
                <div className="text_yellow_very_small_bold">
                  {itemsAvailable}
                </div>
                <div className="text_white_very_small_bold">
                  {t("of_this_item")}
                </div>
              </div>
            </div>
            <div
              className="center_container"
              style={{ height: "60%", marginTop: "10%" }}
            >
              <img className="image" src={tradeIcon} alt="trade" />
            </div>
            <div className="shuttle_trade_menu_confirm_items_offer">
              <div className="text_yellow_very_small_bold">
                {t("exchange_requests_header_5")}
              </div>
              <div
                className="shuttle_trade_menu_confirm_items_offer_badge"
                style={{ backgroundColor: euton.biome.color + "CC" }}
              >
                <img
                  src={euton.item_image.url}
                  alt="item_image"
                  style={{ maxHeight: "8vh" }}
                />
                <div
                  className="text_white_very_small_bold"
                  style={{ marginLeft: "5%" }}
                >
                  {t(
                    "c" + euton?.tier + "_" + euton?.biome.name + "_item_name",
                    { ns: "eutons" }
                  )}
                  {/* {euton.item_name} */}
                </div>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1vh" }}
              >
                <div className="text_white_very_small_bold">
                  {t("you_have")}
                </div>
                <div className="text_yellow_very_small_bold">
                  {itemsObtained}
                </div>
                <div className="text_white_very_small_bold">
                  {t("of_this_item")}
                </div>
              </div>
            </div>
          </div>

          <div className="shuttle_trade_menu_confirm_buttons">
            <motion.div
              className="shuttle_trade_menu_confirm_button_yes"
              whileHover={{ scale: 1.1 }}
              onClick={() => handleConfirmTrade(student.id, euton.id)}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <img className="image" src={okIcon} alt="ok" />
            </motion.div>

            <motion.div
              className="shuttle_trade_menu_confirm_button_no"
              whileHover={{ scale: 1.1 }}
              onClick={() => handleCloseTrade()}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <img className="image" src={closeIcon} alt="close" />
            </motion.div>
          </div>
        </div>
      </div>
    );
  };

  const renderVideo = () => {
    if (videoUrl === null) return null;
    return (
      <div className="shuttle_unlock_video">
        <ReactPlayer
          width="80%"
          height="100%"
          url={videoUrl}
          onEnded={() => closeVideoHandler()}
          onError={() => closeVideoHandler()}
          playing={true}
          controls={false}
        />
      </div>
    );
  };

  const renderBanner = () => {
    return (
      <div className="shuttle_banner_container">
        <img
          className="banner"
          src={
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/banner_shuttle.png"
          }
          alt="banner"
        />
      </div>
    );
  };

  const renderCloseButton = () => {
    return (
      <motion.div
        className="shuttle_close_button"
        whileHover={{ scale: 1.2, rotate: 180 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        onClick={() => closeButtonHandler()}
        style={{
          borderRadius: "50%",
          backgroundColor: "white",
        }}
      >
        <div className="center_container">
          <img className="image" src={closeButton} alt="close" />
        </div>
      </motion.div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="shuttle_title">
        <div className="left_container">
          <div className="text_white_very_big_bold">{t("shuttle")}</div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="shuttle_background">
        <div className="shuttle_main">
          {renderBiomes()}
          {renderEutons()}
        </div>
      </div>
    );
  };

  const renderBiomes = () => {
    return (
      <div className="shuttle_biomes">
        {biomes.map((biome, index) => renderBiome(biome, index))}
      </div>
    );
  };

  const renderBiome = (biome: BiomeType, index: number) => {
    var biomeIcon = calcBiomeIcon(biome.name);
    const biomeUnlockEutons = calcBiomeUnlockEutons(biome);
    return (
      <div
        className="shuttle_biome"
        onClick={() => selectBiomeHandler(biome)}
        key={index}
      >
        <div className="shuttle_biome_icon_container">
          <motion.img
            src={biomeIcon}
            alt="biome"
            style={{
              opacity: selectedBiome === biome ? 1 : 0.25,
              maxHeight: "3.5vh",
            }}
            whileHover={{
              scale: 1.2,
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            transition={{ type: "spring", stiffness: 500, damping: 30 }}
          />
          {biomeUnlockEutons > 0 && (
            <div className="shuttle_biome_badge">
              <div className="center_container">{biomeUnlockEutons}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderEutons = () => {
    return (
      <div className="shuttle_eutons">
        {eutons.map((euton, index) => {
          const items_available =
            euton.student_euton[0]?.items_obtained +
            euton.student_euton[0]?.items_purchased -
            (euton.student_euton[0]?.euton_unlocked ? 1 : 0) -
            euton.student_euton[0]?.items_converted -
            euton.student_euton[0]?.items_sold || 0;
          if (euton.student_euton[0]?.euton_unlocked) {
            return renderEuton(euton, index, items_available);
          } else {
            return renderEutonLocked(euton, index, items_available);
          }
        })}
      </div>
    );
  };

  const renderEutonLocked = (
    euton: EutonType,
    index: number,
    items_available: number
  ) => {
    const eutonClassName = `shuttle_euton euton-0-${euton.biome.name}`;

    const gibberishName = gibberishNames[index];
    const gibberishDescription = gibberishDescriptions[index];
    return (
      <div
        className={eutonClassName}
        style={{
          boxShadow:
            items_available > 0
              ? "0px 0px 10px 0px " + euton.biome.color
              : "none",
          background:
            items_available > 0
              ? "linear-gradient(to left, " + euton.biome.color + ", #FFFFFF)"
              : "#FFFFFF",
          borderLeft: "10px solid " + euton.biome.color,
        }}
        key={index}
        data-biome={euton.biome.name}
      >
        <img
          className="image"
          src={
            euton.image_locked?.url ||
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Eutons/Siluetas/Bosque/c1_bosque_silueta.png"
          }
          alt="euton"
          style={{ height: "fit-content", width: "fit-content" }}
        // onClick={() => openVideoHandler(euton.unlock_video.url)}
        />
        <div className="shuttle_euton_name_description">
          <div
            className="shuttle_euton_name_text"
            style={{
              color: euton.biome.color,
            }}
          >
            <b>{gibberishName}</b>
          </div>

          <div
            className="text_black_very_small"
            style={{
              height: "3vh",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
            }}
          >
            {gibberishDescription}
          </div>

          <motion.div
            className="shuttle_euton_button"
            style={
              items_available > 0
                ? {
                  backgroundColor: "#50F0C0",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }
                : {
                  backgroundColor: "#D9D9D9",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto',
                }
            }
            whileHover={
              items_available > 0
                ? { scale: 1.05, boxShadow: "0px 0px 10px 0px #50F0C0" }
                : undefined
            }
            transition={{ type: "spring", stiffness: 500, damping: 30 }}
            onClick={() => unlockEutonHandler(euton)}
          >
            <div className="center_container">
              <div className="text_white_really_small_bold">
                {t("unlock_euton")}
              </div>
            </div>
          </motion.div>
        </div>
        <div className="shuttle_euton_item">
          <img
            className="image"
            src={euton.item_image?.url}
            alt="euton_item"
            style={{ height: "10vh", width: "fit-content", left: "1vh" }}
          />
          <div className="shuttle_euton_item_text">
            <div
              style={{
                color: euton.biome.color,
                backgroundColor: "#EFEFEF",
                width: "fit-content",
                height: "fit-content",
                borderRadius: "5px",
                boxSizing: "border-box",
                paddingLeft: "0.5vh",
                paddingRight: "0.5vh",
                fontSize: "calc(5px + 1vw)",
                fontFamily: "Causten",
                fontWeight: "bold",
              }}
            >
              {"x" + items_available || 0}
            </div>
            <div className="text_black_very_small_bold"> {t("items")}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderEuton = (
    euton: EutonType,
    index: number,
    items_available: number
  ) => {
    const eutonClassName = `shuttle_euton euton-0-${euton.biome.name}`;

    return (
      <div
        className={eutonClassName}
        key={index}
        style={{
          borderLeft: "10px solid " + euton.biome.color,
        }}
      >
        <img
          className="image"
          src={euton.image?.url}
          alt="euton"
          style={{ height: "fit-content", width: "fit-content" }}
        />
        <div className="shuttle_euton_name_description">
          <div
            className="shuttle_euton_name_text"
            style={{
              color: euton.biome.color,
            }}
          >
            <b>{euton.name}</b>
          </div>

          <div
            className="text_black_very_small"
            style={{
              lineHeight: "2.5vh",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
            }}
          >
            {t("c" + euton.tier + "_" + euton.biome.name + "_desc", {
              ns: "eutons",
            })}
          </div>

          <motion.div
            className="shuttle_euton_button"
            style={{ backgroundColor: "#FECF3C" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 0px 10px 0px #FECF3C" }}
            transition={{ type: "spring", stiffness: 500, damping: 30 }}
            onClick={() => {
              setSelectedEuton(euton);
              // setUnlockVideoUrl(euton.unlock_video?.url)
            }}
          >
            <div className="center_container">
              <div className="text_white_really_small_bold">
                {" "}
                {t("see_euton")}
              </div>
            </div>
          </motion.div>
        </div>
        <div className="shuttle_euton_item">
          <img
            className="image"
            src={euton.item_image?.url}
            alt="euton_item"
            style={{ height: "10vh", width: "fit-content" }}
          />
          <div className="shuttle_euton_item_text">
            <div
              style={{
                color: euton.biome.color,
                backgroundColor: "#EFEFEF",
                width: "fit-content",
                height: "fit-content",
                borderRadius: "5px",
                boxSizing: "border-box",
                paddingLeft: "0.5vh",
                paddingRight: "0.5vh",
                fontSize: "calc(5px + 1vw)",
                fontFamily: "Causten",
                fontWeight: "bold",
              }}
            >
              {" "}
              {"x" + items_available}
            </div>
            <div className="text_black_very_small_bold"> {t("items")}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderEutonVideo = () => {
    return (
      <motion.div
        className="shuttle_euton_video_container"
        transition={{
          duration: 0.7,
        }}
        animate={{
          y: flip ? "0vh" : "-100vh",
        }}
        onClick={() => {
          play();
        }}
      >
        {selectedEuton?.threed_video?.url ? <img src={selectedEuton?.threed_video?.url} alt="3d_video" />
          : <div />}
      </motion.div>
    );
  };

  const biomeTranslations = {
    forest: "bosque",
    desert: "desierto",
    swamp: "pantano",
    mountain: "montaña",
    savannah: "sabana",
    jungle: "jungla",
    grassland: "pradera",
    volcano: "volcan",
    beach: "playa",
    snow: "nieve",
  };

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderEutonInfo = () => {
    if (!selectedEuton) return null;

    return (
      <div className="shuttle_euton_info">
        <motion.div
          className="shuttle_euton_back_button"
          onClick={() => {
            setSelectedEuton(null);
            setFlip(true);
            setImageLoaded(false);
            setImageUrl(null);
          }}
          whileHover={{ scale: 1.05, boxShadow: "0px 0px 10px 0px #FECF3C" }}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
        >
          <BackArrow color={"white"} size={"4vh"} />
          <div className="text_white_small_bold">{t("go_back")}</div>
        </motion.div>
        {renderEutonVideo()}
        <motion.div
          className="shuttle_euton_info_card"
          transition={{
            duration: 0.7,
          }}
          animate={{ bottom: flip ? "-25%" : "10%" }}
          onClick={() => setFlip((prevState) => !prevState)}
        >
          {flip === false && (
            <motion.div
              transition={{ duration: 0.5 }}
              initial={{ rotateY: 180 }}
              animate={{ rotateY: flip ? 180 : 0 }}
              whileHover={{
                scale: 1.05,
                // boxShadow: "0px 0px 10px 0px #6DF2F2",
              }}
              className="shuttle_euton_info_card_front"
              style={{
                // backgroundImage: `url('https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Eutons/Cartas/${capitalizeFirstLetter(biomeTranslations[selectedEuton.biome.name])}/c${selectedEuton.tier}_${biomeTranslations[selectedEuton.biome.name]}_carta.jpg')`,
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top center",
              }}
            >
              {imageLoaded && (
                <div className="shuttle_euton_info_card_front_desc">
                  <div
                    className={
                      t(
                        "c" +
                        selectedEuton.tier +
                        "_" +
                        selectedEuton.biome.name +
                        "_desc",
                        { ns: "eutons" }
                      ).length > 300
                        ? "text_white_mega_small_bold"
                        : t(
                          "c" +
                          selectedEuton.tier +
                          "_" +
                          selectedEuton.biome.name +
                          "_desc",
                          { ns: "eutons" }
                        ).length > 200
                          ? "text_white_super_small_bold"
                          : "text_white_very_small_bold"
                    }
                  >
                    {t(
                      "c" +
                      selectedEuton.tier +
                      "_" +
                      selectedEuton.biome.name +
                      "_desc",
                      { ns: "eutons" }
                    )}
                  </div>
                </div>
              )}
            </motion.div>
          )}

          {flip === true && (
            <motion.div
              transition={{ duration: 0.5 }}
              initial={{ rotateY: 180 }}
              animate={{ rotateY: flip ? 0 : 180 }}
              whileHover={{
                scale: 1.05,
                // boxShadow: "0px 0px 10px 0px #6DF2F2",
              }}
              className="shuttle_euton_info_card_back"
            >
              {/* <img className="image" src={eutopiaLogo} alt="image" /> */}
            </motion.div>
          )}
        </motion.div>

        <motion.div
          className="shuttle_euton_items_button"
          onClick={() => setItemSelection("select")}
          whileHover={{ scale: 1.05, boxShadow: "0px 0px 10px 0px #50F0C0" }}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
        >
          <img
            className="image"
            src={leafIcon}
            alt="leaf"
            style={{ maxHeight: "3vh" }}
          />
          <div className="text_white_small_bold">{"Ítems"}</div>
        </motion.div>
      </div>
    );
  };

  const renderItems = () => {
    return (
      <div className="shuttle_euton_info">
        <motion.div
          className="shuttle_euton_back_button"
          onClick={() => {
            setItemSelection("none");
            setFlip(true);
          }}
          whileHover={{ scale: 1.05, boxShadow: "0px 0px 10px 0px #FECF3C" }}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
        >
          <BackArrow color={"white"} size={"4vh"} />
          <div className="text_white_small_bold">{t("go_back")}</div>
        </motion.div>
        {/* <motion.div
          className="shuttle_item_trade"
          whileHover={{ scale: 1.03, boxShadow: "0px 0px 10px 0px #FECF3C" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          onClick={() => openTradeMenuHandler()}
        >
          <img
            className="image"
            src={leafIcon}
            alt="leaf"
            style={{ maxHeight: "3vh" }}
          />
          <div className="text_white_small_bold">{t("trade")}</div>
        </motion.div> */}
        <motion.div
          className="shuttle_item_restore"
          whileHover={{ scale: 1.03, boxShadow: "0px 0px 10px 0px #50F0C0" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          onClick={() => restoreItemHandler()}
        >
          <img
            className="image"
            src={leafIcon}
            alt="leaf"
            style={{ maxHeight: "3vh" }}
          />
          <div className="text_white_small_bold">{t("restore_biome")}</div>
        </motion.div>
      </div>
    );
  };

  const renderTradeMenu = () => {
    if (!selectedEuton) return null;

    // Filter out the selected Euton's biome from the list
    const filteredBiomes = biomes.filter(
      (biome) => biome.name !== selectedEuton.biome.name
    );
    const filteredEutons = eutons.filter(
      (euton) => euton.biome.name !== selectedEuton.biome.name
    );

    return (
      <div className="shuttle_trade_menu_background">
        <motion.div
          className="shuttle_euton_back_button"
          onClick={() => {
            setItemSelection("none");
            setFlip(true);
          }}
          whileHover={{ scale: 1.05, boxShadow: "0px 0px 10px 0px #FECF3C" }}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
        >
          <BackArrow color={"white"} size={"4vh"} />
          <div className="text_white_small_bold">{t("go_back")}</div>
        </motion.div>
        <div className="shuttle_trade_menu">
          <div className="shuttle_trade_menu_biomes">
            {filteredEutons
              .filter((euton) => euton.tier === selectedEuton.tier)
              .map((euton, index) => (
                <div
                  className="shuttle_trade_menu_biome"
                  key={euton.id}
                  style={{}}
                >
                  <div className="center_container">
                    <img
                      src={calcBiomeIcon(euton.biome.name)}
                      alt="biome"
                      style={{
                        maxHeight: "3.5vh",
                      }}
                    />
                  </div>
                  {renderTradeItemHeader(euton, index)}
                </div>
              ))}
          </div>
          <div className="shuttle_trade_menu_students">
            {mainState.students
              .filter((student) => student.id !== loginState.id)
              .map((student, index) => (
                <div className="shuttle_trade_menu_students_container">
                  <div className="shuttle_trade_menu_students_names">
                    {renderStudentIcon(student)}
                    {renderStudentName(student)}
                  </div>
                  <div className="shuttle_trade_menu_students_buttons">
                    {filteredEutons
                      .filter((euton) => euton.tier === selectedEuton.tier)
                      .map((euton, eutonIndex) => {
                        const itemsAvailable =
                          getItemsObtained(student.id, euton.id) || 0;

                        const tradeExists = tradeRequests.some(
                          (trade) =>
                            trade.seller.student_id === loginState.id &&
                            trade.seller.euton_id === selectedEuton.id &&
                            trade.buyer.student_id === student.id &&
                            trade.buyer.euton_id === euton.id
                        );

                        return (
                          <div className="center_container">
                            <motion.div
                              style={{
                                backgroundColor: tradeExists
                                  ? "#FBC412"
                                  : itemsAvailable > 0
                                    ? "#50F0C0"
                                    : "#D9D9D9",
                                cursor: tradeExists
                                  ? "not-allowed"
                                  : itemsAvailable > 0
                                    ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto'
                                    : "not-allowed",
                                height: "fit-content",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "5%",
                                width: "fit-content",
                                borderRadius: "5px",
                              }}
                              whileHover={{
                                scale: tradeExists
                                  ? 1
                                  : itemsAvailable > 0
                                    ? 1.05
                                    : 1,
                              }}
                              transition={{
                                type: "spring",
                                stiffness: 500,
                                damping: 30,
                              }}
                              onClick={() => {
                                if (itemsAvailable > 0 && !tradeExists) {
                                  setSelectedEutonTrade(euton);
                                  setSelectedStudentTrade(student);
                                }
                              }}
                            >
                              <div className="text_white_super_small">
                                {tradeExists ? t("requested") : t("change")}
                              </div>
                            </motion.div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const renderStudentIcon = (student: StudentType) => {
    return (
      <div className="center_container">
        <div className="shuttle_menu_student_icon">
          <div className="center_container">
            <div className="text_white_small_bold">
              {student.username.charAt(0).toUpperCase()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStudentName = (student: StudentType) => {
    return (
      <div className="left_container">
        <div className="text_black_small">{student.username}</div>
      </div>
    );
  };

  const renderTradeItemHeader = (euton: EutonType, index: number) => {
    const studentEuton = euton.student_euton[0];
    const itemsObtained = studentEuton ? studentEuton.items_obtained : 0;
    return (
      <div
        className="shuttle_trade_menu_item_background"
        key={index}
        style={{ backgroundColor: euton.biome.color + "80" }}
      >
        <div className="shuttle_trade_menu_item_cell">
          <img
            src={euton.item_image.url}
            alt="item_image"
            style={{ maxHeight: "10vh" }}
          />
          {itemsObtained > 0 && (
            <div className="shuttle_biome_badge">
              <div className="center_container">{itemsObtained}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderTradeItemCell = (euton: EutonType, index: number) => {
    return (
      <div className="shuttle_trade_menu_item_cell" key={index}>
        <div className="shuttle_trade_menu_item_cell_image">
          <img src={euton.item_image.url} alt="item_image" />
        </div>
      </div>
    );
  };

  return (
    <div className="shuttle">
      {renderBanner()}
      {renderMain()}
      {renderCloseButton()}
      {renderTitle()}
      {selectedEuton && itemSelection === "none" && renderEutonInfo()}
      {itemSelection === "select" && renderItems()}
      {itemSelection === "trade" && renderTradeMenu()}
      {videoUrl !== null && renderVideo()}
      {selectedStudentTrade !== null &&
        selectedEutonTrade !== null &&
        renderConfirmTradeMenu(selectedStudentTrade, selectedEutonTrade)}
    </div>
  );
};

export default Shuttle;
