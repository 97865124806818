import { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { Group } from "three";
import { AssetType } from "../../../../_newapios/gamecontent/house_upgrades.ts";

import { DreiGLTF } from "../../../../interfaces.ts";
import { StaticEutonProps } from "../Biospheres/EutonStatic.tsx";

const EutopoStatic = ({
  groupProps,
  propsAsset,
  renderShadow,
}: StaticEutonProps) => {
  const [asset, setAsset] = useState<AssetType>(propsAsset);
  const Eutopo = useGLTF(asset.gltf.url) as DreiGLTF;
  const groupRef = useRef<Group>(null);
  const { actions } = useAnimations(Eutopo.animations, groupRef);

  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      for (let i = 0; i < Object.keys(actions).length; i++) {
        const action = Object.keys(actions)[i];
        actions[action]?.reset().play();
      }
    }
  }, [actions]);

  return (
    <group ref={groupRef} dispose={null}>
      <group
        name="Scene"
        position={groupProps?.position}
        rotation={groupProps?.rotation}
      >
        <group
          name="helmet_static_gltf"
          position={[-0.025, 1.35, -0.048]}
          scale={1.375}
        >
          <mesh
            name="helmet"
            castShadow
            receiveShadow
            geometry={Eutopo.nodes.helmet.geometry}
            material={Eutopo.materials.casco}
            morphTargetDictionary={Eutopo.nodes.helmet.morphTargetDictionary}
            morphTargetInfluences={Eutopo.nodes.helmet.morphTargetInfluences}
          />
          <mesh
            name="helmet_1"
            castShadow
            receiveShadow
            geometry={Eutopo.nodes.helmet_1.geometry}
            material={Eutopo.materials.metal}
            morphTargetDictionary={Eutopo.nodes.helmet_1.morphTargetDictionary}
            morphTargetInfluences={Eutopo.nodes.helmet_1.morphTargetInfluences}
          />
        </group>
        <group
          name="glasses_static_gltf"
          position={[-0.03, 1.315, -0.007]}
          scale={1.411}
        >
          <mesh
            name="glasses"
            castShadow
            receiveShadow
            geometry={Eutopo.nodes.glasses.geometry}
            material={Eutopo.materials.cristal_gafas}
            morphTargetDictionary={Eutopo.nodes.glasses.morphTargetDictionary}
            morphTargetInfluences={Eutopo.nodes.glasses.morphTargetInfluences}
          />
          <mesh
            name="glasses_1"
            castShadow
            receiveShadow
            geometry={Eutopo.nodes.glasses_1.geometry}
            material={Eutopo.materials.montura_gafas}
            morphTargetDictionary={Eutopo.nodes.glasses_1.morphTargetDictionary}
            morphTargetInfluences={Eutopo.nodes.glasses_1.morphTargetInfluences}
          />
        </group>
        <group
          name="mole_static_gltf"
          position={[-0.034, 1.675, 0.291]}
          scale={2.218}
        >
          <mesh
            name="mole"
            castShadow
            receiveShadow
            geometry={Eutopo.nodes.mole.geometry}
            material={Eutopo.materials.cuerpo}
            morphTargetDictionary={Eutopo.nodes.mole.morphTargetDictionary}
            morphTargetInfluences={Eutopo.nodes.mole.morphTargetInfluences}
          />
          <mesh
            name="mole_1"
            castShadow
            receiveShadow
            geometry={Eutopo.nodes.mole_1.geometry}
            material={Eutopo.materials.metal}
            morphTargetDictionary={Eutopo.nodes.mole_1.morphTargetDictionary}
            morphTargetInfluences={Eutopo.nodes.mole_1.morphTargetInfluences}
          />
          <mesh
            name="mole_2"
            castShadow
            receiveShadow
            geometry={Eutopo.nodes.mole_2.geometry}
            material={Eutopo.materials.nariz_patas}
            morphTargetDictionary={Eutopo.nodes.mole_2.morphTargetDictionary}
            morphTargetInfluences={Eutopo.nodes.mole_2.morphTargetInfluences}
          />
          <mesh
            name="mole_3"
            castShadow
            receiveShadow
            geometry={Eutopo.nodes.mole_3.geometry}
            material={Eutopo.materials.barriguita}
            morphTargetDictionary={Eutopo.nodes.mole_3.morphTargetDictionary}
            morphTargetInfluences={Eutopo.nodes.mole_3.morphTargetInfluences}
          />
        </group>
      </group>
    </group>
  );
};

export default EutopoStatic;
