import { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { Group } from "three";
import { AssetType } from "../../../../_newapios/gamecontent/house_upgrades.ts";

import { DreiGLTF } from "../../../../interfaces.ts";
import { StaticEutonProps } from "../Biospheres/EutonStatic.tsx";

const SkippyWalking = ({
  groupProps,
  propsAsset,
  renderShadow,
}: StaticEutonProps) => {
  const [asset, setAsset] = useState<AssetType>(propsAsset);
  const Skippy = useGLTF(asset.gltf.url) as DreiGLTF;
  const groupRef = useRef<Group>(null);
  const { actions } = useAnimations(Skippy.animations, groupRef);

  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      for (let i = 0; i < Object.keys(actions).length; i++) {
        const action = Object.keys(actions)[i];
        actions[action]?.reset().play();
      }
    }
  }, [actions]);

  return (
    <group ref={groupRef} dispose={null}>
      <group
        name="Scene"
        position={groupProps?.position}
        rotation={groupProps?.rotation}
      >
        <group
          name="glasses_walking_gltf"
          position={[0.005, 3.025, 14.029]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={11.56}
        >
          <mesh
            name="glasses_walking"
            castShadow
            receiveShadow
            geometry={Skippy.nodes.glasses_walking.geometry}
            material={Skippy.materials.marron}
            morphTargetDictionary={
              Skippy.nodes.glasses_walking.morphTargetDictionary
            }
            morphTargetInfluences={
              Skippy.nodes.glasses_walking.morphTargetInfluences
            }
          />
          <mesh
            name="glasses_walking_1"
            castShadow
            receiveShadow
            geometry={Skippy.nodes.glasses_walking_1.geometry}
            material={Skippy.materials.gafas_montura}
            morphTargetDictionary={
              Skippy.nodes.glasses_walking_1.morphTargetDictionary
            }
            morphTargetInfluences={
              Skippy.nodes.glasses_walking_1.morphTargetInfluences
            }
          />
          <mesh
            name="glasses_walking_2"
            castShadow
            receiveShadow
            geometry={Skippy.nodes.glasses_walking_2.geometry}
            material={Skippy.materials.gafas}
            morphTargetDictionary={
              Skippy.nodes.glasses_walking_2.morphTargetDictionary
            }
            morphTargetInfluences={
              Skippy.nodes.glasses_walking_2.morphTargetInfluences
            }
          />
        </group>
        <group
          name="leaping_fish_walking_gltf"
          position={[0.146, 3.463, 14.534]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={11.958}
        >
          <mesh
            name="leaping_fish_walking"
            castShadow
            receiveShadow
            geometry={Skippy.nodes.leaping_fish_walking.geometry}
            material={Skippy.materials.ojos}
            morphTargetDictionary={
              Skippy.nodes.leaping_fish_walking.morphTargetDictionary
            }
            morphTargetInfluences={
              Skippy.nodes.leaping_fish_walking.morphTargetInfluences
            }
          />
          <mesh
            name="leaping_fish_walking_1"
            castShadow
            receiveShadow
            geometry={Skippy.nodes.leaping_fish_walking_1.geometry}
            material={Skippy.materials.body}
            morphTargetDictionary={
              Skippy.nodes.leaping_fish_walking_1.morphTargetDictionary
            }
            morphTargetInfluences={
              Skippy.nodes.leaping_fish_walking_1.morphTargetInfluences
            }
          />
          <mesh
            name="leaping_fish_walking_2"
            castShadow
            receiveShadow
            geometry={Skippy.nodes.leaping_fish_walking_2.geometry}
            material={Skippy.materials.body_texture}
            morphTargetDictionary={
              Skippy.nodes.leaping_fish_walking_2.morphTargetDictionary
            }
            morphTargetInfluences={
              Skippy.nodes.leaping_fish_walking_2.morphTargetInfluences
            }
          />
          <mesh
            name="leaping_fish_walking_3"
            castShadow
            receiveShadow
            geometry={Skippy.nodes.leaping_fish_walking_3.geometry}
            material={Skippy.materials.body01}
            morphTargetDictionary={
              Skippy.nodes.leaping_fish_walking_3.morphTargetDictionary
            }
            morphTargetInfluences={
              Skippy.nodes.leaping_fish_walking_3.morphTargetInfluences
            }
          />
          <mesh
            name="leaping_fish_walking_4"
            castShadow
            receiveShadow
            geometry={Skippy.nodes.leaping_fish_walking_4.geometry}
            material={Skippy.materials.alas}
            morphTargetDictionary={
              Skippy.nodes.leaping_fish_walking_4.morphTargetDictionary
            }
            morphTargetInfluences={
              Skippy.nodes.leaping_fish_walking_4.morphTargetInfluences
            }
          />
          <mesh
            name="leaping_fish_walking_5"
            castShadow
            receiveShadow
            geometry={Skippy.nodes.leaping_fish_walking_5.geometry}
            material={Skippy.materials.cola_atras}
            morphTargetDictionary={
              Skippy.nodes.leaping_fish_walking_5.morphTargetDictionary
            }
            morphTargetInfluences={
              Skippy.nodes.leaping_fish_walking_5.morphTargetInfluences
            }
          />
          <mesh
            name="leaping_fish_walking_6"
            castShadow
            receiveShadow
            geometry={Skippy.nodes.leaping_fish_walking_6.geometry}
            material={Skippy.materials.linterna}
            morphTargetDictionary={
              Skippy.nodes.leaping_fish_walking_6.morphTargetDictionary
            }
            morphTargetInfluences={
              Skippy.nodes.leaping_fish_walking_6.morphTargetInfluences
            }
          />
        </group>
      </group>
    </group>
  );
};

export default SkippyWalking;
