const UsersIcon = ({ ...props }) => {
  return (
    <svg
      width={props.size ? props.size : "47"}
      height={props.size ? props.size : "49"}
      viewBox="0 0 47 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="contact-book">
        <path
          id="Path 2"
          d="M16 6V47"
          stroke={props.color ? props.color : "black"}
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          id="Rectangle 2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M43.9999 6V47H10C8.89543 47 8 46.1046 8 45V8C8 6.89543 8.89543 6 10 6H43.9999Z"
          stroke={props.color ? props.color : "black"}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Stroke 1"
          d="M20 35C20 31.7097 25.2778 32.2581 27.3889 30.0645C28.4444 28.9677 25.2778 28.9677 25.2778 23.4839C25.2778 19.8283 26.6848 18 29.5 18C32.3152 18 33.7222 19.8283 33.7222 23.4839C33.7222 28.9677 30.5556 28.9677 31.6111 30.0645C33.7222 32.2581 39 31.7097 39 35"
          stroke={props.color ? props.color : "black"}
          stroke-width="3"
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
};

export default UsersIcon;
