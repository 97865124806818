import "./BusinessView.css";
import { useState, useEffect } from "react";
import BVUsers from "./BVUsers";
import BVStats from "./BVStats";
import { m, LazyMotion } from "framer-motion";
import List from "../../../assets/professor/format-list-bulleted.tsx";
import CalendarIcon from "../../../assets/professor/calendar_icon";
import UsersIcon from "../../../assets/professor/contact-book.tsx";
import StatsIcon from "../../../assets/professor/chart.tsx";
import {
  BusinessTeacherType,
  GetAllLoginsAPI,
  GetAllTeachersAPI,
  LoginsType,
} from "../../../_newapios/user/teacher.ts";
import {
  BusinessSchoolType,
  GetAllSchoolsAPI,
} from "../../../_newapios/user/school.ts";
import Loading from "../../loading_screen/LoadingDots.tsx";
import {
  BusinessStudentType,
  GetAllStudentsAPI,
} from "../../../_newapios/user/student.ts";

const BusinessView = ({ ...props }) => {
  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const [view, setView] = useState("loading");
  const [logins, setLogins] = useState<LoginsType[]>([]);
  const [teachers, setTeachers] = useState<BusinessTeacherType[]>([]);
  const [schools, setSchools] = useState<BusinessSchoolType[]>([]);
  const [students, setStudents] = useState<BusinessStudentType[]>([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const promises = [
      GetAllTeachersAPI().then((res) => setTeachers(res)),
      GetAllLoginsAPI().then((res) => setLogins(res)),
      GetAllSchoolsAPI().then((res) => setSchools(res)),
      GetAllStudentsAPI().then((res) => setStudents(res)),
    ];
    Promise.all(promises).then((res) => {
      setView("users");
    });
  };

  const changeView = () => {
    if (view === "users") setView("stats");
    else setView("users");
  };

  const renderViewButtons = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div
          className="business_view_toggle_switch"
          onClick={() => changeView()}
        >
          <m.div
            className={
              view === "users" ? "business_view_toggle_switch_button" : ""
            }
            style={
              view === "users"
                ? { padding: "0.5vh 0.5vh 0.5vh 0vh" }
                : {
                    height: "100%",
                    aspectRatio: "1/1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    padding: "0.5vh 0.5vh 0.5vh 0vh",
                  }
            }
            whileHover={
              view === "users"
                ? {
                    scale: 1.01,
                    boxShadow: "0px 0px 4px 0px #5090F0",
                  }
                : {}
            }
            transition={
              view === "users"
                ? { type: "tween", ease: "easeInOut", duration: 0.2 }
                : {}
            }
          >
            <UsersIcon
              alt="users"
              size="3vh"
              color={view === "users" ? "#FFFFFF" : "#5090F0"}
            />
          </m.div>
          <div />
          <m.div
            className={
              view === "stats" ? "business_view_toggle_switch_button" : ""
            }
            style={
              view === "stats"
                ? { padding: "0.5vh" }
                : {
                    height: "100%",
                    aspectRatio: "1/1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    padding: "0.5vh 0.5vh 0.5vh 0vh",
                  }
            }
            whileHover={
              view === "stats"
                ? {
                    scale: 1.01,
                    boxShadow: "0px 0px 4px 0px #5090F0",
                  }
                : {}
            }
            transition={
              view === "stats"
                ? { type: "tween", ease: "easeInOut", duration: 0.2 }
                : {}
            }
          >
            <StatsIcon
              alt="stats"
              size="3vh"
              color={view === "stats" ? "#FFFFFF" : "#5090F0"}
            />
          </m.div>
        </div>
      </LazyMotion>
    );
  };

  return (
    <div
      className="business_view_container"
      style={
        view === "loading"
          ? { display: "flex", justifyContent: "center", alignItems: "center" }
          : {}
      }
    >
      {view === "users" && (
        <BVUsers logins={logins} teachers={teachers} schools={schools} />
      )}
      {view === "stats" && (
        <BVStats
          logins={logins}
          teachers={teachers}
          schools={schools}
          students={students}
        />
      )}
      {view !== "loading" && renderViewButtons()}
      {view === "loading" && <Loading />}
    </div>
  );
};

export default BusinessView;
