import {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useGLTF, useAnimations, useFBX } from "@react-three/drei";
import { AnimationAction, Group, SkinnedMesh } from "three";
import { DreiGLTF } from "../../../../interfaces.ts";
import { GroupProps, useFrame } from "@react-three/fiber";
import { AssetType } from "../../../../_newapios/gamecontent/house_upgrades.ts";
import { convertToEuler, convertToVector3 } from "../../../../utils/R3F.ts";

export interface StaticEutonProps {
  groupProps?: GroupProps;
  propsAsset: AssetType;
  renderShadow: () => boolean;
}

const EutonStatic = ({
  groupProps,
  propsAsset,
  renderShadow,
}: StaticEutonProps) => {
  const [asset, setAsset] = useState<AssetType>(propsAsset);
  const Euton = useGLTF(asset.gltf.url) as DreiGLTF;
  const groupRef = useRef<Group>(null);
  const { actions } = useAnimations(Euton.animations, groupRef);

  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      for (let i = 0; i < Object.keys(actions).length; i++) {
        const action = Object.keys(actions)[i];
        actions[action]?.reset().play();
      }
    }
  }, [actions]);

  return (
    <group
      ref={groupRef}
      position={groupProps?.position}
      rotation={groupProps?.rotation}
      scale={convertToVector3(asset.scale)}
      dispose={null}
    >
      {asset.gltf.geometry.map((geometry, index) => {
        return (
          <mesh
            key={index}
            name={geometry}
            castShadow={renderShadow()}
            //recieveShadow={renderShadow()}
            geometry={Euton.nodes[geometry].geometry}
            material={Euton.materials[asset.gltf.material[index]]}
            morphTargetDictionary={Euton.nodes[geometry].morphTargetDictionary}
            morphTargetInfluences={Euton.nodes[geometry].morphTargetInfluences}
          />
        );
      })}
    </group>
  );
};

export default EutonStatic;
