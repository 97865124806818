import { StudentType } from './../_newapios/user/student';
import { GroupType } from "../_newapios/user/group";
import { Dispatch, SetStateAction } from 'react';

export const studentsAreGroup = (groups: GroupType[], students: string[]): GroupType | null => {
  for (let i = 0; i < groups.length; i++) {
    if (groups[i].students.length === students.length) {
      let found = true;
      for (let j = 0; j < students.length; j++) {
        if (!groups[i].students.map((student) => student.id).includes(students[j])) {
          found = false;
          break;
        }
      }
      if (found) {
        return groups[i];
      }
    }
  }
  return null;
}
export const setGroupsFunc = (students: StudentType[], class_id: string, groups: GroupType[] | null, setGroups: Dispatch<SetStateAction<GroupType[]>>) => {
  const wholeClass = [
    {
      id: "group1",
      name: "whole_class",
      students: students,
      class_id: class_id,
    },
  ];
  if (groups === null) {
    setGroups(wholeClass);
  } else {
    const newGroups = wholeClass.concat(groups);
    setGroups(newGroups);
  }
};