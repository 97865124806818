import env from "../../config/env.json";
import APIFetch from "../../utils/APIFetch";
import { ImageType } from "../content/unit";
import { BiomeType } from "../gamecontent/biomes";

export interface StudentEutonType {
  id: string;
  items_obtained: number;
  items_converted: number;
  items_purchased: number;
  items_sold: number;
  euton_unlocked: boolean;
  student_id: string;
  euton_id: string;
}

export interface StudentEutonTradeType {
  id: string;
  seller_id: string;
  seller: StudentEutonType & {
    euton: {
      biome: BiomeType;
      item_image: ImageType;
      item_name: string;
      tier: number;
    };
    student: {
      username: string;
    };
  };
  buyer_id: string;
  buyer: StudentEutonType & {
    euton: {
      biome: BiomeType;
      item_image: ImageType;
      item_name: string;
      tier: number;
    };
  };
  status: number;
  alert_seen: boolean;
}

export const GetAllStudentEutonsAPI = async (
  student_id: string
): Promise<StudentEutonType[]> => {
  // var req = await fetch(
  //   env.backend_host + "/student_euton/" + student_id + "/all",
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "GET",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );
  // var data = await req.json();

  var data = APIFetch(env.backend_host + "/student_euton/" + student_id + "/all", {
    method: "GET",
  });
  data = data || null;
  return data;
};

export const UnlockStudentEutonAPI = async (
  student_euton_id: string
): Promise<StudentEutonType> => {
  // var req = await fetch(
  //   env.backend_host + "/student_euton/unlock/" + student_euton_id,
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "POST",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );
  // var data = await req.json();

  var data = APIFetch(env.backend_host + "/student_euton/unlock/" + student_euton_id, {
    method: "POST",
  });
  data = data || null;
  return data;
};

export const ConvertItemAPI = async (
  student_euton_id: string
): Promise<StudentEutonType> => {
  // var req = await fetch(
  //   env.backend_host + "/student_euton/convert_item/" + student_euton_id,
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "POST",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );
  // var data = await req.json();

  var data = APIFetch(env.backend_host + "/student_euton/convert_item/" + student_euton_id, {
    method: "POST",
  });
  data = data || null;
  return data;
};

export const GetTradableItemsAPI = async (
  student_euton_id: string
): Promise<StudentEutonType[]> => {
  // var req = await fetch(
  //   env.backend_host + "/student_euton/tradable_items/" + student_euton_id,
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "GET",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );
  // var data = await req.json();

  var data = APIFetch(env.backend_host + "/student_euton/tradable_items/" + student_euton_id, {
    method: "GET",
  });
  data = data || null;
  return data;
};

export const RequestItemTradeAPI = async (
  student_euton_id: string,
  tradable_student_euton_id: string
): Promise<number> => {
  var req = await fetch(env.backend_host + "/student_euton/request_trade/", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
      student_euton_id,
      tradable_student_euton_id,
    }),
    credentials: "include",
  });
  return req.status;
};

export const GetMyTradeRequestsAPI = async (): Promise<
  StudentEutonTradeType[]
> => {
  // var req = await fetch(env.backend_host + "/me/trade_requests", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  var data = APIFetch(env.backend_host + "/me/trade_requests", {
    method: "GET",
  });
  data = data || null;
  return data;
};

export const AcceptTradeItemAPI = async (
  trade_request_id: string
): Promise<number> => {
  var req = await fetch(
    env.backend_host + "/student_euton/accept_trade/" + trade_request_id,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      mode: "cors",
      credentials: "include",
    }
  );
  return req.status;
};

export const RejectTradeItemAPI = async (
  trade_request_id: string
): Promise<number> => {
  var req = await fetch(
    env.backend_host + "/student_euton/reject_trade/" + trade_request_id,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      mode: "cors",
      credentials: "include",
    }
  );
  return req.status;
};
