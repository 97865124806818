const StatsIcon = ({ ...props }) => {
  return (
    <svg
      width={props.size ? props.size : "38"}
      height={props.size ? props.size : "38"}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chart">
        <path
          id="Path 8"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.16669 19L3.16669 33.25H9.50002V19H3.16669Z"
          stroke={props.color ? props.color : "black"}
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          id="Path 8_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.8333 4.75L15.8333 33.25H22.1666V4.75H15.8333Z"
          stroke={props.color ? props.color : "black"}
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          id="Path 8_3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.5 11.0833L28.5 33.2499H34.8333V11.0833H28.5Z"
          stroke={props.color ? props.color : "black"}
          stroke-width="3"
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
};

export default StatsIcon;
