import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";
import { MeshPhongMaterial } from "three";

const LegendaryPlace = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/legendary_place.glb"
  ) as DreiGLTF;
  return (
    <group {...props} dispose={null}>
      <group
        position={[3.563, 0.324, 23.75]}
        rotation={[-Math.PI, 0.769, -Math.PI]}
        scale={0.801}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.signal_legendary_place_1.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.signal_legendary_place_2.geometry}
          material={materials.logo_eutopia}
        />
      </group>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.sand_legendary_place.geometry}
        material={materials.tierra_legendary}
        position={[3.116, -0.102, 20.384]}
        rotation={[0, 0.271, -Math.PI]}
        scale={[-2.903, -1.462, -1.594]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.cones_legendary_place.geometry}
        material={materials["LOWPOLY-COLORS.004"]}
        position={[1.696, 0.33, 21.649]}
        rotation={[-Math.PI, 1.146, -Math.PI]}
        scale={0.589}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.barricade_legendary_place.geometry}
        material={materials["LOWPOLY-COLORS.004"]}
        position={[1.959, 0.335, 24.026]}
        rotation={[Math.PI, -0.945, Math.PI]}
        scale={0.426}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/legendary_place.glb"
);

export default LegendaryPlace;
