const CalendarIcon = ({ ...props }) => {
  return (
    <svg
      width={props.size ? props.size : "24"}
      height={props.size ? props.size : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5H21V21H3V5Z"
        stroke={props.color ? props.color : "black"}
        stroke-width="1.5"
        stroke-linecap="square"
      />
      <path
        d="M21 9H3"
        stroke={props.color ? props.color : "black"}
        stroke-width="1.5"
        stroke-linecap="square"
      />
      <path
        d="M7 5V3"
        stroke={props.color ? props.color : "black"}
        stroke-width="1.5"
        stroke-linecap="square"
      />
      <path
        d="M17 5V3"
        stroke={props.color ? props.color : "black"}
        stroke-width="1.5"
        stroke-linecap="square"
      />
    </svg>
  );
};

export default CalendarIcon;
