import { useTranslation } from "react-i18next";
import "./CO2Bar.css";
import { ClassCO2Type } from "../../../_newapios/progress/student_game_metrics";

const CO2Bar = ( props: { classCO2: ClassCO2Type } ) => {
  const { t } = useTranslation();
  let widthProgressBar = 0;

  widthProgressBar =
    (props.classCO2.class_co2 / (1500 * props.classCO2.class_size)) * 100;

  return (
    <div className="global_bar_background">
      <div
        className="global_bar_progress"
        style={{ width: widthProgressBar.toString() + "%" }}
      ></div>
      <div className="global_bar_text">
        <div className="left_container" style={{ position: "absolute" }}>
          <div className="text_white_small_bold">
            {Math.round(props.classCO2.class_co2 * 100) / 100 +
              t("global_co2_text")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CO2Bar;
