import { useGLTF } from "@react-three/drei";
import { useState } from "react";
import { DreiGLTF } from "../../../../../../interfaces";

const StaticAssetsDesert = ({ ...props }) => {
  const [funkyIndex, setFunkyIndex] = useState(0);

  const funkyHandler = () => {
    setFunkyIndex(funkyIndex + 1);
    if (funkyIndex > 10) {
      props.setFunkyMode();
    }
  };

  const stoneFlat = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/stone_flat.gltf"
  ) as DreiGLTF;
  const treeForest = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_forest_local_basic_.gltf"
  ) as DreiGLTF;
  const palmHigh = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/palmhigh.gltf"
  ) as DreiGLTF;
  const shrubTropic = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/shrub_tropic_local_basic_.gltf"
  ) as DreiGLTF;
  const palmBush = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/palm_bush.glb"
  ) as DreiGLTF;
  const rockSharp = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/rock_sharp.gltf"
  ) as DreiGLTF;
  const grassClumb = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/grass_clumb_local_basic_.gltf"
  ) as DreiGLTF;
  const treeOld = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/tree_old.gltf"
  ) as DreiGLTF;
  const palmSmall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/palm_small.gltf"
  ) as DreiGLTF;
  const cactusBig = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/cactus_big.gltf"
  ) as DreiGLTF;
  const cactusBasic = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/cactus_basic.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.61, 0.38, 7.46]}
        rotation={[-Math.PI, 1.24, -Math.PI]}
        scale={0.23}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.44, 0.38, -6.19]}
        rotation={[-Math.PI, 0.03, -Math.PI]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.75, 0.38, -4.79]}
        rotation={[0, -1.42, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-0.84, 1.09, 3.56]}
        scale={0.16}
        onClick={() => funkyHandler()}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-0.83, 1.09, 5.09]}
        scale={0.16}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[0.46, 1.09, 5.13]}
        scale={0.16}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[0.4, 1.09, 3.55]}
        scale={0.16}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[1.64, 1.09, 3.58]}
        scale={0.16}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[1.62, 1.09, 5.11]}
        scale={0.16}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[2.9, 1.09, 5.12]}
        scale={0.16}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForest.nodes["tree-forest001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[2.82, 1.09, 3.55]}
        scale={0.16}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-15.19, 1.2, 4.11]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-15.15, 1.2, 3.16]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-15.16, 1.2, 2.12]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-15.17, 1.2, 1.11]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-12.27, 0.36, -5.48]}
        rotation={[0, -0.56, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.03, 0.36, -1.83]}
        rotation={[0, -0.22, 0]}
        scale={0.14}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.8, 0.3, -4.42]}
        rotation={[0, 0.8, 0]}
        scale={0.17}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.63, 0.21, -4.95]}
        rotation={[-Math.PI, 0.9, -Math.PI]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-9, 0.21, -5.06]}
        rotation={[-Math.PI, 0.1, -Math.PI]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.78, 0.21, -3.88]}
        rotation={[-Math.PI, 1.47, -Math.PI]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.95, 0.35, 9.06]}
        rotation={[-Math.PI, 1.49, -Math.PI]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.75, 0.35, -0.64]}
        rotation={[-Math.PI, 0.9, -Math.PI]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-9.92, 0.53, 11.7]}
        rotation={[Math.PI, -1.52, Math.PI]}
        scale={0.38}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[1.45, 0.55, 11.05]}
        rotation={[0, 0.21, 0]}
        scale={0.32}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.18, 0.34, 6.74]}
        rotation={[0, -0.68, 0]}
        scale={0.48}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-7.19, 0.34, 7.71]}
        rotation={[0, -0.68, 0]}
        scale={0.48}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.64, 0.34, 5.05]}
        rotation={[0, -0.68, 0]}
        scale={0.38}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-7.18, 0.34, -3.53]}
        rotation={[0, -0.68, 0]}
        scale={0.48}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.96, 0.34, -0.83]}
        rotation={[Math.PI, -1.01, Math.PI]}
        scale={0.48}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-0.44, 0.34, -7.21]}
        rotation={[Math.PI, -1.01, Math.PI]}
        scale={0.48}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[2.29, 0.34, -7.23]}
        rotation={[0, -1.02, 0]}
        scale={0.48}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.22, 0.34, -1.71]}
        scale={0.48}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubTropic.nodes.shrub_tropic001.geometry}
        material={props.bakedAssetsMaterial}
        position={[9.98, 0.35, -2.77]}
        scale={0.15}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubTropic.nodes.shrub_tropic001.geometry}
        material={props.bakedAssetsMaterial}
        position={[10.79, 0.35, -1.53]}
        scale={0.15}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubTropic.nodes.shrub_tropic001.geometry}
        material={props.bakedAssetsMaterial}
        position={[9.94, 0.35, 0.61]}
        scale={0.15}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubTropic.nodes.shrub_tropic001.geometry}
        material={props.bakedAssetsMaterial}
        position={[8.12, 0.39, 4.4]}
        scale={0.23}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubTropic.nodes.shrub_tropic001.geometry}
        material={props.bakedAssetsMaterial}
        position={[9.81, 0.55, 6.16]}
        scale={0.23}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubTropic.nodes.shrub_tropic001.geometry}
        material={props.bakedAssetsMaterial}
        position={[10.62, 0.39, 4.9]}
        scale={0.23}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.73, 0.32, 8.91]}
        rotation={[0, 0.71, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.05, 0.32, 10.21]}
        rotation={[0, 0.71, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.36, 0.32, 10]}
        rotation={[0, 0.71, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.72, 0.32, 9.24]}
        rotation={[0, 0.45, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.58, 0.32, 9.64]}
        rotation={[0, -0.29, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.93, 0.32, -5.17]}
        rotation={[0, -0.74, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.88, 0.32, -5.58]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.7, 0.32, -4.42]}
        rotation={[0, 0.26, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.74, 0.32, -5.88]}
        rotation={[0, 0.26, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.42, 0.35, -0.41]}
        rotation={[Math.PI, -0.35, Math.PI]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.38, 0.35, -1.56]}
        rotation={[Math.PI, -0.35, Math.PI]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.31, 0.35, -0.72]}
        rotation={[Math.PI, -0.09, Math.PI]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.57, 0.35, -0.83]}
        rotation={[Math.PI, -0.09, Math.PI]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-9.12, 0.32, -5.22]}
        rotation={[0, 0.26, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-9.08, 0.32, -3.75]}
        rotation={[0, 0.26, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-9.26, 0.32, -4.07]}
        rotation={[0, 0.26, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-9.25, 0.32, -4.92]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-9.31, 0.32, -4.5]}
        rotation={[0, -0.74, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBush.nodes["palm-bush-big"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-9.01, 0.32, -4.79]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["rock-sharp001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.09, 0.31, 2.72]}
        scale={0.1}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.puente001.geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.37, 0.33, 3.04]}
        scale={[0.67, 0.31, 0.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-1.51, 0.49, 11.8]}
        rotation={[0, 0.38, 0]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[3.58, 0.48, 8.43]}
        rotation={[0, 1.26, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.96, 0.35, 11.46]}
        rotation={[0, -0.13, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.07, 0.35, 12.04]}
        rotation={[-Math.PI, 1.46, -Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.63, 0.39, 6.57]}
        rotation={[Math.PI, -0.89, Math.PI]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.16, 0.35, -2.82]}
        rotation={[0, 0.38, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-7.32, 0.35, -1.94]}
        rotation={[0, 1.06, 0]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-7.44, 0.39, 0.78]}
        rotation={[0, 1.09, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.23, 0.35, 0.81]}
        rotation={[0, 1.3, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.84, 0.35, -0.91]}
        rotation={[0, 1.39, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.63, 0.35, -5.58]}
        rotation={[-Math.PI, 0.92, -Math.PI]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.1, 0.35, -3.3]}
        rotation={[0, 1.19, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.5, 0.45, -6.6]}
        rotation={[-Math.PI, 1.28, -Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.39, 0.43, -3.87]}
        rotation={[-Math.PI, 1.28, -Math.PI]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.38, 0.55, 6.4]}
        rotation={[-Math.PI, 0.73, -Math.PI]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.48, 0.35, 4.67]}
        rotation={[0, 0.38, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old016"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.1, 0.55, 6.32]}
        rotation={[-Math.PI, 1.32, -Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.guidepost001.geometry}
        material={props.bakedAssetsMaterial}
        position={[6.24, 0.36, 3.78]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.1, 0.39, -4.92]}
        scale={0.15}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.16, 0.39, -3.93]}
        scale={0.15}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[4.6, 0.39, -4.45]}
        scale={0.15}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.46, 0.44, -3.06]}
        scale={0.15}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.5, 0.39, -1.39]}
        scale={0.15}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.1, 0.41, -2.06]}
        scale={0.15}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={grassClumb.nodes["grass-clumb001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.12, 0.39, -0.95]}
        scale={0.15}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["fence-stone-meta"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.85, 0.44, 1.98]}
        scale={[0.31, 0.16, 0.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["fence-stone-metal"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.85, 0.48, -5.13]}
        scale={[0.31, 0.16, 0.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["fence-stone-metal002"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.85, 0.45, -2.1]}
        scale={[0.31, 0.16, 0.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["fence-stone-metal009"].geometry}
        material={props.bakedAssetsMaterial}
        position={[8.58, 0.48, -5.13]}
        scale={[0.31, 0.16, 0.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["fence-stone-metal010"].geometry}
        material={props.bakedAssetsMaterial}
        position={[8.58, 0.44, 1.99]}
        scale={[0.31, 0.16, 0.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["fence-stone-metal011"].geometry}
        material={props.bakedAssetsMaterial}
        position={[8.58, 0.42, -2.1]}
        scale={[0.31, 0.16, 0.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["fence-stone"].geometry}
        material={props.bakedAssetsMaterial}
        position={[1.33, 0.39, 0.72]}
        scale={[1.22, 0.14, 0.18]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.escaleras02.geometry}
        material={props.bakedAssetsMaterial}
        position={[-0.19, 0.24, 6.93]}
        rotation={[0, 0.01, 0]}
        scale={[0.16, 0.31, 0.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.escaleras01.geometry}
        material={props.bakedAssetsMaterial}
        position={[4.43, 0.25, 4.37]}
        rotation={[-Math.PI, 1.56, -Math.PI]}
        scale={[0.16, 0.31, 0.31]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockSharp.nodes["rock-sharp005"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.31, 0.28, 5.49]}
        rotation={[0, 1, 0]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockSharp.nodes["rock-sharp005"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-14.57, 1.21, 0.47]}
        rotation={[Math.PI, -1.52, Math.PI]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockSharp.nodes["rock-sharp005"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-7.71, 0.3, -4.51]}
        rotation={[Math.PI, -1.52, Math.PI]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockSharp.nodes["rock-sharp005"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.36, 0.3, -7.13]}
        rotation={[Math.PI, -1.52, Math.PI]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-13.59, 1.19, 6.9]}
        rotation={[0, -0.51, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.92, 0.41, -1.04]}
        rotation={[0, -0.51, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.54, 0.41, -6.64]}
        rotation={[Math.PI, -0.52, Math.PI]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.06, 0.41, -2.4]}
        rotation={[Math.PI, -1.26, Math.PI]}
        scale={0.34}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/stone_flat.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_forest_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/palmhigh.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_birch_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_birch_tall_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_beech_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/shrub_tropic_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/palm_bush.glb"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/rock_sharp.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/grass_clumb_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/tree_old.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/palm_small.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/cactus_big.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/cactus_basic.gltf"
);

export default StaticAssetsDesert;
