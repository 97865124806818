import "./MyHome.css";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import closeButton from "../../assets/student/close_button.svg";
import background from "../../assets/student/Background_casa_alumno.png";
import StudentRadarChart from "./RadarChart/StudentRadarChart.tsx";
import { IchartStudentData, IscreenSize } from "../../interfaces.ts";
import { menu } from "../../reducers/studentSlice.ts";
import { motion } from "framer-motion";
import {
  GetStudentGlobalCompetencesAPI,
  GlobalCompetencePoints,
} from "../../_newapios/progress/student_global_competences.ts";
import _ from "lodash";
import { getGlobalCompetenceAggregates } from "../utils/evaluation.ts";
import { simpleGlobalCompetences } from "../../constants.ts";

export interface Iroles {
  [key: string]: Irole[];
}

type lang = keyof Iroles;

export interface Irole {
  name: string;
  description: string;
  image: string;
  color: string;
}

const MyHome = () => {
  const { t, i18n } = useTranslation();
  const language: lang = i18n.language;

  const dispatch = useAppDispatch();

  const [globalCompetences, setGlobalCompetences] =
    useState<GlobalCompetencePoints>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const promises = [
          GetStudentGlobalCompetencesAPI("me").then((chartStudentData) =>
            setGlobalCompetences(chartStudentData)
          ),
          // Add other API calls here
        ];

        // Wait for all promises to resolve
        await Promise.all(promises);

        // Once all promises are resolved, setLoading to false
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Ensure loading state is set to false even if there's an error
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    //Load from the db the student and school global competences for the chart section
    //loadCompetences();
  }, []);

  // const loadCompetences = async () => {
  //   const chartStudentData: IchartStudentData =
  //     await getStudentCompetencesScore();
  //   setStudentData([
  //     chartStudentData.CCL,
  //     chartStudentData.CP,
  //     chartStudentData.STEM,
  //     chartStudentData.CD,
  //     chartStudentData.CPSAA,
  //     chartStudentData.CC,
  //     chartStudentData.CCEC,
  //     chartStudentData.CE,
  //   ]);
  // };

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const roles: Iroles = {
    es: [
      {
        name: "Comunicador",
        description:
          "El comunicador es un experto en la lingüística. Es capaz de expresarse, tanto de forma escrita como hablada con gran fluidez. Además comprende perfectamente el mensaje de otros. La responsabilidad del comunicador en la isla es asegurarse que el conocimiento que generamos no es en vano y que sirve para que todas las aldeas tomen buenas decisiones.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/comunicador.png",
        color: "#EC6ED8",
      },
      {
        name: "Explorador",
        description:
          "El explorador tiene como ambición ir a lugares lejanos que aún no conoce. Para conseguirlo es capaz de aprender con facilidad lenguas extranjeras que le permiten comunicarse con más personas que cualquier otro rol. Su responsabilidad es descubrir otras civilizaciones y mediar la comunicación con estas.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/explorador.png",
        color: "#C06DF2",
      },
      {
        name: "Ingeniero",
        description:
          "Los ingenieros son creadores natos. Le sacan el máximo partido a las leyes de la física para crear tecnologías que solucionan problemas. Sus inventos son incomprensibles para el resto de roles, y pueden hacer cosas que parecen mágicas. Tienen la responsabilidad de crear tecnologías que ayudan a la población de la isla.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/ingeniero.png",
        color: "#F0684F",
      },
      {
        name: "Digitalizador",
        description:
          "El digitalizador utiliza la tecnología para llegar a otros mundos que conoce mejor que nadie. Este rol es capaz de sumergirse en el mundo digital, sacándole el máximo partido. Un digitalizador experto es capaz tanto de encontrar cosas inauditas como crear su propio contenido, como youtuber, tiktoker, blogger o de formas novedosas aún no descubiertas.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/Digitalizador.png",
        color: "#FDA844",
      },
      {
        name: "Mediador",
        description:
          "El rol del mediador es vital para que la isla se mantenga unida. Los mediadores son personas tremendamente empáticas, capaces de comprender las emociones de otros y regular las suyas. Este don les permite solucionar problemas entre los miembros de la isla, por lo que su responsabilidad es evitar conflictos y resolver los que surjan.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/mediador.png",
        color: "#6DABF2",
      },
      {
        name: "Líder",
        description:
          "Los líderes son capaces de identificar y comprender problemas a nivel social, lo que les convierte en grandes líderes. Pueden planear objetivos y tienen el carisma para que su pueblo les siga. Su responsabilidad es liderar a la isla en la dirección más beneficiosa para convertirla en un lugar mejor.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/comandante.png",
        color: "#87D836",
      },
      {
        name: "Virtuoso",
        description:
          "El virtuoso es capaz de expresar sus ideas, opiniones y emociones mediante la producción artística. Este don les permite transmitir de formas que llegan con más facilidad y profundidad a sus audiencias. Además, los virtuosos aprecian la obra de otros y son capaces de reconocer producciones brillantes.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/virtuoso.png",
        color: "#8CD6FF",
      },
      {
        name: "Emprendedor",
        description:
          "Los emprendedores son capaces de indentificar oportunidades y desarrollar proyectos que generan valor para sus clientes. Este rol lidera la innovación en Eutopía y permite la creación de empleo de muchas personas que viven de sus proyectos. La responsabilidad de este rol es crear proyectos que dinamicen la economía de la isla.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/Emprendedor.png",
        color: "#FFC700",
      },
    ],
    cat: [
      {
        name: "Comunicador",
        description:
          "El comunicador és un expert en la lingüística. És capaç d'expressar-se, tant de manera escrita com parlada amb gran fluïdesa. A més comprèn perfectament el missatge d'uns altres. La responsabilitat del comunicador a l'illa és assegurar-se que el coneixement que generem no és en va i que serveix perquè tots els llogarets prenguin bones decisions.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/comunicador.png",
        color: "#EC6ED8",
      },
      {
        name: "Explorador",
        description:
          "L'explorador té com a ambició anar a llocs llunyans que encara no coneix. Per a aconseguir-ho és capaç d'aprendre amb facilitat llengües estrangeres que li permeten comunicar-se amb més persones que qualsevol altre rol. La seva responsabilitat és descobrir altres civilitzacions i mediar la comunicació amb aquestes.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/explorador.png",
        color: "#C06DF2",
      },
      {
        name: "Enginyer",
        description:
          "Els enginyers són creadors nats. Li treuen el màxim partit a les lleis de la física per a crear tecnologies que solucionen problemes. Els seus invents són incomprensibles per a la resta de rols, i poden fer coses que semblen màgiques. Tenen la responsabilitat de crear tecnologies que ajuden a la població de l'illa.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/ingeniero.png",
        color: "#F0684F",
      },
      {
        name: "Digitalitzador",
        description:
          "El digitalitzador utilitza la tecnologia per a arribar a altres mons que coneix millor que ningú. Aquest rol és capaç de submergir-se en el món digital, traient-li el màxim partit. Un digitalitzador expert és capaç tant de trobar coses inaudites com crear el seu propi contingut, com youtuber, tiktoker, bloguer o de formes noves encara no descobertes.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/Digitalizador.png",
        color: "#FDA844",
      },
      {
        name: "Mediador",
        description:
          "El rol del mediador és vital perquè l'illa es mantingui unida. Els mediadors són persones tremendament empàtiques, capaces de comprendre les emocions d'uns altres i regular les seves. Aquest do els permet solucionar problemes entre els membres de l'illa, per la qual cosa la seva responsabilitat és evitar conflictes i resoldre els que sorgeixin.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/mediador.png",
        color: "#6DABF2",
      },
      {
        name: "Líder",
        description:
          "Els líders són capaços d'identificar i comprendre problemes a nivell social, la qual cosa els converteix en grans líders. Poden planejar objectius i tenen el carisma perquè el seu poble els segueixi. La seva responsabilitat és liderar a l'illa en la direcció més beneficiosa per a convertir-la en un lloc millor.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/comandante.png",
        color: "#87D836",
      },
      {
        name: "Virtuós",
        description:
          "El virtuós és capaç d'expressar les seves idees, opinions i emocions mitjançant la producció artística. Aquest do els permet transmetre de formes que arriben amb més facilitat i profunditat a les seves audiències. A més, els virtuosos aprecien l'obra d'uns altres i són capaces de reconèixer produccions brillants.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/virtuoso.png",
        color: "#8CD6FF",
      },
      {
        name: "Emprenedor",
        description:
          "Els emprenedors són capaços de indentificar oportunitats i desenvolupar projectes que generen valor per als seus clients. Aquest rol lidera la innovació en Eutopía i permet la creació d'ocupació de moltes persones que viuen dels seus projectes. La responsabilitat d'aquest rol és crear projectes que dinamitzin l'economia de l'illa.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/Emprendedor.png",
        color: "#FFC700",
      },
    ],
    val: [
      {
        name: "Comunicador",
        description:
          "El comunicador és un expert en la lingüística. És capaç d'expressar-se, tant de manera escrita com parlada amb gran fluïdesa. A més comprén perfectament el missatge d'uns altres. La responsabilitat del comunicador a l'illa és assegurar-se que el coneixement que generem no és en va i que servix perquè tots els llogarets prenguen bones decisions.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/comunicador.png",
        color: "#EC6ED8",
      },
      {
        name: "Explorador",
        description:
          "L'explorador té com a ambició anar a llocs llunyans que encara no coneix. Per a aconseguir-ho és capaç d'aprendre amb facilitat llengües estrangeres que li permeten comunicar-se amb més persones que qualsevol altre rol. La seua responsabilitat és descobrir altres civilitzacions i mediar la comunicació amb estes.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/explorador.png",
        color: "#C06DF2",
      },
      {
        name: "Enginyer",
        description:
          "Els enginyers són creadors nats. Li trauen el màxim partit a les lleis de la física per a crear tecnologies que solucionen problemes. Els seus invents són incomprensibles per a la resta de rols, i poden fer coses que semblen màgiques. Tenen la responsabilitat de crear tecnologies que ajuden a la població de l'illa.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/ingeniero.png",
        color: "#F0684F",
      },
      {
        name: "Digitalitzador",
        description:
          "El digitalitzador utilitza la tecnologia per a arribar a altres mons que coneix millor que ningú. Este rol és capaç de submergir-se en el món digital, traient-li el màxim partit. Un digitalitzador expert és capaç tant de trobar coses inaudites com crear el seu propi contingut, com youtuber, tiktoker, bloguer o de formes noves encara no descobertes.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/Digitalizador.png",
        color: "#FDA844",
      },
      {
        name: "Mediador",
        description:
          "El rol del mediador és vital perquè l'illa es mantinga unida. Els mediadors són persones tremendament empàtiques, capaces de comprendre les emocions d'uns altres i regular les seues. Este do els permet solucionar problemes entre els membres de l'illa, per la qual cosa la seua responsabilitat és evitar conflictes i resoldre els que sorgisquen.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/mediador.png",
        color: "#6DABF2",
      },
      {
        name: "Líder",
        description:
          "Els líders són capaços d'identificar i comprendre problemes a nivell social, la qual cosa els convertix en grans líders. Poden planejar objectius i tenen el carisma perquè el seu poble els seguisca. La seua responsabilitat és liderar a l'illa en la direcció més beneficiosa per a convertir-la en un lloc millor.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/comandante.png",
        color: "#87D836",
      },
      {
        name: "Virtuós",
        description:
          "El virtuós és capaç d'expressar les seues idees, opinions i emocions mitjançant la producció artística. Este do els permet transmetre de formes que arriben amb més facilitat i profunditat a les seues audiències. A més, els virtuosos aprecien l'obra d'uns altres i són capaces de reconéixer produccions brillants.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/virtuoso.png",
        color: "#8CD6FF",
      },
      {
        name: "Emprenedor",
        description:
          "Els emprenedors són capaços de *indentificar oportunitats i desenrotllar projectes que generen valor per als seus clients. Este rol lidera la innovació en Eutopía i permet la creació d'ocupació de moltes persones que viuen dels seus projectes. La responsabilitat d'este rol és crear projectes que dinamitzen l'economia de l'illa.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/Emprendedor.png",
        color: "#FFC700",
      },
    ],
    en: [
      {
        name: "Communicator",
        description:
          "The communicator is an expert in linguistics. He is able to express himself both in written and spoken form with great fluency. In addition, he/she understands the message of others perfectly. The communicator's responsibility on the island is to make sure that the knowledge we generate is not wasted and that it is used by all the villages to make good decisions.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/comunicador.png",
        color: "#EC6ED8",
      },
      {
        name: "Explorer",
        description:
          "The explorer's ambition is to go to faraway places he does not yet know. To achieve this, he is able to easily learn foreign languages that allow him to communicate with more people than any other role. His responsibility is to discover other civilizations and mediate communication with them.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/explorador.png",
        color: "#C06DF2",
      },
      {
        name: "Engineer",
        description:
          "Engineers are born creators. They make the most of the laws of physics to create problem-solving technologies. Their inventions are incomprehensible to other roles, and they can do things that seem like magic. They have a responsibility to create technologies that help the people of the island.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/ingeniero.png",
        color: "#F0684F",
      },
      {
        name: "Digitizer",
        description:
          "The digitizer uses technology to reach other worlds that they know better than anyone else. This role is able to immerse himself in the digital world, making the most of it. An expert digitizer is capable of both finding unheard of things and creating their own content, as a youtuber, tiktoker, blogger or in new ways yet to be discovered.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/Digitalizador.png",
        color: "#FDA844",
      },
      {
        name: "Mediator",
        description:
          "The role of the mediator is vital to keep the island together. Mediators are tremendously empathetic people, able to understand others' emotions and regulate their own. This gift enables them to solve problems between island members, so their responsibility is to prevent conflicts and resolve those that arise.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/mediador.png",
        color: "#6DABF2",
      },
      {
        name: "Leader",
        description:
          "Leaders are able to identify and understand problems on a societal level, which makes them great leaders. They can plan objectives and have the charisma to make their people follow them. Their responsibility is to lead the island in the most beneficial direction to make it a better place.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/comandante.png",
        color: "#87D836",
      },
      {
        name: "Virtuoso",
        description:
          "The virtuoso is able to express their ideas, opinions and emotions through artistic production. This gift allows them to convey in ways that more easily and deeply reach their audiences. In addition, virtuosos appreciate the work of others and are able to recognize brilliant productions.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/virtuoso.png",
        color: "#8CD6FF",
      },
      {
        name: "Entrepreneur",
        description:
          "Entrepreneurs are able to identify opportunities and develop projects that generate value for their clients. This role leads innovation in Eutopia and allows the creation of jobs for many people who make a living from their projects. The responsibility of this role is to create projects that dynamize the island's economy.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/Emprendedor.png",
        color: "#FFC700",
      },
    ],
    gal: [
      {
        name: "Comunicador",
        description:
          "O comunicador é un experto na lingüística. É capaz de expresarse, tanto de forma escrita como falada con gran fluidez. Ademáis comprende perfectamente a mensaxe doutros. A responsabilidade do comunicador na illa é asegurarse que o coñecemento que xeramos non é en vano e que serve para que todas as aldeas tomen boas decisións.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/comunicador.png",
        color: "#EC6ED8",
      },
      {
        name: "Explorador",
        description:
          "O explorador ten como ambición ir a lugares afastados que aínda non coñece. Para conseguilo é capaz de aprender con facilidade linguas estranxeiras que lle permiten comunicarse con máis persoas que calquera outro rol. A súa responsabilidade é descubrir outras civilizacións e mediar a comunicación con estas.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/explorador.png",
        color: "#C06DF2",
      },
      {
        name: "Enxeñeiro",
        description:
          "Os enxeñeiros son creadores natos. Sácanlle o máximo partido ás leis da física para crear tecnoloxías que solucionan problemas. Os seus inventos son incomprensibles para o resto de roles, e poden facer cousas que parecen máxicas. Teñen a responsabilidade de crear tecnoloxías que axudan á poboación da illa.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/ingeniero.png",
        color: "#F0684F",
      },
      {
        name: "Dixitalizador",
        description:
          "O dixitalizador utiliza a tecnoloxía para chegar a outros mundos que coñece mellor que ninguén. Este rol é capaz de mergullarse no mundo dixital, sacándolle o máximo partido. Un digitalizador experto é capaz tanto de atopar cousas inauditas como crear o seu propio contido, como youtuber, tiktoker, blogger ou de formas novas aínda non descubertas.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/Digitalizador.png",
        color: "#FDA844",
      },
      {
        name: "Mediador",
        description:
          "O rol do mediador é vital para que a illa se manteña unida. Os mediadores son persoas tremendamente empáticas, capaces de comprender as emocións doutros e regular as súas. Este don permite solucionar problemas entre os membros da illa, polo que a súa responsabilidade é evitar conflitos e resolver os que xurdan.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/mediador.png",
        color: "#6DABF2",
      },
      {
        name: "Líder",
        description:
          "Os líderes son capaces de identificar e comprender problemas a nivel social, o que lles converte en grandes líderes. Poden planear obxectivos e teñen o carisma para que o seu pobo lles siga. A súa responsabilidade é liderar á illa na dirección máis beneficiosa para convertela nun lugar mellor.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/comandante.png",
        color: "#87D836",
      },
      {
        name: "Virtuoso",
        description:
          "O virtuoso é capaz de expresar as súas ideas, opinións e emocións mediante a produción artística. Este don permite transmitir de formas que chegan con máis facilidade e profundidade ás súas audiencias. Ademais, os virtuosos aprecian a obra doutros e son capaces de recoñecer producións brillantes.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/virtuoso.png",
        color: "#8CD6FF",
      },
      {
        name: "Emprendedor",
        description:
          "Os emprendedores son capaces de indentificar oportunidades e desenvolver proxectos que xeran valor para os seus clientes. Este rol lidera a innovación en Eutopía e permite a creación de emprego de moitas persoas que viven dos seus proxectos. A responsabilidade deste rol é crear proxectos que dinamicen a economía da illa.",
        image:
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/Roles/Emprendedor.png",
        color: "#FFC700",
      },
    ],
  };

  const closeButtonHandler = () => {
    dispatch(menu("globalMap"));
  };

  const renderCloseButton = () => {
    return (
      <motion.div
        className="myhome_close_button"
        onClick={() => closeButtonHandler()}
        whileHover={{ scale: 1.2, rotate: 180 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        style={{
          borderRadius: "50%",
          backgroundColor: "white",
        }}
      >
        <div className="center_container">
          <img className="image" src={closeButton} alt="close" />
        </div>
      </motion.div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="myhome_title">
        <div className="left_container">
          <div className="text_white_very_big_bold">{t("my_home")}</div>
        </div>
      </div>
    );
  };

  const renderMenu = () => {
    const studentData = !_.isEmpty(globalCompetences)
      ? Object.values(getGlobalCompetenceAggregates(globalCompetences))
      : _.fill(Array(simpleGlobalCompetences.length), 0);

    let baselinedStudGlComp: number[] = [];

    studentData.forEach((spComp) => {
      if (spComp > 12500) {
        baselinedStudGlComp.push(12499);
      } else {
        baselinedStudGlComp.push(spComp);
      }
    });
    return (
      <div className="myhome_menu" style={{}}>
        <div className="myhome_menu_left">
          <StudentRadarChart
            max={12500}
            full={false}
            labels={roles[language]}
            studentData={baselinedStudGlComp}
          />
        </div>
        <div className="myhome_menu_right">
          {roles[language].map((role: Irole, roleIndex: number) => {
            return (
              <div
                className="myhome_menu_role"
                key={roleIndex}
                style={{ marginTop: roleIndex != 0 ? "5%" : 0 }}
              >
                <div className="myhome_menu_image">
                  <div className="center_container">
                    <img
                      className="image"
                      src={role.image}
                      alt="role_image"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>
                <div className="myhome_menu_text">
                  <div className="left_container">
                    <div
                      className="text_black_medium_bold"
                      style={{
                        textDecoration:
                          "solid underline " + role.color + " 2px",
                        color: role.color,
                      }}
                    >
                      {role.name}
                    </div>
                  </div>
                  <div className="left_container">
                    <div className="text_black_super_small_bold">
                      {role.description}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="myhome">
      <div className="myhome_banner_container">
        <img
          className="myhome_banner"
          src={
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/banner_town_hall.svg"
          }
          alt="banner"
        />
      </div>
      <div className="myhome_background" />
      {renderCloseButton()}
      {renderTitle()}
      {renderMenu()}
    </div>
  );
};

export default MyHome;
