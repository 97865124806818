import React, { useEffect, useState } from "react";
import axios from "axios";
import { PutImageScenarioAPI } from "../../../../_newapios/content/image";
import { EditIcon } from "@chakra-ui/icons";

const ScenarioImageSelector = ({ ...props }) => {
    const [image, setImage] = useState(props.image?.url || "");

    useEffect(() => {
        setImage(props.image?.url);

    }, [props.image]);

    const handleImage = (event: any) => {
        (document.getElementById("scenario_image_file_selector") as HTMLInputElement).value =
            "" as string;
        (document.getElementById("scenario_image_file_selector") as HTMLElement).click();
    };

    const onChangeFile = (event: any) => {
        const file = event.target.files[0] || "";
        PutImageScenarioAPI(file, props.scenarioId).then((scenario) => {
            props.imageHandler(scenario);
        });
    };

    return (
        <div onClick={(e) => handleImage(e)} style={{ cursor: 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto' }}>
            <img
                className="image"
                src={
                    image ||
                    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Tutorial/Tutorial+-+ICON.jpg"
                }
                alt="scenario_image"
                style={{ borderRadius: "10px", maxHeight: "80px" }}
            />
            <input
                id="scenario_image_file_selector"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                hidden
                onChange={onChangeFile}
            />
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "58%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "10px",
                    opacity: 1,
                }}
            >
                <EditIcon style={{ color: "white", fontSize: "1.5rem" }} />
            </div>
        </div>
    );
};

export default ScenarioImageSelector;