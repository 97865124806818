import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";
import { BufferGeometry } from "three";

//Cosas que faltan:

// - Rotación de Sauces
// - Escala de los treeStumps
// - Añadir los corales en playa
// - Flores en pradera

const Vegetation = ({ ...props }) => {
  const biomeName: string = props.biomeName;

  let geometry1: BufferGeometry;
  let geometry2: BufferGeometry;
  let geometry3: BufferGeometry;
  let geometry4: BufferGeometry;
  let geometry5: BufferGeometry;
  let geometry6: BufferGeometry;
  let geometry7: BufferGeometry;
  let geometry8: BufferGeometry;
  let geometry9: BufferGeometry;
  let geometry10: BufferGeometry;
  let geometry11: BufferGeometry;
  let geometry12: BufferGeometry;

  const pino = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/pino5_local_basic_.gltf"
  ) as DreiGLTF;

  const grassClumb = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/grass_clumb_local_basic_.gltf"
  ) as DreiGLTF;

  const shrubTropic = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/shrub_tropic_local_basic_.gltf"
  ) as DreiGLTF;

  const treeBirchTall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_birch_tall_local_basic_.gltf"
  ) as DreiGLTF;

  const treeBirch = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_birch_local_basic_.gltf"
  ) as DreiGLTF;

  const treeBeech = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_beech_local_basic_.gltf"
  ) as DreiGLTF;

  const shrubRound = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/shrub_round_local_basic_.gltf"
  ) as DreiGLTF;

  const plantBush = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Jungle/plant_bush.gltf"
  ) as DreiGLTF;

  const treePalmHigh = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Jungle/palm_tree_high.gltf"
  ) as DreiGLTF;

  const tree = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Jungle/tree.gltf"
  ) as DreiGLTF;

  const treeBaobab = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Jungle/tree_baobab.gltf"
  ) as DreiGLTF;

  const rockPilar = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Jungle/rock_pilar.gltf"
  ) as DreiGLTF;

  const rockSharp = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Jungle/rock_sharp.gltf"
  ) as DreiGLTF;

  const treeOld = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/tree_old.gltf"
  ) as DreiGLTF;

  const palmSmall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/palm_small.gltf"
  ) as DreiGLTF;

  const cactusBig = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/cactus_big.gltf"
  ) as DreiGLTF;

  const cactusBasic = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/cactus_basic.gltf"
  ) as DreiGLTF;

  const palmBush = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/palm_bush.glb"
  ) as DreiGLTF;

  const stoneFlat = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/stone_flat.gltf"
  ) as DreiGLTF;

  const pinoHelado = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Snow/tree_spruce_snow.gltf"
  ) as DreiGLTF;

  const treeBirchTallSnow = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Snow/tree_birch_tall_winter.gltf"
  ) as DreiGLTF;

  const stoneSnow = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Snow/stone_snow.glb"
  ) as DreiGLTF;

  const stumpSnow = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Snow/stump_snow.gltf"
  ) as DreiGLTF;

  const treeOakSnow = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Snow/tree_oak_winter.gltf"
  ) as DreiGLTF;

  const treeDeadSnow = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Snow/tree_dead_snow.gltf"
  ) as DreiGLTF;

  const treeElipseSnow = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Snow/tree_elipse_snow.gltf"
  ) as DreiGLTF;

  const sauceYellow = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/sauce_yellow.gltf"
  ) as DreiGLTF;

  const sauceOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/sauce_orange.gltf"
  ) as DreiGLTF;

  const treeSpruceOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/tree_spruce_orange.gltf"
  ) as DreiGLTF;

  const grassClumbOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/grass_clumb_orange.gltf"
  ) as DreiGLTF;

  const treeBirchYellow = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/tree_birch_orange.gltf"
  ) as DreiGLTF;
  const treeBirchTallOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/tree_birch_tall_orange.gltf"
  ) as DreiGLTF;
  const treeBeechOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/tree_beech_orange.gltf"
  ) as DreiGLTF;

  const grassTall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/grass_tall.gltf"
  ) as DreiGLTF;
  const treeBirchOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/tree_birch_orange.gltf"
  ) as DreiGLTF;

  const treeDead = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/tree_dead.gltf"
  ) as DreiGLTF;

  const stumpSmall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/stump_small.gltf"
  ) as DreiGLTF;

  const arbusto01 = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Volcano/arbusto01.gltf"
  ) as DreiGLTF;

  const sunscreen = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/sunscreen.gltf"
  ) as DreiGLTF;

  const sunscreenClose = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/sunscreen_close.gltf"
  ) as DreiGLTF;

  const palmBig = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/palm_big.gltf"
  ) as DreiGLTF;

  const grassSea = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/grass_sea.gltf"
  ) as DreiGLTF;

  const coralOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/coral_small_orange.gltf"
  ) as DreiGLTF;

  const coralRed = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/coral_small_red.gltf"
  ) as DreiGLTF;

  const coralBlue = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/coral_small_blue.gltf"
  ) as DreiGLTF;

  const mushroomToadstool = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Swamp/mushroom_toadstool.gltf"
  ) as DreiGLTF;

  const stoneFlatBrown = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Swamp/stone_flat_brown.gltf"
  ) as DreiGLTF;

  const mushroomBoletus = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Swamp/mushroom_boletus.gltf"
  ) as DreiGLTF;

  const sauce = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Swamp/sauce.gltf"
  ) as DreiGLTF;

  const treeBeechSwamp = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Swamp/tree_beech2.gltf"
  ) as DreiGLTF;

  const geometryRockPilar = rockPilar.nodes["rock-pillar001"].geometry;
  const geometryRockSharp = rockSharp.nodes["rock-sharp002"].geometry;

  switch (biomeName) {
    case "grassland":
      geometry1 = pino.nodes.pino5001.geometry;
      geometry2 = grassClumb.nodes["grass-clumb001"].geometry;
      geometry3 = shrubTropic.nodes.shrub_tropic001.geometry;
      geometry4 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry5 = treeBirch.nodes["tree-birch001"].geometry;
      geometry6 = treeBeech.nodes["tree-beech001"].geometry;
      geometry7 = shrubRound.nodes["shrub-round001"].geometry;
      geometry8 = pino.nodes.pino5001.geometry;
      geometry9 = treeBeech.nodes["tree-beech001"].geometry;
      geometry10 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry11 = pino.nodes.pino5001.geometry;
      geometry12 = pino.nodes.pino5001.geometry;
      break;

    case "jungle":
      geometry1 = pino.nodes.pino5001.geometry;
      geometry2 = grassClumb.nodes["grass-clumb001"].geometry;
      geometry3 = shrubTropic.nodes.shrub_tropic001.geometry;
      geometry4 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry5 = treeBirch.nodes["tree-birch001"].geometry;
      geometry6 = treeBeech.nodes["tree-beech001"].geometry;
      geometry7 = plantBush.nodes["plant-bush-small044"].geometry;
      geometry8 = treePalmHigh.nodes["palm-high043"].geometry;
      geometry9 = treeBaobab.nodes["tree-baobab004"].geometry;
      geometry10 = tree.nodes.tree.geometry;
      geometry11 = pino.nodes.pino5001.geometry;
      geometry12 = treePalmHigh.nodes["palm-high043"].geometry;
      break;

    case "desert":
      geometry1 = palmSmall.nodes["palm-small"].geometry;
      geometry2 = grassClumb.nodes["grass-clumb001"].geometry;
      geometry3 = stoneFlat.nodes["stone-flat001"].geometry;
      geometry4 = treePalmHigh.nodes["palm-high043"].geometry;
      geometry5 = treePalmHigh.nodes["palm-high043"].geometry;
      geometry6 = cactusBig.nodes["cactus-big004"].geometry;
      geometry7 = palmBush.nodes["palm-bush-big"].geometry;
      geometry8 = treeOld.nodes["tree-old016"].geometry;
      geometry9 = cactusBasic.nodes["cactus-basic001"].geometry;
      geometry10 = treePalmHigh.nodes["palm-high043"].geometry;
      geometry11 = palmSmall.nodes["palm-small"].geometry;
      geometry12 = treeOld.nodes["tree-old016"].geometry;
      break;

    case "snow":
      geometry1 = pinoHelado.nodes["tree-spruce-snow"].geometry;
      geometry2 = grassClumb.nodes["grass-clumb001"].geometry;
      geometry3 = stoneSnow.nodes["stone-snow"].geometry;
      geometry4 = treeBirchTallSnow.nodes["tree-birch-tall_winter"].geometry;
      geometry5 = treeOakSnow.nodes["tree-oak_winter001"].geometry;
      geometry6 = treeElipseSnow.nodes["tree-elipse006"].geometry;
      geometry7 = stoneSnow.nodes["stone-snow"].geometry;
      geometry8 = treeDeadSnow.nodes["tree-dead-snow015"].geometry;
      geometry9 = stumpSnow.nodes["stump-snow001"].geometry;
      geometry10 = treeBirchTallSnow.nodes["tree-birch-tall_winter"].geometry;
      geometry11 = pinoHelado.nodes["tree-spruce-snow"].geometry;
      geometry12 = treeDeadSnow.nodes["tree-dead-snow015"].geometry;
      break;

    case "swamp":
      geometry1 = pino.nodes.pino5001.geometry;
      geometry2 = mushroomBoletus.nodes["mushroom-boletus002"].geometry;
      geometry3 = mushroomToadstool.nodes["mushroom-toadstool001"].geometry;
      geometry4 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry5 = treeBeechSwamp.nodes["tree-beech011"].geometry;
      geometry6 = treeBeech.nodes["tree-beech001"].geometry;
      geometry7 = grassSea.nodes["grass-sea001"].geometry;
      geometry8 = sauce.nodes.sauce001.geometry;
      geometry9 = treeBeechSwamp.nodes["tree-beech011"].geometry;
      geometry10 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry11 = treeDead.nodes["tree-dead001"].geometry;
      geometry12 = mushroomToadstool.nodes["mushroom-toadstool001"].geometry;
      break;

    case "volcano":
      geometry1 = treeSpruceOrange.nodes["tree-spruce_orange014"].geometry;
      geometry2 = grassClumbOrange.nodes["grass-clumb_orange002"].geometry;
      geometry3 = arbusto01.nodes["shrub-round029"].geometry;
      geometry4 =
        treeBirchTallOrange.nodes["tree-birch-tall_orange005"].geometry;
      geometry5 = treeBirchOrange.nodes["tree-birch004"].geometry;
      geometry6 = treeBeechOrange.nodes["tree-beech011"].geometry;
      geometry7 = arbusto01.nodes["shrub-round029"].geometry;
      geometry8 = stumpSmall.nodes["stump-small001"].geometry;
      geometry9 = treeOld.nodes["tree-old016"].geometry;
      geometry10 =
        treeBirchTallOrange.nodes["tree-birch-tall_orange005"].geometry;
      geometry11 = treeDead.nodes["tree-dead001"].geometry;
      geometry12 = pino.nodes.pino5001.geometry;
      break;

    case "beach":
      geometry1 = treePalmHigh.nodes["palm-high043"].geometry;
      geometry2 = coralOrange.nodes["coral-small_orange004"].geometry;
      geometry3 = palmBig.nodes["palm-big002"].geometry;
      geometry4 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry5 = sunscreenClose.nodes["sunscreen-closed009"].geometry;
      geometry6 = sunscreen.nodes.sunscreen007.geometry;
      geometry7 = grassSea.nodes["grass-sea001"].geometry;
      geometry8 = palmBig.nodes["palm-big002"].geometry;
      geometry9 = sunscreen.nodes.sunscreen007.geometry;
      geometry10 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry11 = palmBig.nodes["palm-big002"].geometry;
      geometry12 = treePalmHigh.nodes["palm-high043"].geometry;
      break;

    case "savannah":
      geometry1 = treeSpruceOrange.nodes["tree-spruce_orange014"].geometry;
      geometry2 = grassTall.nodes["grass-tall002"].geometry;
      geometry3 = grassClumbOrange.nodes["grass-clumb_orange002"].geometry;
      geometry4 =
        treeBirchTallOrange.nodes["tree-birch-tall_orange005"].geometry;
      geometry5 = treeBirchYellow.nodes["tree-birch_orange"].geometry;
      geometry6 = treeBeechOrange.nodes["tree-beech011"].geometry;
      geometry7 = grassClumbOrange.nodes["grass-clumb_orange002"].geometry;
      geometry8 = pino.nodes.pino5001.geometry;
      geometry9 = treeBeech.nodes["tree-beech001"].geometry;
      geometry10 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry11 = sauceOrange.nodes["tree-baobab_orange011"].geometry;
      geometry12 = sauceYellow.nodes["tree-baobab_yellow003"].geometry;
      break;
    case "mountain":
      geometry1 = treeSpruceOrange.nodes["tree-spruce_orange014"].geometry;
      geometry2 = grassClumbOrange.nodes["grass-clumb_orange002"].geometry;
      geometry3 = arbusto01.nodes["shrub-round029"].geometry;
      geometry4 =
        treeBirchTallOrange.nodes["tree-birch-tall_orange005"].geometry;
      geometry5 = treeBirchOrange.nodes["tree-birch004"].geometry;
      geometry6 = treeBeechOrange.nodes["tree-beech011"].geometry;
      geometry7 = arbusto01.nodes["shrub-round029"].geometry;
      geometry8 = stumpSmall.nodes["stump-small001"].geometry;
      geometry9 = treeOld.nodes["tree-old016"].geometry;
      geometry10 =
        treeBirchTallOrange.nodes["tree-birch-tall_orange005"].geometry;
      geometry11 = treeDead.nodes["tree-dead001"].geometry;
      geometry12 = pino.nodes.pino5001.geometry;
      break;
    case "forest":
      geometry1 = pino.nodes.pino5001.geometry;
      geometry2 = grassClumb.nodes["grass-clumb001"].geometry;
      geometry3 = shrubTropic.nodes.shrub_tropic001.geometry;
      geometry4 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry5 = treeBirch.nodes["tree-birch001"].geometry;
      geometry6 = treeBeech.nodes["tree-beech001"].geometry;
      geometry7 = shrubRound.nodes["shrub-round001"].geometry;
      geometry8 = pino.nodes.pino5001.geometry;
      geometry9 = treeBeech.nodes["tree-beech001"].geometry;
      geometry10 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry11 = pino.nodes.pino5001.geometry;
      geometry12 = pino.nodes.pino5001.geometry;
      break;

    default:
      geometry1 = pino.nodes.pino5001.geometry;
      geometry2 = grassClumb.nodes["grass-clumb001"].geometry;
      geometry3 = shrubTropic.nodes.shrub_tropic001.geometry;
      geometry4 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry5 = treeBirch.nodes["tree-birch001"].geometry;
      geometry6 = treeBeech.nodes["tree-beech001"].geometry;
      geometry7 = shrubRound.nodes["shrub-round001"].geometry;
      geometry8 = pino.nodes.pino5001.geometry;
      geometry9 = treeBeech.nodes["tree-beech001"].geometry;
      geometry10 = treeBirchTall.nodes["tree-birch-tall001"].geometry;
      geometry11 = pino.nodes.pino5001.geometry;
      geometry12 = pino.nodes.pino5001.geometry;

      break;
  }

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-6.031, 0.392, 26.497]}
        scale={biomeName === "swamp" ? 0.4 : 0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[17.374, 0.348, 17.864]}
        rotation={[0, -0.645, 0]}
        scale={0.233}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[14.935, 0.349, 18.413]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry10}
        material={props.bakedAssetsMaterial}
        position={[-8.089, -0.004, 26.676]}
        rotation={[-Math.PI, 1.251, -Math.PI]}
        scale={0.213}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-11.369, 0.33, 15.981]}
        rotation={[0, -0.563, 0]}
        scale={biomeName === "beach" ? 0.25 : 0.171}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-12.776, 0.422, 14.965]}
        rotation={[Math.PI, -1.259, Math.PI]}
        scale={biomeName === "beach" ? 0.25 : 0.206}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-18.056, 0.348, 16.236]}
        rotation={[Math.PI, -0.961, Math.PI]}
        scale={0.204}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[-15.552, 0.348, 15.612]}
        rotation={[0, -0.645, 0]}
        scale={0.204}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[-27.339, 0.348, 15.824]}
        rotation={[0, -0.645, 0]}
        scale={0.224}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-13.352, 0.464, 25.602]}
        rotation={[0, -1.015, 0]}
        scale={0.201}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[-11.298, 0.348, 27.122]}
        rotation={[0, 0.039, 0]}
        scale={0.243}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-16.83, 0.348, 26.557]}
        rotation={[0, -0.645, 0]}
        scale={0.243}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry12}
        material={props.bakedAssetsMaterial}
        position={[-19.747, 0.348, 22.343]}
        rotation={[0, -0.235, 0]}
        scale={biomeName === "swamp" ? 1 : 0.282}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-21.884, 0.348, 11.025]}
        rotation={[0, -0.062, 0]}
        scale={0.243}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[-16.675, 0.348, 28.648]}
        rotation={[0, -1.507, 0]}
        scale={0.243}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[-14.388, 0.348, 31.235]}
        rotation={[0, -1.339, 0]}
        scale={0.243}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-12.783, 0.348, 39.407]}
        rotation={[0, 0.093, 0]}
        scale={0.182}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[-19.932, 0.348, 39.012]}
        rotation={[0, -0.808, 0]}
        scale={0.188}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-24.924, 0.348, 35.039]}
        rotation={[0, -0.645, 0]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[-19.221, 0.348, 24.159]}
        rotation={[0, -0.645, 0]}
        scale={0.228}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-15.78, 0.348, 31.219]}
        rotation={[0, -0.228, 0]}
        scale={0.204}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-24.025, 0.348, 29.204]}
        rotation={[Math.PI, -0.965, Math.PI]}
        scale={0.243}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[-13.82, 0.344, 27.857]}
        scale={biomeName === "desert" ? 0.44 : 0.217}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry9}
        material={props.bakedAssetsMaterial}
        position={[-24.783, 1.127, 25.284]}
        scale={biomeName === "desert" || biomeName === "snow" ? 0.38 : 0.191}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[-21.382, 0.344, 20.622]}
        rotation={[-Math.PI, 1.486, -Math.PI]}
        scale={biomeName === "desert" ? 0.42 : 0.209}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry9}
        material={props.bakedAssetsMaterial}
        position={[-20.019, 0.344, 31.456]}
        scale={biomeName === "desert" || biomeName === "snow" ? 0.44 : 0.218}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry9}
        material={props.bakedAssetsMaterial}
        position={[-14.588, 0.344, 34.9]}
        scale={biomeName === "desert" || biomeName === "snow" ? 0.42 : 0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[-19.08, 0.344, 25.964]}
        scale={biomeName === "desert" ? 0.4 : 0.202}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry9}
        material={props.bakedAssetsMaterial}
        position={[-18.426, 0.344, 18.685]}
        rotation={[0, -0.811, 0]}
        scale={biomeName === "desert" || biomeName === "snow" ? 0.44 : 0.223}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[-30.318, 0.348, 22.338]}
        rotation={[0, -0.645, 0]}
        scale={0.233}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry9}
        material={props.bakedAssetsMaterial}
        position={[-28.114, 0.344, 10.025]}
        scale={biomeName === "desert" || biomeName === "snow" ? 0.36 : 0.178}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-16.234, 0.348, 26.636]}
        rotation={[0, 0.093, 0]}
        scale={0.195}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-19.297, 0.348, 21.437]}
        rotation={[0, 0.439, 0]}
        scale={0.217}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[-36.035, 0.344, 19.429]}
        rotation={[-Math.PI, 1.486, -Math.PI]}
        scale={biomeName === "desert" ? 0.38 : 0.193}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[20.348, 0.348, 21.311]}
        rotation={[0, -0.772, 0]}
        scale={0.273}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[19.614, 0.348, 22.223]}
        rotation={[0, -0.355, 0]}
        scale={0.213}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[19.356, 0.303, 26.067]}
        rotation={[0, -0.355, 0]}
        scale={0.245}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry9}
        material={props.bakedAssetsMaterial}
        position={[19.51, 0.344, 18.401]}
        scale={biomeName === "desert" || biomeName === "snow" ? 0.42 : 0.208}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[20.522, 0.344, 23.57]}
        scale={biomeName === "desert" ? 0.46 : 0.228}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[18.595, 0.344, 28.396]}
        scale={biomeName === "desert" ? 0.48 : 0.243}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[21.072, 0.348, 20.123]}
        rotation={[0, -0.645, 0]}
        scale={0.233}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[18.465, 0.348, 26.871]}
        rotation={[0, -0.355, 0]}
        scale={0.173}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry12}
        material={props.bakedAssetsMaterial}
        position={[-0.938, 0.348, 34.425]}
        rotation={[0, 0.341, 0]}
        scale={biomeName === "swamp" ? 1 : 0.273}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[1.545, 0.312, 34.227]}
        rotation={[0, 0.759, 0]}
        scale={0.213}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[-3.785, 0.344, 32.974]}
        rotation={[0, 1.114, 0]}
        scale={biomeName === "desert" ? 0.42 : 0.208}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[2.377, 0.214, 34.375]}
        rotation={[0, 1.114, 0]}
        scale={biomeName === "desert" ? 0.46 : 0.228}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-0.706, 0.348, 31.842]}
        rotation={[0, 0.468, 0]}
        scale={0.194}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[-4.279, 0.348, 29.873]}
        rotation={[0, -0.324, 0]}
        scale={0.273}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry9}
        material={props.bakedAssetsMaterial}
        position={[-2.882, 0.344, 35.009]}
        rotation={[0, 0.448, 0]}
        scale={biomeName === "desert" || biomeName === "snow" ? 0.42 : 0.208}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[-1.482, 0.344, 31.765]}
        rotation={[0, 0.448, 0]}
        scale={biomeName === "desert" ? 0.4 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-2.296, 0.348, 28.489]}
        rotation={[0, -0.738, 0]}
        scale={0.188}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-3.812, 0.348, 30.588]}
        rotation={[0, 0.093, 0]}
        scale={0.213}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[4.944, 0.348, 34.11]}
        rotation={[0, 0.341, 0]}
        scale={0.273}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[8.751, 0.348, 34.175]}
        rotation={[0, 0.341, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry12}
        material={props.bakedAssetsMaterial}
        position={[16.071, 0.348, 31.15]}
        rotation={[0, 0.341, 0]}
        scale={biomeName === "swamp" ? 1 : 0.273}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[15.685, 0.348, 30.22]}
        rotation={[0, 0.341, 0]}
        scale={0.216}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[11.696, 0.348, 33.631]}
        rotation={[0, 0.341, 0]}
        scale={0.228}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[5.495, 0.348, 34.888]}
        rotation={[0, 0.341, 0]}
        scale={0.247}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[1.303, 0.344, 32.381]}
        scale={biomeName === "desert" ? 0.46 : 0.228}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[9.718, 0.344, 34.186]}
        scale={biomeName === "desert" ? 0.43 : 0.214}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[14.711, 0.344, 32.251]}
        scale={biomeName === "desert" ? 0.48 : 0.243}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[-1.682, 0.348, 30.252]}
        rotation={[0, 0.341, 0]}
        scale={0.233}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-2.47, 0.348, 24.945]}
        rotation={[0, 0.468, 0]}
        scale={0.194}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-4.947, 0.348, 25.341]}
        rotation={[0, -0.197, 0]}
        scale={0.188}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[-3.211, 0.344, 26.846]}
        rotation={[0, 1.114, 0]}
        scale={biomeName === "desert" ? 0.4 : 0.197}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[-4.647, 0.224, 25.964]}
        rotation={[0, 1.571, 0]}
        scale={0.237}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry9}
        material={props.bakedAssetsMaterial}
        position={[-25.501, 1.127, 40.726]}
        scale={biomeName === "desert" || biomeName === "snow" ? 0.31 : 0.155}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[-31.157, 1.127, 29.953]}
        scale={biomeName === "desert" ? 0.33 : 0.166}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry12}
        material={props.bakedAssetsMaterial}
        position={[-33.588, 0.348, 38.217]}
        rotation={[0, 0.093, 0]}
        scale={biomeName === "swamp" ? 1 : 0.161}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-14.555, 0.348, 46.567]}
        rotation={[0, 0.093, 0]}
        scale={0.195}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[-23.713, 0.348, 47.285]}
        rotation={[0, 0.093, 0]}
        scale={0.195}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[-21.085, 0.348, 16.194]}
        rotation={[-Math.PI, 0.674, -Math.PI]}
        scale={0.217}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-22.227, 0.348, 24.933]}
        rotation={[0, -0.894, 0]}
        scale={0.217}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-20.819, 0.348, 28.967]}
        rotation={[-Math.PI, 1.448, -Math.PI]}
        scale={0.217}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-25.345, 0.348, 19.928]}
        rotation={[0, -0.894, 0]}
        scale={0.217}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[-23.572, 0.348, 23.28]}
        rotation={[0, -0.894, 0]}
        scale={0.217}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry10}
        material={props.bakedAssetsMaterial}
        position={[-8.291, -0.177, 32.756]}
        rotation={[-Math.PI, 1.251, -Math.PI]}
        scale={0.213}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry4}
        material={props.bakedAssetsMaterial}
        position={[-8.457, -0.036, 32.308]}
        rotation={[0, 0.034, 0]}
        scale={0.168}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry4}
        material={props.bakedAssetsMaterial}
        position={[-6.288, 0.093, 32.853]}
        rotation={[0, -1.299, 0]}
        scale={0.17}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry10}
        material={props.bakedAssetsMaterial}
        position={[-6.041, 0.281, 33.175]}
        rotation={[-Math.PI, 1.01, -Math.PI]}
        scale={0.17}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-10.057, 0.361, 25.304]}
        rotation={[0, -0.563, 0]}
        scale={biomeName === "beach" ? 0.25 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-10.354, 0.361, 28.693]}
        rotation={[0, 0.33, 0]}
        scale={biomeName === "beach" ? 0.25 : 0.193}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-9.646, 0.361, 30.441]}
        rotation={[Math.PI, -1.311, Math.PI]}
        scale={biomeName === "beach" ? 0.28 : 0.224}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-11.358, 0.361, 33.454]}
        rotation={[0, 0.622, 0]}
        scale={biomeName === "beach" ? 0.28 : 0.224}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-6.29, 0.361, 30.295]}
        rotation={[Math.PI, -0.919, Math.PI]}
        scale={biomeName === "beach" ? 0.25 : 0.197}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-4.338, 0.361, 35.577]}
        rotation={[-Math.PI, 0.379, -Math.PI]}
        scale={biomeName === "beach" ? 0.28 : 0.224}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-7.831, 0.361, 36.88]}
        rotation={[0, -1.002, 0]}
        scale={biomeName === "beach" ? 0.28 : 0.224}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-5.603, 0.361, 27.707]}
        rotation={[0, 0.674, 0]}
        scale={biomeName === "beach" ? 0.25 : 0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[16.564, 0.344, 18.044]}
        rotation={[0, 1.051, 0]}
        scale={biomeName === "desert" ? 0.41 : 0.205}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[12.89, 0.348, 18.23]}
        rotation={[0, -0.355, 0]}
        scale={0.173}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[18.804, 0.83, -2.182]}
        rotation={[0, 0.281, 0]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[10.938, 0.522, 14.932]}
        rotation={[Math.PI, -0.737, Math.PI]}
        scale={0.264}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[12.625, 0.647, 14.596]}
        rotation={[0, -0.503, 0]}
        scale={0.242}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[11.853, 0.727, 16.096]}
        rotation={[Math.PI, -0.816, Math.PI]}
        scale={0.226}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[14.024, 0.83, 15.059]}
        rotation={[0, 0.974, 0]}
        scale={0.255}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[14.836, 0.78, 14.518]}
        rotation={[0, -0.645, 0]}
        scale={0.208}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[16.699, 0.83, 7.747]}
        rotation={[0, 0.919, 0]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[18.121, 0.635, 8.515]}
        rotation={[0, -1.367, 0]}
        scale={0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[19.058, 1.136, 6.097]}
        rotation={[Math.PI, -1.536, Math.PI]}
        scale={0.228}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[17.721, 0.6, 7.036]}
        rotation={[Math.PI, -1.115, Math.PI]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[16.955, 1.072, 6.097]}
        rotation={[0, -0.645, 0]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[17.296, 0.83, 11.161]}
        rotation={[0, 0.393, 0]}
        scale={0.261}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[15.931, 0.522, 9.738]}
        rotation={[0, 1.419, 0]}
        scale={0.253}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry12}
        material={props.bakedAssetsMaterial}
        position={[15.788, 0.508, 10.507]}
        rotation={[0, -1.272, 0]}
        scale={biomeName === "swamp" ? 1 : 0.277}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[15.248, 0.599, 12.1]}
        rotation={[Math.PI, -1.425, Math.PI]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[14.105, 0.517, 13.01]}
        rotation={[Math.PI, -1.291, Math.PI]}
        scale={0.242}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[17.566, 0.83, -1.556]}
        rotation={[0, -0.987, 0]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[17.78, 0.83, -0.02]}
        rotation={[0, 1.507, 0]}
        scale={0.259}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry12}
        material={props.bakedAssetsMaterial}
        position={[18.207, 0.83, 0.976]}
        rotation={[Math.PI, -1.476, Math.PI]}
        scale={biomeName === "swamp" ? 1 : 0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[16.955, 1.452, 1.004]}
        rotation={[0, 0.42, 0]}
        scale={0.226}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[17.638, 0.83, 1.971]}
        rotation={[Math.PI, -1.305, Math.PI]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[18.178, 0.915, 2.74]}
        rotation={[-Math.PI, 1.484, -Math.PI]}
        scale={0.245}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[17.581, 0.732, 3.707]}
        rotation={[0, -1.305, 0]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[16.898, 1.625, 3.991]}
        rotation={[0, -0.645, 0]}
        scale={0.252}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[17.638, 0.661, 5.158]}
        rotation={[-Math.PI, 0.142, -Math.PI]}
        scale={0.257}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[19.373, 1.657, 4.304]}
        rotation={[0, -0.645, 0]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[19.544, 1.205, 0.414]}
        rotation={[0, 0.278, 0]}
        scale={0.227}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[16.642, 1.523, -3.69]}
        rotation={[0, 0.139, 0]}
        scale={0.243}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[16.812, 0.736, 10.194]}
        rotation={[0, 0.13, 0]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[7.732, 0.341, 15.969]}
        rotation={[0, 1.229, 0]}
        scale={0.194}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[11.502, 0.443, 13.646]}
        rotation={[Math.PI, -0.93, Math.PI]}
        scale={0.266}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[9.606, 0.436, 15.961]}
        rotation={[0, -0.645, 0]}
        scale={0.254}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[11.279, 0.31, 17.635]}
        rotation={[0, 1.012, 0]}
        scale={0.192}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[14.37, 0.39, 17.012]}
        rotation={[0, -0.645, 0]}
        scale={0.228}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[13.48, 0.633, 11.451]}
        rotation={[-Math.PI, 0.359, -Math.PI]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[20.729, 1.854, -0.821]}
        rotation={[Math.PI, -1.567, Math.PI]}
        scale={0.227}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[20.99, 2.194, 3.233]}
        rotation={[-Math.PI, 1.205, -Math.PI]}
        scale={0.231}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[21.51, 2.581, 0.724]}
        rotation={[Math.PI, -0.125, Math.PI]}
        scale={0.252}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[20.402, 1.434, 1.741]}
        rotation={[0, 0.282, 0]}
        scale={0.252}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[21.311, 2.666, -2.147]}
        rotation={[0, -0.678, 0]}
        scale={0.242}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[19.523, 1.553, -1.216]}
        rotation={[0, -1.449, 0]}
        scale={0.294}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry8}
        material={props.bakedAssetsMaterial}
        position={[17.938, 1.312, -3.187]}
        rotation={[0, -1.451, 0]}
        scale={0.267}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[17.997, 0.349, 21.732]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[17.633, 0.349, 22.246]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.169}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[12.305, 0.349, 20.114]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[9.81, 0.349, 17.438]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[12.69, 0.349, 19.524]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.138}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[19.698, 0.349, 24.793]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[19.992, 0.349, 19.714]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[17.657, 0.349, 25.677]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[17.249, 0.349, 29.532]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[16.75, 0.349, 30.167]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[11.874, 0.349, 30.349]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.167}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[11.829, 0.349, 31.074]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[12.963, 0.349, 32.14]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-0.983, 0.349, 28.489]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-1.005, 0.349, 24.816]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-0.62, 0.349, 24.408]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.154}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-4.18, 0.349, 28.013]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-3.02, 0.349, 31.509]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[0.644, 0.349, 31.43]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[4.014, 0.349, 33.451]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.166}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[7.103, 0.349, 34.538]}
        scale={biomeName === "swamp" ? 1 : 0.166}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-0.241, 0.349, 32.728]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.166}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[15.888, 0.349, 17.138]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[18.394, 0.349, 17.549]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-14.794, 0.349, 14.321]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-16.967, 0.349, 19.342]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-15.898, 0.349, 18.599]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-15.97, 0.349, 21.816]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-21.518, 0.349, 22.751]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-19.648, 0.349, 19.592]}
        rotation={[0, -0.229, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-19.37, 0.349, 13.777]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-13.08, 0.349, 29.116]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-12.572, 0.349, 28.757]}
        rotation={[0, -0.229, 0]}
        scale={biomeName === "swamp" ? 1 : 0.196}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-18.675, 0.349, 31.659]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-11.076, 0.349, 25.256]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-9.4, 0.349, 26.513]}
        rotation={[0, -0.229, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-9.849, 0.349, 28.278]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-11.794, 0.349, 32.108]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-2.661, 0.349, 33.872]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-5.653, 0.349, 31.06]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-5.653, 0.349, 25.196]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-9.153, 0.349, 32.167]}
        scale={biomeName === "swamp" ? 1 : 0.171}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-9.729, 0.349, 35.07]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-16.431, 0.349, 33.335]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-11.905, 0.349, 36.606]}
        rotation={[0, -0.229, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-16.931, 0.349, 38.069]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[5.856, 0.349, 16.139]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.199}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[4.673, 0.349, 16.515]}
        scale={biomeName === "swamp" ? 1 : 0.161}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-1.129, 0.471, 16.428]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.163}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-1.804, 0.507, 16.358]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-6.668, 0.392, 29.361]}
        scale={biomeName === "swamp" ? 0.4 : 0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-9.206, 0.392, 27.795]}
        scale={biomeName === "swamp" ? 0.4 : 0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-8.484, 0.392, 24.656]}
        scale={biomeName === "swamp" ? 0.4 : 0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-9.933, 0.463, 15.999]}
        scale={biomeName === "swamp" ? 0.4 : 0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-6.662, 0.392, 16.429]}
        scale={biomeName === "swamp" ? 0.4 : 0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-6.584, 0.392, 24.822]}
        scale={biomeName === "swamp" ? 0.4 : 0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-26.701, 0.348, 3.077]}
        rotation={[0, -0.645, 0]}
        scale={0.224}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[-35.777, 0.348, 8.421]}
        rotation={[0, -0.645, 0]}
        scale={0.224}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry6}
        material={props.bakedAssetsMaterial}
        position={[-41.156, 0.344, 14.516]}
        scale={biomeName === "desert" ? 0.36 : 0.178}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[12.636, 0.31, 17.017]}
        rotation={[0, -0.971, 0]}
        scale={0.192}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-5.81, 0.317, 33.433]}
        rotation={[0, 0.662, 0]}
        scale={0.298}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-7.874, 0.317, 26.021]}
        rotation={[Math.PI, -0.09, Math.PI]}
        scale={0.298}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-6.413, 0.317, 32.709]}
        rotation={[0, 0.4, 0]}
        scale={0.249}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-7.842, 0.317, 26.938]}
        rotation={[-Math.PI, 0.172, -Math.PI]}
        scale={0.249}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-6.116, 0.317, 33.232]}
        rotation={[0, -0.344, 0]}
        scale={0.336}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-6.317, 0.317, 32.957]}
        rotation={[0, 0.4, 0]}
        scale={0.249}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-5.629, 0.317, 33.657]}
        rotation={[0, 0.662, 0]}
        scale={0.194}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-6.43, 0.287, 32.477]}
        rotation={[0, 0.662, 0]}
        scale={0.196}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-8.827, 0.317, 15.149]}
        rotation={[Math.PI, -0.217, Math.PI]}
        scale={0.236}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-8.973, 0.317, 15.407]}
        rotation={[Math.PI, -0.217, Math.PI]}
        scale={0.298}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-8.955, 0.317, 16.197]}
        rotation={[-Math.PI, 0.045, -Math.PI]}
        scale={0.249}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-9.051, 0.317, 15.838]}
        rotation={[-Math.PI, 0.789, -Math.PI]}
        scale={0.336}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-7.809, 0.317, 26.338]}
        rotation={[-Math.PI, 0.916, -Math.PI]}
        scale={0.336}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-7.788, 0.317, 26.678]}
        rotation={[-Math.PI, 0.172, -Math.PI]}
        scale={0.249}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-7.97, 0.317, 25.765]}
        rotation={[Math.PI, -0.09, Math.PI]}
        scale={0.194}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry7}
        material={props.bakedAssetsMaterial}
        position={[-7.953, 0.287, 27.143]}
        rotation={[Math.PI, -0.09, Math.PI]}
        scale={0.196}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-9.129, 0.392, 19.598]}
        rotation={[0, 1.274, 0]}
        scale={biomeName === "swamp" ? 0.4 : 0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-7.264, 0.392, 21.693]}
        rotation={[0, -1.323, 0]}
        scale={biomeName === "swamp" ? 0.4 : 0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry1}
        material={props.bakedAssetsMaterial}
        position={[-2.048, 1.129, 14.794]}
        rotation={[0, 1.229, 0]}
        scale={0.192}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry5}
        material={props.bakedAssetsMaterial}
        position={[-5.177, 0.926, 14.918]}
        rotation={[0, -0.563, 0]}
        scale={biomeName === "beach" ? 0.25 : 0.114}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry11}
        material={props.bakedAssetsMaterial}
        position={[-3.587, 0.851, 15.326]}
        rotation={[0, 0.124, 0]}
        scale={0.145}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-5.092, 0.349, 20.576]}
        rotation={[0, -1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-6.234, 0.349, 18.786]}
        rotation={[0, 1.355, 0]}
        scale={biomeName === "swamp" ? 1 : 0.154}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-6.464, 0.349, 19.448]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry4}
        material={props.bakedAssetsMaterial}
        position={[-9.491, -0.177, 21.224]}
        rotation={[0, -1.384, 0]}
        scale={0.213}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry4}
        material={props.bakedAssetsMaterial}
        position={[-9.302, -0.075, 21.765]}
        rotation={[-Math.PI, 0.571, -Math.PI]}
        scale={0.168}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-9.867, 0.349, 18.231]}
        rotation={[Math.PI, -0.596, Math.PI]}
        scale={biomeName === "swamp" ? 1 : 0.183}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-10.222, 0.349, 20.072]}
        rotation={[Math.PI, -0.596, Math.PI]}
        scale={biomeName === "swamp" ? 1 : 0.208}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry3}
        material={props.bakedAssetsMaterial}
        position={[-9.758, 0.349, 18.556]}
        rotation={[0, -1.482, 0]}
        scale={biomeName === "swamp" ? 1 : 0.173}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-8.777, 0.392, 22.441]}
        rotation={[0, -0.186, 0]}
        scale={biomeName === "swamp" ? 0.4 : 0.251}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={geometry2}
        material={props.bakedAssetsMaterial}
        position={[-8.939, 0.392, 18.16]}
        rotation={[0, -0.523, 0]}
        scale={biomeName === "swamp" ? 0.4 : 0.188}
      />
      {/* Rocas para los distintos biomas*/}
      {biomeName === "jungle" && (
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometryRockPilar}
          material={props.bakedAssetsMaterial}
          position={[-12.9, -0.12, 32.32]}
          rotation={[0, -0.523, 0]}
          scale={0.194}
        />
      )}
      {biomeName === "jungle" && (
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometryRockPilar}
          material={props.bakedAssetsMaterial}
          position={[0.25, -0.13, 24.78]}
          rotation={[0, 2.2, 0]}
          scale={0.206}
        />
      )}
      {(biomeName === "jungle" ||
        biomeName === "desert" ||
        biomeName === "savannah") && (
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={geometryRockSharp}
            material={props.bakedAssetsMaterial}
            position={[16.86, 0.3, 19.25]}
            rotation={[0, 2.523, 0]}
            scale={0.164}
          />
        )}
      {(biomeName === "jungle" ||
        biomeName === "desert" ||
        biomeName === "savannah") && (
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={
              biomeName === "savannah" ? geometryRockPilar : geometryRockSharp
            }
            material={props.bakedAssetsMaterial}
            position={[15.83, 0.3, 29.06]}
            rotation={[0, 1.823, 0]}
            scale={0.144}
          />
        )}
      {(biomeName === "jungle" ||
        biomeName === "desert" ||
        biomeName === "savannah") && (
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={geometryRockSharp}
            material={props.bakedAssetsMaterial}
            position={[8.65, 0.3, 32.67]}
            rotation={[0, 3, 0]}
            scale={0.186}
          />
        )}
      {(biomeName === "jungle" ||
        biomeName === "desert" ||
        biomeName === "savannah") && (
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={geometryRockSharp}
            material={props.bakedAssetsMaterial}
            position={[-2.9, 0.3, 32.69]}
            rotation={[0, 1.823, 0]}
            scale={0.136}
          />
        )}
      {(biomeName === "jungle" ||
        biomeName === "desert" ||
        biomeName === "savannah") && (
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={
              biomeName === "savannah" ? geometryRockPilar : geometryRockSharp
            }
            material={props.bakedAssetsMaterial}
            position={[-3.88, 0.3, 24.5]}
            rotation={[0, -0.3, 0]}
            scale={0.186}
          />
        )}
      {(biomeName === "jungle" ||
        biomeName === "desert" ||
        biomeName === "savannah") && (
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={
              biomeName === "savannah" ? geometryRockPilar : geometryRockSharp
            }
            material={props.bakedAssetsMaterial}
            position={[-17.41, 0.3, 13.96]}
            rotation={[0, -0.3, 0]}
            scale={0.16}
          />
        )}
      {(biomeName === "jungle" ||
        biomeName === "desert" ||
        biomeName === "savannah") && (
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={geometryRockSharp}
            material={props.bakedAssetsMaterial}
            position={[-12.22, 0.3, 25.11]}
            rotation={[0, 3, 0]}
            scale={0.14}
          />
        )}
      {(biomeName === "jungle" ||
        biomeName === "desert" ||
        biomeName === "savannah") && (
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={geometryRockSharp}
            material={props.bakedAssetsMaterial}
            position={[-20, 0.3, 29.67]}
            rotation={[0, -2, 0]}
            scale={0.156}
          />
        )}
      {biomeName === "savannah" && (
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometryRockPilar}
          material={props.bakedAssetsMaterial}
          position={[-12.9, 0.3, 32.32]}
          rotation={[0, -0.523, 0]}
          scale={0.194}
        />
      )}
    </group>
  );
};

useGLTF.preload("/vegetacion_segunda_tem.glb");

export default Vegetation;
