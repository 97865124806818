import "./DoughnutChart.css";
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import HeartIcon from "../../../assets/student/T2/full_heart.tsx";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ ...props }) => {
  const { t } = useTranslation();
  const data = {
    labels: [t("item_1"), t("item_2"), t("item_3"), t("void")],
    datasets: [
      {
        label: (t("probabilities") + "(%)"),
        data: props.chances,
        backgroundColor: ["#6EE6A7", "#6DF2F2", "#FECF3C", "#FFFFFF"],
        borderColor: ["#6EE6A7", "#6DF2F2", "#FECF3C", "#FFFFFF"],
        borderWidth: 1,
        // borderRadius: {
        //   outerStart: 50,
        //   outerEnd: 0,
        //   innerStart: 50,
        //   // innerEnd: 5
        // },
        borderRadius: 10,
        // offset: -50,
        // circular: true,
        // spacing: -120,
      },
    ],
  };

  const options: any = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          family: "Causten",
        },
        bodyFont: {
          family: "Causten",
        },
        yAlign: "bottom",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    rotation: 235, // degrees
    circumference: 250,
    cutout: "70%",
  };

  return (
    <div className="doughnut_chart">
      <Doughnut data={data} options={options} />
      <div className="doughnut_chart_heart">
        <HeartIcon height={"14vh"} text={props.biomeHealth} />
      </div>
    </div>
  );
};

export default DoughnutChart;
