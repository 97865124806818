const Time = ({ ...props }) => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8334 15.695C27.8334 22.6146 22.2239 28.224 15.3044 28.224C8.38481 28.224 2.77539 22.6146 2.77539 15.695C2.77539 8.77543 8.38481 3.16602 15.3044 3.16602C22.2239 3.16602 27.8334 8.77543 27.8334 15.695Z"
        stroke={props.color}
        stroke-width="3"
      />
      <path
        d="M15.3047 6.76758V15.6951L20.4061 20.7966"
        stroke={props.color !== undefined ? props.color : "black"}
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Time;
