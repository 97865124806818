import { useState, useEffect } from "react";
import "./ImageSelector.css";
import close2 from "../../../../../assets/professor/close_user_menu.svg";
import leftArrow from "../../../../../assets/exercises/left_arrow.svg";
import leftArrowTransparent from "../../../../../assets/exercises/left_arrow_transparent.svg";
import rightArrow from "../../../../../assets/exercises/right_arrow.svg";
import rightArrowTransparent from "../../../../../assets/exercises/right_arrow_transparent.svg";
import imagePlaceholder from "../../../../../assets/professor/image_pic.svg";
import Lottie from "lottie-react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { PutImageAPI } from "../../../../../_newapios/content/image";
// import { getImages } from "../../../../../_newapios/content/image";
import { useAppSelector } from "../../../../../hooks/hooks";
import { ImageType } from "../../../../../_newapios/content/unit";
const pako = require("pako");

const ImageSelector = ({ ...props }) => {
  const { t, i18n } = useTranslation();

  const animations = useAppSelector((state) => state.main).animations;

  const [image, setImage] = useState(props.image);
  const [animationUrl, setAnimationUrl] = useState(props.animation);
  const [animationJson, setAnimationJson] = useState<any>();
  console.log(image, animationUrl);

  useEffect(() => {
    const newImage = props.image;
    const newAnimation = props.animation;
    setImage(newImage);
    setAnimationUrl(newAnimation);
    if (newAnimation !== undefined) {
      fetchGzipJSON(newAnimation);
    }
  }, [props.image, props.animation]);

  const handleImage = (event: any) => {
    // event.preventDefault()
    // event.stopPropagation()
    // Trigger file selection
    (document.getElementById("image_file_selector") as HTMLInputElement).value =
      "" as string;
    (document.getElementById("image_file_selector") as HTMLElement).click();
  };

  const onChangeFile = (event: any) => {
    const file = event.target.files[0] || "";
    PutImageAPI(file, props.exerciseId).then((exercise) => {
      props.imageHandler(exercise);
    });
  };

  const fetchGzipJSON = async (animation_url: string) => {
    if (animation_url === "") {
      return;
    }
    // let inflatedJSON = {};
    let inflatedData = {};
    try {
      // Fetch the GZIP compressed data
      const { data } = await axios.get(animation_url, {
        responseType: "arraybuffer",
        decompress: true,
      });
      // Decompress the data using pako
      // inflatedJSON = JSON.parse(pako.inflate(data, { to: "string" }));
      inflatedData = pako.inflate(data, { to: "string" });

      const json = JSON.parse(inflatedData as string);

      setAnimationJson(json);
    } catch (error) {
      console.error("could not fetch gzip json", error);
    }
  };

  const renderTemplate = () => {
    if (image !== "" && image !== undefined && !animations) {
      return (
        <div
          className="image_image_container"
          style={{ backgroundImage: "url('" + image + "')" }}
          onClick={(e) => handleImage(e)}
        >
          <input
            id="image_file_selector"
            type="file"
            name=""
            hidden
            accept="image/png, image/gif, image/jpeg, image/svg+xml"
            onChange={onChangeFile}
          />
        </div>
      );
    }

    if (animationUrl !== undefined && animations) {
      console.log("animationJson", animationJson);
      return (
        <div className="image_image_container">
          <Lottie
            animationData={animationJson}
            loop={animations ? true : false}
            autoplay={true}
            initialSegment={animations ? undefined : [60, 60]}
            onClick={(e) => handleImage(e)}
            style={{ height: "50vh" }}
          />
        </div>
      );
    }

    return (
      <motion.div
        className="image_select_container"
        onClick={(e) => handleImage(e)}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #5090F0"
        }}
        transition={
          { type: "spring", stiffness: 300, damping: 10 }
        }
      >
        <div className="image_select_icon" />
        <div className="image_select_button" />
        <input
          id="image_file_selector"
          className="image_select_button"
          type="file"
          name=""
          hidden
          accept="image/png, image/gif, image/jpeg"
          onChange={onChangeFile}
        />
        <div className="image_select_text">{t("add_image")}</div>
      </motion.div>
    );
  };

  return <>{renderTemplate()}</>;
};

export default ImageSelector;
