import "./InfoBar.css";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/hooks";
import kilowattsIcon from "../../../assets/student/kilowatts_icon.svg";
import populationIcon from "../../../assets/student/population_icon.svg";
import co2Icon from "../../../assets/student/co2_icon.svg";
import biodiversityIcon from "../../../assets/student/T2/biodiversity.svg";
import ecosystemsDestructionIcon from "../../../assets/student/T2/ecosystems_destruction.svg";
import geneticMaterialIcon from "../../../assets/student/T2/genetic_material.svg";

const InfoBar = ({ ...props }) => {
  const { t } = useTranslation();
  const studentInfo = useAppSelector((state) => state.student).infoBar;
  const theme = useAppSelector((state) => state.student).theme;

  if (studentInfo == null || studentInfo.display == "hidden") {
    return <></>;
  }

  const studentName = studentInfo.name;
  const studentPower = studentInfo.power;
  const studentCO2 = studentInfo.co2;
  const studentPopulation = studentInfo.population;

  const studentBiodiversity = studentInfo.biodiversity;
  const studentActiveItems = studentInfo.activeItems;
  const studentDestruction = studentInfo.destruction;

  if (props.localMap) {
    return (
      <div
        className="plot_details_main"
        style={props.isTeacher ? { top: "89%" } : {}}
      >
        <div className="plot_details_text_wrapper">
          <div className="plot_details_text">
            <div className="center_container">
              <div className="text_white_medium_bold">{t("visit_text")}</div>
            </div>
          </div>
          <div
            className="plot_details_background"
            style={{ padding: 0, background: "none" }}
          >
            <div className="plot_details_title">{studentName}</div>
            <div className="plot_details_left">
              <div className="center_container">
                <img
                  className="image"
                  src={
                    theme?.id === "theme_id"
                      ? kilowattsIcon
                      : geneticMaterialIcon
                  }
                  alt={theme?.id === "theme_id" ? "kilowatts" : "items"}
                  style={{ maxHeight: "20px" }}
                />
                <div className="text_white_very_small_bold">
                  {theme?.id === "theme_id"
                    ? studentPower + "kW"
                    : studentActiveItems + " items"}
                </div>
              </div>
            </div>
            <div className="plot_details_center">
              <div className="center_container">
                <img
                  className="image"
                  src={
                    theme?.id === "theme_id"
                      ? co2Icon
                      : ecosystemsDestructionIcon
                  }
                  alt={theme?.id === "theme_id" ? "co2" : "destruction"}
                  style={{ maxHeight: "25px" }}
                />
                <div className="text_white_very_small_bold">
                  {theme?.id === "theme_id"
                    ? studentCO2 + "T"
                    : studentDestruction + " " + t("destruction")}
                </div>
              </div>
            </div>
            <div className="plot_details_right">
              <div className="center_container">
                <img
                  className="image"
                  src={
                    theme?.id === "theme_id" ? populationIcon : biodiversityIcon
                  }
                  alt={theme?.id === "theme_id" ? "population" : "biodiversity"}
                  style={{ maxHeight: "20px" }}
                />
                <div className="text_white_very_small_bold">
                  {theme?.id === "theme_id"
                    ? studentPopulation + t("ppl")
                    : studentBiodiversity + " eutons"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="plot_details_main"
        style={props.isTeacher ? { top: "89%" } : {}}
      >
        <div className="plot_details_background">
          <div className="plot_details_title">{studentName}</div>
          <div className="plot_details_left">
            <div className="center_container">
              <img
                className="image"
                src={
                  theme?.id === "theme_id" ? kilowattsIcon : geneticMaterialIcon
                }
                alt={theme?.id === "theme_id" ? "kilowatts" : "items"}
                style={{ maxHeight: "20px", marginRight: "5px" }}
              />
              <div className="text_white_very_small_bold">
                {theme?.id === "theme_id"
                  ? studentPower + "kW"
                  : studentActiveItems + " items"}
              </div>
            </div>
          </div>
          <div className="plot_details_center">
            <div className="center_container">
              <img
                className="image"
                src={
                  theme?.id === "theme_id" ? co2Icon : ecosystemsDestructionIcon
                }
                alt={theme?.id === "theme_id" ? "co2" : "destruction"}
                style={{ maxHeight: "25px", marginRight: "5px" }}
              />
              <div className="text_white_very_small_bold">
                {theme?.id === "theme_id"
                  ? studentCO2 + "T"
                  : studentDestruction + " " + t("destruction")}
              </div>
            </div>
          </div>
          <div className="plot_details_right">
            <div className="center_container">
              <img
                className="image"
                src={
                  theme?.id === "theme_id" ? populationIcon : biodiversityIcon
                }
                alt={theme?.id === "theme_id" ? "population" : "biodiversity"}
                style={{ maxHeight: "20px", marginRight: "5px" }}
              />
              <div className="text_white_very_small_bold">
                {theme?.id === "theme_id"
                  ? studentPopulation + t("ppl")
                  : studentBiodiversity + " eutons"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default InfoBar;
