const DefinitionIcon =(props: {
    color: string;
    size?: number; // Adding size as an optional number prop
} = {
    color: "#000000",
    size: 48 // Default size set to 48
}) => {
    const { color, size } = props; // Destructuring props

    return(
        <svg width= {size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="flag">
                <path id="Path 2" d="M10 10V40" stroke={color} stroke-width="3" stroke-linecap="round"/>
                <path id="Path 27" d="M10 12C10 12 13.5 8 18.75 8C24 8 28 12 32 12C36 12 38 10 38 10" stroke={color} stroke-width="3" stroke-linecap="round"/>
                <path id="Path 27 Copy" d="M10 30C10 30 13.5 26 18.75 26C24 26 28 30 32 30C36 30 38 28 38 28V10" stroke={color} stroke-width="4" stroke-linecap="round"/>
            </g>
        </svg>
    )
}

export default DefinitionIcon;