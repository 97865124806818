// import { toast } from "../index.tsx";
import { store } from "../store";

const APIFetch = async (url: string, options: RequestInit = {}) => {

    try {
        const response = await fetch(url, {
            ...options,
            "credentials": "include",
            "mode": "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                ...options.headers,
            },
        });
        console.log("estamos pasando por APIFetch")

        if (response.status === 401) {
            // toast({
            //     title: "Unauthorized. Please log in.",
            //     status: "error",
            //     duration: 5000,
            //     isClosable: true
            // });
            // console.log("Dispatching setUnauthorized(true)");

            store.dispatch({ type: "main/setUnauthorized", payload: true });
            console.warn("Unauthorized. Please log in.");
            return;
        }

        return await response.json();
    } catch (error) {
        console.error("API Fetch Error:", error);
        throw error;
    }
};

export default APIFetch;
