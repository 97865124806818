import { ImageType, AnimationType } from "../_newapios/content/unit";
import { BiomeType } from "../_newapios/gamecontent/biomes";
import { BiomeNameType } from "../_newapios/gamecontent/plots";

// Convert BiomeNameType from string to number
export const getBiomeNameTypePosition = (type: BiomeNameType): number => {
  const BiomeNameTypes: BiomeNameType[] = [
    "swamp",
    "desert",
    "savannah",
    "grassland",
    "volcano",
    "beach",
    "jungle",
    "mountain",
    "snow",
  ];
  return BiomeNameTypes.indexOf(type);
};
// Convert BiomeNameType from string to number
export const getBiomeFromNumber = (index: number): BiomeNameType => {
  const BiomeNameTypes: BiomeNameType[] = [
    "swamp",
    "desert",
    "savannah",
    "grassland",
    "volcano",
    "beach",
    "jungle",
    "mountain",
    "snow",
  ];
  return BiomeNameTypes[index];
};

export const calcBiomePoints = (
  studentBiome: BiomeType | undefined,
  biome: BiomeType
): number => {
  if (!studentBiome) return 0;

  // 2 points - Neutral biomes

  var points = 2;

  // 1 point - Same biome

  if (studentBiome.id == biome.id) {
    points = 1;
  }

  // 4 points - Contrary biome

  if (studentBiome.contrary_biome_id == biome.id) {
    points = 4;
  }

  // 3 points - Adverse biomes

  if (studentBiome.adverse_biome.map((b) => b.id).includes(biome.id)) {
    points = 3;
  }

  return points;
};
