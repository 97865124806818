import "./ScenarioEditMenu.css";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks.ts";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useMemo } from "react";
import { LazyMotion, m } from "framer-motion";
import BackCross from "../../../assets/professor/close.tsx";
import Plus from "../../../assets/professor/plus.tsx";
import bin from "../../../assets/professor/bin.svg";
import placeholderExp from "../../../assets/professor/placeholder_exp.png";
import placeholderInv from "../../../assets/professor/placeholder_inv.png";
import { toast } from "../../../index.tsx";

import Loading from "../../loading_screen/LoadingDots.tsx";
import {
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Textarea,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { motion } from "framer-motion";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
//import ScenarioEditMenu from "./ScenarioEditMenu.tsx";
import {
  EvaluationCriterionType,
  SpecificCompetenceType,
} from "../../../_newapios/content/unit.ts";
import {
  UpdateScenarioAPI,
  DeleteScenarioAPI,
  CreateScenarioAPI,
  ModifiedScenarioType,
  GetScenarioAPI,
  PermanentlyDeleteScenarioAPI,
} from "../../../_newapios/content/scenario.ts";
import {
  GetCourseEvaluationCriteriaAPI,
  GetCourseSpecificCompetencesAPI,
} from "../../../_newapios/content/course.ts";
import {
  globalCompetencesBooleanArray,
  prettifyCourseAcronyms,
} from "../../../utils/competences.ts";
import {
  GetResearchAPI,
  ResearchType,
} from "../../../_newapios/content/research.ts";
import {
  ExperimentType,
  GetExperimentAPI,
} from "../../../_newapios/content/experiment.ts";
import close from "../../../assets/student/close_user_menu.svg";
import _ from "lodash";
import Alert from "../../alerts/Alert.tsx";
import ExercisePreviewResearch from "./exercises_edit/ExercisePreviewResearch.tsx";
import ExercisePreviewExperiment from "./exercises_edit/ExercisePreviewExperiment.tsx";
import ScenarioImageSelector from "./ScenarioImageSelector/ScenarioImageSelector.tsx";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { simpleGlobalCompetences } from "../../../constants.ts";
import {
  menu,
  setDeleteOnExit,
  submenu,
  updateSelectedScenario,
} from "../../../reducers/teacherSlice.ts";

const ScenarioEditMenu = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const saveScenario = async (
    new_scenario: ModifiedScenarioType | null = null
  ) => {
    if (new_scenario === null) {
      new_scenario = scenario;
    }

    if (new_scenario === null) {
      return;
    }

    const updatedScenario = await UpdateScenarioAPI(
      new_scenario.id,
      new_scenario
    );
    dispatch(updateSelectedScenario(updatedScenario));
  };
  const { t } = useTranslation(["common", "evaluation"]);

  const allStudents = [{ value: 0, label: t("all_students") }];
  registerLocale("es", es);

  const weightingOptions = [
    { value: 0, label: "No evaluada - 0 puntos por situación" },
    { value: 50, label: "Baja - 50 puntos por situación" },
    { value: 100, label: "Estándar - 100 puntos por situación" },
    { value: 150, label: "Alta - 150 puntos por situación" },
    { value: 200, label: "Muy Alta - 200 puntos por situación" },
  ];

  const languages = [
    { value: "ES", label: t("spanish") },
    { value: "EN", label: t("english") },
    { value: "VAL", label: t("valencian") },
    { value: "CAT", label: t("catalonian") },
    { value: "GAL", label: t("galician") },
    { value: "EUS", label: t("basque") },
    { value: "DE", label: t("deutsch") },
    { value: "FR", label: t("french") },
  ];

  const visibilityOptions = [
    { value: 0, label: t("visibility_text_0") },
    { value: 1, label: t("visibility_text_1") },
    { value: 2, label: t("visibility_text_2") },
  ];
  const mainState = useAppSelector((state) => state.main);
  const teacherState = useAppSelector((state) => state.teacher);

  const courses = mainState.courses;
  const regulations = mainState.regulations;
  const globalCompetences = mainState.globalCompetences;
  const userLicense = teacherState.userLicense;

  const scenario = teacherState.selectedScenario;
  const [selectedRegulationId, setSelectedRegulationId] = useState(
    scenario?.course.regulation_id
  );
  const [loading, setLoading] = useState(false);

  const [openResearchEditMenu, setOpenResearchEditMenu] =
    useState<null | ResearchType>(null);
  const [openExperimentEditMenu, setOpenExperimentEditMenu] =
    useState<null | ExperimentType>(null);
  // const [selectedScenario, setSelectedScenario] = useState<any>();
  // const [scenarioIndex, setScenarioIndex] = useState<number>(0);

  const [evaluationCriteria, setEvaluationCriteria] = useState<
    EvaluationCriterionType[]
  >([]);

  const [specificCompetences, setSpecificCompetences] = useState<
    SpecificCompetenceType[]
  >([]);

  const [deleteMenu, setDeleteMenu] = useState<Boolean>(false);
  const [backMenu, setBackMenu] = useState<Boolean>(false);
  const [visibility, setVisibility] = useState<Number>(
    scenario?.visibility ?? 0
  );
  const [showHoverText, setShowHoverText] = useState<string>("");
  const [showHoverIcon, setShowHoverIcon] = useState<boolean>(false);
  const deleteOnExit = teacherState.deleteOnExit;

  const [alert, setAlert] = useState<string | null>(null);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    loadScenario();
  }, [scenario?.id]);

  const loadScenario = async () => {
    if (scenario === null) {
      return;
    }
    const newScenario = await GetScenarioAPI(scenario.id);
    dispatch(updateSelectedScenario(newScenario));
  };

  useEffect(() => {
    loadEvaluationCriteria();
    loadSpecificCompetences();
  }, [scenario?.course]);

  const loadEvaluationCriteria = async () => {
    if (scenario === null) {
      return;
    }
    setEvaluationCriteria(
      await GetCourseEvaluationCriteriaAPI(scenario.course.id)
    );
  };
  const loadSpecificCompetences = async () => {
    if (scenario === null) {
      return;
    }
    setSpecificCompetences(
      await GetCourseSpecificCompetencesAPI(scenario.course.id)
    );
  };

  if (scenario === null) {
    return;
  }

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const scenarioMainKnowHowHandler = (value: string) => {
    const newscenario = { ...scenario };
    newscenario.main_know_how = value;
    dispatch(updateSelectedScenario(newscenario));
  };

  const setScenarioName = (name: string) => {
    const newScenario = { ...scenario };
    newScenario.name = name;
    dispatch(updateSelectedScenario(newScenario));
  };

  const setScenarioDescription = (description: string) => {
    const newScenario = { ...scenario };
    newScenario.description = description;
    dispatch(updateSelectedScenario(newScenario));
  };

  const setScenarioImage = (image: string) => {
    const newScenario = { ...scenario };
    newScenario.image.url = image;
    dispatch(updateSelectedScenario(newScenario));
  };

  const setScenarioEvalCriteria = (evalCriterionId: string, i: number) => {
    const newScenario = { ...scenario };
    const newEvalCriteriaIds = [...newScenario.evaluation_criteria_ids];
    if (evalCriterionId === "delete") {
      newEvalCriteriaIds.splice(i, 1);
    } else {
      newEvalCriteriaIds[i] = evalCriterionId;
    }
    newScenario.evaluation_criteria_ids = newEvalCriteriaIds;
    dispatch(updateSelectedScenario(newScenario));
  };

  const addScenarioEvalCriteria = () => {
    const newScenario = { ...scenario };
    const newEvalCriteriaIds = [...newScenario.evaluation_criteria_ids, ""];
    newScenario.evaluation_criteria_ids = newEvalCriteriaIds;
    dispatch(updateSelectedScenario(newScenario));
  };

  const scenarioKnowHowsHandler = (value: any, i: number) => {
    const newScenario = { ...scenario };
    const newKnowHows = [...newScenario.other_know_hows];

    if (value === "") {
      newKnowHows.splice(i, 1);
    } else {
      newKnowHows[i] = value;
    }
    newScenario.other_know_hows = newKnowHows;
    dispatch(updateSelectedScenario(newScenario));
  };

  const addScenarioKnowHow = () => {
    const newScenario = { ...scenario };
    const newKnowHows = [...newScenario.other_know_hows, ""];
    newScenario.other_know_hows = newKnowHows;

    dispatch(updateSelectedScenario(newScenario));
  };

  const scenarioCourseHandler = (value: any) => {
    const newScenario = { ...scenario };

    if (value.value !== newScenario.course.id) {
      const newCourse = courses.find((course) => course.id === value.value);
      if (newCourse === undefined) {
        return;
      }
      newScenario.course = newCourse;
      newScenario.evaluation_criteria_ids = [];
    }
    dispatch(updateSelectedScenario(newScenario));
  };

  const scenarioRegulationHandler = (value: any) => {
    const newScenario = { ...scenario };
    if (value.value !== newScenario.course.regulation_id) {
      const newCourse = courses.find(
        (course) => course.regulation_id === value.value
      );

      if (newCourse === undefined) {
        return;
      }

      newScenario.course = newCourse;
      newScenario.evaluation_criteria_ids = [];
    }
    dispatch(updateSelectedScenario(newScenario));
    setSelectedRegulationId(value.value);
  };

  const scenarioLanguageHandler = (value: any) => {
    const newscenario = { ...scenario };
    newscenario.language = value.value;
    dispatch(updateSelectedScenario(newscenario));
  };

  const deleteScenarioHandler = async () => {
    await DeleteScenarioAPI(scenario.id);
    dispatch(submenu("marketplace"));
  };

  const permanentlyDeleteScenarioHandler = async () => {
    await PermanentlyDeleteScenarioAPI(scenario.id);
    dispatch(submenu("marketplace_selector"));
  };

  const deleteMenuHandler = () => {
    setDeleteMenu(true);
  };
  const visibililtyHandler = (value: any) => {
    const newscenario = { ...scenario };
    newscenario.visibility = value.value;
    dispatch(updateSelectedScenario(newscenario));
    setVisibility(newscenario.visibility);
  };

  const scenarioBackHandler = async () => {
    if (deleteOnExit) {
      await permanentlyDeleteScenarioHandler();
      dispatch(submenu("marketplace_selector"));
      dispatch(setDeleteOnExit(false));
      return;
    }
    dispatch(submenu("scenario"));
  };

  console.log(deleteOnExit);

  const scenarioSaveHandler = async () => {
    if (
      scenario.course.regulation_id !== undefined &&
      scenario.course.regulation_id !== null &&
      scenario.course.regulation_id !== "" &&
      scenario.course.id !== undefined &&
      scenario.course.id !== null &&
      scenario.course.id !== ""
    ) {
      await saveScenario();
      dispatch(setDeleteOnExit(false));
      dispatch(submenu("scenario"));
      toast({
        title: t("save_changes_toast"),
        status: "success",
        duration: 3000,
        variant: "subtle",
        isClosable: true,
      });
    } else {
      setAlert("scenario_reg_course_error");
    }
  };

  const researchHandler = async (researchId: string) => {
    const research = await GetResearchAPI(researchId);
    setOpenResearchEditMenu(research);
  };

  const experimentHandler = async (experimentId: string) => {
    const experiment = await GetExperimentAPI(experimentId);
    setOpenExperimentEditMenu(experiment);
  };

  const imageHandler = (newScenario: ModifiedScenarioType) => {
    loadScenario();
  };

  const alertHandler = () => {
    setAlert(null);
  };

  const renderTop = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div className="professor_edit_scenario_menu_top">
          <m.div
            className="professor_edit_scenario_menu_top_left_name"
            whileHover={{ scale: 1.1, rotate: 90 }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.1 }}
            onClick={() => setBackMenu(true)}
          >
            <BackCross />
          </m.div>
          <Input
            className="professor_edit_scenario_menu_top_left_name_input"
            placeholder={t("scenario_main_know_how")}
            value={scenario.main_know_how}
            onChange={(e) => scenarioMainKnowHowHandler(e.target.value)}
            style={{
              fontFamily: "Causten",
              fontWeight: "bold",
              width: "30vw",
              fontSize: "1.5em",
              borderRadius: "2.5px",
              color: "black",
              marginRight: "2vw",
              marginLeft: "1vw",
              boxShadow: "none",
            }}
            as={motion.input}
            size={"lg"}
            whileHover={{
              scale: 1.01,
              boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
            }}
          />
          <Select
            name="regulations"
            options={regulations.map((reg) => {
              return {
                value: reg.id,
                label: t(reg.name, { ns: "evaluation" }),
              };
            })}
            value={
              regulations
                .filter((reg) => reg.id === selectedRegulationId)
                .map((reg) => {
                  return {
                    value: reg.id,
                    label: t(reg.name, { ns: "evaluation" }),
                  };
                })[0]
            }
            useBasicStyles
            placeholder={t("regulation_placeholder")}
            closeMenuOnSelect={true}
            key={1}
            onChange={scenarioRegulationHandler}
            chakraStyles={{
              control: (provided, state) => ({
                ...provided,
                width: "10vw",
                fontFamily: "Causten",
                fontWeight: "bold",
                fontSize: "0.8em",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                background: regulations.find(
                  (reg) => reg.id === selectedRegulationId
                )?.color,
                color: "white",
                borderRadius: "50px",
                marginRight: "1vw",
              }),
              menuList: (provided) => ({
                ...provided,
                fontFamily: "Causten",
              }),
              option: (provided, state) => ({
                ...provided,
                color: regulations.find((reg) => reg.id === state.data.value)
                  ?.color,
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                "&:hover": {
                  background: "#5090F0",
                  color: "white",
                },
              }),
              dropdownIndicator: (provided, { selectProps, hasValue }) => ({
                ...provided,
                color: hasValue ? "white" : "black",
                "> svg": {
                  transitionDuration: "normal",
                  transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                },
              }),
              // To remove space between text and dropdown
              valueContainer: (provided) => ({
                ...provided,
                // width: "fit-content",
                WebkitPaddingEnd: "0px",
              }),
              singleValue: (provided) => ({
                ...provided,
                width: "fit-content",
              }),
            }}
          />
          <Select
            className="professor_edit_scenario_menu_top_left_course_select"
            placeholder={t("subjects_placeholder")}
            options={courses
              .filter((course) => {
                return (
                  course.regulation_id === selectedRegulationId &&
                  course.acronym !== "CM_EP"
                );
              })
              .map((course) => {
                return {
                  value: course.id,
                  label: t(course.acronym + "_name", { ns: "evaluation" }),
                };
              })}
            value={
              courses
                .filter((course) => {
                  return course.id === scenario.course.id;
                })
                .map((course) => {
                  return {
                    value: course.id,
                    label: t(course.acronym + "_name", { ns: "evaluation" }),
                  };
                })[0] || null // JANK - quite janky indeed
            }
            key={scenario?.course.id}
            onChange={scenarioCourseHandler}
            size={"md"}
            useBasicStyles
            closeMenuOnSelect={true}
            focusBorderColor={
              courses.find((course) => course.id === scenario.course.id)
                ?.color || "black"
            }
            chakraStyles={{
              control: (provided, state) => ({
                ...provided,
                width: "12vw",
                fontFamily: "Causten",
                fontWeight: "bold",
                fontSize: "0.8em",
                border: state.hasValue ? "none" : undefined,
                boxShadow: state.isFocused
                  ? "0px 0px 10px 2px " +
                    courses.find((course) => course.id === scenario.course.id)
                      ?.color
                  : "none",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                background: state.hasValue
                  ? courses.find((course) => course.id === scenario.course.id)
                      ?.color
                  : "white",
                color: "white",
                borderRadius: "50px",
              }),
              menuList: (provided) => ({
                ...provided,
                fontFamily: "Causten",
              }),
              dropdownIndicator: (provided, { selectProps, hasValue }) => ({
                ...provided,
                color: hasValue ? "white" : "black",
                "> svg": {
                  transitionDuration: "normal",
                  transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                },
              }),
              valueContainer: (provided) => ({
                ...provided,
                // width: "fit-content",
                WebkitPaddingEnd: "0px",
              }),
              singleValue: (provided) => ({
                ...provided,
                width: "fit-content",
                // textOverflow: "none",
              }),
            }}
          />
          <Select
            name="languages"
            useBasicStyles
            options={languages}
            closeMenuOnSelect={true}
            placeholder={t("languages_placeholder")}
            onChange={scenarioLanguageHandler}
            value={languages.find((lang) => lang.value === scenario.language)}
            key={scenario.language}
            chakraStyles={{
              control: (provided, state) => ({
                ...provided,
                // width: "fit-content",
                width: "8vw",
                fontFamily: "Causten",
                left: "1vw",
                fontWeight: "bold",
                fontSize: "0.8em",
                boxShadow: state.isFocused
                  ? "0px 0px 10px 2px #292F4C"
                  : "none",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                background: scenario.language ? "#292F4C" : "none",
                color: "white",
                borderRadius: "50px",
                marginRight: "1vw",
              }),
              menuList: (provided) => ({
                ...provided,
                fontFamily: "Causten",
              }),
              dropdownIndicator: (provided, { selectProps }) => ({
                ...provided,
                color: scenario.language ? "white" : "black",
                "> svg": {
                  transitionDuration: "normal",
                  transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                },
              }),
              valueContainer: (provided) => ({
                ...provided,
                // width: "fit-content",
                WebkitPaddingEnd: "0px",
              }),
              singleValue: (provided) => ({
                ...provided,
                width: "fit-content",
              }),
            }}
          />
          {!_.isNull(userLicense) && userLicense.license.allow_private && (
            <Select
              name="visibility"
              useBasicStyles
              options={visibilityOptions}
              closeMenuOnSelect={true}
              // placeholder={t("languages_placeholder")}
              onChange={visibililtyHandler}
              value={visibilityOptions.find(
                (vis) => vis.value === scenario.visibility
              )}
              key={scenario.visibility}
              chakraStyles={{
                control: (provided, state) => ({
                  ...provided,
                  width: "9vw",
                  left: "1vw",
                  fontFamily: "Causten",
                  fontWeight: "bold",
                  fontSize: "0.8em",
                  boxShadow: state.isFocused
                    ? "0px 0px 10px 2px #292F4C"
                    : "none",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  background: "#6EE6A7",
                  color: "white",
                  borderRadius: "50px",
                }),
                menuList: (provided) => ({
                  ...provided,
                  fontFamily: "Causten",
                  left: "1vw",
                }),
                dropdownIndicator: (provided, { selectProps }) => ({
                  ...provided,
                  color: scenario.visibility ? "white" : "black",
                  "> svg": {
                    transitionDuration: "normal",
                    transform: `rotate(${
                      selectProps.menuIsOpen ? -180 : 0
                    }deg)`,
                  },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  // width: "fit-content",
                  WebkitPaddingEnd: "0px",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  width: "fit-content",
                }),
              }}
            />
          )}
          <m.div
            className="professor_edit_scenario_menu_top_delete"
            onClick={(e) => {
              e.stopPropagation();
              // deleteScenarioHandler();
              deleteMenuHandler();
            }}
            whileHover={{
              scale: 1.1,
              boxShadow: "0px 0px 10px 1px #F0684F",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <div className="center_container">
              <img
                src={bin}
                style={{ height: "80%", position: "relative" }}
                alt="bin"
              />
            </div>
          </m.div>
        </div>
      </LazyMotion>
    );
  };
  const renderMain = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div className="professor_edit_scenario_menu_main" layoutRoot>
          <m.div
            className="professor_edit_scenario_menu_main_scenarios"
            layoutRoot
          >
            {renderScenario(scenario)}
          </m.div>
        </m.div>
      </LazyMotion>
    );
  };

  const renderScenario = (scenario: ModifiedScenarioType) => {
    const selectedGlobalCompetences = globalCompetencesBooleanArray(
      scenario.global_competences_ids,
      globalCompetences
    );
    return (
      <div className="professor_edit_scenario_menu_main_scenario">
        <div
          className="professor_edit_scenario_menu_main_scenario_open"
          style={{
            boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
            borderRadius: "5px",
          }}
        >
          <div className="professor_edit_scenario_menu_main_scenario_open_top_left">
            <div
              className="image-container"
              onMouseEnter={() => setShowHoverIcon(true)}
              onMouseLeave={() => setShowHoverIcon(false)}
            >
              {!showHoverIcon && (
                <img
                  className="image"
                  src={
                    _.isUndefined(scenario.image?.url)
                      ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Tutorial/Tutorial+-+ICON.jpg"
                      : scenario.image?.url
                  }
                  alt="scenario_image"
                  style={{
                    borderRadius: "10px",
                    maxHeight: "80px",
                  }}
                />
              )}
              {showHoverIcon && (
                <ScenarioImageSelector
                  imageHandler={(scenario: ModifiedScenarioType) =>
                    imageHandler(scenario)
                  }
                  image={scenario.image}
                  scenarioId={scenario.id}
                />
              )}
            </div>
            <div className="professor_edit_scenario_menu_main_scenario_open_top_left_info">
              <div className="professor_edit_scenario_menu_main_scenario_info_top">
                <Input
                  placeholder={t("scenario_name")}
                  value={scenario?.name}
                  onChange={(e) => setScenarioName(e.target.value)}
                  style={{
                    fontFamily: "Causten",
                    fontWeight: "bold",
                    background: "#EEEEEE",
                    fontSize: "0.8em",
                    borderRadius: "2.5px",
                    color: "black",
                    width: "100%",
                  }}
                  as={motion.input}
                  size={"sm"}
                  whileHover={{
                    scale: 1.01,
                    boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                  }}
                />
              </div>
              <div className="professor_edit_scenario_menu_main_scenario_info_bottom">
                <Textarea
                  placeholder={t("scenario_description")}
                  value={scenario.description}
                  resize={"none"}
                  onChange={(e) => setScenarioDescription(e.target.value)}
                  style={{
                    fontFamily: "Causten",
                    background: "#EEEEEE",
                    overflowY: "scroll",
                    fontSize: "0.8em",
                    borderRadius: "2.5px",
                    marginTop: "0.5vh",
                    color: "black",
                    width: "100%",
                  }}
                  as={motion.textarea}
                  size={"sm"}
                  whileHover={{
                    scale: 1.01,
                    boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="professor_edit_scenario_menu_main_scenario_open_top_right">
            <div className="text_black_very_small_bold">
              {t("basic_knowledge")}
            </div>
            <Wrap
              justify="space-between"
              align="center"
              style={{ width: "100%", height: "100%" }}
            >
              <WrapItem w="49%">
                <Input
                  placeholder={t("scenario_main_know_how")}
                  value={scenario.main_know_how}
                  isReadOnly={true}
                  autoFocus={false}
                  style={{
                    fontFamily: "Causten",
                    fontWeight: "bold",
                    background: "#EEEEEE",
                    fontSize: "0.8em",
                    borderRadius: "2.5px",
                    color: "#6B6B6B",
                    width: "100%",
                  }}
                  size={"xs"}
                />
              </WrapItem>
              {scenario.other_know_hows.map((knowHow: any, i: number) => {
                return (
                  <WrapItem w="49%">
                    <Input
                      placeholder={t("scenario_know_hows_placeholder")}
                      value={knowHow}
                      isReadOnly={false}
                      onChange={(e) =>
                        scenarioKnowHowsHandler(e.target.value, i)
                      }
                      style={{
                        fontFamily: "Causten",
                        fontWeight: "bold",
                        background: "#EEEEEE",
                        fontSize: "0.8em",
                        borderRadius: "2.5px",
                        color: "black",
                        width: "100%",
                      }}
                      as={motion.input}
                      size={"xs"}
                      whileHover={{
                        scale: 1.01,
                        boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                      }}
                    />
                  </WrapItem>
                );
              })}
              <WrapItem w="49%">
                <Button
                  onClick={() => {
                    addScenarioKnowHow();
                  }}
                  style={{
                    fontFamily: "Causten",
                    fontWeight: "bold",
                    background: "#EEEEEE",
                    fontSize: "0.8em",
                    borderRadius: "2.5px",
                    color: "black",
                    width: "100%",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  as={motion.button}
                  size={"xs"}
                  whileHover={{
                    scale: 1.01,
                    boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                  }}
                >
                  {t("add_know_how")}
                </Button>
              </WrapItem>
            </Wrap>
          </div>
          <div className="professor_edit_scenario_menu_main_scenario_open_bottom_left">
            <div
              // whileHover={{
              //   scale: 1.05,
              //   boxShadow: "0px 0px 10px 2px #5090F0",
              // }}
              className="professor_edit_scenario_menu_main_scenario_open_bottom_left_image_container"
              onMouseEnter={() => setShowHoverText("inv")}
              onMouseLeave={() => setShowHoverText("none")}
              onClick={() => {
                researchHandler(scenario.research.id);
              }}
            >
              {showHoverText === "inv" && (
                <div className="professor_edit_scenario_menu_main_scenario_open_bottom_left_image_container_hover center_container">
                  <div className="text_white_medium_bold">
                    {t("see_research")}
                  </div>
                </div>
              )}
              <img className="image" src={placeholderInv} alt="Research" />
            </div>
            <div
              // whileHover={{
              //   scale: 1.05,
              //   boxShadow: "0px 0px 10px 2px #5090F0",
              // }}
              className="professor_edit_scenario_menu_main_scenario_open_bottom_left_image_container"
              onMouseEnter={() => setShowHoverText("exp")}
              onMouseLeave={() => setShowHoverText("none")}
              onClick={() => {
                experimentHandler(scenario.experiment.id);
              }}
            >
              {showHoverText === "exp" && (
                <div className="professor_edit_scenario_menu_main_scenario_open_bottom_left_image_container_hover center_container">
                  <div className="text_white_medium_bold">
                    {t("see_experiment")}
                  </div>
                </div>
              )}
              <img className="image" src={placeholderExp} alt="Experiment" />
            </div>
          </div>
          <div className="professor_edit_scenario_menu_main_scenario_open_bottom_right">
            <div className="professor_edit_scenario_menu_main_scenario_open_bottom_right_top">
              <div className="text_black_very_small_bold">
                {t("evaluation_criteria")}
              </div>
              <div
                // justify="space-between"
                // align="center"
                style={{
                  // position: "relative",
                  width: "100%",
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {scenario.evaluation_criteria_ids?.map(
                  (evalCriteriaId: string, i: number) => {
                    const evcrit = evaluationCriteria.find(
                      (crit: any) => crit.id === evalCriteriaId
                    );
                    return (
                      <Menu>
                        <MenuButton
                          style={{
                            width: "100%",
                            height: "4vh",
                            backgroundColor: "#EEEEEE",
                            cursor:
                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                            borderRadius: "50px",
                            marginTop: "1%",
                            marginBottom: "1%",
                          }}
                        >
                          <div className="center_container">
                            <div
                              className="text_black_super_small"
                              style={{
                                width: "calc(100% - 24px)", // Adjust width to accommodate icon
                                overflow: "hidden",
                                // display: "flex",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginTop: "1%",
                                marginLeft: "2%",
                                marginBottom: "1%",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div className="left_container">
                                {_.isUndefined(evcrit)
                                  ? t("evaluation_criteria_placeholder")
                                  : t(
                                      scenario.course.acronym +
                                        evcrit?.specific_competence?.number +
                                        "." +
                                        evcrit?.number +
                                        "_desc",
                                      { ns: "evaluation" }
                                    )}
                              </div>
                            </div>
                            <ChevronDownIcon
                              boxSize="12px"
                              style={{
                                color: "black",
                                marginRight: "3%",
                              }}
                            />
                          </div>
                        </MenuButton>
                        <MenuList
                          style={{
                            maxHeight: "35vh",
                            maxWidth: "35vw",
                            overflowY: "scroll",
                            fontFamily: "Causten",
                          }}
                        >
                          {/* Add "delete eval criteria" option */}
                          <MenuItem
                            onClick={() => setScenarioEvalCriteria("delete", i)}
                          >
                            <div className="left_container">
                              <div className="text_black_super_small">
                                {t("delete_eval_criteria_placeholder")}
                              </div>
                            </div>
                          </MenuItem>
                          {evaluationCriteria
                            .map((evcrit) => ({
                              value: evcrit.id,
                              label: t(
                                scenario.course.acronym +
                                  evcrit?.specific_competence?.number +
                                  "." +
                                  evcrit?.number +
                                  "_desc",
                                { ns: "evaluation" }
                              ),
                            }))
                            .filter((option) => {
                              return !scenario.evaluation_criteria_ids.includes(
                                option.value
                              );
                            })
                            .map((option, index) => (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  setScenarioEvalCriteria(option.value, i)
                                }
                              >
                                <div className="left_container">
                                  <div className="text_black_super_small">
                                    {option.label}
                                  </div>
                                </div>
                              </MenuItem>
                            ))}
                        </MenuList>
                      </Menu>
                    );
                  }
                )}
                {scenario.evaluation_criteria_ids.filter((ev) => ev === "")
                  .length === 0 && (
                  <Button
                    name="add-eval-criteria"
                    size={"sm"}
                    onClick={() => addScenarioEvalCriteria()}
                    width={"95%"}
                    height={"4vh"}
                    style={{
                      fontFamily: "Causten",
                      fontSize: "0.7em",
                      backgroundColor: "#EEEEEE",
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                      color: "black",
                      borderRadius: "50px",
                      marginTop: "1%",
                      marginBottom: "1%",

                      // boxShadow: "0px 0px 5px 2px rgba( 0, 0, 0, 0.25 )"
                    }}
                    as={motion.button}
                    whileHover={{
                      boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                    }}
                  >
                    <div
                      className="text_black_super_small"
                      style={{
                        marginTop: "1%",
                        marginBottom: "1%",
                      }}
                    >
                      {t("add_eval_criteria")}
                    </div>
                  </Button>
                )}
              </div>
            </div>
            <div className="professor_edit_scenario_menu_main_scenario_open_bottom_right_bottom">
              <div className="professor_edit_scenario_menu_main_scenario_open_bottom_right_bottom_spec">
                {specificCompetences.map((competence, index) => {
                  const active: boolean = scenario.evaluation_criteria_ids
                    .map(
                      (id) =>
                        evaluationCriteria.find((ev) => ev.id === id)
                          ?.specific_competence_id
                    )
                    .includes(competence.id);
                  return (
                    <div
                      style={{
                        fontSize: "0.65em",
                        fontFamily: "Causten",
                        backgroundColor: active
                          ? "rgba(41, 47, 76, 1)"
                          : "rgba(41, 47, 76, 0.25)",
                        width: "100%",
                        borderRadius: "100px",
                        maxHeight: "1.3em",
                        color: "white",
                        padding: "0.1vh 0.5vw 0.1vh 0.5vw",
                        textAlign: "center",
                        marginRight: "0.5vw",
                      }}
                    >
                      {prettifyCourseAcronyms(scenario.course.acronym) +
                        (index + 1).toString()}
                    </div>
                  );
                })}
              </div>
              <div className="professor_edit_scenario_menu_main_scenario_open_bottom_right_bottom_key">
                {simpleGlobalCompetences.map((competence, index: number) => {
                  const nonSelectedColor =
                    competence[1].substr(0, competence[1].length - 2) + "0.25)";
                  return (
                    <div
                      style={{
                        fontSize: "0.65em",
                        fontFamily: "Causten",
                        backgroundColor: selectedGlobalCompetences[index]
                          ? competence[1]
                          : nonSelectedColor,
                        width: "100%",
                        borderRadius: "100px",
                        maxHeight: "1.3em",
                        color: "white",
                        padding: "0.1vh 0.5vw 0.1vh 0.5vw",
                        textAlign: "center",
                        marginRight: "0.5vw",
                      }}
                    >
                      {competence[0]}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDeleteMenu = () => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_sidebar_delete_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("delete_scenario_text")}
                      <b>{scenario.main_know_how}</b>?
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => setDeleteMenu(false)}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  />
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => deleteScenarioHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => setDeleteMenu(false)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderBackMenu = () => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_edit_scenario_back_menu_container">
              <div
                className="professor_sidebar_delete_menu_close"
                onClick={() => setBackMenu(false)}
              >
                <m.img
                  className="icon"
                  src={close}
                  alt="close"
                  whileHover={{ scale: 1.1, rotate: 180 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                />
              </div>
              <div className="professor_edit_scenario_back_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("back_scenario_text") +
                        (deleteOnExit ? t("back_scenario_text_delete") : "")}
                    </div>
                  </div>
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => scenarioBackHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => setBackMenu(false)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderButton = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div className="professor_scenario_menu_buttons_container">
          <m.div
            className="professor_edit_scenario_menu_button"
            whileHover={{ scale: 1.1 }}
            onClick={() => scenarioSaveHandler()}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <div className="center_container">
              <div className="text_white_small_bold">{t("save")}</div>
            </div>
          </m.div>
        </div>
      </LazyMotion>
    );
  };

  return (
    <div
      style={
        openResearchEditMenu === null && openExperimentEditMenu === null
          ? { position: "relative", height: "100%", width: "100%" }
          : {
              position: "fixed",
              height: "100vh",
              width: "100vw",
              top: 0,
              left: 0,
              zIndex: 100,
            }
      }
    >
      {openResearchEditMenu === null && openExperimentEditMenu === null && (
        <div
          className={
            loading ? "center_container" : "professor_edit_scenario_menu"
          }
        >
          {loading && <Loading />}
          {!loading && renderTop()}
          {/* {!loading && renderMiddle()} */}
          {!loading && renderMain()}
          {!loading && renderButton()}
          {!loading && alert !== null && (
            <Alert text={alert} handleAlert={() => alertHandler()} />
          )}
        </div>
      )}
      {!loading && openResearchEditMenu !== null && (
        <ExercisePreviewResearch
          subject={scenario.course}
          language={scenario.language}
          research_id={openResearchEditMenu.id}
          exercises={openResearchEditMenu.exercises}
          backHandler={() => setOpenResearchEditMenu(null)}
        />
      )}
      {!loading && openExperimentEditMenu !== null && (
        <ExercisePreviewExperiment
          subject={scenario.course}
          language={scenario.language}
          experiment_id={openExperimentEditMenu.id}
          exercises={openExperimentEditMenu.exercises}
          backHandler={() => setOpenExperimentEditMenu(null)}
        />
      )}
      {deleteMenu && renderDeleteMenu()}
      {backMenu && renderBackMenu()}
    </div>
  );
};

export default ScenarioEditMenu;
