import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces";

const BiosphereForest = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_bosque_op.glb"
  ) as DreiGLTF;
  return (
    <group {...props} dispose={null}>
      <group
        position={[16.584, 0.651, 27.302]}
        rotation={[Math.PI, -1.108, Math.PI]}
        scale={0.177}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_bosque.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_bosque_1.geometry}
          material={materials.gris_puertas}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_bosque_2.geometry}
          material={materials.blanco_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_bosque_3.geometry}
          material={materials.verde_bosque_puertas}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_bosque_4.geometry}
          material={materials.verde_bosque_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_bosque_5.geometry}
          material={materials.verde_bosque_emission}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_bosque_6.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
        />
      </group>
      <group position={[16.776, 1.796, 27.397]} scale={0.177}>
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_bosque_1.geometry}
          material={materials.verde_bosque_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_bosque_2.geometry}
          material={materials.logo_bio_bosque}
        />
      </group>
      <group
        position={[16.776, 1.226, 27.397]}
        rotation={[Math.PI, -1.108, Math.PI]}
        scale={0.177}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_bosque_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_bosque_2.geometry}
          material={materials.verde_bosque_cristales}
        />
      </group>
      <group
        position={[11.466, -0.05, 24.74]}
        rotation={[0, 1.109, 0]}
        scale={[0.138, 0.537, 5.467]}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_bosque_1.geometry}
          material={materials.camino_bordes}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_bosque_2.geometry}
          material={materials.verde_bosque_metal}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_bosque_op.glb"
);

export default BiosphereForest;
