import { Textarea } from "@chakra-ui/react";
import "./DefinitionEdit.css";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const DefinitionEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateDefinition: (newDefinition: ExerciseType) => void;
}) => {
  const [text, setText] = useState(props.exercise.text);
  const [definition, setDefinition] = useState(props.exercise.main_text);
  const [saveToDB, setSaveToDB] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (saveToDB) {
      var exercise = props.exercise;
      exercise.text = text;
      exercise.main_text = definition;
      props.updateDefinition(exercise);

      setSaveToDB(false);
    }
  }, [saveToDB]);

  useEffect(() => {
    setText(props.exercise.text);
    setDefinition(props.exercise.main_text);
  }, [props.exercise.text, props.selectedExercise]);

  const renderProfessor = () => {
    return (
      <div className="edit_definition_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            style={{ maxHeight: "35vh" }}
            alt="professor"
          />
        </div>
      </div>
    );
  };
  const textHandler = (newText: string) => {
    setText(newText);
  };
  const definitionHandler = (newDefinition: string) => {
    setDefinition(newDefinition);
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_definition_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(text) && (
          <div
            className={
              text.length < 200 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "justify",
                fontFamily: "Causten",
                fontSize: "1em",
                textJustify: "inter-word",
              }}
              value={text}
              resize={"none"}
              onChange={(e) => textHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderDefinition = () => {
    return (
      <motion.div
        className="edit_definition_edit_definition_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(definition) && (
          <div
            className={
              definition.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={definition}
              resize={"none"}
              onChange={(e) => definitionHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  return (
    <div className="edit_definition">
      {renderProfessor()}
      {renderTextBox()}
      {renderDefinition()}
    </div>
  );
};

export default DefinitionEdit;
