import {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useGLTF, useAnimations, useFBX } from "@react-three/drei";
import {
  Group,
  SkinnedMesh,
  Mesh,
  Bone,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
} from "three";
import { AssetType } from "../../../../_newapios/gamecontent/house_upgrades.ts";

import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Mesh001: SkinnedMesh;
    Mesh001_1: SkinnedMesh;
    Mesh001_2: SkinnedMesh;
    Mesh001_3: SkinnedMesh;
    Mesh001_4: SkinnedMesh;
    gafas: Mesh;
    gafas_1: Mesh;
    gafas_2: Mesh;
    gafas_3: Mesh;
    stalag_head: Mesh;
    snow_hand_2: Mesh;
    snow_hand_1: Mesh;
    stalags007: Mesh;
    stalags007_1: Mesh;
    joint1: Bone;
  };
  materials: {
    blanco: MeshPhysicalMaterial;
    negro: MeshStandardMaterial;
    pico: MeshPhysicalMaterial;
    patas: MeshPhysicalMaterial;
    barriguita: MeshStandardMaterial;
    metal_gafas: MeshPhysicalMaterial;
    estalagmita_01: MeshPhysicalMaterial;
    cuero_gafas: MeshPhysicalMaterial;
    negro_gafas: MeshPhysicalMaterial;
    estalagmita_02: MeshPhysicalMaterial;
  };
};

const Kowalski = ({ ...props }) => {
  const [asset, setAsset] = useState<AssetType>(props.asset);
  const Kowalski = useGLTF(asset.gltf.url) as GLTFResult;
  const groupRef = useRef<Group>(null);
  const { actions } = useAnimations(Kowalski.animations, groupRef);

  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      if (props.static) {
        actions["static_cycle"]?.reset().play();
      } else {
        //actions["walking_cycle"]?.reset().play();
      }
    }
  }, [actions]);
  console.log("EutonStatic", asset);

  return (
    <group ref={groupRef} dispose={null}>
      <group name="Scene">
        <group
          name="penguin_armature"
          position={props.groupProps.position}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.01}
        >
          <group name="penguin">
            <skinnedMesh
              name="Mesh001"
              castShadow={props.renderShadow()}
              receiveShadow={props.renderShadow()}
              geometry={Kowalski.nodes.Mesh001.geometry}
              material={Kowalski.materials.blanco}
              skeleton={Kowalski.nodes.Mesh001.skeleton}
            />
            <skinnedMesh
              name="Mesh001_1"
              castShadow={props.renderShadow()}
              receiveShadow={props.renderShadow()}
              geometry={Kowalski.nodes.Mesh001_1.geometry}
              material={Kowalski.materials.negro}
              skeleton={Kowalski.nodes.Mesh001_1.skeleton}
            />
            <skinnedMesh
              name="Mesh001_2"
              castShadow={props.renderShadow()}
              receiveShadow={props.renderShadow()}
              geometry={Kowalski.nodes.Mesh001_2.geometry}
              material={Kowalski.materials.pico}
              skeleton={Kowalski.nodes.Mesh001_2.skeleton}
            />
            <skinnedMesh
              name="Mesh001_3"
              castShadow={props.renderShadow()}
              receiveShadow={props.renderShadow()}
              geometry={Kowalski.nodes.Mesh001_3.geometry}
              material={Kowalski.materials.patas}
              skeleton={Kowalski.nodes.Mesh001_3.skeleton}
            />
            <skinnedMesh
              name="Mesh001_4"
              castShadow={props.renderShadow()}
              receiveShadow={props.renderShadow()}
              geometry={Kowalski.nodes.Mesh001_4.geometry}
              material={Kowalski.materials.barriguita}
              skeleton={Kowalski.nodes.Mesh001_4.skeleton}
            />
          </group>
          <primitive object={Kowalski.nodes.joint1} />
        </group>
      </group>
    </group>
  );
};

export default Kowalski;
