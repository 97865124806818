import { Float, useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";

const FountainBiosphere = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/fuente_bio.glb"
  ) as DreiGLTF;
  return (
    <group {...props} dispose={null}>
      <group
        position={[8.693, 0.427, 23.391]}
        rotation={[0, 0.121, 0]}
        scale={[0.965, 0.067, 0.965]}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.fuente_bio_1.geometry}
          material={materials.base_fuente}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.fuente_bio_2.geometry}
          material={materials.agua_fuente}
        />
      </group>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.rocas_fuente_bio.geometry}
        material={materials["LOWPOLY-COLORS.004"]}
        position={[8.554, 0.683, 22.966]}
        rotation={[0, 1.466, 0]}
        scale={0.138}
      />
      <Float
        rotationIntensity={0.04}
        speed={props.renderAnimation() ? 5 : 0}
        floatingRange={[-0.1, 0.05]}
      >
        <group
          position={[8.874, 2.012, 23.731]}
          rotation={[0, 0.416, 0]}
          scale={0.341}
        >
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.isla_fuente_bio_1.geometry}
            material={materials.tierra_isla}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.isla_fuente_bio_2.geometry}
            material={materials.verde_pradera}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.isla_fuente_bio_3.geometry}
            material={materials.verde_sombra_isla}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.isla_fuente_bio_4.geometry}
            material={materials.tierra_rio_isla}
          />
        </group>
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.rio_isla_fuente_bio.geometry}
          material={materials.agua_fuente}
          position={[8.874, 2.012, 23.731]}
          rotation={[0, 0.416, 0]}
          scale={0.341}
        />
      </Float>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.salpicaduras_fuente_bio.geometry}
        material={materials.agua_fuente}
        position={[8.708, 0.731, 23.278]}
        rotation={[0.017, 0.636, 0.21]}
        scale={0.692}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/fuente_bio.glb"
);

export default FountainBiosphere;
