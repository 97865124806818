import env from "../../config/env.json";
import { ExperimentProgressType } from './student_scenario'
import { ExerciseAnswerType } from '../../components/exercises/ExercisesExperiment'

export const CreateExerciseResearchProgressAPI = async (exercise_id: string, research_id: string, exercise_answer: ExerciseAnswerType): Promise<number | null> => {
    var req = await fetch(env.backend_host + "/student_exercise/" + exercise_id + "/research/" + research_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "body": JSON.stringify({
            exercise_answer
        }),
        "mode": "cors",
        "credentials": "include"
    });
    if (req.status !== 200) {
        return null
    }
    return req.status;
}