import env from "../../config/env.json";
import { StudentType } from "../user/student";
import { TeacherType } from "../user/teacher";
import { UserType } from "../user/user";

export type ErrorType = {
  statusCode: number;
  message: string;
};

export const TeacherLoginAPI = async (
  userEmail: string,
  userPassword: string
): Promise<UserType | ErrorType> => {
  var req = await fetch(env.backend_host + "/auth/local/teacher", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ username: userEmail, password: userPassword }),
    method: "POST",
    mode: "cors",
    credentials: "include",
  });

  if (!req.ok) {
    var data = await req.json();
    data.statusCode = req.status;
    return data as ErrorType;
  }

  var data = await req.json();
  return data as UserType;
};

export const StudentLoginAPI = async (
  userName: string,
  userPassword: string
): Promise<UserType | null> => {
  var req = await fetch(env.backend_host + "/auth/local/student", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ username: userName, password: userPassword }),
    method: "POST",
    mode: "cors",
    credentials: "include",
  });

  if (!req.ok) return null;

  var data = await req.json();
  return data;
};

export const AdminLoginAPI = async (
  userName: string,
  userPassword: string
): Promise<UserType | null> => {
  var req = await fetch(env.backend_host + "/auth/local/admin", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ username: userName, password: userPassword }),
    method: "POST",
    mode: "cors",
    credentials: "include",
  });

  if (!req.ok) return null;

  var data = await req.json();
  return data;
};

export const StudentRegisterAPI = async (
  username: string,
  pin: string,
  captcha: string
): Promise<StudentType | 404 | 409 | 418> => {
  var req = await fetch(env.backend_host + "/student", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ username, pin, captcha }),
    method: "POST",
    mode: "cors",
    credentials: "include",
  });

  if (req.status === 404 || req.status === 409 || req.status === 418)
    return req.status;

  var data = await req.json();
  return data;
};

export const TeacherRegisterAPI = async (
  fullname: string,
  username: string,
  password: string,
  school: string,
  phone: string,
  captcha: string,
  pin: string | undefined = undefined
): Promise<TeacherType | null | 418> => {
  var req = await fetch(env.backend_host + "/teacher", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      fullname,
      username,
      password,
      school,
      phone,
      pin,
      captcha,
    }),
    method: "POST",
    mode: "cors",
    credentials: "include",
  });
  if (req.status === 418) return req.status;
  if (!req.ok) return null;

  var data = await req.json();
  return data;
};

export const TeacherVerificationAPI = async (
  username: string,
  verificationCode: string
): Promise<void> => {
  var req = await fetch(env.backend_host + "/teacher/verify", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ username, verification_code: verificationCode }),
    method: "POST",
    mode: "cors",
    credentials: "include",
  });

  if (!req.ok) throw new Error("Verification failed");
  return;
};

export const LogoutAPI = async () => {
  await fetch(env.backend_host + "/auth/logout", {
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
};

export const TeacherRequestResetAPI = async (
  username: string,
  captcha: string
) => {
  var req = await fetch(env.backend_host + "/teacher/request_password_reset", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ username, captcha }),
    method: "POST",
    mode: "cors",
    credentials: "include",
  });

  if (!req.ok) throw new Error("Reset password request failed");
  return;
};

export const TeacherResetPasswordAPI = async (
  username: string,
  new_password: string,
  verification_code: string
) => {
  var req = await fetch(env.backend_host + "/teacher/reset_password", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ username, new_password, verification_code }),
    method: "POST",
    mode: "cors",
    credentials: "include",
  });

  if (!req.ok) throw new Error("Reset password failed");
  return;
};
