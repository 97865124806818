import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces";

const BiosphereGrasslandDesert = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_desierto_pradera_op.glb"
  ) as DreiGLTF;
  return (
    <group {...props} dispose={null}>
      <group
        position={[-0.863, 0.651, 26.489]}
        rotation={[-Math.PI, 1.256, -Math.PI]}
        scale={0.177}
      >
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_desierto_pradera_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_desierto_pradera_2.geometry}
          material={materials.gris_puertas}
        />
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_desierto_pradera_3.geometry}
          material={materials.blanco_metal}
        />
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_desierto_pradera_4.geometry}
          material={materials.rosa_pradera_puertas}
        />
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_desierto_pradera_5.geometry}
          material={materials.rosa_pradera_metal}
        />
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_desierto_pradera_6.geometry}
          material={materials.rosa_pradera_emision}
        />
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_desierto_pradera_7.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
        />
      </group>
      <group position={[-1.066, 1.796, 26.556]} scale={0.177}>
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_desierto_pradera_1.geometry}
          material={materials.rosa_pradera_metal}
        />
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_desierto_pradera_2.geometry}
          material={materials.logo_bio_pradera}
        />
      </group>
      <group
        position={[-1.066, 1.226, 26.555]}
        rotation={[-Math.PI, 1.256, -Math.PI]}
        scale={0.177}
      >
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_desierto_pradera_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_desierto_pradera_2.geometry}
          material={materials.rosa_pradera_cristales}
        />
      </group>
      <group
        position={[4.911, -0.048, 24.603]}
        rotation={[0, -1.253, 0]}
        scale={[0.138, 0.537, 5.467]}
      >
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_desierto_pradera_1.geometry}
          material={materials.camino_bordes}
        />
        <mesh
          castShadow= {props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_desierto_pradera_2.geometry}
          material={materials.rosa_pradera_metal}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_desierto_pradera_op.glb"
);

export default BiosphereGrasslandDesert;
