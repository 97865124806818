import { useGLTF } from "@react-three/drei";
import { MeshPhongMaterial } from "three";
import { DreiGLTF } from "../../../../../interfaces";

const Caminos = ({ ...props }) => {
  const caminos = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Mejoras_energ%C3%ADa_nuevas/caminos.gltf"
  ) as DreiGLTF;

  const bakedPathMaterial = new MeshPhongMaterial({
    color: 0xcdcdcd,
    emissiveIntensity: 0,
    shininess: 0,
    fog: true,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={caminos.nodes.Camino_ayuntamiento.geometry}
        material={bakedPathMaterial}
        position={[0.448, 1.159, 4.375]}
        scale={[2.835, 0.021, 0.309]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={caminos.nodes.Camino_casa.geometry}
        material={bakedPathMaterial}
        position={[-13.83, 1.071, 3.056]}
        scale={[0.67, 0.043, 0.536]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={caminos.nodes.Camino_feria.geometry}
        material={bakedPathMaterial}
        position={[-0.183, 0.413, 8.572]}
        scale={[0.309, 0.021, 1.284]}
        onContextMenu={() => props.onContextMenu()}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={caminos.nodes.Camino_inicial.geometry}
        material={bakedPathMaterial}
        position={[3.428, 0.526, 1.317]}
        scale={[7.015, 0.021, 0.309]}
      />
    </group>
  );
};

export default Caminos;
