import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GameState {
    navMesh: any,
    refs: {
        level: any,
        pathHelper: any
    },

}

const initialState: GameState = {
    navMesh: null,
    refs: {
      level: null,
      pathHelper: null
    },
}

const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setNavMesh: (state, action: PayloadAction<any>) => {
            state.navMesh = action.payload
        },
        setRefs: (state, action: PayloadAction<{level: any, pathHelper: any}>) => {
            state.refs = action.payload
        },
        
    }
});

export const { setNavMesh, setRefs } = gameSlice.actions;

export default gameSlice.reducer;