import { useGLTF, useTexture } from "@react-three/drei";
import { MeshPhongMaterial, SRGBColorSpace } from "three";
import { DreiGLTF } from "../../../../interfaces";

const Model2 = ({ ...props }) => {
  const { nodes } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/GLOBAL_archipielago_mix2.gltf"
  ) as DreiGLTF;
  const bakedTexture2 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/baked_global_organic_mix2_op.jpg"
  );
  const bakedMaterial2 = new MeshPhongMaterial({ map: bakedTexture2 });
  bakedTexture2.flipY = false;
  bakedTexture2.colorSpace = SRGBColorSpace;
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Area_sabana1.geometry}
        material={bakedMaterial2}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Area_pradera1.geometry}
        material={bakedMaterial2}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Area_montañosa1.geometry}
        material={bakedMaterial2}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Islote_pradera.geometry}
        material={bakedMaterial2}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Islote_volc1.geometry}
        material={bakedMaterial2}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Islote_volc3.geometry}
        material={bakedMaterial2}
      />
      <mesh
        castShadow={false}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Islote_volc2.geometry}
        material={bakedMaterial2}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/GLOBAL_archipielago_mix2.gltf"
);

export default Model2;
