import { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { Group } from "three";
import { AssetType } from "../../../../_newapios/gamecontent/house_upgrades.ts";

import { DreiGLTF } from "../../../../interfaces.ts";
import { StaticEutonProps } from "../Biospheres/EutonStatic.tsx";

const TorcasStatic = ({
  groupProps,
  propsAsset,
  renderShadow,
}: StaticEutonProps) => {
  const [asset, setAsset] = useState<AssetType>(propsAsset);
  const Torcas = useGLTF(asset.gltf.url) as DreiGLTF;
  const groupRef = useRef<Group>(null);
  const { actions } = useAnimations(Torcas.animations, groupRef);

  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      for (let i = 0; i < Object.keys(actions).length; i++) {
        const action = Object.keys(actions)[i];
        actions[action]?.reset().play();
      }
    }
  }, [actions]);

  return (
    <group ref={groupRef} dispose={null}>
      <group
        name="Scene"
        position={groupProps?.position}
        rotation={groupProps?.rotation}
      >
        <group
          name="beaver_static_gltf"
          position={[0, -0.258, -0.018]}
          scale={0.981}
        >
          <mesh
            name="beaver"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.beaver.geometry}
            material={Torcas.materials.cuerpo_marron}
            morphTargetDictionary={Torcas.nodes.beaver.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.beaver.morphTargetInfluences}
          />
          <mesh
            name="beaver_1"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.beaver_1.geometry}
            material={Torcas.materials.negro_brillante}
            morphTargetDictionary={Torcas.nodes.beaver_1.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.beaver_1.morphTargetInfluences}
          />
          <mesh
            name="beaver_2"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.beaver_2.geometry}
            material={Torcas.materials.negro_brillante_2}
            morphTargetDictionary={Torcas.nodes.beaver_2.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.beaver_2.morphTargetInfluences}
          />
          <mesh
            name="beaver_3"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.beaver_3.geometry}
            material={Torcas.materials.negro_puro}
            morphTargetDictionary={Torcas.nodes.beaver_3.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.beaver_3.morphTargetInfluences}
          />
          <mesh
            name="beaver_4"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.beaver_4.geometry}
            material={Torcas.materials.mano_dentro}
            morphTargetDictionary={Torcas.nodes.beaver_4.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.beaver_4.morphTargetInfluences}
          />
          <mesh
            name="beaver_5"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.beaver_5.geometry}
            material={Torcas.materials.unhas}
            morphTargetDictionary={Torcas.nodes.beaver_5.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.beaver_5.morphTargetInfluences}
          />
          <mesh
            name="beaver_6"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.beaver_6.geometry}
            material={Torcas.materials.dientes}
            morphTargetDictionary={Torcas.nodes.beaver_6.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.beaver_6.morphTargetInfluences}
          />
          <mesh
            name="beaver_7"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.beaver_7.geometry}
            material={Torcas.materials.radial}
            morphTargetDictionary={Torcas.nodes.beaver_7.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.beaver_7.morphTargetInfluences}
          />
          <mesh
            name="beaver_8"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.beaver_8.geometry}
            material={Torcas.materials.trama_barriguita}
            morphTargetDictionary={Torcas.nodes.beaver_8.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.beaver_8.morphTargetInfluences}
          />
          <mesh
            name="beaver_9"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.beaver_9.geometry}
            material={Torcas.materials.orejas}
            morphTargetDictionary={Torcas.nodes.beaver_9.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.beaver_9.morphTargetInfluences}
          />
        </group>
        <group
          name="stem_static_gltf"
          position={[-0.007, -0.014, -0.053]}
          scale={0.733}
        >
          <mesh
            name="stem"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.stem.geometry}
            material={Torcas.materials.tronco}
            morphTargetDictionary={Torcas.nodes.stem.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.stem.morphTargetInfluences}
          />
          <mesh
            name="stem_1"
            castShadow
            receiveShadow
            geometry={Torcas.nodes.stem_1.geometry}
            material={Torcas.materials.tapa_troncos}
            morphTargetDictionary={Torcas.nodes.stem_1.morphTargetDictionary}
            morphTargetInfluences={Torcas.nodes.stem_1.morphTargetInfluences}
          />
        </group>
      </group>
    </group>
  );
};

export default TorcasStatic;
