const SoundEq = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 7L12 17" stroke={props.color} strokeLinecap="round" />
      <path d="M15 10L15 14" stroke={props.color} strokeLinecap="round" />
      <path d="M18 8L18 16" stroke={props.color} strokeLinecap="round" />
      <path d="M21 13L21 11" stroke={props.color} strokeLinecap="round" />
      <path d="M9 4L9 20" stroke={props.color} strokeLinecap="round" />
      <path d="M6 9L6 15" stroke={props.color} strokeLinecap="round" />
      <path d="M3 13L3 11" stroke={props.color} strokeLinecap="round" />
    </svg>
  );
};

export default SoundEq;
