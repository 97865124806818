import React, { useRef } from "react";
import { OrbitControls, Plane, useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";

const InteriorBiosphere = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "/bio_desierto_interior.glb"
  ) as DreiGLTF;
  return (
    <>
      <OrbitControls
        screenSpacePanning={false}
        keys={{
          LEFT: "ArrowLeft", //left arrow
          UP: "ArrowUp", // up arrow
          RIGHT: "ArrowRight", // right arrow
          BOTTOM: "ArrowDown", // down arrow
        }}
      />
      <pointLight
        castShadow={props.renderShadow()}
        position={[35, 55, 30]}
        intensity={5}
        decay={0}
        shadow-normalBias={0.04}
        color={0xfafafa}
        shadow-mapSize={[2048, 2048]}
      />
      <ambientLight color={0xe9e9e9} intensity={0.4} />
      <Plane
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        args={[40, 40]}
        position={[0, -1, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        material-color={"green"}
      />
      <group {...props} dispose={null}>
        <group
          position={[0.262, 0.651, -0.076]}
          rotation={[-Math.PI, 1.256, -Math.PI]}
          scale={1}
        >
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.Icosphere002.geometry}
            material={materials.blanco_base}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.Icosphere002_1.geometry}
            material={materials.gris_puertas}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.Icosphere002_2.geometry}
            material={materials.blanco_metal}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.Icosphere002_3.geometry}
            material={materials.amarillo_desierto_puertas}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.Icosphere002_4.geometry}
            material={materials.amarillo_desierto_metal}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.Icosphere002_5.geometry}
            material={materials.amarillo_desierto_emision}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.Icosphere002_6.geometry}
            material={materials["LOWPOLY-COLORS-NIGHT.004"]}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.Icosphere002_7.geometry}
            material={materials.logo}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.Icosphere002_8.geometry}
            material={materials.amarillo_desierto_cristales}
          />
        </group>
      </group>
    </>
  );
};

useGLTF.preload("/bio_desierto_interior.glb");

export default InteriorBiosphere;
