const TheoryIcon =(props: {
    color: string;
    size?: number; // Adding size as an optional number prop
} = {
    color: "#000000",
    size: 48 // Default size set to 48

}) => {
    const { color, size } = props; // Destructuring props

    return(
        <svg width= {size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="chat-alt">
                <path id="Combined Shape" d="M42 34V36C43.1046 36 44 35.1046 44 34H42ZM42 8H44C44 6.89543 43.1046 6 42 6V8ZM26 34V32C25.6052 32 25.2191 32.1169 24.8906 32.3359L26 34ZM14 42H12C12 42.7376 12.406 43.4153 13.0563 43.7633C13.7066 44.1114 14.4957 44.0732 15.1094 43.6641L14 42ZM14 34H16C16 32.8954 15.1046 32 14 32V34ZM6 34H4C4 35.1046 4.89543 36 6 36V34ZM6 8V6C4.89543 6 4 6.89543 4 8H6ZM44 34V8H40V34H44ZM26 36H42V32H26V36ZM15.1094 43.6641L27.1094 35.6641L24.8906 32.3359L12.8906 40.3359L15.1094 43.6641ZM12 34V42H16V34H12ZM6 36H14V32H6V36ZM4 8V34H8V8H4ZM42 6H6V10H42V6Z" fill={color}/>
            </g>
        </svg>
    )
}

export default TheoryIcon;
