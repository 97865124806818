import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../interfaces";

const MountainMainland = ({ ...props }) => {
  const stumpSmall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_monta%C3%B1a/stump_small_volcanico.gltf"
  ) as DreiGLTF;
  const treeConifer = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_monta%C3%B1a/tree_conifer_volcanico.gltf"
  ) as DreiGLTF;
  const treeForestOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_monta%C3%B1a/tree_forest_orange_volcanico.gltf"
  ) as DreiGLTF;
  const treeOld = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_monta%C3%B1a/tree_old_volcanico.gltf"
  ) as DreiGLTF;
  const tree = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_monta%C3%B1a/tree_volcanico.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-13.95, 0, -2.06]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.96, 0, -1.52]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-14.88, -0.77, 1.36]}
        rotation={[0, 1.27, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.76, 1.74, -1.4]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-10.05, 1.86, -1.85]}
        rotation={[-2.73, -0.51, -2.85]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeConifer.nodes["tree-conifer001"].geometry}
        material={props.bakedMaterial1}
        position={[-10.09, 1.88, 2.05]}
        rotation={[0, -0.36, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeConifer.nodes["tree-conifer001"].geometry}
        material={props.bakedMaterial1}
        position={[0.33, 1.55, 1.31]}
        rotation={[0, -0.6, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeConifer.nodes["tree-conifer001"].geometry}
        material={props.bakedMaterial1}
        position={[-7.35, 2.06, -2.74]}
        rotation={[0, 0.82, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeConifer.nodes["tree-conifer001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.58, 2.07, 1.11]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[-2.15, 1.46, -2.89]}
        rotation={[0, -0.17, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[-3.05, 1.58, -1.31]}
        rotation={[0, 0.91, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[-6.6, 1.82, -1.91]}
        rotation={[-Math.PI, 0.23, -Math.PI]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[-11.7, 1.44, -3.63]}
        rotation={[0, 0.72, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[-1.68, 2.26, 0.68]}
        rotation={[Math.PI, -1.09, Math.PI]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.41, 1.65, -2.57]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-8.92, 1.85, -1.18]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-11.05, 0.69, 2.76]}
        rotation={[0, 1.37, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-13.24, 0.4, 1.96]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.87, 0.69, 1.28]}
        rotation={[0, -0.92, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[-8.06, 1.27, -3.85]}
        rotation={[0, -1.36, 0]}
        scale={0.34}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.2, 1.57, 2.13]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-6.32, 2.15, 0.68]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-6, 1.97, 0.07]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-6.65, 1.42, 1.97]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.25, 2.35, 0.55]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.41, 2.06, -2.23]}
        rotation={[0, 1.5, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.57, 1.82, -2.89]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[1.13, 1.02, 1.62]}
        rotation={[Math.PI, -1.16, Math.PI]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-0.72, 0.81, 1.99]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-8.5, 1.89, -1.73]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-13.43, 0.01, -2.1]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeConifer.nodes["tree-conifer001"].geometry}
        material={props.bakedMaterial1}
        position={[-10.03, 1.14, 0.04]}
        rotation={[0, 0.78, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.85, -0.06, 0.36]}
        rotation={[0, 0.8, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.82, 1.23, -3.54]}
        rotation={[0, 1.18, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.05, 1.16, 0.75]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-8.53, 1.97, 1.19]}
        rotation={[0, 1.27, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.9, 1.25, 1.31]}
        rotation={[0, 1.25, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-2.47, 1.81, -1.64]}
        rotation={[0, 1.46, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.31, 2.09, 0.35]}
        rotation={[Math.PI, -0.75, Math.PI]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-1.85, 1.59, 1.42]}
        rotation={[0, 1.05, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-0.84, 0.98, -2.86]}
        rotation={[0, -0.57, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.23, 1.12, 2.39]}
        rotation={[-Math.PI, 0.81, -Math.PI]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-10.43, 1.08, -3.33]}
        rotation={[0, 0.4, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.84, 0.95, 2.76]}
        rotation={[0, 0.68, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.27, 0.47, 2.15]}
        rotation={[0.19, 0.38, -0.14]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.88, 0.96, -3.42]}
        rotation={[Math.PI, -1, Math.PI]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.62, 2.02, -0.89]}
        rotation={[-Math.PI, 1.41, -Math.PI]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-7.16, 1.4, -3.65]}
        rotation={[0, -1.32, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.08, 2.24, -0.84]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-6.1, 1.91, -1.08]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.93, 1.49, 2.29]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.11, 2.1, 0.75]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.34, 1.89, 1.08]}
        rotation={[0.39, -0.18, -0.25]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-13.77, 0.09, 0.89]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-14.23, 0.08, 0.59]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.92, 1.09, 0.58]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-10.09, 0.99, 0.39]}
        scale={0.33}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-0.32, 0.43, 2.46]}
        rotation={[0.28, -0.1, -0.17]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-8.13, 0.07, 3.51]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-7.02, 0.19, 3.16]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.15, 0.67, 2.91]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-11.82, 0.52, 3.37]}
        rotation={[0.29, -0.07, -0.12]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.84, 0.09, 0.07]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-0.58, 2.27, -0.14]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-0.97, 2.37, -0.5]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[0.12, 1.82, 0.23]}
        rotation={[0, 1.47, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[0.39, 1.75, -0.39]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[0.48, 1.38, -1.07]}
        rotation={[Math.PI, -0.44, Math.PI]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[0.94, 1.36, 0.77]}
        rotation={[-Math.PI, 0.69, -Math.PI]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeForestOrange.nodes["tree-forest_orange001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.17, 1.88, -0.33]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-6.93, 2.2, 0.75]}
        rotation={[0, 1.18, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-6.47, 1.32, -3.96]}
        rotation={[-0.31, -0.18, 0.14]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stumpSmall.nodes["stump-small001"].geometry}
        material={props.bakedMaterial1}
        position={[-2.61, 1.35, -3.14]}
        rotation={[-0.66, -0.11, 0.03]}
        scale={0.42}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_monta%C3%B1a/stump_small_volcanico.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_monta%C3%B1a/tree_conifer_volcanico.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_monta%C3%B1a/tree_forest_orange_volcanico.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_monta%C3%B1a/tree_old_volcanico.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_monta%C3%B1a/tree_volcanico.gltf"
);

export default MountainMainland;
