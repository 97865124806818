import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../interfaces";

const JungleIsland = ({ ...props }) => {
  const palmHigh = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/palm_high_jungla.gltf"
  ) as DreiGLTF;
  const plantBush = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/plant_bush_jungla.gltf"
  ) as DreiGLTF;
  const rockPilar = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/rock_pilar_jungla.gltf"
  ) as DreiGLTF;
  const rockSharp = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/rock_sharp_jungla.gltf"
  ) as DreiGLTF;
  const treeBaobab = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/tree_baobab_jungla.gltf"
  ) as DreiGLTF;
  const treeSpruce = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/tree_spruce_jungla.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[20.91, -0.02, -17.28]}
        rotation={[0, 0.67, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[19.65, 0.28, -18.9]}
        rotation={[-Math.PI, 0.71, -Math.PI]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockSharp.nodes["rock-sharp001"].geometry}
        material={props.bakedMaterial1}
        position={[16.88, 1.96, -17.72]}
        rotation={[0, 0.78, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockPilar.nodes["rock-pillar001"].geometry}
        material={props.bakedMaterial1}
        position={[13.66, -0.15, -18.9]}
        rotation={[-Math.PI, 0.64, -Math.PI]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[21.04, 0.31, -18.96]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[20.88, 0.11, -18.1]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[21.53, -0.91, -19.27]}
        rotation={[0, -0.71, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[20.74, 0.38, -19.45]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[16.16, -0.17, -15.98]}
        rotation={[0, -0.52, 0]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[17.67, 0.46, -18.88]}
        rotation={[0, -0.44, 0]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[17.31, -0.58, -22.91]}
        rotation={[0, -0.71, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[16.77, 0.1, -22.17]}
        rotation={[0, -1.39, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[15.88, -0.43, -22.61]}
        rotation={[0, -1.39, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[16.15, 0.31, -21.82]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[16.76, 0.31, -20.9]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[15.2, 0.13, -21.15]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.4, 0.25, -20.89]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.79, 0.27, -21.14]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[20.15, 0.32, -20.89]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[20.35, 0.13, -21.1]}
        rotation={[0, -1.39, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[17.11, 0.25, -20.55]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[16.49, 0.26, -21.35]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.47, 0.31, -20.49]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[18.21, 0.02, -22.98]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.89, 0.06, -22.56]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.96, 0.17, -21.83]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[18.64, 0.05, -22.69]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[16.82, 1.96, -17.04]}
        rotation={[0, -0.03, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[17.17, 2, -17.28]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[17.39, 2.01, -17.54]}
        rotation={[0, 0.49, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[16.51, 1.98, -17.3]}
        rotation={[0, -0.06, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[16.94, 1.98, -17.86]}
        rotation={[0, 0.95, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[17.11, 0.18, -20.55]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[15.85, 0.92, -17.49]}
        rotation={[0, -1.39, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[16.27, 0.92, -18.46]}
        rotation={[0, -0.54, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[14.12, -0.47, -20.73]}
        rotation={[0, -0.54, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[10.86, -0.4, -17.78]}
        rotation={[-Math.PI, 0.68, -Math.PI]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[12.39, -0.87, -19.23]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[17.77, 0.22, -19.71]}
        rotation={[-Math.PI, 0.52, -Math.PI]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[18.47, 0.58, -18.33]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[18.58, 0.43, -16.33]}
        rotation={[0, 1.33, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[18.45, 0.41, -15.24]}
        rotation={[0, -1.26, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[19.87, 0.37, -15.44]}
        rotation={[0, -0.74, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[18.3, 0.17, -13.42]}
        rotation={[0, -0.5, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[16.45, -0.02, -19.7]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[19.62, 0.12, -17.5]}
        rotation={[0, 0.72, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[16.39, -0.02, -14.38]}
        rotation={[0, 0.4, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockPilar.nodes["rock-pillar001"].geometry}
        material={props.bakedMaterial1}
        position={[19.66, 0.3, -19.84]}
        rotation={[-Math.PI, 0.64, -Math.PI]}
        scale={0.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[15.73, 0.14, -14.86]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[17.34, 0.14, -12.47]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[17.48, 0.33, -13.7]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[19.13, 0.31, -10.59]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[20.21, 0.52, -12.2]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[18.97, 0.49, -12.17]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[22.1, 0.5, -14.45]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[21.26, 0.5, -15.24]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[22.41, 0.14, -15.78]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[20.87, 0.14, -16.26]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[15.08, 0.14, -20.29]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[14.67, 0.03, -19.09]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[13.89, 0, -19.55]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[12.65, 0.14, -15.21]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[11.43, 0.15, -16.39]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[11.85, 0.14, -15.08]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[14.98, 0.14, -15.54]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[16.84, 0.06, -11.72]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[16.98, 0.27, -14.99]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[19.03, 0.14, -15.42]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[19.03, 0.72, -15.42]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[17.56, 1.03, -16.27]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[16.06, 0.08, -15.28]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[14.88, -0.09, -18.4]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[21.71, 0.4, -15.99]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[20.4, -0.86, -19.89]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[14.76, 0, -19.59]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[13.23, 0.01, -16.08]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[18.9, 0.65, -11.88]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[16.69, -0.78, -10.79]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[10.96, -0.23, -15.04]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[15.02, -0.14, -13.91]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[22.75, -0.47, -18.1]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[18.86, 0.67, -14.71]}
        rotation={[Math.PI, -0.58, Math.PI]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[20.16, 0.49, -15.94]}
        rotation={[0, 0.12, 0]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[19.43, 0.51, -17.69]}
        rotation={[-Math.PI, 1.11, -Math.PI]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[17.94, 0.26, -14.64]}
        rotation={[-Math.PI, 1.4, -Math.PI]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[14.79, -0.15, -17.08]}
        rotation={[-Math.PI, 1.35, -Math.PI]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[20.99, -0.03, -17.22]}
        rotation={[-Math.PI, 0.22, -Math.PI]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockSharp.nodes["rock-sharp001"].geometry}
        material={props.bakedMaterial1}
        position={[17.1, 0.2, -13.06]}
        rotation={[0, 0.78, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={rockSharp.nodes["rock-sharp001"].geometry}
        material={props.bakedMaterial1}
        position={[22.17, -0.01, -16.54]}
        rotation={[0, 0.78, 0]}
        scale={0.28}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[21.82, 0.19, -18.5]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[21.56, 0.06, -17.92]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[14.12, -0.05, -18.52]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[15.36, 0.09, -19.61]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[18.55, 0.31, -16.98]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[15.86, 0.13, -20.39]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[18.36, 0.31, -19.74]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[18.99, 0.81, -17.72]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.52, 0.46, -16.72]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[20.43, 0.42, -15.88]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[17.32, 0.83, -15.69]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[18.49, 0.51, -14.2]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[17.37, 0.42, -14.74]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[16.59, 0.27, -15.23]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[17.87, 0.42, -18.45]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[21.53, 0.24, -16.06]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[20.38, 0.26, -18.5]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.06, 0.42, -19.48]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.21, 0.49, -19.14]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[15.02, 0, -16.12]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[14.71, -0.01, -16.08]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.79, -0.08, -13.15]}
        rotation={[0, -0.32, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[14.15, -0.05, -13.23]}
        rotation={[0, 0.75, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[15.62, 0, -10.66]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[15.84, 0.04, -11.01]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[15.62, 0.09, -14.03]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.59, 0.42, -16.08]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.42, 0.34, -15.82]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11.94, 0.26, -15.54]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.07, 0.28, -11.26]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.4, 0.29, -11.27]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[22.8, 0.04, -16.46]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[22.02, 0.47, -13.64]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[22.01, 0.06, -12.07]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[21.06, 0.28, -20.49]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[22.34, 0.42, -19.56]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[23.08, 0.08, -17.61]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[14.54, 0.19, -21.71]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.55, 0.01, -20.26]}
        rotation={[0, -0.8, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11.89, 0.05, -19.7]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11.77, 0.12, -18.14]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11.32, 0.16, -17.34]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[10.79, 0.16, -16.15]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11.34, 0.21, -14.35]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[12.56, 0.13, -14.02]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.19, 0.01, -19.74]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[21.08, 0.29, -20.18]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[23.21, 0.08, -19.65]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[22.9, 0.1, -16.1]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[23.01, 0.12, -14.34]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[22.3, 0.1, -12.46]}
        rotation={[0, 0.81, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[21.07, 0.21, -21.5]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[22, 0.21, -20.85]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[21.71, 0.25, -20.13]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[21.91, 0.21, -18.23]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[23.17, 0.07, -17.07]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[22.7, 0.21, -18.66]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[22.68, 0.16, -15.84]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[23.23, 0.08, -14.51]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[14.82, 0.21, -21.9]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[14.91, 0.21, -21.55]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[15.52, 0.21, -23.09]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.34, -0.01, -21.31]}
        rotation={[0, -0.8, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.52, -0.03, -20.8]}
        rotation={[0, -0.8, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[12.28, 0.04, -19.64]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.34, 0.06, -19.5]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[12.45, 0.05, -18.82]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.21, 0.08, -18.79]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11.29, 0.03, -18.91]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[21.14, 0.36, -11.64]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[13.54, 0.17, -18.02]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[11.98, -0.05, -18.47]}
        rotation={[0, 0.04, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[12.83, 0, -18.34]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[12.23, 0.25, -16.19]}
        rotation={[0, 1.14, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[9.77, 0.04, -16.87]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[9.97, 0.06, -16.78]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11, 0.1, -17.18]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11.1, 0.16, -16.13]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11.67, 0.2, -17.76]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11.82, 0.25, -14.79]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[11.58, 0.26, -15.18]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[10.91, 0, -15.26]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[10.27, 0, -17.51]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[10.19, 0.05, -15.15]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[12.55, 0.09, -13.67]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.54, -0.01, -13.46]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[23.31, 0.08, -14.13]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[23.25, 0.08, -14.98]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[22.48, 0.28, -13.89]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[19.72, 0.19, -21.85]}
        rotation={[0, 0.63, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[20.23, 0.25, -21.53]}
        rotation={[0, -1.37, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[23.6, -0.7, -16.06]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[22.56, -0.42, -14.18]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[20.64, -0.31, -11.28]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[17.24, 0.06, -10.85]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[17.63, 0.04, -10.58]}
        rotation={[0, -0.43, 0]}
        scale={0.47}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeSpruce.nodes["tree-spruce001"].geometry}
        material={props.bakedMaterial1}
        position={[17.69, 0.13, -11.3]}
        rotation={[0, 0.48, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={plantBush.nodes["plant-bush-small001"].geometry}
        material={props.bakedMaterial1}
        position={[18.4, 0.11, -10.4]}
        rotation={[0, -0.43, 0]}
        scale={0.29}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[18.87, -0.2, -10.34]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[21.25, 0.24, -12.95]}
        rotation={[0, -0.74, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high001"].geometry}
        material={props.bakedMaterial1}
        position={[21.01, 0.57, -15.33]}
        rotation={[0, -0.03, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[18.49, 0.89, -17.14]}
        rotation={[0, 1.4, 0]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[18.03, 0.58, -15.67]}
        rotation={[0, -0.31, 0]}
        scale={0.35}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBaobab.nodes["tree-baobab001"].geometry}
        material={props.bakedMaterial1}
        position={[18.23, 0.2, -12.63]}
        rotation={[Math.PI, -1.2, Math.PI]}
        scale={0.35}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/palm_high_jungla.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/plant_bush_jungla.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/rock_pilar_jungla.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/rock_sharp_jungla.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/tree_baobab_jungla.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Islote_jungla/tree_spruce_jungla.gltf"
);

export default JungleIsland;
