import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../interfaces";

const GrassLandMainland = ({ ...props }) => {
  const flowerPoisonous = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/flower_poisonous_pradera.gltf"
  ) as DreiGLTF;
  const islandTiny = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/island_tiny_pradera.gltf"
  ) as DreiGLTF;
  const shrubFlowers = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/shrub_flowers_pradera.gltf"
  ) as DreiGLTF;
  const stoneFlat = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/stone_flat_pradera.gltf"
  ) as DreiGLTF;
  const sunflower = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/sunflower_pradera.gltf"
  ) as DreiGLTF;
  const treeOak = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/tree_oak_pradera.gltf"
  ) as DreiGLTF;
  const tree = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/tree_pradera.gltf"
  ) as DreiGLTF;
  const treeTall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/tree_tall_pradera.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={islandTiny.nodes["island-tiny001"].geometry}
        material={props.bakedMaterial1}
        position={[-1.05, 1.1, -6.13]}
        rotation={[0, 0.21, 0]}
        scale={0.04}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.92, -0.05, -9.57]}
        rotation={[0, -1.03, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.81, -0.06, -10.92]}
        rotation={[0, -1.5, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubFlowers.nodes["shrub-flowers001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.47, -0.12, -8.87]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-2.98, -0.55, -6.57]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak001"].geometry}
        material={props.bakedMaterial1}
        position={[1.16, 0.11, -5.64]}
        rotation={[0, 0.79, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.5, 1.5, -5.19]}
        rotation={[-Math.PI, 0.66, -Math.PI]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak001"].geometry}
        material={props.bakedMaterial1}
        position={[-10.48, 1.6, -5.27]}
        rotation={[0, 0.01, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[-10.08, 1.65, -4.69]}
        rotation={[-Math.PI, 0.17, -Math.PI]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[-7.64, 1.11, -6.06]}
        rotation={[-Math.PI, 0.36, -Math.PI]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[-5.07, 1.11, -5.33]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[0.57, -0.17, -3.65]}
        rotation={[0, -0.32, 0]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.49, 0.96, -5.05]}
        rotation={[0, -0.28, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak001"].geometry}
        material={props.bakedMaterial1}
        position={[-1.24, 0.46, -4.19]}
        rotation={[0, 0.02, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak001"].geometry}
        material={props.bakedMaterial1}
        position={[1.44, 0.16, -3.59]}
        rotation={[0, 0.52, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak001"].geometry}
        material={props.bakedMaterial1}
        position={[2.21, 0.24, -4.04]}
        rotation={[0, 0.02, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak001"].geometry}
        material={props.bakedMaterial1}
        position={[-13.54, 1.67, -5.42]}
        rotation={[-Math.PI, 0.95, -Math.PI]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={tree.nodes.tree001.geometry}
        material={props.bakedMaterial1}
        position={[-11.95, 1.42, -5.44]}
        rotation={[0, 0.58, 0]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.56, -0.05, -10.06]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-7.7, 0.17, -7.09]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.87, 1.84, -7.74]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubFlowers.nodes["shrub-flowers001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.98, 1.82, -5.61]}
        rotation={[0, 0.73, 0]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubFlowers.nodes["shrub-flowers001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.52, 1.63, -6.2]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubFlowers.nodes["shrub-flowers001"].geometry}
        material={props.bakedMaterial1}
        position={[-13.77, 1.83, -6.65]}
        rotation={[-Math.PI, 1.5, -Math.PI]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-8.62, 1.31, -5.12]}
        rotation={[-Math.PI, 1.13, -Math.PI]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-11.31, 1.77, -4.86]}
        rotation={[-Math.PI, 1.13, -Math.PI]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-2.51, 0.37, -4.4]}
        rotation={[0, 1.07, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubFlowers.nodes["shrub-flowers001"].geometry}
        material={props.bakedMaterial1}
        position={[-7.28, 0.11, -8.13]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-4.98, -0.14, -9.84]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-2.7, 0.83, -5.01]}
        rotation={[0, -0.4, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.73, 0.83, -6.87]}
        rotation={[0, -0.97, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.84, 1.69, -7.2]}
        rotation={[0, -0.43, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-11.76, 1.77, -8.12]}
        rotation={[0, -0.97, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.57, 1.77, -7.16]}
        rotation={[0, -0.97, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.62, 1.84, -5.74]}
        rotation={[0, -0.97, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.67, 1.84, -5.04]}
        rotation={[-Math.PI, 0.01, -Math.PI]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-2.19, -0.05, -7.9]}
        rotation={[0, -1.18, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-2.98, -0.02, -7.26]}
        rotation={[-Math.PI, 0.77, -Math.PI]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.33, -0.05, -8.81]}
        rotation={[0, 1.1, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-0.27, -0.05, -9.46]}
        rotation={[Math.PI, -1.42, Math.PI]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.19, -0.05, -8.24]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.88, -0.05, -8.61]}
        rotation={[0, 1.23, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-8.98, -0.05, -10.44]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.11, -0.05, -10.78]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubFlowers.nodes["shrub-flowers001"].geometry}
        material={props.bakedMaterial1}
        position={[-8.01, 0.11, -9.34]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubFlowers.nodes["shrub-flowers001"].geometry}
        material={props.bakedMaterial1}
        position={[-0.19, -0.12, -8.02]}
        scale={0.63}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-4.64, -0.14, -8.05]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-2.58, -0.14, -7.79]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-2.56, -0.14, -7.26]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-4.98, 0.03, -9.84]}
        scale={0.54}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-7.53, -0.14, -9.11]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-6.73, -0.14, -9.19]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-6.32, -0.14, -8.16]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-3.91, -0.14, -8.35]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-5.89, -0.14, -10.19]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-6.87, -0.14, -9.66]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-2.83, -0.14, -10.41]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-1.03, -0.14, -8.41]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[0.39, -0.14, -7.95]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[0.88, -0.14, -6.36]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.42, -0.05, -9.5]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.63, -0.05, -6.95]}
        rotation={[0, -0.5, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-7.05, -0.05, -8.89]}
        rotation={[0, -1.09, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-8.46, -0.05, -9.79]}
        rotation={[Math.PI, -1.04, Math.PI]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-9.55, -0.14, -10.61]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-4.54, -0.14, -10.44]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-1.79, -0.05, -10.76]}
        rotation={[0, 1.4, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-0.6, -0.05, -8.98]}
        rotation={[0, -0.51, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[0.42, -0.14, -8.53]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-6.02, -0.14, -9.67]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-6.24, -0.14, -7.46]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-2.74, -0.14, -10.81]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-5.27, -0.14, -8.94]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.56, -0.28, -6.22]}
        rotation={[0, 0.8, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.16, -0.22, -5.99]}
        rotation={[Math.PI, -0.35, Math.PI]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.35, 0.27, -5.36]}
        rotation={[0, -1.16, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-6.72, -0.96, -8.56]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-5.8, -0.66, -7.7]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-4.39, -0.84, -7.22]}
        rotation={[0, -1.57, 0]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak001"].geometry}
        material={props.bakedMaterial1}
        position={[-6.53, 0.61, -6.34]}
        rotation={[0, 0.02, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOak.nodes["tree-oak001"].geometry}
        material={props.bakedMaterial1}
        position={[0.31, -0.29, -7.17]}
        rotation={[0, 0.67, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeTall.nodes["tree-tall001"].geometry}
        material={props.bakedMaterial1}
        position={[-1.84, -0.66, -7.6]}
        rotation={[-Math.PI, 1.24, -Math.PI]}
        scale={0.42}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-6.05, 1.2, -5.33]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-7.38, 1.2, -4.93]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-8.98, 1.2, -6.87]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-8.64, 1.2, -6.07]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-12.79, 1.2, -6.32]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-13.16, 1.2, -5.97]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-9.79, 1.2, -7.86]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-9.09, 1.2, -7.35]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-8.11, 1.2, -6.51]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-6.62, 1.2, -5.47]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[0.24, 0.4, -4.73]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-0.51, 0.46, -4.55]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-4.71, 0.57, -6.2]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-11.76, 1.77, -8.12]}
        rotation={[0, -0.97, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedMaterial1}
        position={[-3.31, -0.06, -7.93]}
        rotation={[0, -1.5, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedMaterial1}
        position={[-10.9, 1.99, -8.43]}
        rotation={[0, -1.5, 0]}
        scale={0.37}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-9.81, 1.69, -8.59]}
        rotation={[0, 0.1, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.92, 1.69, -8.8]}
        rotation={[0, -0.43, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={flowerPoisonous.nodes["flower-poisonous001"].geometry}
        material={props.bakedMaterial1}
        position={[-12.4, 1.77, -8.71]}
        rotation={[0, 0.45, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-3.64, 0.57, -6.3]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunflower.nodes.sunflower001.geometry}
        material={props.bakedMaterial1}
        position={[-6.67, 0.57, -7.06]}
        rotation={[0, 1.13, 0]}
        scale={0.57}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/flower_poisonous_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/island_tiny_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/shrub_flowers_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/stone_flat_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/sunflower_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/tree_oak_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/tree_pradera.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_pradera/tree_tall_pradera.gltf"
);

export default GrassLandMainland;
