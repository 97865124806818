import "./ChooseOne.css";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";
import {
  Arrows,
  Background,
  ConfirmButton,
  ExerciseImage,
  ExerciseName,
  HelpMenu,
  Professor,
  TextBox,
} from "./_components";
import { useAppSelector } from "../../../hooks/hooks";

const ChooseOne = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  const [selected, setSelected] = useState("");

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const image = props.exercise.image?.url;
  const animationUrl = props.exercise.animation?.url;
  const propSolutions = props.exercise.solutions as number[];
  const solution = propSolutions[0] as number;
  const propOptions = props.exercise.options as string[];
  const [options, setOptions] = useState(_.shuffle(propOptions));

  const [selectedExercise, setselectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setmaxExercise] = useState(props.maxExercise);
  const [research, setResearch] = useState(props.research);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);

  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (image !== undefined || animationUrl !== undefined) {
      setImageExists(true);
    }
  }, [image, animationUrl]);

  useEffect(() => {
    correctAnswer();
  }, [selectedExercise, maxExercise]);

  useEffect(() => {
    setOptions(_.shuffle(propOptions));
    setselectedExercise(props.exerciseIndex);
    setmaxExercise(props.maxExercise);
  }, [props.exerciseIndex]);

  const correctAnswer = () => {
    if (research) {
      if (selectedExercise < maxExercise) {
        setSelected(propOptions[solution]);
      }
    } else {
      if (isTeacher) {
        setSelected(propOptions[solution]);
      }
    }
  };

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const confirmHandler = () => {
    if (research) {
      if (selected === propOptions[solution]) {
        props.updateLevel("right", [selected], true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
      }
    } else {
      if (selected === propOptions[solution]) {
        props.updateLevel("right", [selected], true);
      } else {
        props.updateLevel("right", [selected], false);
      }
    }
  };

  const optionButtonHandler = (answer: string) => {
    setSelected(answer);
  };

  const renderOptionsButtons = () => {
    return (
      <div
        className="choose_one_options"
        style={{
          gridTemplateColumns:
            "repeat(" + options.length + ", " + 100 / options.length + "%)",
        }}
      >
        {options.map((option, optionIndex) => {
          return (
            <motion.div
              key={optionIndex}
              className={
                selected === option
                  ? "choose_one_option_pressed"
                  : "choose_one_option"
              }
              onClick={() => optionButtonHandler(option)}
              whileHover={{
                scale: 1.02,
                boxShadow: "0px 0px 10px 1px #5090F0",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <div className="center_container">
                <div
                  className={
                    selected === option && option.length < 70
                      ? "text_white_small_bold"
                      : selected === option && option.length >= 70
                        ? "text_white_very_small_bold"
                        : selected !== option && option.length >= 70
                          ? "text_black_very_small_bold"
                          : "text_black_small_bold"
                  }
                  style={{ padding: 3 + "%" }}
                >
                  {option}
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="choose_one">
      <Background
        incorrectFeedback={incorrectFeedback}
        setIncorrectFeedback={setIncorrectFeedback}
      />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={imageExists} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={research}
      />
      <ConfirmButton confirmHandler={confirmHandler} />
      <HelpMenu helpText={helpText} tipHandler={props.tipHandler} />
      <ExerciseImage
        image={image}
        animationUrl={animationUrl}
        imageExists={imageExists}
      />
      <ExerciseName type={props.exercise.type} smallMargin={imageExists} imageExists={imageExists} />
      {renderOptionsButtons()}
    </div>
  );
};

export default ChooseOne;
