import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";
import { MeshPhongMaterial } from "three";
import { useMemo } from "react";
import * as THREE from "three";

const Paths = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caminos+T02/camino_principal_t02.glb"
  ) as DreiGLTF;
  const bakedPathMaterial = useMemo(() => {
    return new MeshPhongMaterial({
      color: 0xcdcdcd,
      emissiveIntensity: 0,
      shininess: 0,
      fog: true,
    });
  }, []);

  const geometries = useMemo(() => {
    return {
      camino_principal_1: new THREE.BufferGeometry().copy(
        nodes.camino_principal_t02_1.geometry
      ),
      camino_principal_2: new THREE.BufferGeometry().copy(
        nodes.camino_principal_t02_2.geometry
      ),
      camino_principal_3: new THREE.BufferGeometry().copy(
        nodes.camino_principal_t02_3.geometry
      ),
      camino_principal_4: new THREE.BufferGeometry().copy(
        nodes.camino_principal_t02_4.geometry
      ),
    };
  }, [nodes]);

  return (
    <group {...props} dispose={null}>
      <group position={[-0.183, 0.474, 18.74]} scale={[0.309, 0.021, 1.284]}>
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometries.camino_principal_1}
          material={bakedPathMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometries.camino_principal_2}
          material={materials.camino_bordes}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometries.camino_principal_3}
          material={materials.bordes_lanzadera}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={geometries.camino_principal_4}
          material={nodes.camino_principal_t02_4.material}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caminos+T02/camino_principal_t02.glb"
);

export default Paths;
