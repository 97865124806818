import "./ProfessorEvaluationGlobalCompetences.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LazyMotion, m } from "framer-motion";
import BackArrow from "../../../../assets/exercises/back_arrow";
import { GlobalCompetencePoints } from "../../../../_newapios/progress/student_global_competences.ts";
import RadarChart from "./RadarChart";
import {
  selectedCompetence,
  setEvaluationMenu,
} from "../../../../reducers/teacherSlice";
import { getGlobalCompetenceAggregates } from "../../../utils/evaluation.ts";
import { colors, simpleGlobalCompetences } from "../../../../constants";
import _ from "lodash";

const ProfessorEvaluationGlobalCompetences = ({ ...props }) => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const student = useAppSelector((state) => state.teacher.selectedStudent);

  const loadFeatures = () =>
    import("../../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const [globalCompetences, setGlobalCompetences] =
    useState<GlobalCompetencePoints>({});
  const [schoolCompetences, setSchoolCompetences] =
    useState<GlobalCompetencePoints>({});

  const [studentData, setStudentData] = useState<number[]>([]);
  const [schoolData, setSchoolData] = useState<number[]>([]);
  const [baselinedStudGlComp, setBaselinedStudGlComp] = useState<number[]>([]);
  const [baselinedSchoolGlComp, setBaselinedSchoolGlComp] = useState<number[]>(
    []
  );
  const [labels, setLabels] = useState<string[]>([]);
  const [acronyms, setAcronyms] = useState<string[]>([]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    setGlobalCompetences(props.globalCompetences);
    setSchoolCompetences(props.schoolCompetences);
  }, [student, props.globalCompetences, props.schoolCompetences]);

  useEffect(() => {
    calcLocalCompetences();
  }, [globalCompetences, schoolCompetences]);

  const calcLocalCompetences = () => {
    const globalCompetenceAggregates =
      getGlobalCompetenceAggregates(globalCompetences);
    const newLabels = !_.isEmpty(globalCompetences)
      ? Object.keys(globalCompetenceAggregates).map((str) =>
          t(str + "_label", { ns: "evaluation" })
        )
      : simpleGlobalCompetences.map((gc) =>
          t(gc[0] + "_label", { ns: "evaluation" })
        );
    const newAcronyms = !_.isEmpty(globalCompetences)
      ? Object.keys(globalCompetenceAggregates).map((str) => str)
      : simpleGlobalCompetences.map((gc) =>
          t(gc[0] + "_name", { ns: "evaluation" })
        );

    const newStudentData = !_.isEmpty(globalCompetences)
      ? Object.values(globalCompetenceAggregates)
      : _.fill(Array(simpleGlobalCompetences.length), 0);

    const newSchoolData = !_.isEmpty(schoolCompetences)
      ? Object.values(getGlobalCompetenceAggregates(schoolCompetences))
      : _.fill(Array(simpleGlobalCompetences.length), 0);

    setLabels(newLabels);
    setAcronyms(newAcronyms);
    setStudentData(newStudentData);
    setSchoolData(newSchoolData);
  };

  useEffect(() => {
    let tempBaselinedStudGlComp: number[] = [];
    let tempBaselinedSchoolGlComp: number[] = [];
    studentData.forEach((spComp) => {
      if (spComp > 12500) {
        tempBaselinedStudGlComp.push(12499);
      } else {
        tempBaselinedStudGlComp.push(spComp);
      }
    });

    schoolData.forEach((spComp) => {
      if (spComp > 12500) {
        tempBaselinedSchoolGlComp.push(12499);
      } else {
        tempBaselinedSchoolGlComp.push(spComp);
      }
    });

    setBaselinedStudGlComp(tempBaselinedStudGlComp);
    setBaselinedSchoolGlComp(tempBaselinedSchoolGlComp);
  }, [studentData, schoolData]);

  const backHandler = () => {
    dispatch(setEvaluationMenu("generalInfo"));
  };

  const competenceHandler = (acronym: string) => {
    dispatch(selectedCompetence(acronym));
    dispatch(setEvaluationMenu("globalSubcompetences"));
  };

  const renderLeft = () => {
    return (
      <div className="professor_evaluation_global_competences_left">
        <LazyMotion features={loadFeatures}>
          <m.div
            className="professor_evaluation_global_competences_title"
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            onClick={() => {
              backHandler();
            }}
            whileHover={{
              scale: 1.1,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "twen", ease: "easeInOut", duration: 0.2 }}
          >
            <BackArrow color={"black"} size={"50"} />
            <div
              className="text_black_small_bold"
              style={{ textDecoration: "underline" }}
            >
              {t("global_competences")}
            </div>
          </m.div>
        </LazyMotion>
        <div className="professor_evaluation_global_competences_left_octagon">
          <RadarChart
            max={12500}
            full={false}
            labels={labels}
            studentData={baselinedStudGlComp}
            schoolData={baselinedSchoolGlComp}
          />
        </div>
      </div>
    );
  };

  const renderRight = () => {
    return (
      <div
        className="professor_evaluation_global_competences_right"
        style={{
          gridTemplateRows:
            "repeat(" +
            globalCompetences.length +
            ", " +
            100 / globalCompetences.length +
            "%)",
        }}
      >
        {acronyms.map((acronym, acronymIndex) => {
          const baseline = 12500;
          return (
            <LazyMotion features={loadFeatures}>
              <m.div
                key={acronymIndex}
                className="professor_evaluation_global_competences_right_element"
                style={{
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => competenceHandler(acronym)}
                whileHover={{
                  scale: 1.04,
                  boxShadow: "0px 0px 10px 2px #5090F0",
                }}
              >
                <div className="left_container">
                  <div
                    className={
                      screenSize.dynamicWidth > 991
                        ? "text_black_very_small_bold"
                        : "text_black_hiper_small_bold"
                    }
                  >
                    {t(acronym + "_name", { ns: "evaluation" })}
                  </div>
                </div>
                <div className="professor_evaluation_global_competences_right_element_line_container">
                  <div className="professor_evaluation_global_competences_right_element_line" />
                  <div
                    className="professor_evaluation_global_competences_right_element_student"
                    style={{
                      width:
                        (baselinedStudGlComp[acronymIndex] * 100) / baseline +
                        "%",
                    }}
                  />
                  <div
                    className="professor_evaluation_global_competences_right_element_school"
                    style={{
                      left:
                        (baselinedSchoolGlComp[acronymIndex] * 100) / baseline +
                        "%",
                    }}
                  />
                </div>
              </m.div>
            </LazyMotion>
          );
        })}
      </div>
    );
  };

  return (
    <div className="professor_evaluation_global_competences">
      {renderLeft()}
      {renderRight()}
    </div>
  );
};

export default ProfessorEvaluationGlobalCompetences;
