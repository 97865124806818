import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";
import { BufferGeometry } from "three";

const ShuttleProps = ({ ...props }) => {
  const biomeName: string = props.biomeName;
  const Reactor = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Lanzadera/reactor_lanzadera.glb"
  ) as DreiGLTF;
  const Tubos = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Lanzadera/scfi_pilar_lanzadera.glb"
  ) as DreiGLTF;
  const Farola = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Lanzadera/lamp_lanzadera.glb"
  ) as DreiGLTF;
  const BrazoMecanico = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Lanzadera/robotic_arm_lanzadera.glb"
  ) as DreiGLTF;
  const treeBeech = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_beech_local_basic_.gltf"
  ) as DreiGLTF;

  const treeElipseSnow = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Snow/tree_elipse_snow.gltf"
  ) as DreiGLTF;

  const cactusBig = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/cactus_big.gltf"
  ) as DreiGLTF;

  const treeBeechSwamp = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Swamp/tree_beech2.gltf"
  ) as DreiGLTF;

  const palmBig = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/palm_big.gltf"
  ) as DreiGLTF;

  const treeBeechOrange = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/tree_beech_orange.gltf"
  ) as DreiGLTF;

  const treeBaobab = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Jungle/tree_baobab.gltf"
  ) as DreiGLTF;

  let arbolesGeometry: BufferGeometry;

  switch (biomeName) {
    case "desert":
      arbolesGeometry = cactusBig.nodes["cactus-big004"].geometry;
      break;
    case "forest":
      arbolesGeometry = treeBeech.nodes["tree-beech001"].geometry;
      break;
    case "grassland":
      arbolesGeometry = treeBeech.nodes["tree-beech001"].geometry;
      break;
    case "jungle":
      arbolesGeometry = treeBaobab.nodes["tree-baobab004"].geometry;
      break;
    case "mountain":
      arbolesGeometry = treeBeechOrange.nodes["tree-beech011"].geometry;
      break;
    case "beach":
      arbolesGeometry = palmBig.nodes["palm-big002"].geometry;
      break;
    case "savannah":
      arbolesGeometry = treeBeechOrange.nodes["tree-beech011"].geometry;
      break;
    case "snow":
      arbolesGeometry = treeElipseSnow.nodes["tree-elipse006"].geometry;
      break;
    case "swamp":
      arbolesGeometry = treeBeechSwamp.nodes["tree-beech011"].geometry;
      break;
    case "volcano":
      arbolesGeometry = treeBeechOrange.nodes["tree-beech011"].geometry;
      break;
    default:
      arbolesGeometry = treeBeech.nodes["tree-beech001"].geometry;
      break;
  }

  return (
    <group {...props} dispose={null}>
      {/* Reactor */}
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={Reactor.nodes["scifi-smelter001"].geometry}
        material={Reactor.materials["LOWPOLY-COLORS.004"]}
        position={[-6.156, 0.169, 21.148]}
        rotation={[0, -0.795, 0]}
        scale={0.474}
      />
      {/* Tubos */}
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={Tubos.nodes["scifi-pillar-light"].geometry}
        material={Tubos.materials["LOWPOLY-COLORS.004"]}
        position={[-1.006, 0.326, 17.812]}
        scale={0.246}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={Tubos.nodes["scifi-pillar-light"].geometry}
        material={Tubos.materials["LOWPOLY-COLORS.004"]}
        position={[-1.006, 0.326, 22.68]}
        scale={0.246}
      />

      {/* Farola */}
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={Farola.nodes["lamp-city003"].geometry}
        material={Farola.materials["LOWPOLY-COLORS.004"]}
        position={[-5.857, 0.227, 17.824]}
        scale={0.141}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={Farola.nodes["lamp-city003"].geometry}
        material={Farola.materials["LOWPOLY-COLORS.004"]}
        position={[-4.252, 0.227, 17.824]}
        scale={0.141}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={Farola.nodes["lamp-city003"].geometry}
        material={Farola.materials["LOWPOLY-COLORS.004"]}
        position={[-2.614, 0.227, 17.824]}
        scale={0.141}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={Farola.nodes["lamp-city003"].geometry}
        material={Farola.materials["LOWPOLY-COLORS.004"]}
        position={[-5.857, 0.227, 22.472]}
        rotation={[0, 0, -Math.PI]}
        scale={-0.141}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={Farola.nodes["lamp-city003"].geometry}
        material={Farola.materials["LOWPOLY-COLORS.004"]}
        position={[-4.252, 0.227, 22.472]}
        rotation={[0, 0, -Math.PI]}
        scale={-0.141}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={Farola.nodes["lamp-city003"].geometry}
        material={Farola.materials["LOWPOLY-COLORS.004"]}
        position={[-2.614, 0.227, 22.472]}
        rotation={[0, 0, -Math.PI]}
        scale={-0.141}
      />
      {/* BrazoMecanico */}
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={BrazoMecanico.nodes["scifi-robotic-arm-base"].geometry}
        material={BrazoMecanico.materials["LOWPOLY-COLORS.004"]}
        position={[-5.307, 0.276, 19.167]}
        rotation={[0, 1.019, 0]}
        scale={0.638}
      />
      {/* Arboles */}
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbolesGeometry}
        material={props.bakedAssetsMaterial}
        position={[-1.907, 0.34, 17.966]}
        rotation={[0, -0.413, 0]}
        scale={biomeName === "desert" ? 0.19 : 0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbolesGeometry}
        material={props.bakedAssetsMaterial}
        position={[-3.363, 0.34, 17.966]}
        rotation={[0, -0.413, 0]}
        scale={biomeName === "desert" ? 0.19 : 0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbolesGeometry}
        material={props.bakedAssetsMaterial}
        position={[-5.097, 0.34, 17.966]}
        rotation={[0, -0.413, 0]}
        scale={biomeName === "desert" ? 0.19 : 0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbolesGeometry}
        material={props.bakedAssetsMaterial}
        position={[-1.907, 0.34, 22.329]}
        rotation={[0, 0.413, -Math.PI]}
        scale={biomeName === "desert" ? -0.19 : -0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbolesGeometry}
        material={props.bakedAssetsMaterial}
        position={[-3.363, 0.34, 22.329]}
        rotation={[0, 0.413, -Math.PI]}
        scale={biomeName === "desert" ? -0.19 : -0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={arbolesGeometry}
        material={props.bakedAssetsMaterial}
        position={[-5.097, 0.34, 22.329]}
        rotation={[0, 0.413, -Math.PI]}
        scale={biomeName === "desert" ? -0.19 : -0.09}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Lanzadera/reactor_lanzadera.glb"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Lanzadera/scfi_pilar_lanzadera.glb"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Lanzadera/lamp_lanzadera.glb"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Lanzadera/robotic_arm_lanzadera.glb"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_beech_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Snow/tree_elipse_snow.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Desert/cactus_big.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Swamp/tree_beech2.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/palm_big.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/tree_beech_orange.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Jungle/tree_baobab.gltf"
);
export default ShuttleProps;
