import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";

const PathsHuts = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caminos+T02/caminos_cabanhas_t02_op.glb"
  ) as DreiGLTF;
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.camino_cabanha01.geometry}
        material={materials["LOWPOLY-COLORS.004"]}
        position={[-14.3, 0.397, 17.167]}
        rotation={[0, -0.161, 0]}
        scale={0.072}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.camino_cabanha02.geometry}
        material={materials["LOWPOLY-COLORS.004"]}
        position={[-13.006, 0.412, 20.212]}
        rotation={[Math.PI, -0.085, Math.PI]}
        scale={0.072}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.camino_cabanha03.geometry}
        material={materials["LOWPOLY-COLORS.004"]}
        position={[-14.299, 0.397, 23.19]}
        rotation={[0, 0.335, 0]}
        scale={[0.071, 0.072, 0.077]}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caminos+T02/caminos_cabanhas_t02_op.glb"
);

export default PathsHuts;
