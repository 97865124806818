import { fi } from "date-fns/locale";
import {
  BundleCompletionBusinessType,
  GetBundleAPI,
} from "../_newapios/content/bundle";
import { BusinessTeacherType, LoginsType } from "../_newapios/user/teacher";
import { BusinessSchoolType } from "../_newapios/user/school";
import { BusinessStudentType } from "../_newapios/user/student";

export interface BOChartData {
  type: string;
  label: string;
  borderColor: string;
  backgroundColor: string;
  data: {
    x: string;
    y: number;
  }[];
}

export const aggregateBundleCompletions = (
  completion_data: BundleCompletionBusinessType[]
) => {
  let final_data: {
    [key: string]: {
      assigned: number;
      completed: number;
    };
  } = {};

  for (let i = 0; i < completion_data.length; i++) {
    const data = completion_data[i];
    const date = new Date(data.start_date).toDateString();

    if (final_data[date] === undefined) {
      final_data[date] = {
        assigned: data.assigned,
        completed: data.completed,
      };
    } else {
      final_data[date].assigned += data.assigned;
      final_data[date].completed += data.completed;
    }
  }

  return final_data;
};

const getFirstAndLastDayOfWeek = (date: Date) => {
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay() + 1
  );
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay() + 7
  );

  return [firstDay, lastDay];
};

const getFirstWeekOfMonth = (year: number, month: number) => {
  const firstDayOfMonth = new Date(year, month, 1);
  const offset = (firstDayOfMonth.getDay() - 1 + 7) % 7;
  firstDayOfMonth.setDate(firstDayOfMonth.getDate() - offset);
  const lastDay = new Date(
    firstDayOfMonth.getFullYear(),
    firstDayOfMonth.getMonth(),
    firstDayOfMonth.getDate() + 6
  );
  return [firstDayOfMonth, lastDay];
};

const getAllWeeksOfPeriod = (startDate: Date, endDate: Date) => {
  const weeks = [];
  let [firstWeek, lastDayFirstWeek] = getFirstAndLastDayOfWeek(startDate);

  weeks.push([firstWeek, lastDayFirstWeek]);
  let currentWeek = lastDayFirstWeek;
  while (currentWeek < endDate) {
    const nextWeek = new Date(
      currentWeek.getFullYear(),
      currentWeek.getMonth(),
      currentWeek.getDate() + 1
    );
    const lastDay = new Date(
      nextWeek.getFullYear(),
      nextWeek.getMonth(),
      nextWeek.getDate() + 6
    );
    weeks.push([nextWeek, lastDay]);
    currentWeek = lastDay;
  }
  return weeks;
};

export const groupDataByTime = (
  data: BOChartData[],
  view: string,
  startDate: Date,
  endDate: Date
) => {
  const groupedData: BOChartData[] = [];
  for (let i = 0; i < data.length; i++) {
    const eachData = data[i];
    const dataMap: { [date: string]: number[] } = {};
    let timeLength = endDate.getTime() - startDate.getTime();
    if (view === "daily") {
      timeLength = Math.ceil(timeLength / (1000 * 3600 * 24));
    } else if (view === "weekly") {
      timeLength = Math.ceil(timeLength / (1000 * 3600 * 24 * 7));
    } else {
      const startYear = startDate.getFullYear();
      const startMonth = startDate.getMonth();
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth();
      timeLength = (endYear - startYear) * 12 + endMonth - startMonth + 1;
    }
    for (let j = 0; j < timeLength; j++) {
      let date = new Date(startDate.getTime() + j * 1000 * 3600 * 24);
      let key: string;
      let y: number = 0;
      if (view === "daily") {
        key = date.toDateString();
        if (date < startDate || date > endDate) {
          continue;
        }
        y =
          eachData.data.find((data) => data.x === date.toDateString())?.y || 0;
      } else if (view === "weekly") {
        const weeks = getAllWeeksOfPeriod(startDate, endDate);
        key = date.toDateString().slice(0, 3);
      } else {
        date = new Date(startDate.getFullYear(), startDate.getMonth() + j, 1);
        key = new Date(date.getFullYear(), date.getMonth(), 1).toDateString();
        for (let k = 0; k < eachData.data.length; k++) {
          const data = eachData.data[k];
          const dataDate = new Date(data.x);
          if (dataDate < startDate || dataDate > endDate) {
            continue;
          }
          if (dataDate.getMonth() === date.getMonth()) {
            y += data.y;
          }
        }
      }
      if (!dataMap[key]) {
        dataMap[key] = [];
      }

      dataMap[key].push(y);
    }
    if (eachData.label.slice(0, 5) === "Media") {
      const newData = Object.keys(dataMap).map((key) => {
        return {
          x: key,
          y: dataMap[key].reduce((acc, curr) => acc + curr, 0),
        };
      });
      const mean =
        newData.reduce((acc, curr) => acc + curr.y, 0) / newData.length;
      const data2 =
        newData.length > 1
          ? [
              {
                x: view === "daily" ? startDate.toDateString() : newData[0].x,
                y: mean,
              },
              {
                x:
                  view === "daily"
                    ? endDate.toDateString()
                    : newData[newData.length - 1].x,
                y: mean,
              },
            ]
          : [
              {
                x: newData[0].x,
                y: mean,
              },
            ];
      groupedData.push({
        type: eachData.type,
        label: eachData.label,
        borderColor: eachData.borderColor,
        backgroundColor: eachData.backgroundColor,
        data: data2,
      });
      continue;
    }
    groupedData.push({
      type: eachData.type,
      label: eachData.label,
      borderColor: eachData.borderColor,
      backgroundColor: eachData.backgroundColor,
      data: Object.keys(dataMap).map((key) => ({
        x: key,
        y: dataMap[key].reduce((acc, curr) => acc + curr, 0),
      })),
    });
  }
  return groupedData;
};

export const groupTeacherLoginsByUser = (
  logins: LoginsType[],
  teachers: BusinessTeacherType[],
  userFilter: string
) => {
  return logins.filter((login) => {
    const teacher = teachers.find((teacher) => teacher.id === login.teacher.id);
    if (!teacher) return false;
    const license = teacher.school.user_license[0].license.name;
    if (userFilter === "all") return true;
    if (userFilter === "schools") return license === "Pioneer";
    if (userFilter === "Free" || userFilter === "Ultimate") {
      return license === userFilter;
    }
    const school_id = teacher.school.id;
    return school_id === userFilter;
  });
};

export const groupStudentsByUser = (
  students: BusinessStudentType[],
  schools: BusinessSchoolType[],
  userFilter: string
) => {
  return students.filter((student) => {
    if (userFilter === "all") return true;
    const school_id = student.class.school_id;
    const license = schools.find((school) => school.id === school_id)
      ?.user_license[0].license.name;
    if (userFilter === "schools") return license === "Pioneer";
    if (userFilter === "Free" || userFilter === "Ultimate") {
      return license === userFilter;
    }
    return school_id === userFilter;
  });
};

export const groupBundlesByUser = (
  bundle_completions: BundleCompletionBusinessType[],
  schools: BusinessSchoolType[],
  userFilter: string
) => {
  return bundle_completions.filter((bundle_completion) => {
    if (userFilter === "all") return true;
    const school_id = bundle_completion.school_id;

    const license = bundle_completion.license_name;
    if (userFilter === "schools") return license === "Pioneer";
    if (userFilter === "Free" || userFilter === "Ultimate") {
      return license === userFilter;
    }
    return school_id === userFilter;
  });
};

export const getLoginsFromStudents = (students: BusinessStudentType[]) => {
  let logins: { [date: string]: number } = {};
  students.forEach((student) => {
    student.student_logins.forEach((login) => {
      const date = new Date(login.timestamp).toDateString();
      if (!logins[date]) {
        logins[date] = 1;
      } else {
        logins[date]++;
      }
    });
  });
  return logins;
};
