const Parks = ({ ...props }) => {
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["wall-japan-edge_medium001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[4.63, 0.41, 0.48]}
        rotation={[-Math.PI, 1.56, -Math.PI]}
        scale={0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["tree-oak004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[9.1, 0.38, -0.27]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["tree-oak005"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.24, 0.38, 0.83]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["tree-oak006"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.24, 0.38, -0.27]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["tree-oak007"].geometry}
        material={props.bakedAssetsMaterial}
        position={[9.1, 0.36, 0.86]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["tree-baobab001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[4.94, 0.46, -2.78]}
        rotation={[Math.PI, -1.55, Math.PI]}
        scale={0.17}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["shrub-flowers004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[8.63, 0.35, 0.61]}
        rotation={[0, -1.11, 0]}
        scale={0.21}
        onClick={() => props.ostrichEggHandler(1)}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["shrub-flowers005"].geometry}
        material={props.bakedAssetsMaterial}
        position={[8.68, 0.36, -0.13]}
        rotation={[0, -1.09, 0]}
        scale={0.21}
        onClick={() => props.ostrichEggHandler(3)}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["shrub-flowers006"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.48, 0.35, -0.14]}
        rotation={[0, 1.34, 0]}
        scale={0.21}
        onClick={() => props.ostrichEggHandler(2)}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["shrub-flowers007"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.48, 0.35, 0.6]}
        rotation={[-Math.PI, 1.52, -Math.PI]}
        scale={0.21}
        onClick={() => props.ostrichEggHandler(4)}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["rocks-small001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[6.26, 0.4, -2.58]}
        scale={0.11}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["rock-terrasse001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.96, 0.4, -2.77]}
        rotation={[0, 1.38, 0]}
        scale={0.16}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.palm003.geometry}
        material={props.bakedAssetsMaterial}
        position={[5.54, 0.48, -3.23]}
        rotation={[0, 0.82, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.palm002.geometry}
        material={props.bakedAssetsMaterial}
        position={[5.54, 0.47, -2.49]}
        rotation={[0, 0.82, 0]}
        scale={0.18}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["japan-tree-tall001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.03, 0.4, 0.36]}
        rotation={[0, 0.01, 0]}
        scale={0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["japan-flag_big002"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.63, 0.41, -0.41]}
        rotation={[0, -0.69, 0]}
        scale={0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["japan-flag_big003"].geometry}
        material={props.bakedAssetsMaterial}
        position={[6.21, 0.39, 0.21]}
        rotation={[0, -0.69, 0]}
        scale={0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["gate-red-japan001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.92, 0.4, -0.1]}
        rotation={[-Math.PI, 0.84, -Math.PI]}
        scale={0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.fountain001.geometry}
        material={props.bakedAssetsMaterial}
        position={[8.06, 0.38, 0.26]}
        scale={0.13}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["flag-golf_red001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.64, 0.61, -2.79]}
        rotation={[Math.PI, -1.53, Math.PI]}
        scale={0.14}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["fence-shrub002"].geometry}
        material={props.bakedAssetsMaterial}
        position={[8.06, 0.37, -0.36]}
        scale={[0.47, 0.25, 0.25]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["fence-shrub003"].geometry}
        material={props.bakedAssetsMaterial}
        position={[8.06, 0.36, 0.84]}
        scale={[0.47, 0.25, 0.25]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["bridge-japan001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.61, 0.48, 0.29]}
        rotation={[-Math.PI, 0.74, -Math.PI]}
        scale={0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["bench-forest001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[8.88, 0.42, -2.79]}
        rotation={[0, -1.51, 0]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["bar-tropic001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.92, 0.47, -2.84]}
        rotation={[Math.PI, -1.57, Math.PI]}
        scale={0.12}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["bamboo-leaves004"].geometry}
        material={props.bakedAssetsMaterial}
        position={[4.54, 0.42, -0.38]}
        rotation={[-Math.PI, 1.56, -Math.PI]}
        scale={0.12}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["bamboo-leaves005"].geometry}
        material={props.bakedAssetsMaterial}
        position={[4.55, 0.42, -0.14]}
        rotation={[-Math.PI, 1.56, -Math.PI]}
        scale={0.12}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["bamboo-leaves006"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.62, 0.4, 0.86]}
        rotation={[-Math.PI, 1.56, -Math.PI]}
        scale={0.12}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes["bamboo-leaves007"].geometry}
        material={props.bakedAssetsMaterial}
        position={[5.49, 0.4, 0.86]}
        rotation={[-Math.PI, 1.56, -Math.PI]}
        scale={0.12}
      />
    </group>
  );
};

export default Parks;
