import env from "../../config/env.json";
import APIFetch from "../../utils/APIFetch.ts";
import { StudentType } from "../user/student.ts";
import { BiomeType } from "./biomes.ts";

export interface StudentPlotType {
    plot_id: string,
    username: string,
}

export interface PlotType {
    id: string,
    number: number,
    biome: BiomeNameType,
    position: number[],
    biome_id?: string,
    biome_v2?: BiomeType
}
export type BiomeNameType = "swamp" | "desert" | "savannah" | "grassland" | "volcano" | "forest" | "beach" | "jungle" | "mountain" | "snow"

export const GetAllPlotsAPI = async (): Promise<PlotType[]> => {
    // var req = await fetch(env.backend_host + "/plot/all", {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(env.backend_host + "/plot/all", {
        method: "GET"
    });

    data = data || null
    return data;
}

export const SetStudentPlotAPI = async (plot_id: string): Promise<StudentType> => {
    // var req = await fetch(env.backend_host + "/me/plot/" + plot_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "POST",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(env.backend_host + "/me/plot/" + plot_id, {
        method: "POST"
    });
    return data;
}