import { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";
import { Group } from "three";

const HutIndy = ({ ...props }) => {
  const group = useRef<Group>(null);
  const { nodes, materials, animations } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caba%C3%B1as/cabanha02.glb"
  ) as DreiGLTF;
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      for (let i = 0; i < Object.keys(actions).length; i++) {
        const action = Object.keys(actions)[i];
        if (props.renderAnimation()) {
          actions[action]?.reset().play();
        } else {
          actions[action]?.stop();
        }
      }
    }
  }, [actions, props.renderAnimation()]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="cabanha_02"
          position={[-15.517, 0.382, 20.243]}
          rotation={[0, 1.571, 0]}
          scale={0.208}
        >
          <mesh
            name="cabanha02"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.cabanha02.geometry}
            material={materials["LOWPOLY-COLORS.004"]}
          />
          <mesh
            name="cabanha02_1"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.cabanha02_1.geometry}
            material={materials.logo_cabanha02}
          />
        </group>
        <mesh
          name="guardamoto_cabanha02"
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.guardamoto_cabanha02.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
          position={[-15.517, 0.382, 20.243]}
          rotation={[0, 1.571, 0]}
          scale={0.208}
        />
        <mesh
          name="gallo_veletacabanha02"
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.gallo_veletacabanha02.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
          position={[-14.969, 1.693, 20.241]}
          scale={[0.011, 0.005, 0.011]}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caba%C3%B1as/cabanha02.glb"
);

export default HutIndy;
