// export function calc_item_chances(biome_health: number) {
//     let max_chances: number[] = [50,25,10,15]
//     let min_chances: number[] = [25,12.5,5,57.5]
//     let chances: number[] = []

//     for (let i = 0; i < 4; i++) {
//         chances.push((max_chances[i] - min_chances[i]) * (biome_health / 100) + min_chances[i])
//     }
//     if (chances.reduce((a,b) => a + b, 0) != 100) {
//         console.log("Error: Chances do not add up to 100")
//         return null
//     }
//     return chances
// }

export function calc_item_chances(biome_health: number, explorerName?: string) {
  let max_chances: number[];
  let min_chances: number[];

  if (explorerName === "Agatha") {
    max_chances = [55.9, 27.9, 11.2, 5];
    min_chances = [30.9, 15.4, 6.2, 47.5];
  } else if (explorerName === "Magnus") {
    max_chances = [50, 25, 20, 5];
    min_chances = [25, 12.5, 15, 47.5];
  } else {
    // Hippie goes here
    max_chances = [50, 25, 10, 15];
    min_chances = [25, 12.5, 5, 57.5];
  }

  let chances: number[] = [];

  for (let i = 0; i < 4; i++) {
    chances.push(
      (max_chances[i] - min_chances[i]) * (biome_health / 100) + min_chances[i]
    );
  }
  if (chances.reduce((a, b) => a + b, 0) != 100) {
    console.log("Error: Chances do not add up to 100");
    return null;
  }
  return chances;
}

export const gibberish_converter = (text: string) => {
  const chars =
    "@#¢†¥÷¬∞|“”≠$%&/()=?»«¡¨´`^+*~'.,;:<>{}[]_-§¦€£™©®•√π∆°∑µ≈∫µ∂∏∑∫√";

  let gibberish = text
    .split(" ")
    .map((word) => {
      let gibberish_word = "";
      const randomArray = new Uint8Array(word.length);
      crypto.getRandomValues(randomArray);
      randomArray.forEach((number) => {
        gibberish_word += chars[number % chars.length];
      });
      return gibberish_word;
    })
    .join(" ");
  return gibberish;
};
