import "./DragDropTwoListsEdit.css";
import { useState, useEffect } from "react";
import help from "../../../../assets/exercises/help.svg";
import plus from "../../../../assets/exercises/plus_grey.svg";
import bin from "../../../../assets/professor/bin.svg";
import { Textarea, Input } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";

const DragDropTwoListsEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateDragDropTwoLists: (newDragDropTwoLists: ExerciseType) => void;
}) => {
  const { t, i18n } = useTranslation();

  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [text, setText] = useState(props.exercise.text as string);
  const [helpText, setHelpText] = useState(props.exercise.help_text as string);
  const [labels, setLabels] = useState(props.exercise.labels as string[]);
  const [displayedOptions, setDisplayedOptions] = useState<string[][]>([
    [],
    [],
  ]);
  const [saveToDB, setSaveToDB] = useState<boolean>(false);

  useEffect(() => {
    if (saveToDB) {
      var exercise = props.exercise;
      exercise.text = text;
      exercise.help_text = helpText;
      exercise.labels = labels;
      exercise.options = getOptionsList(displayedOptions);
      exercise.solutions = getSolutionsList(displayedOptions);
      props.updateDragDropTwoLists(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);

  useEffect(() => {
    setText(props.exercise.text as string);
    setHelpText(props.exercise.help_text as string);
    let temp_labels = props.exercise.labels as string[];
    for (let i = 0; i < 2; i++) {
      if (_.isUndefined(temp_labels[i])) {
        temp_labels[i] = "";
      }
    }
    setLabels(temp_labels);
    orderOptions(
      props.exercise.options as string[],
      props.exercise.solutions as number[]
    );
  }, [props.exercise.text, props.selectedExercise]);
  console.log(labels);

  // useEffect(() => {
  //   orderOptions();
  // }, [options]);

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
  };

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const helpTextHandler = (newHelpText: string) => {
    setHelpText(newHelpText);
  };
  const getOptionsList = (
    displayedOptions: string[][]
  ): string[] | undefined => {
    return displayedOptions[0].concat(displayedOptions[1]);
  };

  const getSolutionsList = (
    displayedOptions: string[][]
  ): number[] | undefined => {
    return Array(displayedOptions[0].length)
      .fill(0)
      .concat(Array(displayedOptions[1].length).fill(1));
  };

  const orderOptions = (options: string[], solution: number[]) => {
    var options0 = [];
    var options1 = [];
    for (var i = 0; i < options.length; i++) {
      if (solution[i] === 0) {
        options0.push(options[i]);
      } else {
        options1.push(options[i]);
      }
    }
    setDisplayedOptions([options0, options1]);
  };

  const solutionHandler = (
    newOption: string,
    newOptionIndex: number,
    column: number
  ) => {
    var updatedOptions = [...displayedOptions];
    updatedOptions[column][newOptionIndex] = newOption;
    setDisplayedOptions(updatedOptions);

    console.log("new opt", updatedOptions);
  };

  const addSolutionHandler = (column: number) => {
    var updatedDisplayedOptions = [...displayedOptions];
    updatedDisplayedOptions[column].push(
      "$%" + updatedDisplayedOptions[column].length.toString()
    );
    setDisplayedOptions(updatedDisplayedOptions);
  };

  const deleteSolutionHandler = (column: number, deletedOption: number) => {
    var updatedDisplayedOptions = [...displayedOptions];
    updatedDisplayedOptions[column].splice(deletedOption, 1);
    setDisplayedOptions(updatedDisplayedOptions);
    setSaveToDB(true);
  };

  const labelHandler = (newLabel: string, labelIndex: number) => {
    var newLabels = [...labels];
    newLabels[labelIndex] = newLabel;

    setLabels(newLabels);
  };

  const renderProfessor = () => {
    return (
      <div className="edit_drag_drop_two_lists_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            style={{ maxHeight: "35vh" }}
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_drag_drop_two_lists_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(text) && (
          <div
            className={
              text.length < 200 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "justify",
                fontFamily: "Causten",
                fontSize: "1em",
                textJustify: "inter-word",
              }}
              value={text}
              resize={"none"}
              onChange={(e) => textHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="edit_drag_drop_two_lists_help_button">
        <div className="center_container">
          <motion.div
            className="write_help_text_button"
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #5090F0",
              borderRadius: "5 px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            onClick={() => helpButtonHandler()}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container" style={{ fontFamily: "Causten" }}>
              {t("write_your_clue")}
            </div>
          </motion.div>
        </div>
        <div
          className="center_container"
          onClick={() => helpButtonHandler()}
          style={{ cursor: "pointer" }}
        >
          <img
            className="icon"
            style={{ maxHeight: "7vh" }}
            src={help}
            alt="help"
          />
        </div>
      </div>
    );
  };

  const renderAddButton = (
    currentSolutions: string[],
    solutionList: number
  ) => {
    if (currentSolutions.length < 5) {
      return (
        <motion.div
          layout
          className="edit_drag_drop_two_lists_add_option"
          onClick={() => addSolutionHandler(solutionList)}
          whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <img src={plus} style={{ maxHeight: "5vh" }} />
          </div>
        </motion.div>
      );
    }
  };

  const renderDragDrop = () => {
    return (
      <div className="edit_drag_drop_two_lists_background_edit">
        <div className="edit_drag_drop_two_lists_top">
          <div className="edit_drag_drop_two_lists_droppable_0">
            <motion.div
              className="edit_drag_drop_two_lists_label"
              whileHover={{
                scale: 1.02,
                // boxShadow: "0px 0px 10px 1px #6EE6A7",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Textarea
                variant="unstyled"
                placeholder={t("click_here_to_edit")}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  textAlign: "center",
                  color: "white",
                  fontFamily: "Causten",
                  fontSize: "1.8em",
                  fontWeight: "bold",
                }}
                value={labels[0]}
                resize={"none"}
                onChange={(e) => labelHandler(e.target.value, 0)}
                onBlur={() => setSaveToDB(true)}
              />
            </motion.div>
            <motion.div
              layoutRoot
              className="edit_drag_drop_two_lists_draggable_container"
              style={{ gridTemplateRows: "repeat(5, 1fr)" }}
            >
              {displayedOptions[0]?.map((item, index) => (
                <motion.div
                  className="edit_drag_drop_two_lists_draggable"
                  style={{
                    marginTop: "1em",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  key={index}
                  whileHover={{
                    scale: 1.02,
                    boxShadow: "0px 0px 10px 1px #5090F0",
                  }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  layout
                >
                  <Input
                    placeholder={t("click_here_to_edit")}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      textAlign: "center",
                      fontFamily: "Causten",
                      fontSize: item.length < 20 ? "1.5em" : "1em",
                      fontWeight: "bold",
                    }}
                    value={item.substring(0, 2) !== "$%" ? item : ""}
                    size={"lg"}
                    // onClick = {e=>{e.stopPropagation()}}
                    onChange={(e) => solutionHandler(e.target.value, index, 0)}
                    onBlur={() => setSaveToDB(true)}
                  />
                  <motion.div
                    className="edit_drag_drop_two_lists_option_delete"
                    onClick={() => deleteSolutionHandler(0, index)}
                    whileHover={{
                      scale: 1.1,
                      boxShadow: "0px 0px 10px 1px #F0684F",
                    }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    style={{
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    <div className="center_container">
                      <img src={bin} style={{ height: "3vh" }} />
                    </div>
                  </motion.div>
                </motion.div>
              ))}
              {renderAddButton(displayedOptions[0], 0)}
            </motion.div>
          </div>

          <div className="edit_drag_drop_two_lists_droppable_1">
            <motion.div
              className="edit_drag_drop_two_lists_label"
              whileHover={{
                scale: 1.02,
                // boxShadow: "0px 0px 10px 1px #F0684F",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Textarea
                variant="unstyled"
                placeholder={t("click_here_to_edit")}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  textAlign: "center",
                  color: "white",
                  fontFamily: "Causten",
                  fontSize: "1.8em",
                  fontWeight: "bold",
                }}
                value={labels[1]}
                resize={"none"}
                onChange={(e) => labelHandler(e.target.value, 1)}
                onBlur={() => setSaveToDB(true)}
              />
            </motion.div>
            <motion.div
              layoutRoot
              className="edit_drag_drop_two_lists_draggable_container"
              style={{ gridTemplateRows: "repeat(5, 1fr)" }}
            >
              {displayedOptions[1]?.map((item, index) => (
                <motion.div
                  className="edit_drag_drop_two_lists_draggable"
                  key={index}
                  layout
                  style={{
                    marginTop: "1em",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  whileHover={{
                    scale: 1.02,
                    boxShadow: "0px 0px 10px 1px #5090F0",
                  }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  <Input
                    placeholder={t("click_here_to_edit")}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      textAlign: "center",
                      fontFamily: "Causten",
                      fontSize: item.length < 20 ? "1.5em" : "1em",
                      fontWeight: "bold",
                    }}
                    value={item.substring(0, 2) !== "$%" ? item : ""}
                    size={"lg"}
                    // onClick = {e=>{e.stopPropagation()}}
                    onChange={(e) => solutionHandler(e.target.value, index, 1)}
                    onBlur={() => setSaveToDB(true)}
                  />
                  <motion.div
                    className="edit_drag_drop_two_lists_option_delete"
                    onClick={() => deleteSolutionHandler(1, index)}
                    whileHover={{
                      scale: 1.1,
                      boxShadow: "0px 0px 10px 1px #F0684F",
                    }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    style={{
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    <div className="center_container">
                      <img src={bin} style={{ height: "3vh" }} />
                    </div>
                  </motion.div>
                </motion.div>
              ))}
              {renderAddButton(displayedOptions[1], 1)}
            </motion.div>
          </div>
        </div>
      </div>
    );
  };

  const renderHelpMenu = () => {
    return (
      <motion.div
        className="edit_drag_drop_two_lists_help_menu"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        {!_.isUndefined(helpText) && (
          <div
            className={
              helpText.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={helpText}
              resize={"none"}
              onChange={(e) => helpTextHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  return (
    <div className="edit_drag_drop_two_lists">
      {renderProfessor()}
      {renderTextBox()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderDragDrop()}
    </div>
  );
};

export default DragDropTwoListsEdit;
