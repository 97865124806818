import { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces.ts";
import { Group } from "three";

const HutOhio = ({ ...props }) => {
  const group = useRef<Group>(null);
  const { nodes, materials, animations } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caba%C3%B1as/cabanha03.glb"
  ) as DreiGLTF;
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    // Ensure actions are available before accessing
    if (actions && Object.keys(actions).length > 0) {
      for (let i = 0; i < Object.keys(actions).length; i++) {
        const action = Object.keys(actions)[i];
        if (props.renderAnimation()) {
          actions[action]?.reset().play();
        } else {
          actions[action]?.stop();
        }
      }
    }
  }, [actions, props.renderAnimation()]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="cabanha_03"
          position={[-15.081, 0.373, 23.465]}
          rotation={[-Math.PI, 1.321, -Math.PI]}
          scale={0.161}
        >
          <mesh
            name="cabanha03"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.cabanha03.geometry}
            material={materials["LOWPOLY-COLORS.004"]}
          />
          <mesh
            name="cabanha03_1"
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={nodes.cabanha03_1.geometry}
            material={materials.logo_cabanha03}
          />
        </group>
        <mesh
          name="antenas_cabanha03"
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.antenas_cabanha03.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
          position={[-15.798, 0.373, 24.054]}
          rotation={[-Math.PI, 1.321, -Math.PI]}
          scale={0.161}
        />
        <mesh
          name="antena_satelite_cabanha03"
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.antena_satelite_cabanha03.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
          position={[-14.871, 1.6, 23.011]}
          rotation={[0, 0.92, 0]}
          scale={0.161}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Caba%C3%B1as/cabanha03.glb"
);

export default HutOhio;
