
const studentAddGroup = (
  props: {
    color?: string;
    size?: number;
  } = {
      color: "#5090F0",
      size: 36,
    }
) => {
  const { color, size } = props;
  return (

    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.2433" cy="16.6769" r="16.6769" fill={color} />
      <mask id="mask0_7913_10674" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 27C16.5 23.625 21 23.625 23.25 21.375C24.375 20.25 21 20.25 21 14.625C21 10.8754 22.4996 9 25.5 9C28.5004 9 30 10.8754 30 14.625C30 20.25 26.625 20.25 27.75 21.375C30 23.625 34.5 23.625 34.5 27" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 27C1.5 23.625 6 23.625 8.25 21.375C9.375 20.25 6 20.25 6 14.625C6 10.8754 7.49962 9 10.5 9C13.5004 9 15 10.8754 15 14.625C15 20.25 11.625 20.25 12.75 21.375C15 23.625 19.5 23.625 19.5 27" fill="white" />
      </mask>
      <g mask="url(#mask0_7913_10674)">
        <path d="M34.9201 16.6769C34.9201 25.8872 27.4536 33.3537 18.2433 33.3537C9.03289 33.3537 1.56641 25.8872 1.56641 16.6769C1.56641 7.46649 9.03289 0 18.2433 0C27.4536 0 34.9201 7.46649 34.9201 16.6769Z" fill="white" />
      </g>
    </svg>

  );
};

export default studentAddGroup;


