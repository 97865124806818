import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces";

const MejoraVegetacionBeach = ({ ...props }) => {
  const palmBig = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/palm_big.gltf"
  ) as DreiGLTF;
  const treeBirch = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_birch_local_basic_.gltf"
  ) as DreiGLTF;
  const shrubRound = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/shrub_round_local_basic_.gltf"
  ) as DreiGLTF;
  const palmHigh = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/palmhigh.gltf"
  ) as DreiGLTF;
  const sunscreen = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/sunscreen.gltf"
  ) as DreiGLTF;
  const sunscreenClose = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/sunscreen_close.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirch.nodes["tree-birch001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.09, 0.36, 9.71]}
        rotation={[0, 0.02, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeBirch.nodes["tree-birch001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.64, 0.36, -1.39]}
        rotation={[0, -1.03, 0]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClose.nodes["sunscreen-closed009"].geometry}
        material={props.bakedAssetsMaterial}
        position={[3.04, 0.35, 7.51]}
        rotation={[0, -0.37, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[3.08, 0.56, 10.27]}
        rotation={[Math.PI, -0.27, Math.PI]}
        scale={0.23}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreen.nodes.sunscreen007.geometry}
        material={props.bakedAssetsMaterial}
        position={[0.28, 0.54, 11.34]}
        rotation={[0, 0.86, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.07, 0.44, 11.26]}
        rotation={[Math.PI, -0.19, Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBig.nodes["palm-big002"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.61, 0.35, 6.55]}
        rotation={[0, -0.23, 0]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.78, 0.4, 1.61]}
        rotation={[0, -0.95, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-13.48, 0.35, -2.94]}
        rotation={[0, 0.82, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.02, 0.36, 1.69]}
        rotation={[0, 1.22, 0]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.88, 0.37, 1.42]}
        rotation={[Math.PI, -0.17, Math.PI]}
        scale={0.19}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.93, 0.35, -0.13]}
        rotation={[0, -0.35, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClose.nodes["sunscreen-closed009"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.62, 0.35, -0.19]}
        rotation={[0, -0.8, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.19, 0.35, -1.15]}
        rotation={[Math.PI, -0.14, Math.PI]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreen.nodes.sunscreen007.geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.68, 0.35, -2.09]}
        rotation={[0, -0.27, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-6.41, 0.35, -2.71]}
        rotation={[Math.PI, -0.31, Math.PI]}
        scale={0.22}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-7.16, 0.35, -5.82]}
        rotation={[Math.PI, -0.56, Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClose.nodes["sunscreen-closed009"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.92, 0.35, -3.69]}
        rotation={[-Math.PI, 0.03, -Math.PI]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.98, 0.35, -3.11]}
        rotation={[-Math.PI, 0.03, -Math.PI]}
        scale={0.16}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClose.nodes["sunscreen-closed009"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.49, 0.35, -6.98]}
        rotation={[Math.PI, -0.66, Math.PI]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.83, 0.35, -6.33]}
        rotation={[0, -0.01, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.3, 0.35, -5.38]}
        rotation={[-Math.PI, 0.19, -Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreen.nodes.sunscreen007.geometry}
        material={props.bakedAssetsMaterial}
        position={[-4.08, 0.35, -3.89]}
        rotation={[0, 0.21, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.9, 0.35, -2.25]}
        rotation={[-Math.PI, 0.03, -Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClose.nodes["sunscreen-closed009"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.39, 0.35, -3.23]}
        rotation={[0, -0.4, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.35, 0.35, -1.72]}
        rotation={[Math.PI, -0.27, Math.PI]}
        scale={0.2}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-1.92, 0.35, 0.36]}
        rotation={[0, -0.27, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreen.nodes.sunscreen007.geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.49, 0.35, -1.47]}
        rotation={[0, 0.96, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-1.68, 0.35, -2.24]}
        rotation={[0, -0.69, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClose.nodes["sunscreen-closed009"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-2.59, 0.35, -4.71]}
        rotation={[0, -0.69, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-1.7, 0.35, -5.94]}
        rotation={[0, -0.18, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[0.63, 0.35, -7.55]}
        rotation={[0, -0.18, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[9.47, 0.53, 6.42]}
        rotation={[0, 0.15, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.23, 0.35, 3.15]}
        rotation={[0, -0.83, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[9.1, 0.36, 3.76]}
        rotation={[0, -0.13, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreenClose.nodes["sunscreen-closed009"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.77, 0.38, -2.67]}
        rotation={[0, -0.65, 0]}
        scale={0.4}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[10.23, 0.35, -0.33]}
        rotation={[0, -0.65, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBig.nodes["palm-big002"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.3, 0.37, -1.98]}
        rotation={[0, 0.07, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBig.nodes["palm-big002"].geometry}
        material={props.bakedAssetsMaterial}
        position={[4.6, 0.42, -4.03]}
        rotation={[0, -0.18, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmHigh.nodes["palm-high043"].geometry}
        material={props.bakedAssetsMaterial}
        position={[6.31, 0.44, -6.06]}
        rotation={[0, -0.8, 0]}
        scale={0.24}
      />
      {/* <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={sunscreen.nodes.sunscreen007.geometry}
        material={props.bakedAssetsMaterial}
        position={[7.51, 0.4, 4.62]}
        rotation={[0, 0.38, 0]}
        scale={0.4}
      /> */}{" "}
      {/* This sunscreen is repeated from the static assets*/}
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmBig.nodes["palm-big002"].geometry}
        material={props.bakedAssetsMaterial}
        position={[7.29, 0.35, -1.97]}
        rotation={[0, 0.38, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-8.44, 0.32, 9.25]}
        rotation={[0, 0.45, 0]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.63, 0.32, -5.45]}
        scale={0.25}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-11.89, 0.32, -4.74]}
        rotation={[0, 0.26, 0]}
        scale={0.3}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.59, 0.27, -1.87]}
        rotation={[Math.PI, -0.35, Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={shrubRound.nodes["shrub-round001"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-10.3, 0.27, -1.14]}
        rotation={[-Math.PI, 0.65, -Math.PI]}
        scale={0.34}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/pino5_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_beech_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/tree_birch_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/Trees/shrub_round_local_basic_.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/sunscreen.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/sunscreen_close.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Beach/palmhigh.gltf"
);
export default MejoraVegetacionBeach;
