import "./ExercisesResearch.css";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../hooks/hooks";
import _ from "lodash";
import ExercisesBar from "./ExercisesResearchBar";
import Theory from "./Templates/Theory";
import DragDropList from "./Templates/DragDropList";
import DragDropTwoLists from "./Templates/DragDropTwoLists";
import TrueFalse from "./Templates/TrueFalse";
import DragDropOptions from "./Templates/DragDropOptions";
import Definition from "./Templates/Definition";
import ChooseOne from "./Templates/ChooseOne";
import ChooseMany from "./Templates/ChooseMany";
import WriteAnswers from "./Templates/WriteAnswers";
import FillInTheGaps from "./Templates/FillInTheGaps";
import DragDropArrows from "./Templates/DragDropArrows";
import Media from "./Templates/Media";
import { Idirection, IscreenSize } from "../../interfaces";
import { ExerciseType } from "../../_newapios/content/unit";
import { ResearchType } from "../../_newapios/content/research";
import { ResearchProgressType } from "../../_newapios/progress/student_scenario";
import { CreateResearchProgressAPI } from "../../_newapios/progress/student_research";
import { CreateExerciseResearchProgressAPI } from "../../_newapios/progress/student_exercise";
import { ExerciseAnswerType } from "./ExercisesExperiment";
import blueBackground from "../../assets/exercises/bluebackgroundexercise.svg";

import useSound from "use-sound";

const ExercisesResearch = (props: {
  exercises: ExerciseType[];
  progress: ResearchProgressType;
  updateMaxLevelResearch: () => void;
  backHandler: () => void;
}) => {
  const mainState = useAppSelector((state) => state.main);
  const loginState = useAppSelector((state) => state.login);

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  // const [playExerciseTheme, setPlayExerciseTheme] = useState(true);

  // const [exerciseTheme, { stop: exerciseThemeStop }] = useSound(
  //   "./exercise+music+-+05.mp3",
  //   {
  //     volume: mainState.volumeSounds,
  //   }
  // );

  const [amogus] = useSound("./amongas.mp3", {
    volume: mainState.volumeSounds,
  });

  const [completeResearch] = useSound("./Complete+Research+-+01.mp3", {
    volume: mainState.volumeSounds,
  });
  const [rightAnswer] = useSound("./Correct+-+01.wav", {
    volume: mainState.volumeSounds,
  });
  const [wrongAnswer] = useSound("./wrong+-+01.wav", {
    volume: mainState.volumeSounds,
  });

  // useEffect(() => {
  //   console.log("playExerciseTheme", playExerciseTheme);
  //   setPlayExerciseTheme(true);
  //   return () => {
  //     console.log("stopExerciseTheme", playExerciseTheme);
  //     setPlayExerciseTheme(false);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (playExerciseTheme) {
  //     exerciseTheme();
  //   } else {
  //     exerciseThemeStop();
  //   }
  // }, [playExerciseTheme]);

  const exercises: ExerciseType[] = props.exercises;

  const [maxExercise, setMaxExercise] = useState(
    loginState.type === "teacher" ? exercises.length - 1 : 0
  );
  const [selectedExercise, setSelectedExercise] = useState(0);

  const [attempts, setAttempts] = useState(0);
  const [tips, setTips] = useState(0);
  // Create progress if non existent
  useEffect(() => {
    if (loginState.type === "student") {
      if (props.progress !== undefined) {
        setMaxExercise(
          props.progress.exercise_progress !== undefined
            ? props.progress.exercise_progress.length === props.exercises.length
              ? props.exercises.length - 1
              : props.progress.exercise_progress.length
            : 0
        );
        setSelectedExercise(
          props.progress.exercise_progress !== undefined
            ? props.progress.exercise_progress.length === props.exercises.length
              ? props.exercises.length - 1
              : props.progress.exercise_progress.length
            : 0
        );
      } else {
        console.log("No progress found");
        props.backHandler();
      }
    } else {
      setSelectedExercise(maxExercise);
    }
  }, [props.progress]);

  useEffect(() => {
    // if (loginState.type === "student") {
    //   if (mainState.researchExercises !== undefined) {
    //     setResearchProgress(mainState.researchExercises)
    //   }
    // }
  }, [maxExercise]);

  const [count, setCount] = useState(0);

  var initTime = new Date().getTime();

  // useEffect(() => {
  //   var ds = setInterval(() => {
  //     var left = count + (new Date().getTime() - initTime)
  //     setCount(Math.floor(left))

  //   }, 100);
  //   return () => clearInterval(ds);
  // }, [selectedExercise])

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize, selectedExercise]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const updateResearchExercise = async (exerciseAnswer: ExerciseAnswerType) => {
    if (!_.isNull(mainState.researchExercises)) {
      CreateExerciseResearchProgressAPI(
        exerciseAnswer.exercise_id,
        mainState.researchExercises.id,
        exerciseAnswer
      ).then((res) => {
        if (res === null) {
          console.log("Error updating exercise");
        }
        console.log(res);
        resetExerciseVars();
        if (selectedExercise + 1 === exercises.length) {
          props.backHandler();
          completeResearch();
        }
        setMaxExercise(maxExercise + 1);
        setSelectedExercise(selectedExercise + 1);
      });
    }
  };

  const tipHandler = () => {
    setTips(tips + 1);
    if (tips > 10) {
      amogus();
    }
  };

  const incorrectHandler = () => {
    setAttempts(attempts + 1);
  };

  const resetExerciseVars = () => {
    setTips(0);
    setAttempts(0);
  };

  const updateLevel = (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => {
    if (direction === "left") {
      setSelectedExercise(selectedExercise - 1);
      return;
    }

    if (direction === "right") {
      if (
        !_.isNull(correct) &&
        !_.isNull(answer) &&
        loginState.type === "student" &&
        selectedExercise === maxExercise
      ) {
        const newAnswer = {
          exercise_id: exercises[selectedExercise].id,
          answer: answer,
          correct: correct,
          time: 0,
          hint: tips > 0,
          attempts: attempts,
        };
        updateResearchExercise(newAnswer);
      } else {
        if (selectedExercise + 1 === exercises.length) {
          props.backHandler();
        }
        if (selectedExercise < maxExercise) {
          setSelectedExercise(selectedExercise + 1);
        }
      }
    }
  };

  if (exercises == null) {
    return <div className="exercises_experiment"></div>;
  }

  return (
    <div className="exercises_research">
      <ExercisesBar
        exercises={exercises}
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevelBar={(exercise) => setSelectedExercise(exercise)}
        backHandler={() => props.backHandler()}
      />

      {(exercises.length === 0 || exercises[selectedExercise] == null) && (
        <img
          className="background"
          src={blueBackground}
          alt="background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "relative",
            zIndex: -1,
          }}
        />
      )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "theory" && (
          <Theory
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            maxExercise={maxExercise}
            exerciseIndex={selectedExercise}
            research={true}
            updateLevel={updateLevel}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "dragdroplist" && (
          <DragDropList
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            research={true}
            incorrectHandler={incorrectHandler}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() ===
          "dragdroptwolists" && (
          <DragDropTwoLists
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={true}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            incorrectHandler={incorrectHandler}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "dragdroparrows" && (
          <DragDropArrows
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            research={true}
            updateLevel={updateLevel}
            incorrectHandler={incorrectHandler}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "truefalse" && (
          <TrueFalse
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={true}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            incorrectHandler={incorrectHandler}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() ===
          "dragdropoptions" && (
          <DragDropOptions
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={true}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            incorrectHandler={incorrectHandler}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "definition" && (
          <Definition
            exercise={exercises[selectedExercise]}
            researchProgress={props.progress}
            totalExercises={exercises.length}
            maxExercise={maxExercise}
            exerciseIndex={selectedExercise}
            research={true}
            updateLevel={updateLevel}
            backHandler={() => props.backHandler()}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "chooseone" && (
          <ChooseOne
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={true}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            incorrectHandler={incorrectHandler}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "choosemany" && (
          <ChooseMany
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={true}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            incorrectHandler={incorrectHandler}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "writeanswers" && (
          <WriteAnswers
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            research={true}
            updateLevel={updateLevel}
            incorrectHandler={incorrectHandler}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "fillinthegaps" && (
          <FillInTheGaps
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            research={true}
            updateLevel={updateLevel}
            incorrectHandler={incorrectHandler}
            tipHandler={tipHandler}
          />
        )}
      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "media" && (
          <Media
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            maxExercise={maxExercise}
            exerciseIndex={selectedExercise}
            research={true}
            updateLevel={updateLevel}
          />
        )}
    </div>
  );
};

export default ExercisesResearch;
