import { ClassType, GetMyClassAPI } from "../../_newapios/user/class";
import { StudentType } from "../../_newapios/user/student";
import { useAppSelector } from "../../hooks/hooks";
import { setStudents } from "../../reducers/mainSlice";


export const updateStudents = async (dispatch: any) => {
    const myclass = (await GetMyClassAPI()) as ClassType;
    dispatch(setStudents(myclass.students || []));
    return myclass;
};

export const studentNeedsPlot = (myclass: ClassType, loginState: any) => {
    const students = myclass.students as StudentType[];
    const me = students.find(
        (student) => student.id === loginState.id
    ) as StudentType;
    return me.plot_id === null;
};