import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import mainReducer from './reducers/mainSlice';
import loginReducer from './reducers/loginSlice';
import teacherReducer from './reducers/teacherSlice';
import studentReducer from './reducers/studentSlice';
import gameReducer from './reducers/gameSlice';

export const store = configureStore({
  reducer: {
    main: mainReducer,
    login: loginReducer,
    teacher: teacherReducer,
    student: studentReducer,
    game: gameReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
