const MiniAldea = ({ ...props }) => {
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.aldea.nodes.minialdea01.geometry}
        material={props.material}
        position={props.positions.position}
        rotation={props.positions.rotation}
        scale={props.positions.scale}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.aldea.nodes.parcela01.geometry}
        material={props.materialParcela}
        position={props.positions.position}
        rotation={props.positions.rotation}
        scale={props.positions.scale}
      />
    </group>
  );
};

export default MiniAldea;
