import "./DragDropTwoLists.css";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Arrows,
  Background,
  ConfirmButton,
  ExerciseName,
  HelpMenu,
  Professor,
  TextBox,
} from "./_components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";
import { useAppSelector } from "../../../hooks/hooks";

const DragDropTwoLists = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  const [answers, setAnswers] = useState<string[][]>([]);

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const solutions = props.exercise.solutions as number[];
  const labels = props.exercise.labels as string[];
  const options = props.exercise.options as string[];

  const [selectedExercise, setSelectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setMaxExercise] = useState(props.maxExercise);
  const [research, setResearch] = useState(props.research);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    orderAnswers();
  }, [selectedExercise, maxExercise]);

  useEffect(() => {
    setSelectedExercise(props.exerciseIndex);
    setMaxExercise(props.maxExercise);
  }, [props.exerciseIndex]);

  const orderAnswers = () => {
    var answers0 = [];
    var answers1 = [];
    for (var i = 0; i < options.length; i++) {
      if (solutions[i] === 0) {
        answers0.push(options[i]);
      } else {
        answers1.push(options[i]);
      }
    }
    var orderedAnswers = [answers0, answers1];
    if (props.research) {
      if (selectedExercise < maxExercise) {
        setAnswers(distributeSolution(orderedAnswers));
      } else {
        setAnswers(distributeOptions());
      }
    } else {
      if (isTeacher) {
        setAnswers(distributeSolution(orderedAnswers));
      } else {
        setAnswers(distributeOptions());
      }
    }
  };

  useEffect(() => {
    const newAnswers = [...answers];
    newAnswers.forEach((answer) => {
      answer.forEach((item, index) => {
        if (item === undefined) {
          answer.splice(index, 1);
        }
      });
    });
  }, [answers]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const distributeOptions = () => {
    var temp_options = [...options];
    var newAnswers: any[] = [[], [], [], [], []];
    var j = 2;
    const shuffledOptions = _.shuffle(temp_options);
    for (var i = 0; i < shuffledOptions.length; i++) {
      if (j === 4) {
        newAnswers[j].push(shuffledOptions[i]);
        j = 2;
      } else {
        newAnswers[j].push(shuffledOptions[i]);
        j++;
      }
    }
    return newAnswers;
  };

  const distributeSolution = (solution: string[][]) => {
    var newSolution: any[] = [[], [], [], [], []];
    newSolution[0] = solution[0];
    newSolution[1] = solution[1];
    return newSolution;
  };

  const confirmHandler = () => {
    var correct = true;
    var exists: number;
    if (
      answers[2].length > 0 ||
      answers[3].length > 0 ||
      answers[4].length > 0
    ) {
      correct = false;
    }
    for (var i = 0; i < answers[0].length; i++) {
      exists = options.findIndex((item) => {
        return item === answers[0][i];
      });
      if (exists === -1) correct = false;
      if (solutions[exists] !== 0) correct = false;
    }
    for (var i = 0; i < answers[1].length; i++) {
      exists = options.findIndex((item) => {
        return item === answers[1][i];
      });
      if (exists === -1) correct = false;
      if (solutions[exists] !== 1) correct = false;
    }
    if (research) {
      if (correct) {
        props.updateLevel("right", answers[0].concat(answers[1]), true); //concatenate vertically both arrays
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers[0].concat(answers[1]), true);
      } else {
        props.updateLevel("right", answers[0].concat(answers[1]), false);
      }
    }
  };

  const updateDashboard = (
    srcC: string,
    desC: string,
    srcI: number,
    desI: number
  ) => {
    let copiedDashboards = [...answers];
    console.log(srcC, desC, srcI, desI);
    if (srcC === desC) {
      const [removed] = copiedDashboards[parseInt(srcC)].splice(srcI, 1);
      copiedDashboards[parseInt(srcC)].splice(desI, 0, removed);
      console.log(copiedDashboards);
    } else {
      console.log(copiedDashboards[parseInt(desC)].length);
      if (desC === "2" || desC === "3") {
        if (copiedDashboards[parseInt(desC)].length > 2) return;
      }
      if (desC === "4") {
        if (copiedDashboards[parseInt(desC)].length > 3) return;
      }
      if (desC === "0" || desC === "1") {
        if (copiedDashboards[parseInt(desC)].length > 4) return;
      }
      copiedDashboards[parseInt(desC)].splice(
        desI,
        0,
        copiedDashboards[parseInt(srcC)][srcI]
      );
      copiedDashboards[parseInt(srcC)].splice(srcI, 1);
    }

    setAnswers(copiedDashboards);
  };

  const renderDragDrop = () => {
    return (
      <div className="drag_drop_two_lists_background">
        <DragDropContext
          onDragEnd={(param) => {
            const srcC = param.source.droppableId.slice(-1);
            const desC =
              param.destination === null || param.destination === undefined
                ? param.source.droppableId.slice(-1)
                : param.destination.droppableId.slice(-1);

            const srcI = param.source.index;
            const desI =
              param.destination === null || param.destination === undefined
                ? param.source.index
                : param.destination.index;

            updateDashboard(srcC, desC, srcI, desI);
          }}
        >
          <div className="drag_drop_two_lists_top">
            <Droppable key={"droppable-0"} droppableId={"droppable-0"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_two_lists_droppable_0"
                >
                  <div className="drag_drop_two_lists_label">
                    <div className="center_container">
                      <div className="text_black_medium_bold">{labels[0]}</div>
                    </div>
                  </div>
                  {answers[0]?.map((item, i) => (
                    <Draggable
                      key={item}
                      draggableId={"draggable-" + item}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_two_lists_draggable"
                          style={{
                            top: 2 + 4 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                            onClick={() => console.log(item)}
                          >
                            {!_.isUndefined(item) && (
                              <div
                                className={
                                  item.length < 20
                                    ? "text_black_small_bold"
                                    : "text_black_very_small_bold"
                                }
                              >
                                {item}
                              </div>
                            )}
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId={"droppable-1"} key={"droppable-1"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_two_lists_droppable_1"
                >
                  <div className="drag_drop_two_lists_label">
                    <div className="center_container">
                      <div className="text_black_medium_bold">{labels[1]}</div>
                    </div>
                  </div>
                  {answers[1]?.map((item, i) => (
                    <Draggable
                      key={item}
                      draggableId={"draggable-" + item}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_two_lists_draggable"
                          style={{
                            top: 2 + 4 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            {!_.isUndefined(item) && (
                              <div
                                className={
                                  item.length < 20
                                    ? "text_black_small_bold"
                                    : "text_black_very_small_bold"
                                }
                              >
                                {item}
                              </div>
                            )}
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="drag_drop_two_lists_bottom">
            <Droppable droppableId={"droppable-2"} key={"droppable-2"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_two_lists_droppable_2"
                >
                  {answers[2]?.map((item, i) => (
                    <Draggable
                      key={item}
                      draggableId={"draggable-" + item}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_two_lists_draggable_bottom"
                          style={{
                            top: 7 + 5.5 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            {!_.isUndefined(item) && (
                              <div
                                className={
                                  item.length < 20
                                    ? "text_black_small_bold"
                                    : "text_black_very_small_bold"
                                }
                              >
                                {item}
                              </div>
                            )}
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId={"droppable-3"} key={"droppable-3"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_two_lists_droppable_2"
                >
                  {answers[3]?.map((item, i) => (
                    <Draggable
                      key={item}
                      draggableId={"draggable-" + item}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_two_lists_draggable_bottom"
                          style={{
                            top: 7 + 5.5 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            {!_.isUndefined(item) && (
                              <div
                                className={
                                  item.length < 20
                                    ? "text_black_small_bold"
                                    : "text_black_very_small_bold"
                                }
                              >
                                {item}
                              </div>
                            )}
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId={"droppable-4"} key={"droppable-4"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_two_lists_droppable_2"
                >
                  {answers[4]?.map((item, i) => (
                    <Draggable
                      key={item}
                      draggableId={"draggable-" + item}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_two_lists_draggable_bottom"
                          style={{
                            top: 7 + 5.5 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            {!_.isUndefined(item) && (
                              <div
                                className={
                                  item.length < 20
                                    ? "text_black_small_bold"
                                    : "text_black_very_small_bold"
                                }
                              >
                                {item}
                              </div>
                            )}
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    );
  };

  return (
    <div className="drag_drop_two_lists">
      <Background
        incorrectFeedback={incorrectFeedback}
        setIncorrectFeedback={setIncorrectFeedback}
      />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={true} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={research}
      />
      <ConfirmButton confirmHandler={confirmHandler} />
      <HelpMenu helpText={helpText} tipHandler={props.tipHandler} />
      <ExerciseName type={props.exercise.type} smallMargin={true} imageExists={true} />
      {renderDragDrop()}
    </div>
  );
};

export default DragDropTwoLists;
