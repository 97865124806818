import "./ProfessorClassMapMenu.css";
import R3FMap from "../../student/R3FMap";
import InfoBar from "../../student/InfoBar/InfoBar";
import { useAppSelector } from "../../../hooks/hooks";
import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PlotType } from "../../../_newapios/gamecontent/plots";

const ProfessorClassMapMenu = ({ ...props }) => {
  const { t } = useTranslation();

  const mainState = useAppSelector((state) => state.main);
  const [localMap, setLocalMap] = useState(false);
  const [localMapStudentId, setLocalMapStudentId] = useState<string>();
  const [localMapType, setLocalMapType] = useState("grassland");

  useEffect(() => {
    setLocalMap(false);
  }, []);

  const renderShadow = () => {
    const shadows = mainState.shadows;
    return shadows;
  };

  const renderAnimation = () => {
    const animations = mainState.animations;
    return animations;
  };

  const localHandler = (studentId: string, plotId: string) => {
    setLocalMap(!localMap);
    setLocalMapStudentId(studentId);

    const plot = mainState.plots.find((plot) => plot.id === plotId) as PlotType;

    if (!localMap) {
      setLocalMapStudentId(studentId);
      if (plot.biome === "forest") plot.biome = "grassland";
      setLocalMapType(plot.biome);
    }
  };

  return (
    <div className="professor_class_map_menu">
      <InfoBar isTeacher={true} />
      <R3FMap
        biosphere={null}
        localMap={localMap}
        localMapStudentId={localMapStudentId}
        localMapType={localMapType}
        localHandler={localHandler}
        renderShadow={() => renderShadow()}
        renderAnimation={() => renderAnimation()}
        selectedClass={props.selectedClass}
        isChallenge1Open={() => false}
      />
    </div>
  );
};

export default ProfessorClassMapMenu;
