import "./CalendarWeek.css";
import { useTranslation, withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { colors } from "../../../constants.ts";
import { BundleType } from "../../../_newapios/user/class.ts";
import { useAppSelector } from "../../../hooks/hooks.ts";

const CalendarWeek = ({ ...props }) => {
  const [dates, setDates] = useState<Date[]>([]);
  const [bundles, setBundles] = useState<BundleType[]>();
  const { t, i18n } = useTranslation();

  const courses = useAppSelector((state) => state.main.courses);

  useEffect(() => {
    setDates(getAllDaysInWeek(props.week));
    setBundles(props.bundles);
  }, [props.week, props.bundles]);

  const weekDaysTitles = [
    { name: t("monday") },
    { name: t("tuesday") },
    { name: t("wednesday") },
    { name: t("thursday") },
    { name: t("friday") },
    { name: t("saturday") },
    { name: t("sunday") },
  ];

  const getAllDaysInWeek = (week: Date[]) => {
    let myWeek = [];

    if (week[0] !== undefined) {
      let today = new Date(week[0].getTime());
      for (var i = 0; i < 7; i++) {
        myWeek.push(new Date(today));
        today.setDate(today.getDate() + 1);
      }
    }
    return myWeek;
  };

  const renderWeekDayTitle = (weekDayIndex: number) => {
    return (
      <div
        className="calendar_week_title"
        style={{
          borderTop: "1px solid #B9B9B9",
          borderLeft: "1px solid #B9B9B9",
          borderRight: weekDayIndex === 6 ? "1px solid #B9B9B9" : "",
        }}
      >
        <div className="center_container">
          <div className="text_light_grey_small">
            {weekDaysTitles[weekDayIndex].name}
          </div>
        </div>
        <div className="center_container">
          <div className="text_light_grey_medium">
            {dates[weekDayIndex] !== undefined
              ? dates[weekDayIndex].getDate()
              : null}
          </div>
        </div>
      </div>
    );
  };

  const renderWeekDay = (weekDayIndex: number) => {
    const weekDay = dates[weekDayIndex];
    let bundlesInSameDay: BundleType[] = [];
    if (bundles !== undefined && bundles !== null) {
      for (var i = 0; i < bundles.length; i++) {
        let startingTime = new Date(bundles[i].start_date);
        startingTime.setHours(0, 0, 0);
        let endingTime = new Date(bundles[i].end_date);
        endingTime.setHours(23, 59, 59);
        weekDay.setMinutes(weekDay.getMinutes(), 5);
        if (
          weekDay.getTime() >= startingTime.getTime() &&
          weekDay.getTime() <= endingTime.getTime()
        ) {
          bundlesInSameDay.push(bundles[i]);
        }
      }
    }

    const today = new Date();
    let isToday =
      weekDay.getDate() === today.getDate() &&
      weekDay.getMonth() === today.getMonth() &&
      weekDay.getFullYear() === today.getFullYear();

    return (
      <div
        key={weekDayIndex}
        className="calendar_week_units_container"
        style={{
          borderTop: "1px solid #B9B9B9",
          borderLeft: "1px solid #B9B9B9",
          borderRight: weekDayIndex === 6 ? "1px solid #B9B9B9" : "",
          borderBottom: "1px solid #B9B9B9",
        }}
      >
        <div
          className="calendar_week_units"
          style={{
            gridTemplateRows: "repeat(" + 4 + ", 25%)",
            border: isToday ? "2px solid #000000" : "2px solid #ffffff",
          }}
        >
          {bundlesInSameDay.map((bundle, bundleIndex) => {
            var scenarioCourse = courses.find(
              (course) => course.id === bundle.scenario?.course_id
            );
            return (
              <div
                className="calendar_week_unit_text_container"
                style={{ backgroundColor: scenarioCourse?.color + "75" }}
              >
                <div className="calendar_week_unit_text">
                  {bundle.scenario?.main_know_how}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="calendar_week">
      {dates.map((weekDay, weekDayIndex) => {
        return (
          <div key={weekDayIndex} className="calendar_week_day">
            {renderWeekDayTitle(weekDayIndex)}
            {renderWeekDay(weekDayIndex)}
          </div>
        );
      })}
    </div>
  );
};

export default withTranslation()(CalendarWeek);
