import "./DragDropListEdit.css";
import { useState, useEffect } from "react";
import help from "../../../../assets/exercises/help.svg";
import plus from "../../../../assets/exercises/plus_grey.svg";
import bin from "../../../../assets/professor/bin.svg";
import { Textarea, Input } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";

const DragDropListEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateDragDropList: (newDragDropList: ExerciseType) => void;
}) => {
  const { t, i18n } = useTranslation();

  const [text, setText] = useState(props.exercise.text as string);
  const [helpText, setHelpText] = useState(props.exercise.help_text as string);
  const [options, setOptions] = useState(props.exercise.options as string[]);
  const [labels, setLabels] = useState(props.exercise.labels as string[]);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [saveToDB, setSaveToDB] = useState<boolean>(false);

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
  };
  useEffect(() => {
    if (saveToDB) {
      var exercise = props.exercise;
      exercise.text = text;
      exercise.help_text = helpText;
      exercise.options = options;
      exercise.labels = labels;
      props.updateDragDropList(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);

  useEffect(() => {
    setText(props.exercise.text as string);
    setHelpText(props.exercise.help_text as string);
    setOptions(props.exercise.options as string[]);
    let temp_labels = props.exercise.labels as string[];
    for (let i = 0; i < 2; i++) {
      if (_.isUndefined(temp_labels[i])) {
        temp_labels[i] = "";
      }
    }
    setLabels(temp_labels);
  }, [props.exercise.text, props.selectedExercise]);

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const helpTextHandler = (newHelpText: string) => {
    setHelpText(newHelpText);
  };

  const optionHandler = (newOption: string, newOptionIndex: number) => {
    var updatedOptions = [...options];
    // Editing option & solution routine
    updatedOptions[newOptionIndex] = newOption;
    // Deleting option & solution routine
    if (newOption == "") {
      updatedOptions.splice(newOptionIndex, 1);
    }
    // Updating state
    setOptions(updatedOptions);
  };

  const addOptionHandler = () => {
    var updatedOptions = [...options];
    updatedOptions.push("");
    setOptions(updatedOptions);
  };

  const deleteOptionHandler = (optionIndex: number) => {
    var updatedOptions = [...options];
    updatedOptions.splice(optionIndex, 1);
    setOptions(updatedOptions);
    setSaveToDB(true);
  };

  const labelHandler = (newLabel: string, newLabelIndex: number) => {
    var updatedLabels = [...labels];
    updatedLabels[newLabelIndex] = newLabel;
    setLabels(updatedLabels);
  };

  const renderProfessor = () => {
    return (
      <div className="edit_drag_drop_list_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            style={{ maxHeight: "35vh" }}
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_drag_drop_list_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(text) && (
          <div
            className={
              text.length < 200 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "justify",
                fontFamily: "Causten",
                fontSize: "1em",
                textJustify: "inter-word",
              }}
              value={text}
              resize={"none"}
              onChange={(e) => textHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderAddButton = (currentOptions: string[]) => {
    if (currentOptions.length < 5) {
      return (
        <motion.div
          className="edit_drag_drop_list_add_option"
          onClick={() => addOptionHandler()}
          whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          layout
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <img src={plus} style={{ maxHeight: "4vh" }} />
          </div>
        </motion.div>
      );
    }
  };

  const renderDragDrop = () => {
    return (
      <div className="edit_drag_drop_list_background">
        <div className="edit_drag_drop_list_background_components">
          <motion.div
            className="edit_drag_drop_list_top"
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #F0684F",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                color: "white",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={labels[0]}
              resize={"none"}
              onChange={(e) => labelHandler(e.target.value, 0)}
              onBlur={() => setSaveToDB(true)}
            />
          </motion.div>
          <div className="edit_drag_drop_list_center">
            <motion.div layoutRoot className="edit_drag_drop_list_droppable">
              {options.map((option, optionIndex) => {
                return (
                  <motion.div
                    key={optionIndex}
                    className="edit_drag_drop_list_draggable_edit"
                    whileHover={{
                      scale: 1.02,
                      boxShadow: "0px 0px 10px 1px #5090F0",
                    }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    layout
                  >
                    <Input
                      placeholder={t("click_here_to_edit")}
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        borderRadius: "5px",
                        textAlign: "center",
                        fontFamily: "Causten",
                        fontSize: option.length < 20 ? "1.5em" : "1em",
                      }}
                      value={option}
                      resize={"none"}
                      bgColor={"white"}
                      size={"lg"}
                      // onClick = {e=>{e.stopPropagation()}}
                      onChange={(e) =>
                        optionHandler(e.target.value, optionIndex)
                      }
                      onBlur={() => setSaveToDB(true)}
                    ></Input>
                    <motion.div
                      className="edit_drag_drop_list_option_delete"
                      onClick={() => deleteOptionHandler(optionIndex)}
                      key={optionIndex}
                      whileHover={{
                        scale: 1.1,
                        boxShadow: "0px 0px 10px 1px #F0684F",
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 10,
                      }}
                      style={{
                        cursor:
                          'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                      }}
                    >
                      <div className="center_container">
                        <img src={bin} style={{ height: "4vh" }} />
                      </div>
                    </motion.div>
                  </motion.div>
                );
              })}
              {renderAddButton(options)}
            </motion.div>
          </div>
          <motion.div
            className="edit_drag_drop_list_bottom"
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #6EE6A7",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                color: "white",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={labels[1]}
              resize={"none"}
              onChange={(e) => labelHandler(e.target.value, 1)}
              onBlur={() => setSaveToDB(true)}
            />
          </motion.div>
        </div>
      </div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="edit_drag_drop_list_help_button">
        <div className="center_container">
          <motion.div
            className="write_help_text_button"
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #5090F0",
              borderRadius: "5 px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            onClick={() => helpButtonHandler()}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container" style={{ fontFamily: "Causten" }}>
              {t("write_your_clue")}
            </div>
          </motion.div>
        </div>
        <div
          className="center_container"
          onClick={() => helpButtonHandler()}
          style={{ cursor: "pointer" }}
        >
          <img
            className="icon"
            style={{ maxHeight: "7vh" }}
            src={help}
            alt="help"
          />
        </div>
      </div>
    );
  };

  const renderHelpMenu = () => {
    return (
      <motion.div
        className="edit_drag_drop_list_help_menu"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        {!_.isUndefined(helpText) && (
          <div
            className={
              helpText.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={helpText}
              resize={"none"}
              onChange={(e) => helpTextHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };
  return (
    <div className="edit_drag_drop_list">
      {renderProfessor()}
      {renderTextBox()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderDragDrop()}
    </div>
  );
};

export default DragDropListEdit;
