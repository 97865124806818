import { useEffect, useRef, useState } from "react";
import { useAnimations, Float } from "@react-three/drei";
import { Group, Vector3 } from "three";
import {
  Select,
  Selection,
  EffectComposer,
  Outline,
} from "@react-three/postprocessing";

const MiniAldeaOutline = ({ ...props }) => {
  const [positionParcela, setPositionParcela] = useState(
    props.positions.position
  );

  const flechaRef = useRef<Group>(null!);
  const { actions } = useAnimations(props.flecha?.animations, flechaRef);

  useEffect(() => {
    if (actions["CubeAction.001"] !== null) {
      if (props.renderAnimation()) {
        actions["CubeAction.001"].play();
      } else {
        actions["CubeAction.001"].stop();
      }
    }
  }, [props.renderAnimation()]);

  return (
    <group {...props} dispose={null}>
      <Selection>
        <EffectComposer multisampling={8} autoClear={false}>
          <Outline
            blur
            visibleEdgeColor={0xffffff}
            edgeStrength={100}
            width={3000}
          />
        </EffectComposer>
        <Select enabled={true}>
          <Float
            rotationIntensity={0}
            speed={props.renderAnimation() ? 5 : 0}
            floatingRange={[-1, 0.5]}
          >
            <group ref={flechaRef} {...props} dispose={null}>
              <group name="Scene">
                <mesh
                  name="Cube"
                  castShadow
                  receiveShadow
                  geometry={props.flecha.nodes.Cube.geometry}
                  material={props.materialFlecha}
                  position={
                    new Vector3(
                      props.positions.position[0],
                      props.positions.position[1] + 3,
                      props.positions.position[2]
                    )
                  }
                  scale={[0.33, 0.45, 0.33]}
                />
              </group>
            </group>
          </Float>
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={props.aldea.nodes.minialdea01.geometry}
            material={props.material}
            position={props.positions.position}
            rotation={props.positions.rotation}
            scale={props.positions.scale}
          />
          <mesh
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={props.aldea.nodes.parcela01.geometry}
            material={props.materialParcela}
            position={positionParcela}
            rotation={props.positions.rotation}
            scale={props.positions.scale}
          />
        </Select>
      </Selection>
    </group>
  );
};

export default MiniAldeaOutline;
