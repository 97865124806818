import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../hooks/hooks";
import { m, LazyMotion } from "framer-motion";

import closeButton from "../../../../assets/student/tutorial/close.svg";
import confirmButton from "../../../../assets/student/tutorial/ok.svg";
import { SetStudentPlotAPI } from "../../../../_newapios/gamecontent/plots";
import { setChosenPlot } from "../../../../reducers/studentSlice";
import { updateStudents } from "../../../utils/student";

const ParcelaSelector = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const plotHandler = async (plot: string) => {
    const student = await SetStudentPlotAPI(plot);
    const myClass = await updateStudents(dispatch);
    dispatch(setChosenPlot(undefined));
  };

  const loadFeatures = () =>
    import("../../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  return (
    <LazyMotion features={loadFeatures}>
      <div className="tutorial_bubble">
        <div className="center_container" style={{ flexDirection: "column" }}>
          <div className="text_black_big_bold">
            {t("choose_plot_text_1") +
              t(props.chosenPlot.biome + "_lowercase") +
              "."}
          </div>
          <div className="text_black_big_bold">{t("choose_plot_text_2")}</div>
        </div>
        <div className="tutorial_confirm_buttons">
          <div className="center_container">
            <m.div
              className="tutorial_confirm_button"
              onClick={() => plotHandler(props.chosenPlot.id)}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <div className="center_container">
                <img
                  className="icon"
                  src={confirmButton}
                  alt="confirm_button"
                />
              </div>
            </m.div>
          </div>
          <div className="center_container">
            <m.div
              className="tutorial_reject_button"
              onClick={() => dispatch(setChosenPlot(undefined))}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <div className="center_container">
                <img className="icon" src={closeButton} alt="close_button" />
              </div>
            </m.div>
          </div>
        </div>
      </div>
    </LazyMotion>
  );
};

export default ParcelaSelector;
