import "./MarketplaceSelectorMenu.css";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { LazyMotion, m } from "framer-motion";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import UserMenu from "../UserMenu/UserMenu.tsx";
import {
  menu,
  setDeleteOnExit,
  submenu,
  updateSelectedScenario,
} from "../../../reducers/teacherSlice.ts";
import { CreateScenarioAPI } from "../../../_newapios/content/scenario.ts";

const MarketplaceSelectorMenu = ({ ...props }) => {
  const { t } = useTranslation(["common", "evaluation"]);
  const teacherState = useAppSelector((state) => state.teacher);

  const dispatch = useAppDispatch();

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const createScenarioHandler = async () => {
    const selectedClass = teacherState.selectedClass;
    const regulationId = selectedClass?.regulation_id;
    const newScenario = await CreateScenarioAPI(regulationId || "");

    dispatch(setDeleteOnExit(true));

    dispatch(submenu("scenario_edit"));
    dispatch(updateSelectedScenario(newScenario));
  };

  const renderBack = () => {
    return (
      <div className="marketplace_selector_menu_top">
        <LazyMotion features={loadFeatures}>
          <m.div
            className="marketplace_selector_menu_title"
            whileHover={{
              scale: 1.05,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "tween", duration: 0.2 }}
            onClick={() => {
              dispatch(menu("menus"));
              dispatch(submenu("activities"));
            }}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BackArrow color={"#000000"} size={"8vh"} />
              <div className="text_black_big_bold">{t("activities")}</div>
            </div>
          </m.div>
        </LazyMotion>
        <UserMenu color={"black"} />
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="marketplace_selector_menu_body">
        <LazyMotion features={loadFeatures}>
          <div className="marketplace_selector_menu_body_title">
            <div className="center_container">
              <div className="text_black_medium_bold">
                {t("program_new_situation")}
              </div>
            </div>
          </div>
          <div className="marketplace_selector_menu_body_buttons">
            <m.div
              className="marketplace_selector_menu_body_button"
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 4px 20px #6ee6a7",
              }}
              transition={{ type: "tween", duration: 0.2 }}
              onClick={() => dispatch(submenu("marketplace"))}
            >
              <div className="center_container">
                <img
                  src={
                    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Evolutivo+crear+situaciones/situations_menu_libreria.png"
                  }
                  alt="situationsLib"
                  style={{
                    maxWidth: "40vh",
                  }}
                />
              </div>
              <div className="center_container">
                <div className="text_black_small_bold">{t("library")}</div>
              </div>
              <div className="text_black_small">{t("choose_from_library")}</div>
            </m.div>
            <m.div
              className="marketplace_selector_menu_body_button"
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 4px 20px #FEFFC2",
              }}
              transition={{ type: "tween", duration: 0.2 }}
              onClick={() => dispatch(submenu("my_scenarios"))}
            >
              <div className="center_container">
                <img
                  src={
                    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Evolutivo+crear+situaciones/situation_menu_mine.png"
                  }
                  alt="mySituations"
                  style={{
                    maxWidth: "40vh",
                  }}
                />
              </div>
              <div className="center_container">
                <div className="text_black_small_bold">
                  {t("my_situations")}
                </div>
              </div>
              <div className="text_black_small">
                {t("my_situations_text")}
              </div>
            </m.div>
            <m.div
              className="marketplace_selector_menu_body_button"
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 4px 20px #99c2ff",
              }}
              transition={{ type: "tween", duration: 0.2 }}
              onClick={() => createScenarioHandler()}
            >
              <div className="center_container">
                <img
                  src={
                    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Evolutivo+crear+situaciones/situations_menu_cero.png"
                  }
                  alt="situationsCero"
                  style={{
                    maxWidth: "40vh",
                  }}
                />
              </div>
              <div className="center_container">
                <div className="text_black_small_bold">
                  {t("create_from_zero")}
                </div>
              </div>
              <div className="text_black_small">
                {t("create_from_zero_text")}
              </div>
            </m.div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  return (
    <div className="marketplace_selector_menu">
      {renderBack()}
      {renderButtons()}
    </div>
  );
};

export default MarketplaceSelectorMenu;
