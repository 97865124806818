const ActivitiesIcon = (
  props: {
    color?: string;
    size?: number; // Adding size as an optional number prop
  } = {
    color: "#000000",
    size: 24, // Default size set to 48
  }
) => {
  const { color, size } = props; // Destructuring props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 4C9 2.89543 9.89543 2 11 2C12.1046 2 13 2.89543 13 4V6H18V11H20C21.1046 11 22 11.8954 22 13C22 14.1046 21.1046 15 20 15H18V20H13V18C13 16.8954 12.1046 16 11 16C9.89543 16 9 16.8954 9 18V20H4V15H6C7.10457 15 8 14.1046 8 13C8 11.8954 7.10457 11 6 11H4V6H9V4Z"
        stroke={color}
        stroke-width="1.5"
      />
    </svg>
  );
};

export default ActivitiesIcon;
