import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces";

const BiosphereSavannah = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_sabana_op.glb"
  ) as DreiGLTF;
  return (
    <group {...props} dispose={null}>
      <group
        position={[6.767, 0.651, 32.624]}
        rotation={[-Math.PI, 0.234, -Math.PI]}
        scale={0.177}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_sabana_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_sabana_2.geometry}
          material={materials.gris_puertas}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_sabana_3.geometry}
          material={materials.blanco_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_sabana_4.geometry}
          material={materials.amarillo_sabana_puertas}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_sabana_5.geometry}
          material={materials.amarillo_sabana_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_sabana_6.geometry}
          material={materials.amarillo_sabana_emision}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.base_sabana_7.geometry}
          material={materials["LOWPOLY-COLORS.004"]}
        />
      </group>
      <group position={[6.718, 1.796, 32.832]} scale={0.177}>
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_sabana_1.geometry}
          material={materials.amarillo_sabana_metal}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.chapa_sabana_2.geometry}
          material={materials.logo_bio_sabana}
        />
      </group>
      <group
        position={[6.717, 1.226, 32.832]}
        rotation={[-Math.PI, 0.234, -Math.PI]}
        scale={0.177}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_sabana_1.geometry}
          material={materials.blanco_base}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.cupula_sabana_2.geometry}
          material={materials.amarillo_sabana_cristales}
        />
      </group>
      <group
        position={[7.959, -0.054, 27.673]}
        rotation={[0, -0.234, 0]}
        scale={[0.138, 0.537, 5.467]}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_sabana_1.geometry}
          material={materials.camino_bordes}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={nodes.camino_bio_sabana_2.geometry}
          material={materials.amarillo_sabana_metal}
        />
      </group>
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/T2/Bioesferas/bio_sabana_op.glb"
);

export default BiosphereSavannah;
