import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../../interfaces";

const ModelSavannah = ({ ...props }) => {
  const { nodes, materials } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/local_sabana.glb"
  ) as DreiGLTF;
  return (
    <group {...props} dispose={null}>
      <group position={[-10.38, 0.381, 0.382]} scale={15.214}>
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.local_sabana.geometry}
          material={materials.amarillo_sabana}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.local_sabana_1.geometry}
          material={materials.tierra_tunel}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.local_sabana_2.geometry}
          material={materials.paredes_tunel}
        />
      </group>
      <mesh
        // castShadow
        receiveShadow
        geometry={nodes.Plano_sabana.geometry}
        material={materials.amarillo_sabana}
        position={[0, 0.367, 0]}
        scale={54.636}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.River_sabana.geometry}
        material={materials.rio}
        position={[-6.692, 0.174, 0.382]}
        scale={[5.457, 3.663, 15.294]}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Savannah/local_sabana.glb"
);

export default ModelSavannah;
