import { useGLTF } from "@react-three/drei";
import { DreiGLTF } from "../../../../../interfaces";

const DesertMainland = ({ ...props }) => {
  const cactusBasic = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/cactus_basic_desierto.gltf"
  ) as DreiGLTF;
  const cactusBig = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/cactus_big_desierto.gltf"
  ) as DreiGLTF;
  const cactusMedium = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/cactus_medium_desierto.gltf"
  ) as DreiGLTF;
  const egyptGate = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/egypt_gate_desierto.gltf"
  ) as DreiGLTF;
  const palmSmall = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/palm_small_desierto.gltf"
  ) as DreiGLTF;
  const pyramidOld = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/pyramid_old_desierto.gltf"
  ) as DreiGLTF;
  const stoneFlat = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/stone_flat_desierto.gltf"
  ) as DreiGLTF;
  const treeOld = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/tree_old_desierto.gltf"
  ) as DreiGLTF;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedMaterial1}
        position={[13.09, 0.24, 3.51]}
        rotation={[0, -0.51, 0]}
        scale={0.73}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={pyramidOld.nodes["pyramid-old001"].geometry}
        material={props.bakedMaterial1}
        position={[5.61, 0.67, 8.31]}
        rotation={[-Math.PI, 1.49, -Math.PI]}
        scale={[0.05, 0.06, 0.05]}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small001"].geometry}
        material={props.bakedMaterial1}
        position={[13.28, 0.22, 6.54]}
        rotation={[0, 1.3, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small001"].geometry}
        material={props.bakedMaterial1}
        position={[7.04, 0.4, 5.1]}
        rotation={[Math.PI, -0.75, Math.PI]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small001"].geometry}
        material={props.bakedMaterial1}
        position={[6.48, 0.45, 6.92]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={egyptGate.nodes["egypt-gate001"].geometry}
        material={props.bakedMaterial1}
        position={[3.85, 0.72, 5.86]}
        rotation={[0, 1.37, 0]}
        scale={0.09}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusMedium.nodes["cactus-medium001"].geometry}
        material={props.bakedMaterial1}
        position={[7.2, 0.26, 7.57]}
        rotation={[0, 0.55, 0]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusMedium.nodes["cactus-medium001"].geometry}
        material={props.bakedMaterial1}
        position={[7.58, 0.18, 3.5]}
        rotation={[0, 0.01, 0]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big001"].geometry}
        material={props.bakedMaterial1}
        position={[11, 0, 4.19]}
        rotation={[0, -0.37, 0]}
        scale={0.44}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big001"].geometry}
        material={props.bakedMaterial1}
        position={[10.29, 0.37, 4.36]}
        rotation={[0, 0.99, 0]}
        scale={0.44}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big001"].geometry}
        material={props.bakedMaterial1}
        position={[6.42, 0.14, 2.91]}
        rotation={[0, 0.67, 0]}
        scale={0.44}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedMaterial1}
        position={[11.5, 0.06, 3.24]}
        scale={0.36}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedMaterial1}
        position={[8.07, 0.39, 5.05]}
        rotation={[0, -1.3, 0]}
        scale={0.36}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={stoneFlat.nodes["stone-flat001"].geometry}
        material={props.bakedMaterial1}
        position={[8.75, 0.24, 4.08]}
        rotation={[-Math.PI, 1.03, -Math.PI]}
        scale={0.41}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[10.58, 0.03, 2.61]}
        rotation={[-Math.PI, 1.14, -Math.PI]}
        scale={0.39}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[8.94, -0.12, 2.92]}
        rotation={[0, 1.36, 0]}
        scale={0.39}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[4.33, -0.67, 3.08]}
        rotation={[-Math.PI, 1.05, -Math.PI]}
        scale={0.39}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[3.73, -0.1, 3.61]}
        rotation={[-Math.PI, 0.75, -Math.PI]}
        scale={0.39}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[4.17, 0.03, 4.36]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[8.1, -0.43, 3.69]}
        rotation={[-Math.PI, 0.94, -Math.PI]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small001"].geometry}
        material={props.bakedMaterial1}
        position={[14.68, -0.17, 2.63]}
        rotation={[0, -1.12, 0]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={palmSmall.nodes["palm-small001"].geometry}
        material={props.bakedMaterial1}
        position={[3.6, 0.22, 7.98]}
        scale={0.24}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedMaterial1}
        position={[13.44, 0.4, 5.02]}
        rotation={[0, 0.67, 0]}
        scale={0.36}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedMaterial1}
        position={[5.47, 0.52, 6.42]}
        scale={0.36}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusMedium.nodes["cactus-medium001"].geometry}
        material={props.bakedMaterial1}
        position={[6.72, 0.25, 3.56]}
        rotation={[0, 1, 0]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusMedium.nodes["cactus-medium001"].geometry}
        material={props.bakedMaterial1}
        position={[14.22, 0.01, 3.77]}
        rotation={[0, 1, 0]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedMaterial1}
        position={[10.56, 0.39, 6.41]}
        rotation={[0, -1.39, 0]}
        scale={0.36}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedMaterial1}
        position={[6.99, 0.39, 6.93]}
        rotation={[Math.PI, -0.83, Math.PI]}
        scale={0.36}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[10.31, -0.28, 7.22]}
        rotation={[-Math.PI, 0.41, -Math.PI]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[14.17, -0.21, 4.78]}
        rotation={[0, -0.36, 0]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusMedium.nodes["cactus-medium001"].geometry}
        material={props.bakedMaterial1}
        position={[7.84, -0.05, 9.16]}
        rotation={[0, 0.01, 0]}
        scale={0.31}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big001"].geometry}
        material={props.bakedMaterial1}
        position={[9.78, -0.2, 8.21]}
        rotation={[0, 0.99, 0]}
        scale={0.44}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedMaterial1}
        position={[9.25, -0.03, 9.61]}
        rotation={[Math.PI, -0.38, Math.PI]}
        scale={0.36}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedMaterial1}
        position={[11.79, 0.15, 7.74]}
        rotation={[0, -1.39, 0]}
        scale={0.36}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBig.nodes["cactus-big001"].geometry}
        material={props.bakedMaterial1}
        position={[12.86, -0.23, 8.26]}
        rotation={[-Math.PI, 0.68, -Math.PI]}
        scale={0.44}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={cactusBasic.nodes["cactus-basic001"].geometry}
        material={props.bakedMaterial1}
        position={[14.51, 0.16, 5.93]}
        scale={0.36}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[-18.06, -0.09, -18.16]}
        scale={0.32}
      />

      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[12.92, 0.28, 4.57]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[12.21, 0.12, 4.14]}
        rotation={[-Math.PI, 0.38, -Math.PI]}
        scale={0.39}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[10.23, 0.11, 6.55]}
        scale={0.27}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={treeOld.nodes["tree-old001"].geometry}
        material={props.bakedMaterial1}
        position={[6.88, 0.39, 4.44]}
        scale={0.27}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/cactus_basic_desierto.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/cactus_big_desierto.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/cactus_medium_desierto.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/egypt_gate_desierto.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/palm_small_desierto.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/pyramid_old_desierto.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/stone_flat_desierto.gltf"
);
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/Assets+globales/Principal_desierto/tree_old_desierto.gltf"
);

export default DesertMainland;
