import "./Campus.css";
import { useState, useEffect, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import closeButton from "../../../assets/student/close_button.svg";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import Time from "../../../assets/student/time.tsx";
import Calendar from "../../../assets/student/calendar";
import { colors } from "../../../constants";
import Loading from "../../loading_screen/LoadingDots.tsx";
import { motion } from "framer-motion";

import Cookies from "universal-cookie";
import { IscreenSize } from "../../../interfaces.ts";
import { campusMenu, menu, points } from "../../../reducers/studentSlice.ts";
import { GetStudentBundlesAPI } from "../../../_newapios/content/bundle.ts";
import { ModifiedBundleType } from "../../../_newapios/user/class.ts";
import {
  ExperimentProgressType,
  GetMyStudentProgressAPI,
  GetMyStudentScenarioProgressAPI,
  ResearchProgressType,
  ScenarioProgressType,
} from "../../../_newapios/progress/student_scenario.ts";
import {
  ExerciseType,
  ModifiedUnitType,
  UnitType,
  ScenarioType,
} from "../../../_newapios/content/unit.ts";

import ScenarioMenu from "./ScenarioMenu.tsx";
import {
  getExerciseProgressPoints,
  getUnitProgressPoints,
} from "../../../utils/progress.ts";
import {
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuGroup,
  TagRightIcon,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { ChevronDownIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { CourseType } from "../../../_newapios/content/course.ts";
import { ModifiedScenarioType } from "../../../_newapios/content/scenario.ts";

export interface Isubjects {
  topics: any[];
  on_time_array: any[];
  datesArray: any[];
  lang: any[];
  images: string[][];
  points: number[];
}

const Campus = ({ ...props }) => {
  const { t, i18n } = useTranslation(["common", "evaluation"]);

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const dispatch = useAppDispatch();
  const cookies = new Cookies();

  const mainState = useAppSelector((state) => state.main);
  const studentState = useAppSelector((state) => state.student);

  //const user = useAppSelector(state => state.login).user

  const [selectedSubject, setSelectedSubject] = useState<CourseType | null>(
    null
  );
  const [selectedScenario, setSelectedScenario] =
    useState<ModifiedScenarioType | null>(null);

  const [bundles, setBundles] = useState<ModifiedBundleType[]>([]);
  const [filteredCourses, setFilteredCourses] = useState<CourseType[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const [scenarioProgresses, setScenarioProgresses] = useState<
    ScenarioProgressType[]
  >([]);

  const [loadingCourse, setLoadingCourse] = useState(false);

  const sortingOptions = [
    "name",
    "points",
    "due_date",
    "subject",
    "start_date",
  ];

  const [selectedSorting, setSelectedSorting] = useState("due_date");

  useEffect(() => {
    loadProgress();
  }, []);

  useEffect(() => {
    loadSubjects();
  }, [scenarioProgresses]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const loadProgress = async () => {
    const studentProgress = await GetMyStudentProgressAPI("me");
    setScenarioProgresses(studentProgress);
  };
  // Get progress of single scenario for percentage done
  const loadScenarioProgress = async (scenarioId: string) => {
    const scenarioIndex = scenarioProgresses.findIndex(
      (scenario) => scenario.id === scenarioId
    );
    if (scenarioIndex === -1) return loadProgress();
    const scenarioProgress = await GetMyStudentScenarioProgressAPI(
      "me",
      scenarioId
    );
    const newScenarioProgresses = [...scenarioProgresses];
    newScenarioProgresses[scenarioIndex] = scenarioProgress;
    setScenarioProgresses(newScenarioProgresses);
  };

  const loadSubjects = async () => {
    //setSubjects with all the subjects of the class from the db
    //setSelectedSubjectHandler(0)
    const getCourseFoundforStudents = async () => {
      var foundCourses = mainState.courses;
      var tempBundles = await GetStudentBundlesAPI("me");

      var tempFilteredCourses = foundCourses.filter((course) => {
        return tempBundles.find(
          (bundle) => bundle.scenario?.course_id === course.id
        );
      });

      setFilteredCourses(tempFilteredCourses);

      setBundles(tempBundles);
      setLoadingCourse(false);
    };
    getCourseFoundforStudents();
  };

  const closeButtonHandler = () => {
    dispatch(menu("globalMap"));
  };

  const openScenarioMenu = async (situation: ModifiedScenarioType) => {
    setSelectedScenario(situation);
  };

  const subjectHandler = (value: CourseType | null) => {
    setSelectedSubject(value);
  };

  const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const sortingHandler = (value: string) => {
    setSelectedSorting(value);
  };

  const loadScenarioResearchProgress = (
    scenario_id: string
  ): ResearchProgressType | null => {
    const scenarioProgress = scenarioProgresses.find(
      (prog) => prog.scenario_id === scenario_id
    );
    const researchProgress = scenarioProgress?.research_progress;
    console.log(researchProgress);

    if (researchProgress === undefined) return null;

    return researchProgress;
  };

  const loadScenarioExperimentProgress = (
    scenario_id: string
  ): ExperimentProgressType | null => {
    const scenarioProgress = scenarioProgresses.find(
      (prog) => prog.scenario_id === scenario_id
    );
    const experimentProgresses = scenarioProgress?.experiment_progress;
    if (experimentProgresses === undefined) return null;
    const experimentProgress = _.maxBy(
      experimentProgresses.filter(
        (prog) => prog.priority_index === experimentProgresses[0].priority_index
      ),
      (prog) => prog.completed_on
    );
    if (experimentProgress === undefined) return null;
    return experimentProgress;
  };

  const filterBundles = (bundles: ModifiedBundleType[]) => {
    var filteredBundles;

    if (selectedSubject === null) {
      filteredBundles = bundles;
    } else {
      filteredBundles = bundles.filter((bundle) => {
        return bundle.scenario?.course_id === selectedSubject.id;
      });
    }

    if (searchValue !== "") {
      filteredBundles = filteredBundles.filter((bundle) => {
        return bundle.scenario?.name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    }

    return filteredBundles;
  };

  const sortBundles = (bundles: ModifiedBundleType[]) => {
    return bundles
      .sort((a, b) => {
        if (a.scenario === undefined || b.scenario === undefined) return 0;
        switch (selectedSorting) {
          case "name":
            return a.scenario.name.localeCompare(b.scenario.name);
          case "points":
            const scenarioProgressA = scenarioProgresses.find(
              (scenarioProgress) =>
                scenarioProgress.scenario_id === a.scenario_id
            );
            const scenarioProgressB = scenarioProgresses.find(
              (scenarioProgress) =>
                scenarioProgress.scenario_id === b.scenario_id
            );
            const scenarioPointsA =
              scenarioProgressA?.experiment_progress[0] !== undefined
                ? getExerciseProgressPoints(
                  scenarioProgressA?.experiment_progress[0].exercise_progress
                )
                : 0;
            const scenarioPointsB =
              scenarioProgressB?.experiment_progress[0] !== undefined
                ? getExerciseProgressPoints(
                  scenarioProgressB?.experiment_progress[0].exercise_progress
                )
                : 0;
            return scenarioPointsA - scenarioPointsB;
          case "due_date":
            return (
              new Date(a.end_date).getTime() - new Date(b.end_date).getTime()
            );
          case "subject":
            return (
              filteredCourses.find(
                (course) => course.id === a.scenario?.course_id
              )?.acronym || ""
            ).localeCompare(
              filteredCourses.find(
                (course) => course.id === b.scenario?.course_id
              )?.acronym || ""
            );
          case "start_date":
            return (
              new Date(b.start_date).getTime() -
              new Date(a.start_date).getTime()
            );

          default:
            return 0;
        }
      })
      .sort((a, b) => {
        const scenarioProgressA = scenarioProgresses.find(
          (scenarioProgress) => scenarioProgress.scenario_id === a.scenario_id
        );
        const scenarioProgressB = scenarioProgresses.find(
          (scenarioProgress) => scenarioProgress.scenario_id === b.scenario_id
        );
        const scenarioPointsA =
          scenarioProgressA?.experiment_progress[0] !== undefined
            ? getExerciseProgressPoints(
              scenarioProgressA?.experiment_progress[0].exercise_progress
            )
            : 0;
        const scenarioPointsB =
          scenarioProgressB?.experiment_progress[0] !== undefined
            ? getExerciseProgressPoints(
              scenarioProgressB?.experiment_progress[0].exercise_progress
            )
            : 0;
        if (scenarioPointsA === 4) {
          return 1;
        }
        if (scenarioPointsB === 4) {
          return -1;
        }
        return 0;
      });
  };

  const renderCloseButton = () => {
    return (
      <motion.div
        className="campus_close_button"

        whileHover={{ scale: 1.2, rotate: 180 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        onClick={() => closeButtonHandler()}
        style={{
          borderRadius: "50%",
          backgroundColor: "white",
          boxShadow: props.part === "close_campus" ? "0px 0px 10px 6px #fbc412" : "none",
        }}
      >
        <div className="center_container">
          <img className="image" style={{
            borderRadius: "50%"
          }} src={closeButton} alt="close" />
        </div>
      </motion.div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="campus_title">
        <div className="left_container">
          <div className="text_white_very_big_bold">{t("campus")}</div>
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className="campus_filters">
        <div className="campus_filters_filter">
          {renderSearchFilter()}
          {renderSubjectsFilter()}
        </div>
        <div className="campus_filters_sorting">{renderSorting()}</div>
      </div>
    );
  };

  const renderSearchFilter = () => {
    return (
      <InputGroup
        borderRadius={"3px"}
        w={"20vw"}
        minW={"15vw"}
        ml={"2.5%"}
        color={"white"}
      >
        <Input
          placeholder={t("search_placeholder")}
          size="md"
          style={{
            fontFamily: "Causten",
            fontSize: "calc(5px + 1vw)",
            fontWeight: "bold",
          }}
          as={motion.input}
          variant="outline"
          value={searchValue}
          onChange={searchHandler}
          whileHover={{
            scale: 1.01,
            boxShadow: "0px 0px 10px 1px #ffffff",
          }}
          _focusVisible={{
            boxShadow: "0px 0px 10px 1px #ffffff",
            borderColor: "white",
          }}
          transition="0.1s ease"
          _placeholder={{ color: "white" }}
        />
        <InputRightElement onClick={() => setSearchValue("")}>
          <SmallCloseIcon color="white" />
        </InputRightElement>
      </InputGroup>
    );
  };

  const renderSubjectsFilter = () => {
    return (
      <Menu variant="outline">
        <MenuButton
          // variant="outline"
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            display: "flex",
            flexDirection: "column",
            borderRadius: "50px",
            boxSizing: "border-box",
            paddingTop: "0.1em",
            paddingBottom: "0.1em",
            paddingLeft: "1em",
            paddingRight: "1em",
            marginRight: "2.5%",
            backgroundColor: selectedSubject
              ? selectedSubject.color
              : undefined,
            boxShadow: selectedSubject ? undefined : "inset 0 0 0 2px #ffffff",
          }}
          as={motion.div}
          whileHover={{
            scale: 1.05,
          }}
        >
          <div className="center_container">
            <div className="text_white_small_bold">
              {_.isUndefined(selectedSubject) || _.isNull(selectedSubject)
                ? t("subject")
                : t(selectedSubject.acronym + "_name", { ns: "evaluation" })}
            </div>

            <TagRightIcon
              boxSize="3vh"
              as={ChevronDownIcon}
              style={{ color: "white" }}
            />
          </div>
        </MenuButton>
        <MenuList style={{ maxHeight: "40vh", overflowY: "scroll" }}>
          <MenuItem
            style={{
              fontFamily: "Causten",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            onClick={() => subjectHandler(null)}
          >
            {t("all_subjects")}
          </MenuItem>
          {filteredCourses.map((course) => {
            return (
              <MenuItem
                key={course.id}
                style={{
                  fontFamily: "Causten",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => subjectHandler(course)}
              >
                {t(course.acronym + "_name", { ns: "evaluation" })}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  };

  const renderSorting = () => {
    return (
      <Menu variant="ghost">
        <MenuButton
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            display: "flex",
            flexDirection: "column",
            borderRadius: "50px",
            boxSizing: "border-box",
            paddingTop: "0.1em",
            paddingBottom: "0.1em",
            paddingLeft: "1em",
            paddingRight: "1em",
            marginRight: "2.5%",
          }}
        >
          <div className="center_container">
            <div className="text_white_small" style={{ marginRight: "0.3em" }}>
              {t("sort_by")}
            </div>
            <div className="text_white_small_bold">{t(selectedSorting)}</div>

            <TagRightIcon
              boxSize="3vh"
              as={ChevronDownIcon}
              style={{ color: "white" }}
            />
          </div>
        </MenuButton>
        <MenuList style={{ maxHeight: "40vh", overflowY: "scroll" }}>
          {sortingOptions.map((option) => {
            return (
              <MenuItem
                key={option}
                style={{
                  fontFamily: "Causten",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => sortingHandler(option)}
              >
                {t(option)}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  };

  const renderBody = () => {
    if (loadingCourse) {
      return <Loading />;
    }

    const MAIN_MENU_OPEN = selectedScenario === null;
    if (MAIN_MENU_OPEN) {
      return !_.isEmpty(bundles) ? renderMain() : renderEmpty();
    }

    const SCENARIO_OPEN = selectedScenario !== null;

    if (SCENARIO_OPEN) {
      const bundle = bundles?.find(
        (bundle) => bundle.scenario_id === selectedScenario?.id
      );
      const researchProgress = loadScenarioResearchProgress(
        selectedScenario.id
      );
      const experimentProgress = loadScenarioExperimentProgress(
        selectedScenario.id
      );
      if (bundle === undefined) return;
      const scenario = bundle.scenario;
      if (scenario === undefined) return;
      return (
        selectedScenario !== null &&
        !loadingCourse && (
          <ScenarioMenu
            bundle={bundle}
            scenario_id={selectedScenario.id}
            researchProgress={researchProgress}
            experimentProgress={experimentProgress}
            color={scenario.course.color}
            updateProgress={() => loadScenarioProgress(selectedScenario.id)}
            closeSituationMenu={() => {
              loadScenarioProgress(selectedScenario.id);
              setSelectedScenario(null);
              setSelectedScenario(null);
            }}
          />
        )
      );
    }
  };

  const renderEmpty = () => {
    return (
      <div className="campus_subject_scenarios_loading">
        <div className=" center_container">
          <div className="text_white_small">Nothing to see here...</div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="campus_main">
        {bundles !== undefined &&
          sortBundles(filterBundles(bundles)).map((bundle) => {
            // JANK - It do be like that sometimes
            return <div>{renderScenario(bundle)}</div>;
          })}
      </div>
    );
  };

  const renderScenario = (bundle: ModifiedBundleType) => {
    const scenario = bundle.scenario;
    if (scenario === undefined) return;
    let tutorialBan = false;
    if (studentState.tutorialStep !== 0) {
      if (
        scenario.main_know_how !== "Tutorial ESP" &&
        scenario.main_know_how !== "Tutorial ENG"
      ) {
        tutorialBan = true;
      }
    }
    const banner = scenario.banner.url;
    const color = scenario.course.color;
    const courseName = t(scenario.course.acronym + "_short", {
      ns: "evaluation",
    });
    const scenarioProgress = scenarioProgresses.find(
      (scenarioProgress) => scenarioProgress.scenario_id === bundle.scenario_id
    );
    const points =
      scenarioProgress?.experiment_progress[0] !== undefined
        ? getExerciseProgressPoints(
          scenarioProgress?.experiment_progress[0].exercise_progress
        )
        : 0;

    return (
      <motion.div
        className="campus_main_scenario"
        whileHover={
          tutorialBan
            ? {}
            : {
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px " + color,
            }
        }
        style={{
          filter: tutorialBan ? "brightness(0.5)" : "brightness(1)",
          boxShadow: tutorialBan ? "" : (studentState.tutorialStep !== 0 ? "0px 0px 15px 2px #fbc412" : ""),
        }}
        transition={
          tutorialBan ? {} : { type: "spring", stiffness: 400, damping: 10 }
        }
        onClick={
          tutorialBan
            ? () => { }
            : () => {
              openScenarioMenu(scenario);
            }
        }
      >
        <div className="campus_main_scenario_banner">
          <img
            src={banner}
            alt="image"
            style={{
              borderRadius: "5px 5px 0px 0px",
              objectFit: "cover",
              height: "100%",
              width: "100%",
            }}
          />
        </div>
        <div className="campus_main_scenario_mid">
          <div className="left_container">
            <div className="campus_main_scenario_mid_left">
              <div className="left_container">
                <div className="text_black_small_bold">
                  {scenario.main_know_how}
                </div>
              </div>
              <div className="campus_main_scenario_mid_left_bottom">
                <div className="left_container">
                  <div
                    className="text_black_very_small"
                    style={{ color: "#676767" }}
                  >
                    {scenario.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right_container">
            <div className="campus_main_scenario_mid_right">
              <div className="center_container">
                {/* HAHAHAHAHAHHAAH, school 22 be like -____- */}
                {
                  /* {user.school_id !== 22*/ true && (
                    <div
                      className="course_tag_background"
                      style={{
                        backgroundColor: color,
                      }}
                    >
                      {" "}
                      {/* This is hardcoded af */}
                      <div className="center_container">
                        <div className="text_white_very_small_bold">
                          {courseName}
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className="campus_main_scenario_bot">
          <div
            className="campus_main_scenario_bot_images"
            style={{
              gridTemplateColumns: "repeat(" + 3 + ", 1fr)",
            }}
          >
            <div className="campus_main_scenario_bot_images_image center_container">
              <img
                src={scenario.image.url}
                alt="image"
                style={{
                  borderRadius: "5px",
                  objectFit: "cover",
                  maxWidth: "100%",
                }}
              />
            </div>{" "}
            {/*Sometimes I hate Typescript*/}
            {/* Only sometimes? */}
            {/* {scenario.scenarios.map((scenario) => {
              if (
                scenario.image.url === null ||
                scenario.image.url === undefined ||
                scenario.image.url === ""
              )
                return <div />;
              return (
                <div className="campus_main_scenario_bot_images_image center_container">
                  <img
                    src={scenario.image.url}
                    alt="image"
                    style={{
                      borderRadius: "5px",
                      objectFit: "cover",
                      maxWidth: "100%",
                    }}
                  />
                </div>
              );
            })} */}
          </div>
          <div className="campus_main_scenario_bot_points">
            <div className="center_container">
              <div className="text_black_very_small">
                <b
                  style={{
                    fontSize: "2em",
                    color: color,
                  }}
                >
                  {points}
                </b>
                <b
                  style={{
                    fontSize: "1.5em",
                    fontWeight: "normal",
                    color: color,
                  }}
                >
                  {" / " + 4}
                </b>
                {" " + t("points")}
              </div>
            </div>
          </div>
          <div className="campus_main_scenario_bot_date">
            <div className="center_container image" style={{ width: "90%" }}>
              <Time color={color} />
            </div>
            <div className="center_container">
              <div className="text_black_very_small">
                {t("due_date") + ": "}
                <b>
                  {new Date(bundle.end_date).toLocaleDateString(i18n.language, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </b>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="campus">
      {(studentState.campusMenu === "campus" || true) && (
        <div className="campus_menu">
          <div className="campus_banner_container">
            <img
              className="campus_banner"
              src={
                "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/banner_lab.svg"
              }
              alt="banner"
            />
          </div>
          <div className="campus_background" />
          {renderCloseButton()}
          {renderTitle()}
          {selectedScenario === null && renderFilters()}
          <div
            className="campus_subjects"
            style={
              selectedScenario === null
                ? { height: "73.5%", top: "26.5%" }
                : { height: "80%", top: "20%" }
            }
          >
            {renderBody()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Campus;
